import { ImageFile } from 'src/components/data-entry/ImageUpload/ImageUpload';

export interface PatientInsurance {
  id?: string;
  patientId: string;
  relationshipToInsured: RelationshipToEnsured;
  registeredPartnerForTreatment?: boolean;
  insuranceType: InsuranceType;
  insuranceId: string;
  insurancePlanName?: string;
  insurancePolicyGroupOrFecaNumber?: string;
  employerName?: string;
  insuredName?: string;
  insuredGender?: Gender;
  insuredDob?: Date;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  imageIdCardFrontFile?: ImageFile[];
  imageIdCardBackFile?: ImageFile[];
  imageIdCardFront?: string;
  imageIdCardBack?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female'
}

export enum RelationshipToEnsured {
  SELF = 'Self',
  SPOUSE = 'Spouse',
  OTHER = 'Other'
}

export enum InsuranceType {
  MEDICARE = 'Medicare',
  MEDICAID = 'Medicaid',
  TRICARE = 'TRICARE',
  CHAMPVA = 'CHAMPVA',
  GROUP_HEALTH_PLAN = 'Group Health Plan',
  FECA = 'FECA',
  BLACK_LUNG = 'Black Lung',
  OTHER = 'Other'
}
