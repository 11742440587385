import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { radii, spacings } from 'src/components/styles/constants';
import Flex from 'src/components/layout/Flex';
import Typography from 'src/components/display/Typography';
import { Colors } from 'src/components/styles/colors';
import { weights } from 'src/components/styles/fonts';
import Avatar from 'src/components/display/Avatar/Avatar';
import Chip from 'src/components/data-entry/Chips/Chip';
import { Box, styled } from '@mui/material';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  useDialog,
  useDocumentEditor
} from 'src/components/components-api/GlobalProvider/GlobalProvider';
import AddEditAppointment from '../../calendar/AddEditAppointment';
import usePatientsApi from '../../../hooks/usePatientsApi';
import Loader from 'src/components/display/Loader/Loader';
import { getFullName } from 'src/utils/general';
import dayjs from 'dayjs';
import {
  Appointment,
  AppointmentPurposes,
  InCycleAppointmentTypes
} from 'src/types/appointment';
import { getDateFormat, getTimeFormat } from 'src/utils/dateAndTIme';
import useAppointments from 'src/hooks/useAppointments';
import useStaffMembers from 'src/hooks/useStaffMembers';
import Card from 'src/components/display/Card';
import { orderTemplateId } from 'src/types/documents';
import useDocumentsApi from 'src/hooks/useDocumentsApi';
import { getDocumentSignatureStatus } from '../../documents/utils/editorUtils/getDocumentSignatureStatus';
import { UserTypes } from 'src/types/user';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';
import PatientAvatar from 'src/components/display/Avatar/PatientAvatar';

type OrderDocumentPreviewProps = {
  patientId: string;
  labOrderExternalId?: string;
};

const StyledDocumentStatusChip = styled(Chip)<{
  statusColor?: Colors;
}>`
  font-weight: ${weights.extraBold};
  background: ${({ statusColor }) => statusColor || Colors.alto2};
`;

const OrderDocumentPreview: FC<OrderDocumentPreviewProps> = ({
  patientId,
  labOrderExternalId
}) => {
  const { t } = useTranslation();

  const { getDocumentByExternalId } = useDocumentsApi();

  const { setDocumentEditorParams, setDocumentsModalOpen } =
    useDocumentEditor();

  const { data: ordersDocument, isLoading: isLoadingOrdersDocument } =
    getDocumentByExternalId(patientId, labOrderExternalId, {
      enabled: !!labOrderExternalId && !!patientId
    });

  const { color, status } =
    getDocumentSignatureStatus({
      isDocumentCompleted: ordersDocument?.isCompleted,
      isInitialVendorEmailSent: ordersDocument?.isInitialVendorEmailSent
    }) || {};

  if (!labOrderExternalId) {
    return (
      <Typography fontWeight={weights.bold} variant="h3" color="black">
        {t('THERE_IS_NO_ORDERS_DOCUMENT_TO_DISPLAY')}
      </Typography>
    );
  }

  if (isLoadingOrdersDocument) return <Loader />;
  return (
    <DocumentSummaryDisplay
      onClickEdit={() => {
        setDocumentEditorParams({
          patientId,
          templateId: orderTemplateId,
          documentId: ordersDocument?.id
        });
        setDocumentsModalOpen(true);
      }}
      status={status}
      statusColor={color}
      title={`${t('ORDER').toUpperCase()} #${labOrderExternalId}`}
    />
  );
};

export const DocumentSummaryDisplay: FC<{
  status?: string;
  statusColor?: Colors;
  onClickEdit: () => void;
  title: string;
}> = ({ status, statusColor, onClickEdit, title }) => {
  const { t } = useTranslation();

  return (
    <Card
      padding={spacings.large}
      shadow
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flex={1}
      borderRadius={radii.medium}
    >
      <Flex alignItems="center" gap={spacings.large}>
        <MiniIconButton icon={<EditIcon />} onClick={onClickEdit} />
        <Typography fontWeight={weights.bold} variant="h3">
          {title}
        </Typography>
      </Flex>

      <StyledDocumentStatusChip
        statusColor={statusColor}
        label={t(status).toUpperCase()}
      />
    </Card>
  );
};
const DetailsBox = ({
  title,
  value,
  textAlign = 'start',
  valueElementId
}: {
  title: string;
  value: any;
  textAlign?: 'center' | 'start' | 'end';
  valueElementId?: string;
}) => {
  const { t } = useTranslation();
  const valueTypographyProps: { id?: string } = {};

  if (valueElementId) {
    valueTypographyProps.id = valueElementId;
  }

  return (
    <Flex flexDirection="column">
      <Typography
        variant="body1"
        fontWeight={weights.extraBold}
        color={Colors.emperor}
      >
        {title}
      </Typography>
      <Typography
        {...valueTypographyProps}
        variant="body1"
        color={Colors.emperor}
        textAlign={textAlign}
      >
        {value || t('NO_DATA')}
      </Typography>
    </Flex>
  );
};

interface AppointmentDetailsProps {
  appointmentId?: string;
  appointment?: Appointment;
}

export const AppointmentDetails: FC<AppointmentDetailsProps> = ({
  appointmentId,
  appointment: appointmentProp
}) => {
  const currentAppointmentId = appointmentId || appointmentProp?.id;
  const { t } = useTranslation();
  const { getDefaultAvatar } = useGetDefaultAvatar();
  const { openDialog, closeDialog } = useDialog();

  const { getPatientById } = usePatientsApi();
  const { getStaffMembersNames } = useStaffMembers();

  const { getAppointmentById, deleteAppointment } = useAppointments();
  const { data: appointment, isLoading: isLoadingAppointment } =
    getAppointmentById(currentAppointmentId, {
      enabled: !!currentAppointmentId
    });

  const appointmentToDisplay = useMemo<Appointment>(
    () => appointment || appointmentProp,
    [appointment]
  );

  const { data: staffMembers, isLoading: isLoadingStaffMembers } =
    getStaffMembersNames();

  const { data: patient, isLoading: isLoadingPatient } = getPatientById(
    appointmentToDisplay?.patientId
  );

  const { mutate: deleteAppointmentMutate, isLoading: isDeletingAppointment } =
    deleteAppointment();

  const isLoading =
    isLoadingAppointment ||
    isLoadingStaffMembers ||
    isDeletingAppointment ||
    isLoadingPatient;

  if (isLoading)
    return (
      <Flex justifyContent={'center'} alignItems={'center'} width="100%">
        <Loader />
      </Flex>
    );
  const {
    appointmentType,
    date,
    endTime,
    location,
    staffIds,
    patientNotes,
    labOrders,
    isVirtual,
    labOrderInfo,
    patientId
  } = appointmentToDisplay || {
    appointmentType: InCycleAppointmentTypes.EGG_RETRIEVAL,
    appointmentPurpose: AppointmentPurposes.IN_CYCLE,
    patient: {
      personalInfo: {
        firstName: 'Jane',
        lastName: 'Doe'
      }
    }
  };

  const personalInfo = patient?.personalInfo;

  const physicians =
    staffMembers?.filter(({ id }) => staffIds?.includes(id)) || [];

  const patientFullName = getFullName(personalInfo);

  const appointmentTime = `${dayjs(date).format(
    getTimeFormat({ isShort: true })
  )} - ${dayjs(endTime).format(getTimeFormat({ isShort: true }))}`;

  const avatar = getDefaultAvatar({
    userId: patientId,
    userType: UserTypes.patients,
    pictureId: patient?.personalInfo?.pictureId
  });

  return (
    <Flex flexDirection="column" gap={spacings.xlarge} minWidth={576}>
      <Flex gap={spacings.medium}>
        <Flex flexGrow={1} flexDirection="column" gap={spacings.small}>
          <Flex>
            <Flex flexDirection="row" alignItems="center" gap={spacings.large}>
              <PatientAvatar image={avatar} patientId={patientId} />
              <Typography
                id="appointment-details-patient-name"
                variant="h1"
                color={Colors.emperor}
              >
                {`${patientFullName || t('PATIENT_PLACEHOLDER')}`}
              </Typography>
            </Flex>
          </Flex>
          <Typography
            id="appointment-details-type"
            variant="h2"
            color={Colors.emperor}
          >
            {`${appointmentType || t('APPOINTMENT_TYPE_PLACEHOLDER')}`}
          </Typography>
        </Flex>
        <Box alignSelf="center">
          <MiniIconButton
            id="edit-appointment-button"
            icon={<EditIcon style={{ fill: 'black' }} />}
            onClick={() =>
              openDialog({
                header: t('EDIT_APPOINTMENT'),
                children: (
                  <AddEditAppointment
                    appointmentId={appointmentToDisplay?.id}
                    patientId={appointmentToDisplay?.patientId}
                  />
                ),
                fullWidth: true,
                maxWidth: 'lg'
              })
            }
          />
        </Box>
        <Box alignSelf="center">
          <MiniIconButton
            icon={<DeleteIcon />}
            onClick={async () => {
              await deleteAppointmentMutate({
                appointmentId: appointmentId || appointmentToDisplay?.id,
                patientId: appointmentToDisplay?.patientId
              });
              closeDialog();
            }}
          />
        </Box>
      </Flex>
      <Flex gap={spacings.xxlarge}>
        <DetailsBox
          title={t('DATE_OF_APPOINTMENT')}
          value={dayjs(date).format(getDateFormat())}
          valueElementId="appointment-details-date"
        />
        <DetailsBox
          title={t('TIME_LABEL')}
          valueElementId="appointment-details-time"
          value={appointmentTime}
        />
      </Flex>
      <Flex gap={spacings.xxlarge}>
        <DetailsBox
          title={t('LOCATION_LABEL')}
          value={isVirtual ? t('VIRTUAL') : location}
          valueElementId="appointment-details-location"
        />
      </Flex>
      <Flex gap={spacings.xxlarge} flexDirection="column" flex={0.5}>
        <Typography
          variant="h3"
          fontWeight={weights.extraBold}
          color={Colors.emperor}
        >
          {t('PHYSICIAN_LABEL')}
        </Typography>
        <Flex flexWrap="wrap" gap={spacings.large}>
          {physicians?.map((physician, index) => {
            return (
              <Avatar
                key={index}
                alignAvatar="left"
                title={getFullName(physician)}
                subtitle={physician?.role}
              />
            );
          })}
        </Flex>
      </Flex>

      <Flex gap={spacings.large} flexDirection="column">
        <Typography variant="h2" color={Colors.emperor}>
          {t('NOTES')}
        </Typography>
        <Flex flexDirection="column" gap={spacings.large}>
          <DetailsBox title={t('NOTES_FOR_PATIENT')} value={patientNotes} />
        </Flex>
      </Flex>

      <Flex gap={spacings.large} flexDirection="column">
        <Typography variant="h2" color={Colors.emperor}>
          {t('ORDERS')}
        </Typography>
        <Flex flexWrap="wrap" marginTop={spacings.medium} gap={spacings.small}>
          {labOrders?.map(({ panel, labTest }, index) => {
            if (panel?.name || labTest?.name)
              return (
                <Chip
                  key={`${panel?.name || labTest?.name} - ${index}`}
                  label={t(panel?.name || labTest?.name)}
                />
              );
          })}
        </Flex>

        <Flex marginY={spacings.xlarge}>
          <OrderDocumentPreview
            labOrderExternalId={labOrderInfo?.externalId}
            patientId={appointmentToDisplay?.patientId}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
