import { dashSeparatedDateFormat } from './../utils/dateAndTIme';
import {
  CreateExternalOrderResponse,
  RequestRange
} from './../types/appointment';
import axiosApiInstance from '../utils/axios';
import {
  ArrowDirection,
  PatientKpisResponse,
  PatientStatus,
  Patient,
  EstrodialHistory,
  MedicationTypes,
  PatientOverview,
  DashboardSummary,
  PatientPersonalInfo,
  PatientHistory,
  PatientAllergies,
  Basics,
  PatientIdentificationInfo,
  NextOfKin,
  GeneralPhysician,
  PatientDiagnosisResult
} from 'src/types/patient';
import {
  PatientServer,
  ExamReportServer,
  LabResultServer,
  PatientDiagnosisServer,
  ExaminationServer,
  EggAndEmbryoServer,
  PrescriptionServer,
  ExternalOrderFormServer
} from 'src/types/patient-server';
import dayjs from 'dayjs';
import {
  convertBasicsDetailsClientToServer,
  convertExaminationServerToClient,
  convertLabReportClientToServer,
  convertMedicalFormToPrescriptions,
  convertPatientServerToClient,
  convertCareTeamClientToServer,
  convertPatientAndPartnerInfoWithTzToTimezone,
  convertPrescriptionClientToServer,
  convertPrescriptionServerToClient,
  convertDosageChangeClientToServer,
  getLabResultAsFormData,
  convertLabResultServerToClient
} from '../modules/patients/utils/conversions';

import { LabResultForm } from '../modules/patients/medicalHistory/EditPatientLabResult';
import { getExaminationFormData } from 'src/utils/examination';
import { CreatePatientDocumentPayload } from 'src/types/documents';
import { CareTeamMember, StaffMember } from 'src/types/staff';
import { ChannelTypes, MessageRecent } from 'src/types/inbox';
import { ExamReport, Examination } from 'src/types/exam';
import { LabOrder, LabReport, LabResult, LabTest } from 'src/types/appointment';
import {
  FertilizationRate,
  GameteReport,
  KpiCycleStatus,
  PregnancyRate
} from 'src/types/cycle';
import {
  MedicalProtocolForm,
  Medication,
  MedicationRx,
  Prescription,
  PrescriptionRxForm,
  UpdatePrescriptionDosage
} from 'src/types/prescription';
import { EggAndEmbryo } from 'src/types/eggAndEmbryo';

export async function getPatientsRequest(): Promise<PatientPersonalInfo[]> {
  const response = await axiosApiInstance.get<PatientPersonalInfo[]>(
    '/patients'
  );
  return response.data;
}

export async function getPatientById(patientId: string): Promise<Patient> {
  const response = await axiosApiInstance.get<PatientServer>(
    `/patients/${patientId}`
  );
  const patient = convertPatientServerToClient(response.data);
  return patient;
}

export async function getExaminationsByPatientIdRequest(
  patientId: string
): Promise<Examination[]> {
  const response = await axiosApiInstance.get<ExaminationServer[]>(
    `/examinations/patient/${patientId}/staff`
  );
  return response.data.map((examServer) =>
    convertExaminationServerToClient(examServer)
  );
}

export async function getPatientOverviewRequest(
  patientId: string
): Promise<PatientOverview> {
  const response = await axiosApiInstance.get<PatientOverview>(
    `/patients/${patientId}/overview`
  );
  const { data } = response;
  return {
    ...data,
    partnerInfo: data.partnerInfo
      ? { ...data.partnerInfo, id: `${data?.partnerInfo?.id}` }
      : null
  };
}

export async function getLabTestsRequest(): Promise<LabTest[]> {
  const response = await axiosApiInstance.get<LabTest[]>('/lab-tests');

  return response.data.sort((testA, testB) =>
    testA.name.localeCompare(testB.name)
  );
}

export async function createPatientRequest(
  patient: PatientServer
): Promise<string> {
  const response = await axiosApiInstance.post<number>(
    '/patients',
    convertPatientAndPartnerInfoWithTzToTimezone(patient)
  );
  const { data: patientId } = response;

  return `${patientId}`;
}

export async function resendWelcomeEmailRequest(patientId: string) {
  await axiosApiInstance.post(`/patients/${patientId}/send-welcome-email`);
}

export async function resendPatientConfirmationEmailRequest(patientId: string) {
  await axiosApiInstance.post(`/patients/${patientId}/resendConfirmation`);
}

export async function updatePatientRequest(
  patientId: string,
  patient: Partial<PatientServer>
): Promise<string> {
  const response = await axiosApiInstance.put<{ _id: string }>(
    `/patients/${patientId}`,
    convertPatientAndPartnerInfoWithTzToTimezone(patient)
  );
  const { data } = response;
  return data._id;
}

export async function updatePatientPropertiesRequest(
  patientId: string,
  patientProperties: Basics
): Promise<string> {
  const response = await axiosApiInstance.put<{ _id: string }>(
    `/patients/${patientId}/properties`,
    convertBasicsDetailsClientToServer(patientProperties)
  );
  const { data } = response;
  return data._id;
}

export async function updatePatientIdentificationInfoRequest(
  patientId: string,
  {
    countryOfBirth,
    idDocumentType,
    idImage,
    idNumber,
    isConfirmed = false,
    deleteImage = false
  }: PatientIdentificationInfo
): Promise<string> {
  const formData = new FormData();

  if (idDocumentType) {
    formData.append('idType', idDocumentType);
  }
  if (countryOfBirth) {
    formData.append('countryOfBirth', countryOfBirth);
  }
  if (idImage) {
    formData.append('images', idImage[0]);
  }
  if (idNumber) {
    formData.append('idNumber', idNumber);
  }
  formData.append('isIdConfirmed', JSON.stringify(isConfirmed));

  if (deleteImage) {
    formData.append('deleteImage', JSON.stringify(deleteImage));
  }
  const response = await axiosApiInstance.put<{ _id: string }>(
    `/patients/${patientId}/identification/staff`,
    formData
  );
  const { data } = response;
  return data._id;
}

export async function updatePatientNextOfKinInfoRequest(
  patientId: string,
  nextOfKinInfo: NextOfKin
): Promise<string> {
  const response = await axiosApiInstance.put<{ _id: string }>(
    `/patients/${patientId}/next-of-kin`,
    nextOfKinInfo
  );
  const { data } = response;
  return data._id;
}

export async function updatePatientGeneralPhysicianInfoRequest(
  patientId: string,
  generalPhysicianInfo: GeneralPhysician
): Promise<string> {
  const response = await axiosApiInstance.put<{ _id: string }>(
    `/patients/${patientId}/general-physician`,
    generalPhysicianInfo
  );
  const { data } = response;
  return data._id;
}

export async function getPatientCareTeamMembersRequest(
  patientId: string
): Promise<StaffMember[]> {
  const response = await axiosApiInstance.get<StaffMember[]>(
    `/care-team/${patientId}/staff`
  );
  const { data } = response;

  return data;
}

export async function getPatientPrimaryCareTeamMemberRequest(
  patientId: string
): Promise<StaffMember> {
  const response = await axiosApiInstance.get<StaffMember>(
    `/care-team/${patientId}/primary`
  );

  const { data } = response;

  return data;
}

export async function addCareTeamMemberRequest({
  patientId,
  careTeamMember
}: {
  patientId: string;
  careTeamMember: CareTeamMember;
}): Promise<string> {
  await axiosApiInstance.post<any>(
    `/care-team/${patientId}`,
    convertCareTeamClientToServer(careTeamMember)
  );

  return `${patientId}`;
}

export const removePatientCareTeamMemberRequest = async ({
  patientId,
  staffId
}: {
  patientId: string;
  staffId: string;
}) => {
  const response = await axiosApiInstance.delete<StaffMember>(
    `/care-team/${patientId}/${staffId}`
  );
  return response.data[0];
};

export const getPatientKpisRequest = async (
  _patientId: string
): Promise<PatientKpisResponse> => {
  const patientStatus: PatientStatus = {
    active: {
      value: 37,
      arrowDirection: ArrowDirection.none,
      isWithPercentage: false
    },
    onboarding: {
      value: 6,
      arrowDirection: ArrowDirection.none,
      isWithPercentage: false
    }
  };

  const fertilizationRate: FertilizationRate = {
    icsi: {
      value: 37,
      arrowDirection: ArrowDirection.none,
      isWithPercentage: true
    },
    conventional: {
      value: 6,
      arrowDirection: ArrowDirection.none,
      isWithPercentage: true
    }
  };

  const pregnancyRate: PregnancyRate = {
    ivfPlusFresh: {
      value: 32,
      arrowDirection: ArrowDirection.up,
      isWithPercentage: true
    },
    fet: {
      value: 48,
      arrowDirection: ArrowDirection.down,
      isWithPercentage: true
    },
    iui: {
      value: 8,
      arrowDirection: ArrowDirection.none,
      isWithPercentage: true
    },
    coo: {
      value: 20,
      arrowDirection: ArrowDirection.none,
      isWithPercentage: true
    }
  };

  const cycleStatus: KpiCycleStatus = {
    ivf: {
      value: 17,
      arrowDirection: ArrowDirection.none,
      isWithPercentage: false
    },
    ivfPlusFresh: {
      value: 15,
      arrowDirection: ArrowDirection.none,
      isWithPercentage: false
    },
    fet: {
      value: 5,
      arrowDirection: ArrowDirection.none,
      isWithPercentage: false
    },
    eggFreezing: {
      value: 5,
      arrowDirection: ArrowDirection.none,
      isWithPercentage: false
    },
    iui: {
      value: 22,
      arrowDirection: ArrowDirection.none,
      isWithPercentage: false
    }
  };

  return {
    patientStatus,
    fertilizationRate,
    pregnancyRate,
    cycleStatus
  };
};

export const getPatientGameteReportRequest = async (
  _patientId: string
): Promise<GameteReport> => {
  const report: GameteReport = {
    aneuploid: 1,
    cryo: 4,
    transfered: 1,
    discarded: 8,
    euploid: 1,
    mosiac: 1
  };
  return report;
};

export const getPatientHistoryRequest = async (
  patientId: string
): Promise<PatientHistory> => {
  const response = await axiosApiInstance.get<PatientHistory>(
    `/patients/${patientId}/history`
  );
  return response.data;
};

export const getPatientEstrodialHistoryRequest = async (
  _patientId: string
): Promise<EstrodialHistory> => {
  const history: EstrodialHistory = [
    {
      date: dayjs().toDate(),
      value: 123
    },
    {
      date: dayjs().toDate(),
      value: 123
    },
    {
      date: dayjs().toDate(),
      value: 123
    },
    {
      date: dayjs().toDate(),
      value: 123
    }
  ];

  return history;
};

export const getPatientLabResultsRequest = async (
  patientId: string
): Promise<LabResult[]> => {
  const { data } = await axiosApiInstance.get<LabResultServer[]>(
    `/lab-results/patient/${patientId}`
  );
  return data.map((result) => convertLabResultServerToClient(result));
};

export const updatePatientFlagsRequest = async ({
  patientId,
  patientFlags
}: {
  patientId: string;
  patientFlags: string;
}) => {
  const response = await axiosApiInstance.put(`patients/${patientId}/flags`, {
    flags: patientFlags
  });
  return response.data;
};

export const getStaffMemberByIdRequest = async (
  staffId: string
): Promise<StaffMember> => {
  const response = await axiosApiInstance.get<StaffMember>(`/staff/${staffId}`);

  const { data } = response;
  return data;
};

export const updatePatientMedicationTreatmentRequest = async ({
  patientId,
  patientMedication
}: {
  patientId: string;
  name: MedicationTypes;
  patientMedication: Medication;
}): Promise<{ medication: Medication; patientId: string }> => {
  // TODO: Update the medication at the backend
  return { patientId, medication: patientMedication };
};

export async function createExamReportRequest(
  examReport: ExamReport
): Promise<ExamReportServer> {
  const formData = getExaminationFormData(examReport);

  const response = await axiosApiInstance.post<ExamReportServer>(
    `/examinations/patient/${examReport.patientId}`,
    formData
  );
  return response.data;
}

export async function updateExamReportRequest(
  examReport: ExamReport
): Promise<ExamReportServer> {
  const formData = getExaminationFormData(examReport);

  const response = await axiosApiInstance.patch<ExamReportServer>(
    `/examinations/${examReport.id}/patients/${examReport.patientId}`,
    formData
  );
  return response.data;
}

export async function getAliveEggsListRequest(
  patientId: string
): Promise<EggAndEmbryo[]> {
  const response = await axiosApiInstance.get<EggAndEmbryoServer[]>(
    `/egg-and-embryo/${patientId}/alive`
  );
  return response.data;
}

export async function getDashboardSummaryRequest({
  minDate,
  maxDate
}: RequestRange): Promise<DashboardSummary> {
  const response = await axiosApiInstance.get<DashboardSummary>(
    `patients/dashboardSummary?minDate=${minDate.format(
      dashSeparatedDateFormat
    )}&maxDate=${maxDate.format(dashSeparatedDateFormat)}`
  );
  const { data } = response;
  return data;
}

export async function getMedicationsRequest(): Promise<Medication[]> {
  const response = await axiosApiInstance.get<Medication[]>('/medications');

  return response.data.sort((medA, medB) => medA.name.localeCompare(medB.name));
}

export async function getMedicationsRxRequest(): Promise<MedicationRx[]> {
  const response = await axiosApiInstance.get<MedicationRx[]>(
    '/medications/rx'
  );

  return response.data.sort((medA, medB) =>
    medA.description.localeCompare(medB.description)
  );
}

export async function getPatientDiagnosisRequest(
  patientId: string
): Promise<PatientDiagnosisResult[]> {
  const response = await axiosApiInstance.get<PatientDiagnosisResult[]>(
    `/diagnosis/${patientId}`
  );
  return response.data;
}

export const updatePatientAllergiesInfoRequest = async ({
  patientId,
  patientAllergiesInfo
}: {
  patientId: string;
  patientAllergiesInfo: PatientAllergies;
}): Promise<{ patientAllergiesInfo: PatientAllergies; patientId: string }> => {
  return { patientId, patientAllergiesInfo };
};

export async function getTelehealthLinkRequest(
  patientId: string
): Promise<{ id: string; token: string }> {
  const response = await axiosApiInstance.get('/meetings/' + patientId);
  return response.data;
}

export async function createLabReportRequest(
  report: LabReport
): Promise<LabResultServer[]> {
  const labResults = convertLabReportClientToServer(report);
  const response = await axiosApiInstance.post<LabResultServer[]>(
    `/lab-results/patients/bulkCreate`,
    labResults
  );
  const { data } = response;
  return data;
}

export async function createLabResultRequest(
  labResult: LabResultForm
): Promise<any> {
  const labResultFormData = getLabResultAsFormData({ labResult });

  const response = await axiosApiInstance.post<any>(
    `/lab-results/${labResult.patientId}`,
    labResultFormData
  );
  const { data } = response;

  return data;
}

export async function updateLabResultRequest(
  labResult: Partial<LabResultForm>
): Promise<any> {
  const labResultFormData = getLabResultAsFormData({ labResult });

  const response = await axiosApiInstance.patch<any>(
    `/lab-results/${labResult.patientId}/update/${labResult.id}`,
    labResultFormData
  );
  const { data } = response;

  return data;
}

export const createDiagnosisRequest = async (
  diagnosis: PatientDiagnosisServer
) => {
  const response = await axiosApiInstance.post('/diagnosis', diagnosis);
  return response.data;
};

export async function addPrescriptionRequest({
  cycle,
  prescriptionsDocumentPayload
}: {
  cycle: MedicalProtocolForm & PrescriptionRxForm & { cycleId?: string };
  prescriptionsDocumentPayload?: CreatePatientDocumentPayload;
}): Promise<{ prescriptionsDocumentId: string }> {
  const formData = new FormData();

  const { patientId, cycleId, prescriptions, prescriptionsRx } =
    convertMedicalFormToPrescriptions(cycle);

  if (prescriptionsDocumentPayload) {
    const { file, patientId, templateId, isCompleted, metadata } =
      prescriptionsDocumentPayload;

    formData.append('prescriptionsDocumentFile', file);
    formData.append(
      'prescriptionsDocumentData',
      JSON.stringify({
        patientId,
        templateId,
        metadata,
        isCompleted
      })
    );
  }

  formData.append('patientId', JSON.stringify(patientId));
  formData.append('prescriptions', JSON.stringify(prescriptions));
  formData.append('prescriptionsRx', JSON.stringify(prescriptionsRx));
  if (cycleId) {
    formData.append('cycleId', cycleId);
  }

  const response = await axiosApiInstance.post(
    `/prescriptions/bulkCreate/${patientId}`,
    formData
  );

  return response.data;
}

export async function updatePrescriptionRequest(
  prescription: Partial<Prescription>
) {
  await axiosApiInstance.patch(
    `/prescriptions/${prescription.id}`,
    convertPrescriptionClientToServer(prescription)
  );
}

export async function updatePrescriptionDosageRequest({
  cycleId: _cycleId,
  ...dosageDetails
}: UpdatePrescriptionDosage) {
  await axiosApiInstance.patch(
    `/prescriptions/${dosageDetails.prescriptionId}/updateDosage`,
    convertDosageChangeClientToServer(dosageDetails)
  );
}

export const getInboxRecentsRequest = async (
  type: ChannelTypes
): Promise<MessageRecent[]> => {
  const response = await axiosApiInstance.get<MessageRecent[]>(
    `/inbox/recent/${type}`
  );
  return response.data;
};

export const getLabOrderRequest = async (
  labOrderId: string
): Promise<LabOrder> => {
  const response = await axiosApiInstance.get<LabOrder>(
    `/lab-orders/${labOrderId}`
  );

  return response.data;
};

export async function createOrderRequest(
  ordersForm: ExternalOrderFormServer
): Promise<CreateExternalOrderResponse> {
  const response = await axiosApiInstance.post<CreateExternalOrderResponse>(
    '/lab-orders',
    ordersForm
  );

  return response.data;
}

export const markAsReadRequest = async ({
  messageId,
  patientId
}: {
  patientId: string;
  messageId: string;
}) => {
  const response = await axiosApiInstance.post(`/messages/${messageId}/read`, {
    patientId
  });
  return response.data;
};

export async function getExaminationByIdRequest(
  examId: string
): Promise<Examination> {
  const response = await axiosApiInstance.get<ExaminationServer>(
    `/examinations/${examId}`
  );
  return convertExaminationServerToClient(response.data);
}

export async function getPrescriptionByIdRequest(
  prescriptionId: string
): Promise<Prescription> {
  const response = await axiosApiInstance.get<PrescriptionServer>(
    `/prescriptions/${prescriptionId}`
  );
  return convertPrescriptionServerToClient(response.data);
}

export async function getPrescriptionByCycleIdRequest(
  cycleId: string
): Promise<Prescription[]> {
  const response = await axiosApiInstance.get<PrescriptionServer[]>(
    `/prescriptions/cycle/${cycleId}/staff`
  );

  return response.data.map((prescription) =>
    convertPrescriptionServerToClient(prescription)
  );
}

export const getLabResultByIdRequest = async (
  resultId: string
): Promise<LabResult> => {
  const { data } = await axiosApiInstance.get<LabResult>(
    `/lab-results/${resultId}`
  );
  return data;
};

export async function deleteLabResultRequest(resultId: string) {
  const response = await axiosApiInstance.delete<any>(
    `/lab-results/${resultId}`
  );

  return response.data;
}
