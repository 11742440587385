import { Box, Card, Typography, Container, styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import FirebaseAuthLogin from '../LoginFirebaseAuth';
import { useAuth } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import { ToastType } from 'src/components/display/Toast/Toast';
import { useEffect } from 'react';
import { useToast } from 'src/components/components-api/ToastProvider';

const Content = styled(Box)`
  display: flex;
  flex: 1;
  width: 100%;
`;

const MainContent = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
`;

function LoginCover() {
  const { t }: { t: any } = useTranslation();
  const auth = useAuth();
  const { openToast } = useToast();

  useEffect(() => {
    if (auth.error) {
      openToast({
        title: t(auth.error),
        children: t('TRY_AGAIN_LATER'),
        type: ToastType.ERROR
      });
    }
  }, [auth]);

  return (
    <>
      <Helmet>
        <title>Login - Cover</title>
      </Helmet>
      <Content>
        <MainContent>
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
            maxWidth="sm"
          >
            <Card
              sx={{
                p: 4,
                my: 4
              }}
            >
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1
                  }}
                >
                  {t('SIGN_IN_TITLE')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3
                  }}
                >
                  {t('SIGN_IN_SUBTITLE')}
                </Typography>
              </Box>
              <FirebaseAuthLogin />
            </Card>
          </Container>
        </MainContent>
      </Content>
    </>
  );
}

export default LoginCover;
