import axiosApiInstance from '../utils/axios';
import { InsuranceType, PatientInsurance } from '../types/patientInsurance';
import { getPatientInsuranceFormData } from 'src/utils/patientInsurances';

export async function getInsuranceTypesRequest(): Promise<InsuranceType[]> {
  const response = await axiosApiInstance.get<InsuranceType[]>(
    '/patient-insurances/insurance-types'
  );

  return response.data;
}

export async function getInsurancesByPatientIdRequest(
  patientId: string
): Promise<PatientInsurance[]> {
  const response = await axiosApiInstance.get<PatientInsurance[]>(
    `/patient-insurances/${patientId}`
  );

  return response.data;
}

export async function createPatientInsuranceRequest(
  insurance: PatientInsurance
): Promise<string> {
  const formData = getPatientInsuranceFormData(insurance);

  const response = await axiosApiInstance.post<PatientInsurance>(
    '/patient-insurances',
    formData
  );

  const { data } = response;

  return data.id;
}

export async function updatePatientInsuranceRequest(
  insurance: PatientInsurance
): Promise<string> {
  const formData = getPatientInsuranceFormData(insurance);

  const response = await axiosApiInstance.patch<PatientInsurance>(
    `/patient-insurances/${insurance.id}`,
    formData
  );

  const { data } = response;

  return data.id;
}
