import { ImageFile } from 'src/components/data-entry/ImageUpload/ImageUpload';
import { NewCycleFormValues } from './cycle';
import { PatientPersonalInfo } from './patient';
import { Clinic } from './clinic';
import { Dayjs } from 'dayjs';
import { YesOrNo } from './global';
import { Icd10Code } from './codes';

export enum ConsultationAppointmentTypes {
  NEW_PATIENT_APP_VISIT = 'New Patient APP Visit',
  NEW_PATIENT_APP_VISIT_MALE_EVAL = 'New Patient APP Visit - Male Eval',
  WELCOME_CALL = 'Welcome Call',
  FOLLOW_UP_APP_VISIT = 'Follow-up APP Visit',
  FOLLOW_UP_APP_VISIT_MALE_EVAL = 'Follow-up APP Visit - Male Eval',
  APP_PHONE_CONSULT = 'APP Phone Consult',
  NEW_PATIENT_REI_VISIT_APP_REFERRAL = 'New Patient REI Visit - APP Referral',
  NEW_PATIENT_REI_VISIT = 'New Patient REI Visit',
  THIRD_PARTY_NEW_PATIENT_REI_VISIT = '3rd Party New Patient REI Visit',
  FOLLOW_UP_REI_VISIT = 'Follow-up REI Visit',
  IVF_PREP = 'IVF Prep',
  POST_IVF = 'Post IVF',
  RN_HEALTHCARE_PROFESSIONAL_CONSULT = 'RN/Healthcare Professional Consult',
  FINANCIAL_CONSULTATION = 'Financial Consultation',
  URGENT_CONSULTATION_WITH_HEALTHCARE_PROFESSIONAL = 'Urgent Consultation With Healthcare Professional',
  GESTATIONAL_CARRIER_CONSULTATION = 'Gestational Carrier Consultation',
  DONOR_SCREENING = 'Donor Screening',
  EGG_DONOR_SCREENING_PRE_TREATMENT_FIRST_CYCLE = 'Egg Donor Screening (pre treatment 1st cycle)',
  EGG_DONOR_SCREENING_PRE_TREATMENT_SUBSEQUENT_CYCLE = 'Egg Donor screening (pre treatment subsequent cycle)'
}

export enum PregnancyAppointmentTypes {
  PREGNANCY_TEST_BLOODWORK = 'Pregnancy test (bloodwork)',
  PREGNANCY_TEST_TWO_BLOODWORK = 'Pregnancy test 2 (bloodwork)',
  OB_ULTRASOUND_TRANSVAGINAL = 'OB Ultrasound (Transvaginal)',
  OB_ULTRASOUND_TWO_VIABLITIY_DISCHARGE_TO_OB = 'OB Ultrasound 2 (Viability) + Discharge to OB'
}

export enum DiagnosticAppointmentTypes {
  HSG_HYCOSY_HYFOSY = 'HSG/HyCoSy/HyFoSy',
  SALINE_SONOHYSTEROGRAM = 'Saline Sonohysterogram',
  ENDOMETRIAL_BIOPSY = 'Endometrial Biopsy',
  BLOOD_DRAW_ONLY = 'Blood Draw Only',
  SEMEN_ANALYSIS = 'Semen Analysis',
  ANTRAL_FOLLICLE_COUNT = 'Antral Follicle Count (AFC) US',
  TRIAL_EMBRYO_TRANSFER = 'Trial Embryo Transfer'
}

export enum InCycleAppointmentTypes {
  MEDICATED_TIMED_INTERCOURSE = 'Medicated Timed Intercourse (MTIC)',
  MONITORING = 'Monitoring',
  EGG_RETRIEVAL = 'Egg Retrieval',
  IUI = 'IUI',
  TRANSFER = 'Transfer',
  BASELINE = 'Baseline'
}

export enum AppointmentTypeCategory {
  EGG_RETRIEVAL = 'Egg Retrieval',
  IUI = 'IUI',
  MONITORING = 'Monitoring',
  TRANSFER = 'Transfer',
  DIAGNOSTIC_APPOINTMENT = 'Diagnostic',
  FOLLOWUP = 'Followup',
  INITIAL_CONSULTATION = 'Initial Consultation',
  ULTRASOUND = 'Ultrasound',
  ALL_COUNSELING_EDUCATION = 'All Counseling/Education',
  THIRD_PARTY = '3rd Party Screening',
  URGENT_CONSULT = 'Urgent Consult',
  CONSULTATION = 'Consultation'
}

export interface ClincRoomsBusyTimesRequest {
  roomId: string;
  minDate: Dayjs;
  maxDate: Dayjs;
}

export interface StaffBusyTimesRequest {
  staffIds: string[];
  minDate: Dayjs;
  maxDate: Dayjs;
}

export enum AppointmentPurposes {
  IN_CYCLE = 'IN_CYCLE',
  DIAGNOSTIC = 'DIAGNOSTIC',
  CONSULTATION = 'CONSULTATION',
  PREGNANCY = 'PREGNANCY'
}

export enum AllPurposesOption {
  ALL_TYPES = 'ALL_TYPES'
}

export enum DefaultClinicRoomOptions {
  SCAN_ROOM = 'SCAN_ROOM',
  THEATER = 'THEATER'
}

export const VIRTUAL_LOCATION = 'Virtual';

export const ALL_ROOMS_OPTION = 'ALL_ROOMS_OPTION';
export const NO_ROOM_OPTION = 'NO_ROOM';

export type AppointmentPurposeFilterOptions =
  | AllPurposesOption
  | AppointmentPurposes;

export type AppointmentTypes =
  | DiagnosticAppointmentTypes
  | PregnancyAppointmentTypes
  | ConsultationAppointmentTypes
  | InCycleAppointmentTypes;

export interface LabTest {
  id: string;
  name: string;
  sampleType: string[];
  tatHours: number;
  category: string;
  labCode?: string;
  units?: string;
  min?: number;
  max?: number;
}

export enum OrderPurpose {
  DIAGNOSTIC = 'Diagnostic',
  IN_CYCLE = 'In Cycle',
  PREGNANCY = 'Pregnancy'
}

interface LabOrderBasics {
  id?: string;
  appointmentId?: string;
  patientId?: string;
  purpose?: OrderPurpose;
  requestingPhysician?: string;
  createdByStaffId?: string;
  isUrgent?: boolean;
  billTo?: LabOrderBillTo;
  performingLabEmail?: string;
  isFasting?: boolean;
  externalId?: string;
  isInitialLabOrderEmailSent?: boolean;
}

interface LabOrderWithLabTest extends LabOrderBasics {
  labTestId: string;
  labTest: LabTest;
  panelId?: string;
  panel?: Panel;
}

interface LabOrderWithPanel extends LabOrderBasics {
  labTestId?: string;
  labTest?: LabTest;
  panelId: string;
  panel: Panel;
}

export type LabOrder = LabOrderWithPanel | LabOrderWithLabTest;

export enum ThirdPartyAppointmentStatusType {
  PRE_ARRIVAL = 'Pre-Arrival',
  CHECKED_IN = 'Checked In',
  Consultation = 'Consultation',
  Diagnostics = 'Diagnostics',
  WAITING_FOR_RESULTS = 'Waiting for Results',
  RESULTS_AVAILABLE = 'Results Available',
  REVIEWED_BY_PROVIDER = 'Reviewed by Provider'
}

export interface LabOrderInfo {
  isUrgent?: boolean;
  billTo?: LabOrderBillTo;
  performingLabEmail?: string;
  isFasting?: boolean;
  externalId?: string;
}

export interface Appointment {
  id?: string;
  patientId?: string;
  patient?: Partial<PatientPersonalInfo> & { clinic?: Clinic };
  cycleId?: string;
  cycle?: Partial<NewCycleFormValues>;
  staffIds?: string[];
  date?: Date;
  startTime?: Date;
  endTime?: Date;
  appointmentType?: AppointmentTypes;
  appointmentPurpose?: AppointmentPurposes;
  location?: string;
  room?: string;
  patientNotes?: string;
  panelIds?: string[];
  labTestIds?: string[];
  labOrders?: LabOrder[];
  isVirtual?: boolean;
  status?: AppointmentStatusType;
  instructions?: string;
  createdByStaff?: string;
  createdByPatient?: string;
  labOrderInfo?: LabOrderInfo;
}

export enum CycleMonitoringAppointmentStatusType {
  PRE_ARRIVAL = 'Pre-Arrival',
  CHECKED_IN = 'Checked In',
  ULTRASOUND_BLOODWORK = 'Ultrasound/Bloodwork',
  WAITING_FOR_RESULTS = 'Waiting for Results',
  RESULTS_AVAILABLE = 'Results Available',
  REVIEWED_BY_DR = 'Reviewed by Dr',
  INSTRUCTIONS_GIVEN_TO_PATIENTS = 'Instructions Given to Patients',
  NO_SHOW = 'No Show'
}

export enum DiagnosticAppointmentAppointmentStatusType {
  PRE_ARRIVAL = 'Pre-Arrival',
  CHECKED_IN = 'Checked In',
  DIAGNOSTICS = 'Diagnostics',
  WAITING_FOR_RESULTS = 'Waiting for Results',
  RESULTS_AVAILABLE = 'Results Available',
  REVIEWED_BY_PROVIDER = 'Reviewed by Provider',
  NO_SHOW = 'No Show'
}

export enum FollowupAppointmentStatusType {
  PRE_ARRIVAL = 'Pre-Arrival',
  CHECKED_IN = 'Checked In',
  IN_APPOINTMENT = 'In Appointment',
  WITH_NURSE = 'With Nurse',
  DISCHARGED = 'Discharged',
  NO_SHOW = 'No Show'
}

export enum InitialConsultationAppointmentStatusType {
  PRE_ARRIVAL = 'Pre-Arrival',
  CHECKED_IN = 'Checked In',
  IN_APPOINTMENT = 'In Appointment',
  WITH_NURSE = 'With Nurse',
  DISCHARGED = 'Discharged',
  NO_SHOW = 'No Show'
}

export enum AnyUltrasoundAppointmentStatusType {
  PRE_ARRIVAL = 'Pre-Arrival',
  CHECKED_IN = 'Checked In',
  ULTRASOUND = 'Ultrasound',
  WAITING_FOR_RESULTS = 'Waiting for Results',
  RESULTS_AVAILABLE = 'Results Available',
  REVIEWED_BY_DR = 'Reviewed by Dr',
  DISCHARGED = 'Discharged',
  NO_SHOW = 'No Show'
}

export enum AllCounselingEducationAppointmentStatusType {
  PRE_ARRIVAL = 'Pre-Arrival',
  CHECKED_IN = 'Checked In',
  IN_APPOINTMENT = 'In Appointment',
  DISCHARGED = 'Discharged',
  NO_SHOW = 'No Show'
}

export enum UrgentConsultAppointmentStatusType {
  PRE_ARRIVAL = 'Pre-Arrival',
  CHECKED_IN = 'Checked In',
  CONSULT = 'Consult',
  DIAGNOSTICS = 'Diagnostics',
  TREATMENT_PLAN = 'Treatment Plan',
  COUNSELING = 'Counseling',
  DISCHARGE = 'Discharge',
  NO_SHOW = 'No Show'
}

export enum EggRetrievalAppointmentStatusType {
  PRE_ARRIVAL = 'Pre-Arrival',
  CHECKED_IN = 'Checked In',
  PRE_OP = 'Pre-Op',
  PROCEDURE = 'Procedure',
  POST_OP = 'Post-Op',
  DISCHARGED = 'Discharged',
  NO_SHOW = 'No Show'
}

export enum IUIAppointmentStatusType {
  PRE_ARRIVAL = 'Pre-Arrival',
  CHECKED_IN = 'Checked In',
  PRE_PROCEDURE_CHECK = 'Pre-Procedure Check',
  PROCEDURE = 'Procedure',
  POST_PROCEDURE_CHECK = 'Post-Procedure Check',
  DISCHARGED = 'Discharged',
  NO_SHOW = 'No Show'
}

export enum ConsultationStatusType {
  PRE_ARRIVAL = 'Pre-Arrival',
  CHECKED_IN = 'Checked In',
  IN_APPOINTMENT = 'In Appointment',
  WITH_NURSE = 'With CN/Nurse',
  WITH_REI = 'With REI',
  WITH_FINANCIAL_COUNSELOR = 'With Financial Counselor',
  DISCHARGED = 'Discharged',
  NO_SHOW = 'No Show'
}

export type AppointmentStatusType =
  | EggRetrievalAppointmentStatusType
  | UrgentConsultAppointmentStatusType
  | AllCounselingEducationAppointmentStatusType
  | AnyUltrasoundAppointmentStatusType
  | InitialConsultationAppointmentStatusType
  | FollowupAppointmentStatusType
  | DiagnosticAppointmentAppointmentStatusType
  | CycleMonitoringAppointmentStatusType
  | ThirdPartyAppointmentStatusType
  | IUIAppointmentStatusType
  | ConsultationStatusType;

enum OrderType {
  LAB_ORDER = 'lab_order',
  PROCEDURE_ORDER = 'procedure_order'
}

export interface ExternalOrderForm {
  patientId: string;
  patientName?: string;
  purpose: OrderPurpose;
  requestingPhysician: string;
  id?: string;
  labTestsIds?: string[];
  labInstructions?: string;
  labOrderIcd10Codes?: Icd10Code[];
  proceduresIds?: string[];
  proceduresInstructions?: string;
  procedureOrderIcd10Codes?: Icd10Code[];
  urgency: Urgency;
  billTo: LabOrderBillTo;
  vendorId?: string;
  isFasting?: YesOrNo;
  externalId?: string;
}

export interface ExternalOrderBundle {
  clinicId: string;
  vendorName: string;
  loincList?: string[];
  cptList?: string[];
  bundleName: string;
}

export type CreateExternalOrderResponse = Record<OrderType, string>;

export enum Urgency {
  URGENT = 'Urgent',
  ROUTINE = 'Routine',
  ASAP = 'ASAP',
  STAT = 'Stat'
}

export enum AppointmentStatus {
  UPCOMING = 'upcoming',
  PREVIOUS = 'previous'
}

export interface LabResult {
  id: string;
  testId: string;
  value: string;
  patientId: string;
  cycleId?: string;
  date: string;
  comment?: string;
  measurementType?: string;
  labTest?: {
    category: string;
    id: string;
    labCode: string;
    min?: number;
    max?: number;
    name: string;
    sampleTYpe: string[];
    tatHours: number;
    units?: string;
  };
  externalLabImage?: ImageFile;
}

export interface LabResultClient {
  id?: string;
  patientId: string;
  date: Date;
  testId: string;
  value: string;
  measurementType: string;
  comment?: string;
  cycleId?: string;
}

export enum TestNameTypes {
  ESTRADIOL = 'ESTRADIOL',
  FSH = 'FSH',
  PROGESTERONE = 'PROGESTERONE',
  LH = 'LH'
}

interface ReportLabResults {
  testId: string;
  testName: TestNameTypes;
  value: string;
  measurementType: string;
}

export interface LabReport {
  patientId: string;
  patientName: string;
  cycleId: string;
  date: Date;
  results: ReportLabResults[];
  comment?: string;
}

export enum LabOrderBillTo {
  PATIENT = 'patient',
  CLINIC = 'clinic'
}

export interface Panel {
  id: string;
  vendorId?: number;
  parentId?: string;
  code?: string;
  name?: string;
  type?: string;
  codingScheme?: string;
  orderGroupingId?: string;
  comments?: string;
}

export interface ClinicRoom {
  id: string;
  name: string;
  roomEmail?: string;
}

export const requiredAppointmentOrderKeys = [
  'billTo',
  'isUrgent',
  'performingLabEmail'
];

export enum BusyTimeType {
  STAFF = 'staff',
  ROOM = 'room'
}
interface BusyTimeBasics {
  type: BusyTimeType.STAFF | BusyTimeType.ROOM;
  startTime: Dayjs;
  endTime: Dayjs;
  status?: string;
}

interface RoomBusyTime extends BusyTimeBasics {
  type: BusyTimeType.ROOM;
  roomId: string;
  roomName: string;
}

interface StaffBusyTime extends BusyTimeBasics {
  type: BusyTimeType.STAFF;
  staffId: string;
  staffName: string;
}

export type BusyTime = StaffBusyTime | RoomBusyTime;

export interface RequestRange {
  minDate: Dayjs;
  maxDate: Dayjs;
}

export enum AddEditAppointmentTabs {
  SCHEDULE_OR_ORDERS = 'SCHEDULE_OR_ORDERS',
  VITALS = 'VITALS',
  ENCOUNTER_NOTE = 'ENCOUNTER_NOTE',
  CODING = 'CODING'
}

interface OrderItemOptions {
  id?: string;
  loincCode?: string;
  name?: string;
}

export type ProcedureItem = OrderItemOptions;
export type LabItem = OrderItemOptions;
