import { FC, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import InputField from 'src/components/data-entry/InputField/InputField';
import Select from 'src/components/data-entry/Select/Select';
import Button from 'src/components/display/Button/Button';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings, spacingsPercentage } from 'src/components/styles/constants';
import { Patient, PersonSex } from 'src/types/patient';
import Loader from 'src/components/display/Loader/Loader';
import usePatientsApi from '../../../hooks/usePatientsApi';
import TimePicker from 'src/components/data-entry/TimePicker/TimePicker';
import Typography from 'src/components/display/Typography/Typography';
import { DoctorChips } from '../common/DoctorChips';
import { InputAdornment, styled } from '@mui/material';
import { Colors } from 'src/components/styles/colors';
import Card from 'src/components/display/Card/Card';
import { weights } from 'src/components/styles/fonts';
import TextArea from 'src/components/data-entry/TextArea/TextArea';
import dayjs from 'dayjs';
import { getFullName, getNumericValue } from 'src/utils/general';
import Chips from 'src/components/data-entry/Chips/Chips';
import { getTimeFormat } from 'src/utils/dateAndTIme';
import useExams from 'src/hooks/useExams';
import FormActionsContainer from 'src/components/display/FormActionsContainer/FormActionsContainer';
import { YesOrNo } from 'src/types/global';
import i18next from 'i18next';
import {
  Agglutination,
  Appearance,
  CollectionLocation,
  CollectionMethod,
  ContainerTypes,
  CryoDetails,
  ExamReason,
  Liquefaction,
  OtherExaminationTypes,
  PurposeOfFreeze,
  SampleFate,
  SemenAnalysisExamReason,
  SemenSource,
  SpermPrepMethod,
  SpermPrepSuitability,
  SpermType,
  TypeOfAgglutination,
  VialType,
  Viscosity
} from 'src/types/exam';

const YesNoOptions = Object.entries(YesOrNo).map(([key, value]) => ({
  label: i18next.t(key),
  value
}));

const containerOptions = Object.entries(ContainerTypes).map(([key, value]) => ({
  label: i18next.t(key),
  value
}));

const spermPrepSuitabilityOptions = Object.entries(SpermPrepSuitability).map(
  ([key, value]) => ({
    label: i18next.t(key),
    value
  })
);

const defaultCryoDetails: CryoDetails = {
  purposeOfFreeze: PurposeOfFreeze.FERTILITY_PRESERVATION,
  numberOfVialsStored: 0,
  vialType: VialType.AMPS,
  consentExpiration: dayjs().toDate(),
  storageLocation: '',
  cryoWitnessedById: null
};

const StyledCryoContainer = styled(Card)`
  background-color: ${Colors.vistaWhite};
  padding-block: ${spacings.xlarge};
  padding-inline: ${spacings.xxlarge};
`;

interface SemenAnalysisReportForm {
  id?: string;
  patientId: string;
  cycleId: string;
  patientName: string;
  requestingPhysicianId: string;
  type: OtherExaminationTypes.SPERM_ANALYSIS;
  collectionLocation: CollectionLocation;
  reason: ExamReason;
  performedById: string;
  witnessedBy: string;
  signedOffById: string;
  dateProduced?: Date;
  timeReceived?: Date;
  timeOfAnalysis?: Date;
  collectionType?: CollectionMethod;
  spermType?: SpermType;
  dateOfInitialCryo?: Date;
  semenSource?: SemenSource;
  spermPrepMethod?: SpermPrepMethod[];
  spermPrepSuitability?: SpermPrepSuitability;
  sampleFate?: SampleFate;
  daysOfAbstinence?: number;
  volumeOfSpermSample?: number;
  volume?: number;
  ph?: number;
  appearance?: Appearance;
  concentration?: number;
  totalSpermCount?: number;
  normalMorphology?: number;
  progressiveMotileA?: number;
  progressiveMotileB?: number;
  progressiveMotileC?: number;
  generalComments?: string;
  motileSpermCount?: number;
  progressiveMotileSpermCount?: number;
  totalMotile?: number;
  progressiveMotility?: number;
  agglutination?: Agglutination;
  typeOfAgglutination?: TypeOfAgglutination;
  viscosity?: Viscosity;
  marTest?: number;
  roundCell?: number;
  liquefaction?: Liquefaction;
  resultsGeneralComments?: string;
  spermPrepComments?: string;
  cryoDetails?: CryoDetails;
  spermPrepFinishTime?: Date;
  spermPrepStartTime?: Date;
  containerType?: ContainerTypes;
  completeSample?: boolean;
  spermPrepVolume?: number;
  spermPrepConcentration?: number;
  spermPrepTotalSpermCount?: number;
  spermPrepNormalMorphology?: number;
  spermPrepProgressiveMotileA?: number;
  spermPrepProgressiveMotileB?: number;
  spermPrepProgressiveMotileC?: number;
  spermPrepMotileSpermCount?: number;
  spermPrepProgressiveMotileSpermCount?: number;
  spermPrepTotalMotile?: number;
  spermPrepProgressiveMotility?: number;
  immotileD?: number;
  spermPrepImmotileD?: number;
}

interface PossiblePatient {
  id: string;
  name: string;
}

const getPossiblePatients = (patient: Patient): PossiblePatient[] => {
  const possiblePatients: PossiblePatient[] = [];

  if (patient?.personalInfo?.sexAtBirth === PersonSex.MALE) {
    possiblePatients.push({
      id: patient.personalInfo.id,
      name: getFullName(patient.personalInfo)
    });
  }

  if (patient?.partnerInfo?.sexAtBirth === PersonSex.MALE) {
    possiblePatients.push({
      id: patient.partnerInfo.id,
      name: getFullName(patient.partnerInfo)
    });
  }

  return possiblePatients;
};

export const AddEditSemenAnalysisReportForm: FC<{
  patientId: string;
  cycleId?: string;
  examinationId?: string;
}> = ({ patientId, cycleId, examinationId }) => {
  const isEdit = !!examinationId;
  const { getPatientById } = usePatientsApi();
  const { createExamReport, updateExamReport, getExaminationById } = useExams();
  const { t } = useTranslation();

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);
  const { data: examination } = getExaminationById(examinationId, {
    enabled: !!examinationId
  });

  const examReasonOptions = useMemo(
    () =>
      Object.entries(SemenAnalysisExamReason)
        .filter(([_, value]) =>
          cycleId ? true : value !== SemenAnalysisExamReason.IN_CYCLE
        )
        .map(([key, value]) => ({
          label: t(key),
          value: value
        })),
    [cycleId]
  );

  const possiblePatients = getPossiblePatients(patient);

  const { mutate: createReport, isLoading: isCreatingReport } =
    createExamReport();
  const { mutate: updateReport, isLoading: isUpdatingReport } =
    updateExamReport();

  const defaultValues: SemenAnalysisReportForm = {
    patientId,
    cycleId,
    patientName: '',
    requestingPhysicianId: '',
    type: OtherExaminationTypes.SPERM_ANALYSIS,
    performedById: '',
    witnessedBy: '',
    signedOffById: '',
    dateProduced: dayjs().toDate(),
    timeReceived: dayjs().toDate(),
    timeOfAnalysis: dayjs().toDate(),
    collectionType: null,
    collectionLocation: null,
    spermType: null,
    dateOfInitialCryo: dayjs().toDate(),
    semenSource: null,
    reason: cycleId ? ExamReason.IN_CYCLE : null,
    spermPrepMethod: [],
    spermPrepSuitability: null,
    sampleFate: null,
    daysOfAbstinence: null,
    volumeOfSpermSample: null,
    volume: null,
    ph: null,
    appearance: null,
    concentration: null,
    totalSpermCount: null,
    normalMorphology: null,
    progressiveMotileA: null,
    progressiveMotileB: null,
    progressiveMotileC: null,
    generalComments: '',
    motileSpermCount: null,
    progressiveMotileSpermCount: null,
    totalMotile: null,
    progressiveMotility: null,
    agglutination: null,
    typeOfAgglutination: null,
    viscosity: null,
    marTest: null,
    roundCell: null,
    liquefaction: null,
    resultsGeneralComments: '',
    spermPrepStartTime: dayjs().toDate(),
    spermPrepFinishTime: dayjs().toDate(),
    spermPrepComments: '',
    containerType: null,
    completeSample: false,
    spermPrepVolume: null,
    spermPrepConcentration: null,
    spermPrepTotalSpermCount: null,
    spermPrepNormalMorphology: null,
    spermPrepProgressiveMotileA: null,
    spermPrepProgressiveMotileB: null,
    spermPrepProgressiveMotileC: null,
    spermPrepMotileSpermCount: null,
    spermPrepProgressiveMotileSpermCount: null,
    spermPrepTotalMotile: null,
    spermPrepProgressiveMotility: null,
    immotileD: null,
    cryoDetails: defaultCryoDetails
  };

  const {
    control,
    formState,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset
  } = useForm<SemenAnalysisReportForm>({
    mode: 'onChange',
    defaultValues
  });
  const { sampleFate, reason: examReason } = watch();

  useEffect(() => {
    if (examination) {
      reset({
        id: examination.id,
        patientId: examination.patientId,
        cycleId: examination.cycleId,
        requestingPhysicianId: examination.requestedPhysician || '',
        type: OtherExaminationTypes.SPERM_ANALYSIS,
        performedById: examination.performedBy || '',
        witnessedBy: examination.witnessedBy || '',
        signedOffById: examination.signedOffBy || '',
        reason: examination.reason,
        dateProduced:
          examination?.semenAnalysis?.dateProduced || dayjs().toDate(),
        timeReceived: dayjs(
          examination?.semenAnalysis?.timeReceived,
          getTimeFormat()
        ).toDate(),
        timeOfAnalysis: dayjs(
          examination?.semenAnalysis?.timeOfAnalysis,
          getTimeFormat()
        ).toDate(),
        collectionType: examination?.semenAnalysis?.collectionType,
        collectionLocation: examination?.semenAnalysis?.collectionLocation,
        spermType: examination?.semenAnalysis?.spermType,
        dateOfInitialCryo:
          examination?.semenAnalysis?.dateOfInitialCryo || dayjs().toDate(),
        semenSource: examination?.semenAnalysis?.semenSource,
        spermPrepMethod: examination?.semenAnalysis?.spermPrepMethod || [],
        spermPrepSuitability: examination?.semenAnalysis?.spermPrepSuitability,
        sampleFate: examination?.semenAnalysis?.sampleFate,
        daysOfAbstinence: examination?.semenAnalysis?.daysOfAbstinence,
        volumeOfSpermSample: examination?.semenAnalysis?.volumeOfSpermSample,
        volume: examination?.semenAnalysis?.volume.value,
        ph: examination?.semenAnalysis?.ph,
        appearance: examination?.semenAnalysis?.appearance,
        concentration: examination?.semenAnalysis?.concentration.value,
        totalSpermCount: examination?.semenAnalysis?.totalSpermCount.value,
        normalMorphology: examination?.semenAnalysis?.normalMorphology.value,
        progressiveMotileA: examination?.semenAnalysis?.progressiveMotileA,
        progressiveMotileB: examination?.semenAnalysis?.progressiveMotileB,
        progressiveMotileC: examination?.semenAnalysis?.progressiveMotileC,
        generalComments: examination?.notes,
        motileSpermCount: examination?.semenAnalysis?.motileSpermCount,
        progressiveMotileSpermCount:
          examination?.semenAnalysis?.progressiveMotileSpermCount,
        totalMotile: examination?.semenAnalysis?.totalMotile.value,
        progressiveMotility: examination?.semenAnalysis?.progressiveMotility,
        agglutination: examination?.semenAnalysis?.agglutination,
        typeOfAgglutination: examination?.semenAnalysis?.typeOfAgglutination,
        viscosity: examination?.semenAnalysis?.viscosity,
        marTest: examination?.semenAnalysis?.marTest,
        roundCell: examination?.semenAnalysis?.roundCell,
        liquefaction: examination?.semenAnalysis?.liquefaction,
        resultsGeneralComments:
          examination?.semenAnalysis?.resultsGeneralComments || '',
        spermPrepComments: examination?.semenAnalysis?.spermPrepComments || '',
        spermPrepStartTime: dayjs(
          examination?.semenAnalysis?.spermPrepStartTime,
          getTimeFormat()
        ).toDate(),
        spermPrepFinishTime: dayjs(
          examination?.semenAnalysis?.spermPrepFinishTime,
          getTimeFormat()
        ).toDate(),
        containerType: examination?.semenAnalysis?.containerType,
        completeSample: examination?.semenAnalysis?.completeSample,
        spermPrepVolume: examination?.semenAnalysis?.spermPrepVolume,
        spermPrepConcentration:
          examination?.semenAnalysis?.spermPrepConcentration,
        spermPrepTotalSpermCount:
          examination?.semenAnalysis?.spermPrepTotalSpermCount,
        spermPrepNormalMorphology:
          examination?.semenAnalysis?.spermPrepNormalMorphology,
        spermPrepProgressiveMotileA:
          examination?.semenAnalysis?.spermPrepProgressiveMotileA,
        spermPrepProgressiveMotileB:
          examination?.semenAnalysis?.spermPrepProgressiveMotileB,
        spermPrepProgressiveMotileC:
          examination?.semenAnalysis?.spermPrepProgressiveMotileC,
        spermPrepMotileSpermCount:
          examination?.semenAnalysis?.spermPrepMotileSpermCount,
        spermPrepProgressiveMotileSpermCount:
          examination?.semenAnalysis?.spermPrepProgressiveMotileSpermCount,
        spermPrepTotalMotile: examination?.semenAnalysis?.spermPrepTotalMotile,
        spermPrepProgressiveMotility:
          examination?.semenAnalysis?.spermPrepProgressiveMotility,
        immotileD: examination?.semenAnalysis?.immotileD,
        spermPrepImmotileD: examination?.semenAnalysis?.spermPrepImmotileD,
        cryoDetails: {
          purposeOfFreeze: examination?.semenAnalysis?.purposeOfFreeze,
          numberOfVialsStored: examination?.semenAnalysis?.numberOfVialsStored,
          vialType: examination?.semenAnalysis?.vialType,
          consentExpiration: examination?.semenAnalysis?.consentExpiration,
          storageLocation: examination?.semenAnalysis?.storageLocation,
          cryoWitnessedById: examination?.semenAnalysis?.cryoWitnessedById || ''
        }
      });
    }

    if (patient) {
      setValue('patientName', getFullName(patient?.personalInfo));
    }
  }, [patient, examination]);

  const { errors } = formState;

  const onSubmit = async ({
    id,
    requestingPhysicianId,
    type,
    performedById,
    witnessedBy,
    signedOffById,
    reason,
    patientId,
    patientName,
    cycleId,
    volume,
    concentration,
    normalMorphology,
    progressiveMotileA,
    progressiveMotileB,
    progressiveMotileC,
    volumeOfSpermSample,
    daysOfAbstinence,
    immotileD,
    ph,
    roundCell,
    marTest,
    spermPrepVolume,
    spermPrepConcentration,
    spermPrepNormalMorphology,
    spermPrepProgressiveMotileA,
    spermPrepProgressiveMotileB,
    spermPrepProgressiveMotileC,
    spermPrepImmotileD,
    cryoDetails,
    sampleFate,
    ...restReport
  }: SemenAnalysisReportForm) => {
    const examToSend = {
      requestingPhysicianId,
      type,
      cycleId,
      performedById,
      witnessedBy,
      signedOffById,
      reason,
      patientId,
      patientName,
      semenAnalysis: {
        volume: getNumericValue(volume),
        concentration: getNumericValue(concentration),
        normalMorphology: getNumericValue(normalMorphology),
        progressiveMotileA: getNumericValue(progressiveMotileA),
        progressiveMotileB: getNumericValue(progressiveMotileB),
        progressiveMotileC: getNumericValue(progressiveMotileC),
        volumeOfSpermSample: getNumericValue(volumeOfSpermSample),
        daysOfAbstinence: getNumericValue(daysOfAbstinence),
        immotileD: getNumericValue(immotileD),
        ph: getNumericValue(ph),
        roundCell: getNumericValue(roundCell),
        marTest: getNumericValue(marTest),
        spermPrepVolume: getNumericValue(spermPrepVolume),
        spermPrepConcentration: getNumericValue(spermPrepConcentration),
        spermPrepNormalMorphology: getNumericValue(spermPrepNormalMorphology),
        spermPrepProgressiveMotileA: getNumericValue(
          spermPrepProgressiveMotileA
        ),
        spermPrepProgressiveMotileB: getNumericValue(
          spermPrepProgressiveMotileB
        ),
        spermPrepProgressiveMotileC: getNumericValue(
          spermPrepProgressiveMotileC
        ),
        spermPrepImmotileD: getNumericValue(spermPrepImmotileD),
        sampleFate,
        cryoDetails:
          sampleFate === SampleFate.CRYO
            ? {
                numberOfVialsStored: cryoDetails.numberOfVialsStored || null,
                storageLocation: cryoDetails.storageLocation,
                cryoWitnessedById: cryoDetails.cryoWitnessedById || null,
                vialType: cryoDetails.vialType,
                consentExpiration: cryoDetails.consentExpiration,
                purposeOfFreeze: cryoDetails.purposeOfFreeze
              }
            : null,
        ...restReport
      }
    };

    if (isEdit) {
      await updateReport({
        id,
        ...examToSend
      });
    } else {
      await createReport(examToSend);
    }
  };

  const calcMotileSpermCount = () => {
    const {
      progressiveMotileA,
      progressiveMotileB,
      progressiveMotileC,
      concentration,
      volume,
      spermPrepProgressiveMotileA,
      spermPrepProgressiveMotileB,
      spermPrepProgressiveMotileC,
      spermPrepConcentration,
      spermPrepVolume
    } = getValues();
    if (
      !isNaN(progressiveMotileA) &&
      !isNaN(progressiveMotileB) &&
      !isNaN(progressiveMotileC) &&
      !isNaN(concentration) &&
      !isNaN(volume)
    ) {
      setValue(
        'motileSpermCount',
        parseFloat(
          (
            ((+progressiveMotileA + +progressiveMotileB + +progressiveMotileC) *
              +concentration *
              +volume) /
            100
          ).toFixed(2)
        )
      );
    } else {
      setValue('motileSpermCount', 0);
    }
    if (
      !isNaN(spermPrepProgressiveMotileA) &&
      !isNaN(spermPrepProgressiveMotileB) &&
      !isNaN(spermPrepProgressiveMotileC) &&
      !isNaN(spermPrepConcentration) &&
      !isNaN(spermPrepVolume)
    ) {
      setValue(
        'spermPrepMotileSpermCount',
        parseFloat(
          (
            ((+spermPrepProgressiveMotileA +
              +spermPrepProgressiveMotileB +
              +spermPrepProgressiveMotileC) *
              +spermPrepConcentration *
              +spermPrepVolume) /
            100
          ).toFixed(2)
        )
      );
    } else {
      setValue('spermPrepMotileSpermCount', 0);
    }
  };

  const calcProgressiveMotileSpermCount = () => {
    const {
      progressiveMotileA,
      progressiveMotileB,
      concentration,
      volume,
      spermPrepProgressiveMotileA,
      spermPrepProgressiveMotileB,
      spermPrepConcentration,
      spermPrepVolume
    } = getValues();

    if (
      !isNaN(progressiveMotileA) &&
      !isNaN(progressiveMotileB) &&
      !isNaN(concentration) &&
      !isNaN(volume)
    ) {
      setValue(
        'progressiveMotileSpermCount',
        parseFloat(
          (
            ((+progressiveMotileA + +progressiveMotileB) *
              +concentration *
              +volume) /
            100
          ).toFixed(2)
        )
      );
    } else {
      setValue('progressiveMotileSpermCount', 0);
    }
    if (
      !isNaN(spermPrepProgressiveMotileA) &&
      !isNaN(spermPrepProgressiveMotileB) &&
      !isNaN(spermPrepConcentration) &&
      !isNaN(spermPrepVolume)
    ) {
      setValue(
        'spermPrepProgressiveMotileSpermCount',
        parseFloat(
          (
            ((+spermPrepProgressiveMotileA + +spermPrepProgressiveMotileB) *
              +spermPrepConcentration *
              +spermPrepVolume) /
            100
          ).toFixed(2)
        )
      );
    } else {
      setValue('spermPrepProgressiveMotileSpermCount', 0);
    }
  };

  const calcTotalMotile = () => {
    const {
      progressiveMotileA,
      progressiveMotileB,
      progressiveMotileC,
      spermPrepProgressiveMotileA,
      spermPrepProgressiveMotileB,
      spermPrepProgressiveMotileC
    } = getValues();
    if (
      !isNaN(progressiveMotileA) &&
      !isNaN(progressiveMotileB) &&
      !isNaN(progressiveMotileC)
    ) {
      setValue(
        'totalMotile',
        +progressiveMotileA + +progressiveMotileB + +progressiveMotileC
      );
    } else {
      setValue('totalMotile', 0);
    }
    if (
      !isNaN(spermPrepProgressiveMotileA) &&
      !isNaN(spermPrepProgressiveMotileB) &&
      !isNaN(spermPrepProgressiveMotileC)
    ) {
      setValue(
        'spermPrepTotalMotile',
        +spermPrepProgressiveMotileA +
          +spermPrepProgressiveMotileB +
          +spermPrepProgressiveMotileC
      );
    } else {
      setValue('spermPrepTotalMotile', 0);
    }
  };

  const calcProgressiveMotile = () => {
    const {
      progressiveMotileA,
      progressiveMotileB,
      spermPrepProgressiveMotileA,
      spermPrepProgressiveMotileB
    } = getValues();
    if (!isNaN(progressiveMotileA) && !isNaN(progressiveMotileB)) {
      setValue(
        'progressiveMotility',
        +progressiveMotileA + +progressiveMotileB
      );
    } else {
      setValue('progressiveMotility', 0);
    }
    if (
      !isNaN(spermPrepProgressiveMotileA) &&
      !isNaN(spermPrepProgressiveMotileB)
    ) {
      setValue(
        'spermPrepProgressiveMotility',
        +spermPrepProgressiveMotileA + +spermPrepProgressiveMotileB
      );
    } else {
      setValue('spermPrepProgressiveMotility', 0);
    }
  };

  const calcTotalSpermCount = () => {
    const { volume, concentration, spermPrepVolume, spermPrepConcentration } =
      getValues();

    if (!isNaN(volume) && !isNaN(concentration)) {
      setValue(
        'totalSpermCount',
        parseFloat((+volume * +concentration).toFixed(2))
      );
    } else {
      setValue('totalSpermCount', 0);
    }
    if (!isNaN(spermPrepVolume) && !isNaN(spermPrepConcentration)) {
      setValue(
        'spermPrepTotalSpermCount',
        parseFloat((+spermPrepVolume * +spermPrepConcentration).toFixed(2))
      );
    } else {
      setValue('spermPrepTotalSpermCount', 0);
    }
  };

  if (isLoadingPatient) return <Loader />;

  const formActions = [
    <Button
      key="action-save"
      width={spacingsPercentage.large}
      disabled={isCreatingReport || isUpdatingReport}
      onClick={handleSubmit(onSubmit)}
    >
      {t('SAVE')}
    </Button>
  ];

  return (
    <Box marginTop={spacings.large}>
      <form noValidate>
        <Flex marginBottom={spacings.medium} gap={spacings.large}>
          <Box flex={2}>
            <Controller
              name="patientName"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <Select
                  {...field}
                  inputRef={ref}
                  label={t('PATIENT_LABEL')}
                  value={getValues('patientId')}
                  defaultOption={t('PLEASE_CHOOSE_PATIENT')}
                  options={possiblePatients.map(({ id, name }) => ({
                    value: id,
                    label: name
                  }))}
                  onChange={(ev) => {
                    const patientId = ev.target.value;
                    const chosenPatient = possiblePatients.find(
                      ({ id }) => id === patientId
                    );
                    onChange(chosenPatient?.name);
                    setValue('patientId', chosenPatient?.id);
                  }}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name={'requestingPhysicianId'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <DoctorChips
                  id="semen-analysis-requesting-physician"
                  showSelectedValue
                  label={t('REQUESTING_PROVIDER').toUpperCase()}
                  value={[value]}
                  onAddChip={(selectedDoctorId) => onChange(selectedDoctorId)}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name={'performedById'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <DoctorChips
                  id="semen-analysis-performed-by"
                  showSelectedValue
                  label={t('PERFORMED_BY').toUpperCase()}
                  value={[value]}
                  onAddChip={(selectedDoctorId) => onChange(selectedDoctorId)}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name={'witnessedBy'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <DoctorChips
                  id="semen-analysis-witnessed-by"
                  showSelectedValue
                  label={t('WITNESSED_BY').toUpperCase()}
                  value={[value]}
                  onAddChip={(selectedDoctorId) => onChange(selectedDoctorId)}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name={'signedOffById'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <DoctorChips
                  id="semen-analysis-signed-off-by"
                  showSelectedValue
                  label={t('SIGNED_OFF_BY').toUpperCase()}
                  value={[value]}
                  onAddChip={(selectedDoctorId) => onChange(selectedDoctorId)}
                />
              )}
            />
          </Box>
        </Flex>
        <Flex gap={spacings.large} marginBottom={spacings.large}>
          <Box flex={1}>
            <Controller
              name={'dateProduced'}
              control={control}
              rules={{
                validate: (value) => {
                  const date = dayjs(value);
                  if (date.isAfter(dayjs())) {
                    return t('DATE_CANT_BE_IN_THE_FUTURE');
                  }
                  return true;
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <DatePicker
                  {...field}
                  label={t('DATE_PRODUCED').toUpperCase()}
                  inputRef={ref}
                  error={!!errors?.dateProduced}
                  helperText={errors.dateProduced?.message}
                  fullWidth
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name={'timeReceived'}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TimePicker
                  {...field}
                  label={t('TIME_RECEIVED').toUpperCase()}
                  inputRef={ref}
                  error={!!errors?.timeReceived}
                  helperText={errors.timeReceived?.message}
                  fullWidth
                />
              )}
            />
          </Box>

          <Box flex={1}>
            <Controller
              name={'timeOfAnalysis'}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TimePicker
                  {...field}
                  label={t('TIME_OF_ANALYSIS').toUpperCase()}
                  inputRef={ref}
                  error={!!errors?.timeOfAnalysis}
                  helperText={errors.timeOfAnalysis?.message}
                  fullWidth
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="reason"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('EXAM_REASON')}
                  inputRef={ref}
                  disabled={!!cycleId}
                  error={!!errors?.reason}
                  helperText={errors?.reason?.message}
                  defaultOption={t('EXAM_REASON')}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={examReasonOptions}
                />
              )}
            />
          </Box>
        </Flex>
        <Flex gap={spacings.large} marginBottom={spacings.large}>
          <Box flex={1}>
            <Controller
              name="collectionLocation"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('COLLECTION_LOCATION')}
                  inputRef={ref}
                  error={!!errors?.collectionLocation}
                  helperText={errors?.collectionLocation?.message}
                  defaultOption={t('COLLECTION_LOCATION')}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={Object.entries(CollectionLocation).map(
                    ([key, value]) => ({
                      label: t(key),
                      value
                    })
                  )}
                />
              )}
            />
          </Box>

          <Box flex={1}>
            <Controller
              name="collectionType"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('COLLECTION_METHOD')}
                  inputRef={ref}
                  error={!!errors?.collectionType}
                  helperText={errors?.collectionType?.message}
                  defaultOption={t('COLLECTION_METHOD')}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={Object.entries(CollectionMethod).map(
                    ([key, value]) => ({
                      label: t(key),
                      value
                    })
                  )}
                />
              )}
            />
          </Box>

          <Box flex={1}>
            <Controller
              name="semenSource"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('SEMEN_SOURCE')}
                  inputRef={ref}
                  error={!!errors?.semenSource}
                  helperText={errors?.semenSource?.message}
                  defaultOption={t('SEMEN_SOURCE')}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={Object.entries(SemenSource).map(([key, value]) => ({
                    label: t(key),
                    value
                  }))}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="spermType"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('SPERM_TYPE')}
                  inputRef={ref}
                  error={!!errors?.spermType}
                  helperText={errors?.spermType?.message}
                  defaultOption={t('SPERM_TYPE')}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={Object.entries(SpermType).map(([key, value]) => ({
                    label: t(key),
                    value
                  }))}
                />
              )}
            />
          </Box>
        </Flex>
        <Flex gap={spacings.large} marginBottom={spacings.large}>
          <Box flex={1}>
            <Controller
              name="containerType"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <Select
                  {...field}
                  label={t('CONTAINER_TYPE')}
                  inputRef={ref}
                  error={!!errors?.containerType}
                  helperText={errors?.containerType?.message}
                  defaultOption={t('CONTAINER_TYPE')}
                  onChange={(event) => {
                    onChange(event.target.value);
                  }}
                  options={containerOptions}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="daysOfAbstinence"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('DAYS_OF_ABSTINENCE').toUpperCase()}
                  placeholder={t('DAYS_OF_ABSTINENCE')}
                  error={!!errors?.daysOfAbstinence}
                  helperText={errors?.daysOfAbstinence?.message}
                  fullWidth
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="completeSample"
              control={control}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Select
                  {...field}
                  label={t('COMPLETE_SAMPLE')}
                  inputRef={ref}
                  error={!!errors?.completeSample}
                  helperText={errors?.completeSample?.message}
                  defaultOption={t('COMPLETE_SAMPLE')}
                  value={value ? YesOrNo.YES : YesOrNo.NO}
                  onChange={(event) => {
                    onChange(event.target.value === YesOrNo.YES);
                  }}
                  options={YesNoOptions}
                />
              )}
            />
          </Box>
          {getValues('spermType') === SpermType.CRYO ? (
            <Box flex={1}>
              <Controller
                name={'dateOfInitialCryo'}
                control={control}
                rules={{
                  validate: (value) => {
                    const date = dayjs(value);
                    if (date.isAfter(dayjs())) {
                      return t('DATE_CANT_BE_IN_THE_FUTURE');
                    }
                    return true;
                  }
                }}
                render={({ field: { ref, ...field } }) => (
                  <DatePicker
                    {...field}
                    label={t('DATE_OF_INITIAL_CRYO').toUpperCase()}
                    inputRef={ref}
                    error={!!errors?.dateOfInitialCryo}
                    helperText={errors.dateOfInitialCryo?.message}
                    fullWidth
                  />
                )}
              />
            </Box>
          ) : (
            <Box flex={1}></Box>
          )}
        </Flex>
        <Flex gap={spacings.large} marginBottom={spacings.large}>
          <Box flex={1}>
            <Controller
              name="sampleFate"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('SAMPLE_FATE')}
                  inputRef={ref}
                  error={!!errors?.sampleFate}
                  helperText={errors?.sampleFate?.message}
                  defaultOption={t('SAMPLE_FATE')}
                  options={Object.entries(SampleFate).map(([key, value]) => ({
                    label: t(key),
                    value
                  }))}
                />
              )}
            />
          </Box>
          <Box flex={1}></Box>
          <Box flex={1}></Box>
          <Box flex={1}></Box>
        </Flex>

        <Box marginBottom={spacings.medium}>
          <Typography
            marginBottom={spacings.large}
            variant="h2"
            fontWeight={weights.extraBold}
          >
            {t('RESULTS_INITIAL_SAMPLE')}
          </Typography>
        </Box>

        <Flex gap={spacings.large} marginBottom={spacings.large}>
          <Box flex={1}>
            <Controller
              name="appearance"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('APPEARANCE')}
                  inputRef={ref}
                  error={!!errors?.appearance}
                  helperText={errors?.appearance?.message}
                  defaultOption={t('APPEARANCE')}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={Object.entries(Appearance).map(([key, value]) => ({
                    label: t(key),
                    value
                  }))}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="liquefaction"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('LIQUEFACTION')}
                  inputRef={ref}
                  error={!!errors?.liquefaction}
                  helperText={errors?.liquefaction?.message}
                  defaultOption={t('LIQUEFACTION')}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={Object.entries(Liquefaction).map(([key, value]) => ({
                    label: t(key),
                    value
                  }))}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="viscosity"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('VISCOSITY')}
                  inputRef={ref}
                  error={!!errors?.viscosity}
                  helperText={errors?.viscosity?.message}
                  defaultOption={t('VISCOSITY')}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={Object.entries(Viscosity).map(([key, value]) => ({
                    label: t(key),
                    value
                  }))}
                />
              )}
            />
          </Box>

          <Box flex={1}></Box>
        </Flex>

        <Flex gap={spacings.large} marginBottom={spacings.large}>
          <Box flex={1}>
            <Controller
              name="volume"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={`${t('VOLUME').toUpperCase()} (${t('ML')})`}
                  placeholder={t('VOLUME')}
                  error={!!errors?.volume}
                  helperText={errors?.volume?.message}
                  fullWidth
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{t('ML')}</InputAdornment>
                    ),
                    inputProps: { min: 0 }
                  }}
                  onChange={(ev) => {
                    onChange(ev);
                    calcTotalSpermCount();
                    calcMotileSpermCount();
                    calcProgressiveMotileSpermCount();
                  }}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="concentration"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  onChange={(ev) => {
                    onChange(ev);
                    calcMotileSpermCount();
                    calcProgressiveMotileSpermCount();
                    calcTotalSpermCount();
                    calcProgressiveMotile();
                  }}
                  label={`${t('CONCENTRATION').toUpperCase()} (${t('M/ML')})`}
                  placeholder={t('CONCENTRATION')}
                  error={!!errors?.concentration}
                  helperText={errors?.concentration?.message}
                  fullWidth
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                />
              )}
            />
          </Box>

          <Box flex={1}>
            <Controller
              name="totalSpermCount"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={`${t('TOTAL_SPERM_COUNT').toUpperCase()} (${t(
                    'M/EJAC'
                  )})`}
                  placeholder={t('TOTAL_SPERM_COUNT')}
                  error={!!errors?.totalSpermCount}
                  helperText={errors?.totalSpermCount?.message}
                  InputProps={{
                    readOnly: true
                  }}
                  fullWidth
                  type="number"
                />
              )}
            />
          </Box>

          <Box flex={1}>
            <Controller
              name="normalMorphology"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={`${t('NORMAL_MORPHOLOGY').toUpperCase()}`}
                  placeholder={t('NORMAL_MORPHOLOGY')}
                  error={!!errors?.normalMorphology}
                  helperText={errors?.normalMorphology?.message}
                  fullWidth
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                />
              )}
            />
          </Box>
        </Flex>

        <Flex gap={spacings.large} marginBottom={spacings.large}>
          <Box flex={1}>
            <Controller
              name="progressiveMotileA"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  onChange={(ev) => {
                    onChange(ev);
                    calcMotileSpermCount();
                    calcProgressiveMotileSpermCount();
                    calcTotalMotile();
                    calcProgressiveMotile();
                  }}
                  label={`${t('A_PROGRESSIVE_MOTILE').toUpperCase()}`}
                  placeholder={t('A_PROGRESSIVE_MOTILE')}
                  error={!!errors?.progressiveMotileA}
                  helperText={errors?.progressiveMotileA?.message}
                  fullWidth
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                    inputProps: { min: 0 }
                  }}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="progressiveMotileB"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  onChange={(ev) => {
                    onChange(ev);
                    calcMotileSpermCount();
                    calcProgressiveMotileSpermCount();
                    calcTotalMotile();
                    calcProgressiveMotile();
                  }}
                  label={`${t('B_NON_PROGRESSIVE_MOTILE').toUpperCase()}`}
                  placeholder={t('B_NON_PROGRESSIVE_MOTILE')}
                  error={!!errors?.progressiveMotileB}
                  helperText={errors?.progressiveMotileB?.message}
                  fullWidth
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                    inputProps: { min: 0 }
                  }}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="progressiveMotileC"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  onChange={(ev) => {
                    onChange(ev);
                    calcTotalMotile();
                    calcMotileSpermCount();
                  }}
                  label={`${t('C_NON_MOTILE').toUpperCase()}`}
                  placeholder={t('C_NON_MOTILE')}
                  error={!!errors?.progressiveMotileC}
                  helperText={errors?.progressiveMotileC?.message}
                  fullWidth
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                    inputProps: { min: 0 }
                  }}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="immotileD"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  onChange={(ev) => {
                    onChange(ev);
                    calcTotalMotile();
                  }}
                  label={`${t('D_IMMOTILE').toUpperCase()}`}
                  placeholder={t('D_IMMOTILE')}
                  error={!!errors?.immotileD}
                  helperText={errors?.immotileD?.message}
                  fullWidth
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                    inputProps: { min: 0 }
                  }}
                />
              )}
            />
          </Box>
        </Flex>

        <Flex gap={spacings.large} marginBottom={spacings.large}>
          <Box flex={1}>
            <Controller
              name="progressiveMotileSpermCount"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('PROGRESSIVE_MOTILE_SPERM_COUNT').toUpperCase()}
                  placeholder={t('PROGRESSIVE_MOTILE_SPERM_COUNT')}
                  error={!!errors?.progressiveMotileSpermCount}
                  helperText={errors?.progressiveMotileSpermCount?.message}
                  fullWidth
                  InputProps={{
                    readOnly: true
                  }}
                  type="number"
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="motileSpermCount"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={`${t('MOTILE_SPERM_COUNT').toUpperCase()}`}
                  placeholder={t('MOTILE_SPERM_COUNT')}
                  error={!!errors?.motileSpermCount}
                  helperText={errors?.motileSpermCount?.message}
                  fullWidth
                  InputProps={{
                    readOnly: true
                  }}
                  type="number"
                />
              )}
            />
          </Box>

          <Box flex={1}>
            <Controller
              name="totalMotile"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('TOTAL_MOTILITY').toUpperCase()}
                  placeholder={t('TOTAL_MOTILITY')}
                  error={!!errors?.totalMotile}
                  helperText={errors?.totalMotile?.message}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                    readOnly: true
                  }}
                  type="number"
                />
              )}
            />
          </Box>

          <Box flex={1}>
            <Controller
              name="progressiveMotility"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('PROGRESSIVE_MOTILITY').toUpperCase()}
                  placeholder={t('PROGRESSIVE_MOTILITY')}
                  error={!!errors?.progressiveMotility}
                  helperText={errors?.progressiveMotility?.message}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                    readOnly: true
                  }}
                  type="number"
                />
              )}
            />
          </Box>
        </Flex>

        <Flex gap={spacings.large} marginBottom={spacings.large}>
          <Box flex={1}>
            <Controller
              name="ph"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('PH')}
                  placeholder={t('PH')}
                  error={!!errors?.ph}
                  helperText={errors?.ph?.message}
                  fullWidth
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 14 } }}
                />
              )}
            />
          </Box>

          <Box flex={1}>
            <Controller
              name="roundCell"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={`${t('ROUND_CELL').toUpperCase()}`}
                  placeholder={t('ROUND_CELL')}
                  error={!!errors?.roundCell}
                  helperText={errors?.roundCell?.message}
                  fullWidth
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                />
              )}
            />
          </Box>
          <Box flex={1}></Box>
          <Box flex={1}></Box>
        </Flex>

        <Flex gap={spacings.large} marginBottom={spacings.large}>
          <Box flex={1}>
            <Controller
              name="marTest"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={`${t('MAR_TEST').toUpperCase()}`}
                  placeholder={t('MAR_TEST')}
                  error={!!errors?.marTest}
                  helperText={errors?.marTest?.message}
                  fullWidth
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                />
              )}
            />
          </Box>

          <Box flex={1}>
            <Controller
              name="agglutination"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('AGGLUTINATION')}
                  inputRef={ref}
                  error={!!errors?.agglutination}
                  helperText={errors?.agglutination?.message}
                  defaultOption={t('AGGLUTINATION')}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={Object.entries(Agglutination).map(
                    ([key, value]) => ({
                      label: t(key),
                      value
                    })
                  )}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="typeOfAgglutination"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('TYPE_OF_AGGLUTINATION')}
                  inputRef={ref}
                  error={!!errors?.typeOfAgglutination}
                  helperText={errors?.typeOfAgglutination?.message}
                  defaultOption={t('TYPE_OF_AGGLUTINATION')}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={Object.entries(TypeOfAgglutination).map(
                    ([key, value]) => ({
                      label: t(key),
                      value
                    })
                  )}
                />
              )}
            />
          </Box>

          <Box flex={1}></Box>
        </Flex>

        <Box marginBottom={spacings.large}>
          <Controller
            name="resultsGeneralComments"
            control={control}
            render={({ field }) => (
              <TextArea
                {...field}
                label={t('GENERAL_COMMENTS').toUpperCase()}
                placeholder={t('GENERAL_COMMENTS')}
                error={!!errors.resultsGeneralComments}
                helperText={errors?.resultsGeneralComments?.message}
                minRows={3}
                maxRows={3}
                resize
                fullWidth
              />
            )}
          />
        </Box>

        {examReason === ExamReason.IN_CYCLE && (
          <>
            <Box marginBottom={spacings.medium}>
              <Typography
                marginBottom={spacings.medium}
                variant="h2"
                fontWeight={weights.extraBold}
              >
                {t('SPERM_PREP')}
              </Typography>
            </Box>

            <Flex gap={spacings.large} marginBottom={spacings.large}>
              <Box flex={1}>
                <Controller
                  name={'spermPrepStartTime'}
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <TimePicker
                      {...field}
                      label={t('SPERM_PREP_START_TIME').toUpperCase()}
                      inputRef={ref}
                      error={!!errors?.timeReceived}
                      helperText={errors.timeReceived?.message}
                      fullWidth
                    />
                  )}
                />
              </Box>

              <Box flex={1}>
                <Controller
                  name={'spermPrepFinishTime'}
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <TimePicker
                      {...field}
                      label={t('SPERM_PREP_FINISH_TIME').toUpperCase()}
                      inputRef={ref}
                      error={!!errors?.timeReceived}
                      helperText={errors.timeReceived?.message}
                      fullWidth
                    />
                  )}
                />
              </Box>

              <Box flex={1}>
                <Controller
                  name="spermPrepMethod"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Chips
                      helperText={errors?.spermPrepMethod?.message}
                      value={value}
                      options={Object.entries(SpermPrepMethod).map(
                        ([key, value]) => ({
                          label: t(key),
                          value,
                          labelText: t(key)
                        })
                      )}
                      renderSelectedOptionsOutside
                      title={t('SPERM_PREP_METHOD').toUpperCase()}
                      onAddChip={(newChip) => onChange([...value, newChip])}
                      onRemoveChip={(chipValueToDelete) => {
                        const updatedMethods = value.filter(
                          (embryo) => embryo !== chipValueToDelete
                        );
                        onChange(updatedMethods);
                      }}
                      shouldSortOptions
                    />
                  )}
                />
              </Box>

              <Box flex={1}>
                <Controller
                  name="spermPrepSuitability"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <Select
                      {...field}
                      label={t('SPERM_PREP_SUITABILITY')}
                      inputRef={ref}
                      error={!!errors?.spermPrepSuitability}
                      helperText={errors?.spermPrepSuitability?.message}
                      defaultOption={t('SPERM_PREP_SUITABILITY')}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      options={spermPrepSuitabilityOptions}
                    />
                  )}
                />
              </Box>
            </Flex>

            <Flex gap={spacings.large} marginBottom={spacings.large}>
              <Box flex={1}>
                <Controller
                  name="spermPrepVolume"
                  control={control}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={`${t('VOLUME').toUpperCase()} (${t('ML')})`}
                      placeholder={t('VOLUME')}
                      error={!!errors?.spermPrepVolume}
                      helperText={errors?.spermPrepVolume?.message}
                      fullWidth
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {t('ML')}
                          </InputAdornment>
                        ),
                        inputProps: { min: 0 }
                      }}
                      onChange={(ev) => {
                        onChange(ev);
                        calcTotalSpermCount();
                        calcMotileSpermCount();
                        calcProgressiveMotileSpermCount();
                      }}
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="spermPrepConcentration"
                  control={control}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      onChange={(ev) => {
                        onChange(ev);
                        calcMotileSpermCount();
                        calcProgressiveMotileSpermCount();
                        calcTotalSpermCount();
                        calcProgressiveMotile();
                      }}
                      label={`${t('CONCENTRATION').toUpperCase()} (${t(
                        'M/ML'
                      )})`}
                      placeholder={t('CONCENTRATION')}
                      error={!!errors?.spermPrepConcentration}
                      helperText={errors?.spermPrepConcentration?.message}
                      fullWidth
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="spermPrepTotalSpermCount"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={`${t('TOTAL_SPERM_COUNT').toUpperCase()} (${t(
                        'M/EJAC'
                      )})`}
                      placeholder={t('TOTAL_SPERM_COUNT')}
                      error={!!errors?.spermPrepTotalSpermCount}
                      helperText={errors?.spermPrepTotalSpermCount?.message}
                      fullWidth
                      InputProps={{
                        readOnly: true
                      }}
                      type="number"
                    />
                  )}
                />
              </Box>

              <Box flex={1}>
                <Controller
                  name="spermPrepNormalMorphology"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={`${t('NORMAL_MORPHOLOGY').toUpperCase()}`}
                      placeholder={t('NORMAL_MORPHOLOGY')}
                      error={!!errors?.spermPrepNormalMorphology}
                      helperText={errors?.spermPrepNormalMorphology?.message}
                      fullWidth
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  )}
                />
              </Box>
            </Flex>

            <Flex gap={spacings.large} marginBottom={spacings.large}>
              <Box flex={1}></Box>
            </Flex>

            <Flex gap={spacings.large} marginBottom={spacings.large}>
              <Box flex={1}>
                <Controller
                  name="spermPrepProgressiveMotileA"
                  control={control}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      onChange={(ev) => {
                        onChange(ev);
                        calcMotileSpermCount();
                        calcProgressiveMotileSpermCount();
                        calcTotalMotile();
                        calcProgressiveMotile();
                      }}
                      label={`${t('A_PROGRESSIVE_MOTILE').toUpperCase()}`}
                      placeholder={t('A_PROGRESSIVE_MOTILE')}
                      error={!!errors?.spermPrepProgressiveMotileA}
                      helperText={errors?.spermPrepProgressiveMotileA?.message}
                      fullWidth
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        inputProps: { min: 0 }
                      }}
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="spermPrepProgressiveMotileB"
                  control={control}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      onChange={(ev) => {
                        onChange(ev);
                        calcMotileSpermCount();
                        calcProgressiveMotileSpermCount();
                        calcTotalMotile();
                        calcProgressiveMotile();
                      }}
                      label={`${t('B_NON_PROGRESSIVE_MOTILE').toUpperCase()}`}
                      placeholder={t('B_NON_PROGRESSIVE_MOTILE')}
                      error={!!errors?.spermPrepProgressiveMotileB}
                      helperText={errors?.spermPrepProgressiveMotileB?.message}
                      fullWidth
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        inputProps: { min: 0 }
                      }}
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="spermPrepProgressiveMotileC"
                  control={control}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      onChange={(ev) => {
                        onChange(ev);
                        calcTotalMotile();
                        calcMotileSpermCount();
                      }}
                      label={`${t('C_NON_MOTILE').toUpperCase()}`}
                      placeholder={t('C_NON_MOTILE')}
                      error={!!errors?.spermPrepProgressiveMotileC}
                      helperText={errors?.spermPrepProgressiveMotileC?.message}
                      fullWidth
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        inputProps: { min: 0 }
                      }}
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="spermPrepImmotileD"
                  control={control}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      onChange={(ev) => {
                        onChange(ev);
                      }}
                      label={`${t('D_IMMOTILE').toUpperCase()}`}
                      placeholder={t('D_IMMOTILE')}
                      error={!!errors?.spermPrepImmotileD}
                      helperText={errors?.spermPrepImmotileD?.message}
                      fullWidth
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        inputProps: { min: 0 }
                      }}
                    />
                  )}
                />
              </Box>
            </Flex>

            <Flex gap={spacings.large} marginBottom={spacings.large}>
              <Box flex={1}>
                <Controller
                  name="spermPrepProgressiveMotileSpermCount"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={t('PROGRESSIVE_MOTILE_SPERM_COUNT').toUpperCase()}
                      placeholder={t('PROGRESSIVE_MOTILE_SPERM_COUNT')}
                      error={!!errors?.spermPrepProgressiveMotileSpermCount}
                      helperText={
                        errors?.spermPrepProgressiveMotileSpermCount?.message
                      }
                      fullWidth
                      InputProps={{
                        readOnly: true
                      }}
                      type="number"
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="spermPrepMotileSpermCount"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={`${t('MOTILE_SPERM_COUNT').toUpperCase()}`}
                      placeholder={t('MOTILE_SPERM_COUNT')}
                      error={!!errors?.spermPrepMotileSpermCount}
                      helperText={errors?.spermPrepMotileSpermCount?.message}
                      fullWidth
                      InputProps={{
                        readOnly: true
                      }}
                      type="number"
                    />
                  )}
                />
              </Box>

              <Box flex={1}>
                <Controller
                  name="spermPrepTotalMotile"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={t('TOTAL_MOTILITY').toUpperCase()}
                      placeholder={t('TOTAL_MOTILITY')}
                      error={!!errors?.spermPrepTotalMotile}
                      helperText={errors?.spermPrepTotalMotile?.message}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        readOnly: true
                      }}
                      type="number"
                    />
                  )}
                />
              </Box>

              <Box flex={1}>
                <Controller
                  name="spermPrepProgressiveMotility"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={t('PROGRESSIVE_MOTILITY').toUpperCase()}
                      placeholder={t('PROGRESSIVE_MOTILITY')}
                      error={!!errors?.spermPrepProgressiveMotility}
                      helperText={errors?.spermPrepProgressiveMotility?.message}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        readOnly: true
                      }}
                      type="number"
                    />
                  )}
                />
              </Box>
            </Flex>

            <Box flex={1}>
              <Controller
                name="spermPrepComments"
                control={control}
                render={({ field }) => (
                  <TextArea
                    {...field}
                    label={t('GENERAL_COMMENTS').toUpperCase()}
                    placeholder={t('GENERAL_COMMENTS')}
                    error={!!errors.spermPrepComments}
                    helperText={errors?.spermPrepComments?.message}
                    minRows={3}
                    maxRows={3}
                    resize
                    fullWidth
                  />
                )}
              />
            </Box>
          </>
        )}

        {sampleFate === SampleFate.CRYO && (
          <StyledCryoContainer marginTop={spacings.large}>
            <Typography
              marginBottom={spacings.medium}
              variant="h2"
              fontWeight={weights.extraBold}
            >
              {t('CRYO_DETAILS')}
            </Typography>
            <Flex gap={spacings.large} marginBottom={spacings.large}>
              <Box flex={1}>
                <Controller
                  name="cryoDetails.purposeOfFreeze"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <Select
                      {...field}
                      label={t('PURPOSE_OF_FREEZE')}
                      inputRef={ref}
                      error={!!errors?.['cryoDetails.purposeOfFreeze']}
                      helperText={
                        errors?.['cryoDetails.purposeOfFreeze']?.message
                      }
                      defaultOption={t('PURPOSE_OF_FREEZE')}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      options={Object.entries(PurposeOfFreeze).map(
                        ([key, value]) => ({
                          label: t(key),
                          value
                        })
                      )}
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="cryoDetails.numberOfVialsStored"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={`${t('NUMBER_OF_VIALS_STORED').toUpperCase()}`}
                      placeholder={t('NUMBER_OF_VIALS_STORED')}
                      error={!!errors?.['cryoDetails.numberOfVialsStored']}
                      helperText={
                        errors?.['cryoDetails.numberOfVialsStored']?.message
                      }
                      fullWidth
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="cryoDetails.vialType"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <Select
                      {...field}
                      label={t('VIAL_TYPE')}
                      inputRef={ref}
                      error={!!errors?.['cryoDetails.vialType']}
                      helperText={errors?.['cryoDetails.vialType']?.message}
                      defaultOption={t('VIAL_TYPE')}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      options={Object.entries(VialType).map(([key, value]) => ({
                        label: t(key),
                        value
                      }))}
                    />
                  )}
                />
              </Box>
              <Box flex={1}></Box>
            </Flex>
            <Flex gap={spacings.large}>
              <Box flex={1}>
                <Controller
                  name="cryoDetails.consentExpiration"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <DatePicker
                      {...field}
                      label={t('CONSENT_EXPIRATION')}
                      inputRef={ref}
                      error={!!errors?.['cryoDetails.consentExpiration']}
                      helperText={
                        errors?.['cryoDetails.consentExpiration']?.message
                      }
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="cryoDetails.storageLocation"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={`${t('STORAGE_LOCATION').toUpperCase()}`}
                      placeholder={t('STORAGE_LOCATION')}
                      error={!!errors?.['cryoDetails.storageLocation']}
                      helperText={
                        errors?.['cryoDetails.storageLocation']?.message
                      }
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name={'cryoDetails.cryoWitnessedById'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DoctorChips
                      id="add-edit-seminal-analysis-cryo-witnessed-by"
                      showSelectedValue
                      label={t('CRYO_WITNESSED_BY')}
                      value={[`${value}`]}
                      onAddChip={(selectedDoctorId) =>
                        onChange(selectedDoctorId)
                      }
                    />
                  )}
                />
              </Box>
              <Box flex={1}></Box>
            </Flex>
          </StyledCryoContainer>
        )}
        <FormActionsContainer actions={formActions} />
      </form>
    </Box>
  );
};
