import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, css, styled } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Flex from 'src/components/layout/Flex/Flex';
import { containerSizes, spacings } from 'src/components/styles/constants';
import Typography, {
  TypographyProps
} from 'src/components/display/Typography/Typography';
import { Colors } from 'src/components/styles/colors';
import Card from 'src/components/display/Card/Card';
import Box from 'src/components/layout/Box/Box';
import { makeShouldForwardProps } from 'src/components/utils';
import { ArrowDirection, PatientStatus } from '../../../types/patient';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import {
  FertilizationRate,
  KpiCycleStatus,
  PregnancyRate
} from 'src/types/cycle';

const shouldForwardBadgeProp = makeShouldForwardProps(['fontSize']);
const StyledTypography = styled(Typography, {
  shouldForwardProp: shouldForwardBadgeProp
})<TypographyProps>`
  ${({ fontSize }) => fontSize && css(`font-size: ${fontSize}`)}
`;

const KPIContent: FunctionComponent<{
  title: string;
  subTitle: string;
  data?: KpiCycleStatus | PatientStatus | FertilizationRate | PregnancyRate;
}> = ({ title, subTitle, data = false }) => {
  const keys = Object.keys(data || {});

  return (
    <Card
      paddingTop={spacings.xxlarge}
      paddingX={spacings.xxlarge}
      paddingBottom={spacings.small}
      flex={1}
      height={145}
    >
      <Flex height="100%" justifyContent="space-between">
        <Flex
          flexDirection="column"
          justifyContent="center"
          gap={spacings.large}
          height="100%"
        >
          <Flex flexDirection="column" gap={spacings.small}>
            <Typography width={84} variant="h2" color={Colors.emperor}>
              {title}
            </Typography>
          </Flex>
          <Typography variant="h3" color={Colors.silverChalice}>
            {subTitle}
          </Typography>
        </Flex>
        <Flex gap={spacings.large} height="100%">
          {Object.keys(data || {}).length &&
            keys?.map((key, index) => {
              const { value, arrowDirection, isWithPercentage } = data[key];

              return (
                <TitleNumbers
                  key={key}
                  value={value || 0}
                  label={key.toUpperCase()}
                  hideBox={index === keys.length - 1}
                  isWithPercentage={isWithPercentage}
                  arrowDirection={arrowDirection}
                />
              );
            })}
        </Flex>
      </Flex>
    </Card>
  );
};

const TitleNumbers: FunctionComponent<{
  value?: number;
  isWithPercentage?: boolean;
  arrowDirection?: ArrowDirection;
  hideBox?: boolean;
  label: string;
}> = ({
  label,
  isWithPercentage = false,
  arrowDirection = ArrowDirection.none,
  hideBox = false
}) => {
  const formattedNum = '-'; // `${value || 0}`.padStart(2, '0'); // TODO: Uncomment when API is ready

  return (
    <Flex
      flexDirection="row"
      height="100%"
      alignItems="center"
      gap={spacings.xxlarge}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        gap={spacings.xxlarge}
        flex={1}
      >
        <Flex>
          <StyledTypography
            variant="h1"
            fontSize="70px"
            color={Colors.darkGray}
          >
            {formattedNum}
          </StyledTypography>
          {isWithPercentage && (
            <StyledTypography
              variant="h1"
              color={Colors.darkGray}
              alignSelf="end"
            >
              %
            </StyledTypography>
          )}
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          <StyledTypography
            variant="h3"
            color={Colors.silverChalice}
            alignItems="center"
          >
            {label}
          </StyledTypography>

          {arrowDirection === ArrowDirection.up ? (
            <ArrowDropUpIcon color="inherit" />
          ) : arrowDirection === ArrowDirection.down ? (
            <ArrowDropDownIcon color="error" />
          ) : (
            <></>
          )}
        </Flex>
      </Flex>

      {!hideBox && (
        <Box height="80%" border={`1px solid ${Colors.silverChalice}`} />
      )}
    </Flex>
  );
};

export const KPIDashboard: FunctionComponent<{}> = ({}) => {
  const { t } = useTranslation();
  const { getPatientKPIStatuses } = usePatientsApi();
  const { patientId } = useParams();

  const { data: patientData, isLoading: isLoadingPatientStatuses } =
    getPatientKPIStatuses(patientId);

  const { cycleStatus, fertilizationRate, patientStatus, pregnancyRate } =
    patientData || {};

  return (
    <>
      <Helmet>
        <title>{t('PATIENT_KPI_PAGE_TITLE')}</title>
      </Helmet>
      <Flex
        flexDirection="column"
        maxWidth={containerSizes.boxed}
        gap={spacings.xxlarge}
        marginLeft={spacings.xxlarge}
        paddingBottom={spacings.xxlarge}
      >
        <Typography variant="h1" color={Colors.emperor}>
          {t('KPI_PAGE_TITLE')}
        </Typography>
        <Flex gap={spacings.xxlarge}>
          {isLoadingPatientStatuses ? (
            <Flex flex={1} alignItems="center" justifyContent="center">
              <CircularProgress color="inherit" />
            </Flex>
          ) : (
            <KPIContent
              data={patientStatus}
              subTitle={t('KPI_LAST_THIRTY_DAYS')}
              title={t('KPI_PATIENT_STATUS')}
            />
          )}

          {isLoadingPatientStatuses ? (
            <Flex flex={1} alignItems="center" justifyContent="center">
              <CircularProgress color="inherit" />
            </Flex>
          ) : (
            <KPIContent
              data={fertilizationRate}
              subTitle={t('KPI_LAST_THIRTY_DAYS')}
              title={t('KPI_FERTILIZATION_RATE')}
            />
          )}
        </Flex>

        {isLoadingPatientStatuses ? (
          <Flex flex={1} alignItems="center" justifyContent="center">
            <CircularProgress color="inherit" />
          </Flex>
        ) : (
          <Flex>
            <KPIContent
              data={cycleStatus}
              subTitle={t('KPI_LAST_THIRTY_DAYS')}
              title={t('KPI_CYCLE_STATUS')}
            />
          </Flex>
        )}

        {isLoadingPatientStatuses ? (
          <Flex flex={1} alignItems="center" justifyContent="center">
            <CircularProgress color="inherit" />
          </Flex>
        ) : (
          <Flex>
            <KPIContent
              data={pregnancyRate}
              subTitle={t('KPI_LAST_THIRTY_DAYS')}
              title={t('KPI_PREGNANCY_RATE')}
            />
            <Box flex={0.1} />
          </Flex>
        )}
      </Flex>
    </>
  );
};
