import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Flex from 'src/components/layout/Flex/Flex';
import Box from 'src/components/layout/Box/Box';
import Button from 'src/components/display/Button/Button';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Chips, { ChipsVariants } from 'src/components/data-entry/Chips/Chips';
import { YesOrNo } from 'src/types/global';
import { spacings } from 'src/components/styles/constants';
import {
  CycleWizardVariant,
  CycleLocation,
  EggOptions,
  EggSource,
  GeneticOptions,
  NewCycleForm,
  ProtocolTemplates,
  ProtocolTypes,
  SpermOptions,
  SpermSource,
  TreatmentTypes,
  UterusOptions,
  NewCycleFormValues
} from 'src/types/cycle';
import Select from '../../../components/data-entry/Select';
import DatePicker from '../../../components/data-entry/DatePicker';
import InputField from '../../../components/data-entry/InputField';

interface StartNewCycleFormProps {
  variant?: CycleWizardVariant;
  cycleData?: NewCycleFormValues;
  activate?: boolean;
  handleSubmitForm: (details: NewCycleForm) => void;
}

const AddEditNewCycleForm: FC<StartNewCycleFormProps> = ({
  cycleData,
  variant = CycleWizardVariant.INTENDED_CYCLE,
  handleSubmitForm
}) => {
  const { t } = useTranslation();
  const isEditing = !!cycleData?.id;
  const isActivate = variant === CycleWizardVariant.ACTIVATE_CYCLE;
  const isHistorical = cycleData.isHistorical;

  const defaultValues = {
    treatmentType: null,
    startDate: new Date(),
    location: CycleLocation.THIS_CLINIC,
    eggSource: EggSource.OWN,
    spermSource: SpermSource.OWN,
    treatmentProtocol: null,
    treatmentProtocolTemplate: null,
    surrogacy: YesOrNo.NO,
    eggOptions: [],
    spermOptions: [],
    uterusOptions: [],
    geneticOptions: []
  };
  const { handleSubmit, getValues, reset, control, formState } =
    useForm<NewCycleForm>({
      mode: 'onChange',
      defaultValues
    });

  const { errors } = formState;

  useEffect(() => {
    if (cycleData) {
      const {
        eggOptions,
        eggSource,
        geneticOptions,
        location,
        spermOptions,
        spermSource,
        startDate,
        treatmentType,
        treatmentProtocol,
        treatmentProtocolTemplate,
        uterusOptions,
        surrogacy
      } = cycleData;
      reset({
        id: cycleData.id,
        eggOptions: eggOptions || [],
        geneticOptions: geneticOptions || [],
        spermOptions: spermOptions || [],
        uterusOptions: uterusOptions || [],
        eggSource,
        spermSource,
        location,
        startDate: dayjs(startDate).toDate(),
        treatmentType,
        treatmentProtocol,
        treatmentProtocolTemplate,
        surrogacy
      });
    }
  }, [cycleData]);

  const onSubmit = async (details: NewCycleForm) => {
    handleSubmitForm(details);
  };

  return (
    <Box marginTop={spacings.xlarge}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Flex gap={spacings.large}>
          <Flex
            flexDirection={'column'}
            justifyContent="space-between"
            flex={1}
            gap={spacings.large}
          >
            <Box>
              <Controller
                name={'treatmentType'}
                control={control}
                rules={{
                  required: t('PLEASE_SELECT_ONE_TREATMENT')
                }}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    inputRef={ref}
                    label={t('TREATMENT_TYPE')}
                    error={!!errors.treatmentType}
                    helperText={errors.treatmentType?.message}
                    initialDisabled
                    defaultOption={t('PLEASE_CHOOSE_TREATMENT')}
                    options={Object.entries(TreatmentTypes).map(
                      ([label, value]) => ({
                        label: t(label),
                        value
                      })
                    )}
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                name="startDate"
                rules={{
                  required: t('PLEASE_SELECT_DATE'),
                  validate: (value) => {
                    if (variant !== CycleWizardVariant.PREVIOUS_CYCLE) {
                      return true;
                    }

                    if (dayjs(value).isAfter(dayjs())) {
                      return t('DATE_MUST_BE_PREVIOUS');
                    }
                    return true;
                  }
                }}
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <DatePicker
                    {...field}
                    inputRef={ref}
                    label={
                      isActivate ? t('CONFIRM_START_DATE') : t('START_DATE')
                    }
                    error={!!errors.startDate}
                    helperText={errors?.startDate?.message}
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                name={'treatmentProtocol'}
                control={control}
                rules={{
                  required: t('PLEASE_SELECT_ONE_PROTOCOL')
                }}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    inputRef={ref}
                    label={t('PROTOCOL_TYPE')}
                    error={!!errors.treatmentProtocol}
                    helperText={errors.treatmentProtocol?.message}
                    initialDisabled
                    defaultOption={t('PLEASE_CHOOSE_PROTOCOL')}
                    options={Object.entries(ProtocolTypes).map(
                      ([label, value]) => ({
                        label: t(label),
                        value
                      })
                    )}
                    fullWidth
                  />
                )}
              />
            </Box>
            {!isHistorical && (
              <Box>
                <Controller
                  name={'treatmentProtocolTemplate'}
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <Select
                      {...field}
                      // TODO: Remove this when protocol templates feature is implemented
                      disabled
                      inputRef={ref}
                      label={t('PROTOCOL_TEMPLATE')}
                      defaultOption={t('PLEASE_CHOOSE_TEMPLATE')}
                      options={Object.entries(ProtocolTemplates).map(
                        ([label, value]) => ({
                          label: t(label),
                          value
                        })
                      )}
                      fullWidth
                    />
                  )}
                />
              </Box>
            )}
            <Box>
              <Controller
                name={'location'}
                control={control}
                rules={{
                  required: t('PLEASE_SELECT_LOCATION')
                }}
                render={({ field: { ref, ...field } }) =>
                  isHistorical ? (
                    <InputField
                      {...field}
                      inputRef={ref}
                      InputProps={{ inputProps: { min: 0 } }}
                      label={t('LOCATION_OF_TREATMENT')}
                      error={!!errors.location}
                      helperText={errors?.location?.message}
                    />
                  ) : (
                    <Select
                      {...field}
                      inputRef={ref}
                      label={t('LOCATION_OF_TREATMENT')}
                      error={!!errors.location}
                      helperText={errors.location?.message}
                      initialDisabled
                      defaultOption={t('PLEASE_CHOOSE_LOCATION')}
                      options={Object.entries(CycleLocation).map(
                        ([label, value]) => ({
                          label: t(label),
                          value
                        })
                      )}
                      fullWidth
                    />
                  )
                }
              />
            </Box>
            <Box>
              <Controller
                name={'eggSource'}
                control={control}
                rules={{
                  required: t('PLEASE_SELECT_EGG_SOURCE')
                }}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    inputRef={ref}
                    label={t('EGG_SOURCE')}
                    error={!!errors.eggSource}
                    helperText={errors.eggSource?.message}
                    initialDisabled
                    defaultOption={t('PLEASE_CHOOSE_EGG_SOURCE')}
                    options={Object.entries(EggSource).map(
                      ([label, value]) => ({
                        label: t(label),
                        value
                      })
                    )}
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                name={'spermSource'}
                control={control}
                rules={{
                  required: t('PLEASE_SELECT_SPERM_SOURCE')
                }}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    inputRef={ref}
                    label={t('SPERM_SOURCE')}
                    error={!!errors.spermSource}
                    helperText={errors.spermSource?.message}
                    initialDisabled
                    defaultOption={t('PLEASE_CHOOSE_SPERM_SOURCE')}
                    options={Object.entries(SpermSource).map(
                      ([label, value]) => ({
                        label: t(label),
                        value
                      })
                    )}
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                name={'surrogacy'}
                control={control}
                rules={{
                  required: t('YES_OR_NO')
                }}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    inputRef={ref}
                    label={t('SURROGACY')}
                    error={!!errors.surrogacy}
                    helperText={errors.surrogacy?.message}
                    initialDisabled
                    defaultOption={t('PLEASE_CHOOSE_OPTION')}
                    options={Object.entries(YesOrNo).map(([label, value]) => ({
                      label: t(label),
                      value
                    }))}
                    fullWidth
                  />
                )}
              />
            </Box>
          </Flex>
          <Flex flexDirection={'column'} flex={1} gap={spacings.large}>
            <Box height={100}>
              <Controller
                name={'eggOptions'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Chips
                    value={value}
                    variant={ChipsVariants.EXPANDED}
                    options={
                      Object.entries(EggOptions).map(([label, value]) => ({
                        label,
                        value,
                        labelText: label
                      })) || []
                    }
                    title={t('EGG_AND_EMBRYO_OPTIONS')}
                    onAddChip={(newChip) => onChange([...value, newChip])}
                    onRemoveChip={(chipValueToDelete) => {
                      const updatedValues = value.filter(
                        (embryo) => embryo !== chipValueToDelete
                      );
                      onChange(updatedValues);
                    }}
                    shouldSortOptions
                  />
                )}
              />
            </Box>
            <Box height={100}>
              <Controller
                name={'spermOptions'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Chips
                    value={value}
                    variant={ChipsVariants.EXPANDED}
                    options={
                      Object.entries(SpermOptions).map(([label, value]) => ({
                        label,
                        value,
                        labelText: label
                      })) || []
                    }
                    title={t('SPERM_OPTIONS')}
                    onAddChip={(newChip) => onChange([...value, newChip])}
                    onRemoveChip={(chipValueToDelete) => {
                      const updatedValues = value.filter(
                        (embryo) => embryo !== chipValueToDelete
                      );
                      onChange(updatedValues);
                    }}
                    shouldSortOptions
                  />
                )}
              />
            </Box>
            <Box height={100}>
              <Controller
                name={'uterusOptions'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Chips
                    value={value}
                    variant={ChipsVariants.EXPANDED}
                    options={
                      Object.entries(UterusOptions).map(([label, value]) => ({
                        label,
                        value,
                        labelText: label
                      })) || []
                    }
                    title={t('UTERUS_OPTIONS')}
                    onAddChip={(newChip) => onChange([...value, newChip])}
                    onRemoveChip={(chipValueToDelete) => {
                      const updatedValues = value.filter(
                        (embryo) => embryo !== chipValueToDelete
                      );
                      onChange(updatedValues);
                    }}
                    shouldSortOptions
                  />
                )}
              />
            </Box>
            <Box height={100}>
              <Controller
                name={'geneticOptions'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Chips
                    value={value}
                    variant={ChipsVariants.EXPANDED}
                    options={
                      Object.entries(GeneticOptions).map(([label, value]) => ({
                        label,
                        value,
                        labelText: label
                      })) || []
                    }
                    title={t('GENETIC_OPTIONS')}
                    onAddChip={(newChip) => onChange([...value, newChip])}
                    onRemoveChip={(chipValueToDelete) => {
                      const updatedValues = value.filter(
                        (embryo) => embryo !== chipValueToDelete
                      );
                      onChange(updatedValues);
                    }}
                    shouldSortOptions
                  />
                )}
              />
            </Box>
          </Flex>
        </Flex>
      </form>
      <Flex marginTop={spacings.large} justifyContent="flex-end">
        <Box width={'20%'}>
          <Button onClick={handleSubmit(() => onSubmit(getValues()))} fullWidth>
            {isEditing ? t('SAVE_CYCLE') : t('ADD_CYCLE')}
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default AddEditNewCycleForm;
