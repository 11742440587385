import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import InputField from 'src/components/data-entry/InputField/InputField';
import Button from 'src/components/display/Button/Button';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import {
  BladderStatus,
  Catheter,
  Difficulty,
  ExamReason,
  ExamReport,
  OtherExaminationTypes,
  OneTwoThree,
  PositionOfUterus,
  TranferGeneralRemarks
} from 'src/types/exam';
import Loader from 'src/components/display/Loader/Loader';
import dayjs from 'dayjs';
import Select from 'src/components/data-entry/Select/Select';
import Chips, { ChipsVariants } from 'src/components/data-entry/Chips/Chips';
import { YesOrNo } from 'src/types/global';
import { getFullName } from 'src/utils/general';
import { DoctorChips } from '../common/DoctorChips';
import usePatientsApi from '../../../hooks/usePatientsApi';
import useExams from 'src/hooks/useExams';
import useEggsEmbryos from 'src/hooks/useEggsEmbryos';
import InputLabel from 'src/components/data-entry/InputLabel/InputLabel';
import Switch from 'src/components/data-entry/Switch/Switch';

export const AddEditTransferReportForm: FC<{
  patientId?: string;
  cycleId?: string;
  examId?: string;
}> = ({ cycleId, patientId, examId }) => {
  const isEdit = !!examId;
  const { getPatientById, getMedications } = usePatientsApi();
  const { getAliveEggsList } = useEggsEmbryos();
  const { createExamReport, updateExamReport, getExaminationById } = useExams();
  const { t } = useTranslation();

  const { data: examination } = getExaminationById(examId, {
    enabled: !!examId
  });

  const { data: patient, isLoading: isLoadingPatient } = getPatientById(
    examination?.patientId || patientId
  );

  const { data: medications, isLoading: isLoadingMedications } =
    getMedications();
  const { data: eggsList, isLoading: isLoadingEggs } = getAliveEggsList(
    examination?.patientId || patientId
  );

  const { mutate: createReport, isLoading: isCreatingReport } =
    createExamReport();
  const { mutate: updateReport, isLoading: isUpdatingReport } =
    updateExamReport();

  const defaultValues: ExamReport = {
    patientId: patientId,
    cycleId: cycleId,
    patientName: '',
    type: OtherExaminationTypes.EMBRYO_TRANSFER,
    reason: ExamReason.IN_CYCLE,
    dateOfExamination: dayjs().toDate(),
    bladderStatus: null,
    catheter: null,
    easeOfTransfer: null,
    flashSeen: null,
    noOfEmbryosTransferred: null,
    positionOfUterus: null,
    taScan: null,
    medicationsAdministered: [],
    requestingPhysicianId: '',
    performedById: '',
    signedOffById: '',
    generalRemarks: [],
    taScanFindings: [],
    embryosTransferred: [],
    embryosTransferredDeleted: [],
    isNonCompliantET: false,
    nonCompliantETReason: null
  };

  const {
    control,
    formState,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch
  } = useForm<ExamReport>({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    if (examination || patient) {
      reset({
        id: examination?.id,
        patientId: examination?.patientId || patientId,
        cycleId: examination?.cycleId || cycleId,
        patientName: getFullName(patient?.personalInfo),
        type: OtherExaminationTypes.EMBRYO_TRANSFER,
        reason: examination?.reason || ExamReason.IN_CYCLE,
        dateOfExamination: dayjs(examination?.examDate).toDate(),
        bladderStatus: examination?.bladderStatus,
        catheter: examination?.catheter,
        easeOfTransfer: examination?.easeOfTransfer,
        flashSeen: examination?.flashSeen,
        noOfEmbryosTransferred: examination?.noOfEmbryosTransferred,
        positionOfUterus: examination?.positionOfUterus,
        taScan: examination?.taScan,
        medicationsAdministered:
          examination?.medicationsAdministered?.map(
            ({ medicationId }) => medicationId
          ) || [],
        requestingPhysicianId: examination?.requestedPhysician,
        performedById: examination?.performedBy,
        signedOffById: examination?.signedOffBy,
        generalRemarks: examination?.generalRemarks || [],
        taScanFindings: examination?.findings?.map(({ name }) => name) || [],
        embryosTransferred: examination?.eggAndEmbryoTransferred || [],
        embryosTransferredDeleted: [],
        isNonCompliantET: examination?.isNonCompliantET || false,
        nonCompliantETReason: examination?.nonCompliantETReason || null
      });
    }
  }, [examination, patient]);

  const { errors } = formState;

  const onSubmit = async (report: ExamReport) => {
    if (isEdit) {
      await updateReport(report);
    } else {
      await createReport(report);
    }
  };

  if (isLoadingPatient) return <Loader />;

  const { noOfEmbryosTransferred, isNonCompliantET } = watch();
  const showNonCompliantETFields =
    noOfEmbryosTransferred === OneTwoThree.TWO ||
    noOfEmbryosTransferred === OneTwoThree.THREE;

  return (
    <Box marginTop={spacings.large}>
      <form noValidate>
        <Flex gap={spacings.xlarge}>
          <Box flex={1} marginBottom={spacings.large}>
            <Controller
              name="patientName"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  disabled
                  label={t('PATIENT_NAME')}
                  placeholder={t('PATIENT_NAME')}
                  error={!!errors.patientName}
                  helperText={errors?.patientName?.message}
                  required
                  fullWidth
                />
              )}
            />
          </Box>
          <Flex flex={1} gap={spacings.large} marginBottom={spacings.medium}>
            <Box flex={1} marginBottom={spacings.large}>
              <Controller
                name={'requestingPhysicianId'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DoctorChips
                    id="add-edit-transfer-report-form-requesting-physician"
                    showSelectedValue
                    label={t('REQUESTING_PROVIDER')}
                    value={[value]}
                    onAddChip={(newSelectedDoctorId) =>
                      onChange(newSelectedDoctorId)
                    }
                  />
                )}
              />
            </Box>
            <Box flex={1}>
              <Controller
                name={'performedById'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DoctorChips
                    id="add-edit-transfer-report-form-performed-by"
                    showSelectedValue
                    label={t('PERFORMED_BY')}
                    value={[value]}
                    onAddChip={(newSelectedDoctorId) =>
                      onChange(newSelectedDoctorId)
                    }
                  />
                )}
              />
            </Box>
            <Box flex={1}>
              <Controller
                name={'signedOffById'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DoctorChips
                    id="add-edit-transfer-report-form-signed-off-by"
                    showSelectedValue
                    label={t('SIGNED_OFF_BY')}
                    value={[value]}
                    onAddChip={(newSelectedDoctorId) =>
                      onChange(newSelectedDoctorId)
                    }
                  />
                )}
              />
            </Box>
          </Flex>
        </Flex>

        <Flex gap={spacings.xlarge}>
          <Box flex={1} marginBottom={spacings.large}>
            <Controller
              name={'dateOfExamination'}
              control={control}
              rules={{
                required: t('DATE_REQUIRED'),
                validate: (value) => {
                  const date = dayjs(value);
                  if (date.isAfter(dayjs())) {
                    return t('DATE_CANT_BE_IN_THE_FUTURE');
                  }
                  return true;
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <DatePicker
                  {...field}
                  label={t('DATE_OF_PROCEDURE')}
                  inputRef={ref}
                  error={!!errors?.dateOfExamination}
                  helperText={errors.dateOfExamination?.message}
                  fullWidth
                />
              )}
            />
          </Box>

          <Box flex={1}>
            <Flex marginBottom={spacings.large} gap={spacings.large}>
              <Box flex={1}>
                <Controller
                  name="noOfEmbryosTransferred"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <Select
                      {...field}
                      label={t('NO_OF_EMBRYOS_TRANSFERED')}
                      inputRef={ref}
                      error={!!errors?.noOfEmbryosTransferred}
                      helperText={errors?.noOfEmbryosTransferred?.message}
                      defaultOption={t('NO_OF_EMBRYOS_TRANSFERED')}
                      options={Object.keys(OneTwoThree).map((number) => ({
                        label: t(number),
                        value: number
                      }))}
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name={'embryosTransferred'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Chips
                      value={value}
                      options={
                        eggsList?.map((egg) => ({
                          value: egg.id,
                          label: egg.id,
                          labelText: egg.id
                        })) || []
                      }
                      renderSelectedOptionsOutside
                      title={t('EMBRYOS_TRANSFERED')}
                      onAddChip={(newChip) => onChange([...value, newChip])}
                      onRemoveChip={(chipValueToDelete) => {
                        const updatedEmbryos = value.filter(
                          (embryo) => embryo !== chipValueToDelete
                        );
                        onChange(updatedEmbryos);
                        if (
                          examination?.eggAndEmbryoTransferred?.includes(
                            chipValueToDelete
                          )
                        ) {
                          setValue('embryosTransferredDeleted', [
                            ...getValues('embryosTransferredDeleted'),
                            chipValueToDelete
                          ]);
                        }
                      }}
                      shouldSortOptions
                    />
                  )}
                />
              </Box>
            </Flex>
            {showNonCompliantETFields && (
              <Flex marginBottom={spacings.large} gap={spacings.large}>
                <Box flex={1}>
                  <Controller
                    name="isNonCompliantET"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Box>
                        <InputLabel
                          label={t(
                            'IS_NON_COMPLIANT_ET_QUESTION'
                          ).toUpperCase()}
                        />
                        <Switch
                          checked={value}
                          switchOnText={t('YES')}
                          switchOffText={t('NO')}
                          onChange={(_, checked) => onChange(checked)}
                        />
                      </Box>
                    )}
                  />
                </Box>
                {isNonCompliantET && (
                  <Box flex={1}>
                    <Controller
                      name={'nonCompliantETReason'}
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <InputField
                          {...field}
                          inputRef={ref}
                          label={t('REASON_FOR_NON_COMPLIANT_ET').toUpperCase()}
                          placeholder={t('TYPE_IN_THE_REASON')}
                          error={!!errors.nonCompliantETReason}
                          helperText={errors.nonCompliantETReason?.message}
                          fullWidth
                        />
                      )}
                    />
                  </Box>
                )}
              </Flex>
            )}
          </Box>
        </Flex>

        <Flex gap={spacings.xlarge} marginBottom={spacings.large}>
          <Box flex={1}>
            <Flex gap={spacings.large} marginBottom={spacings.large}>
              <Controller
                name="taScan"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    label={t('TA_SCAN')}
                    inputRef={ref}
                    error={!!errors?.taScan}
                    helperText={errors?.taScan?.message}
                    defaultOption={t('TA_SCAN')}
                    options={Object.keys(YesOrNo).map((option) => ({
                      label: t(option),
                      value: option
                    }))}
                  />
                )}
              />
              <Controller
                name="flashSeen"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    label={t('FLASH_SEEN')}
                    inputRef={ref}
                    error={!!errors?.flashSeen}
                    helperText={errors?.flashSeen?.message}
                    defaultOption={t('FLASH_SEEN')}
                    options={Object.keys(YesOrNo).map((option) => ({
                      label: t(option),
                      value: option
                    }))}
                  />
                )}
              />
            </Flex>

            <Flex gap={spacings.large} marginBottom={spacings.large}>
              <Controller
                name="positionOfUterus"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    label={t('POSITION_OF_UTERUS')}
                    inputRef={ref}
                    error={!!errors?.positionOfUterus}
                    helperText={errors?.positionOfUterus?.message}
                    defaultOption={t('POSITION_OF_UTERUS')}
                    options={Object.entries(PositionOfUterus).map(
                      ([key, value]) => ({
                        label: t(key),
                        value
                      })
                    )}
                  />
                )}
              />
              <Controller
                name="catheter"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    label={t('CATHETER')}
                    inputRef={ref}
                    error={!!errors?.catheter}
                    helperText={errors?.catheter?.message}
                    defaultOption={t('CATHETER')}
                    options={Object.entries(Catheter).map(([key, value]) => ({
                      label: t(key),
                      value
                    }))}
                  />
                )}
              />
            </Flex>
            <Flex gap={spacings.large}>
              <Controller
                name="bladderStatus"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    label={t('BLADDER_STATUS')}
                    inputRef={ref}
                    error={!!errors?.bladderStatus}
                    helperText={errors?.bladderStatus?.message}
                    defaultOption={t('BLADDER_STATUS')}
                    options={Object.entries(BladderStatus).map(
                      ([key, value]) => ({
                        label: t(key),
                        value
                      })
                    )}
                  />
                )}
              />
              <Controller
                name="easeOfTransfer"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    label={t('EASE_OF_TRANSFER')}
                    inputRef={ref}
                    error={!!errors?.easeOfTransfer}
                    helperText={errors?.easeOfTransfer?.message}
                    defaultOption={t('EASE_OF_TRANSFER')}
                    options={Object.entries(Difficulty).map(([key, value]) => ({
                      label: t(key),
                      value
                    }))}
                  />
                )}
              />
            </Flex>
          </Box>
          <Box flex={1}>
            <Controller
              name={'taScanFindings'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Chips
                  chipsInputSx={{ height: '100%' }}
                  variant={ChipsVariants.EXPANDED}
                  value={value}
                  allowAddingNewOptions
                  title={t('TA_SCAN_FINDINGS')}
                  onAddChip={(newChip) => onChange([...value, newChip])}
                  onRemoveChip={(chipValue) => {
                    const updatedFindings = value.filter(
                      (finding) => finding !== chipValue
                    );
                    onChange(updatedFindings);
                  }}
                />
              )}
            />
          </Box>
        </Flex>

        <Flex gap={spacings.xlarge}>
          <Box flex={1}>
            <Controller
              name="medicationsAdministered"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Chips
                  variant={ChipsVariants.EXPANDED}
                  options={medications?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                    labelText: name
                  }))}
                  value={value}
                  title={t('MEDICATIONS_ADMINISTERED')}
                  onAddChip={(newChip) => onChange([...value, newChip])}
                  onRemoveChip={(chipValue) => {
                    const updatedFindings = value.filter(
                      (finding) => finding !== chipValue
                    );
                    onChange(updatedFindings);
                  }}
                  shouldSortOptions
                />
              )}
            />
          </Box>

          <Box flex={1}>
            <Controller
              name={'generalRemarks'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Chips
                  variant={ChipsVariants.EXPANDED}
                  allowAddingNewOptions
                  value={value}
                  options={Object.keys(TranferGeneralRemarks).map((remark) => ({
                    value: remark,
                    label: t(remark),
                    labelText: t(remark)
                  }))}
                  title={t('GENERAL_REMARKS')}
                  onAddChip={(newChip) => onChange([...value, newChip])}
                  onRemoveChip={(chipValueToDelete) => {
                    const updatedRemarks = value.filter(
                      (remark) => remark !== chipValueToDelete
                    );
                    onChange(updatedRemarks);
                  }}
                  shouldSortOptions
                />
              )}
            />
          </Box>
        </Flex>

        <Box marginBottom={spacings.medium}></Box>
        <Flex justifyContent="center" marginTop={spacings.large} width="20%">
          <Button
            fullWidth
            disabled={
              isCreatingReport ||
              isUpdatingReport ||
              isLoadingEggs ||
              isLoadingMedications
            }
            onClick={handleSubmit(onSubmit)}
            type="button"
          >
            {t('SUBMIT')}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};
