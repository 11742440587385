import { FC, useMemo, useState } from 'react';
import Flex from 'src/components/layout/Flex/Flex';
import Loader from 'src/components/display/Loader/Loader';
import { iconSizes, spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography/Typography';
import { weights } from 'src/components/styles/fonts';
import { useTranslation } from 'react-i18next';
import {
  GridColDef,
  GridEditInputCell,
  GridRenderEditCellParams
} from '@mui/x-data-grid-premium';
import Box from 'src/components/layout/Box/Box';
import Table from 'src/components/display/Table/Table';
import { GeneticStatus } from 'src/types/patient';
import { YesOrNo } from 'src/types/global';
import { Colors } from 'src/components/styles/colors';
import {
  StyledDefaultInputWrapper,
  StyledSelect
} from '../treatment/EggAndEmbryoTable';
import Center from 'src/components/layout/Center/Center';
import { styled } from '@mui/material';

import useGameteThaw from 'src/hooks/useGameteThaw';
import { Gamete, GameteThawReport, GameteThawed } from 'src/types/cycle';
import { Fate } from 'src/types/eggAndEmbryo';

export enum GameteThawTableVariant {
  DEFAULT = 'default',
  SHORT = 'short'
}

interface GameteThawTableProps {
  variant?: GameteThawTableVariant;
  cycleId: string;
  patientId: string;
}

interface GridRowDef {
  id: string;
  type: TableRowType;
  [key: number]: string | number;
}

enum TableRowType {
  ID = 'ID',
  EMBRYOS_THAWED = 'EMBRYOS_THAWED',
  GRADE_AT_FREEZE = 'GRADE_AT_FREEZE',
  GRADE_AT_THAW = 'GRADE_AT_THAW',
  GRADE_AT_ET = 'GRADE_AT_ET',
  ASSISTED_HATCHING = 'ASSISTED_HATCHING',
  BIOPSY = 'BIOPSY',
  BIOPSY_RESULTS = 'BIOPSY_RESULTS',
  PLOIDY = 'PLOIDY',
  FATE = 'FATE'
}

const getCellBackgroundColor = (rowType: TableRowType): string => {
  const grayCellsRowType = [
    TableRowType.EMBRYOS_THAWED,
    TableRowType.ID,
    TableRowType.GRADE_AT_FREEZE
  ];

  if (grayCellsRowType.includes(rowType)) {
    return Colors.mercury;
  }

  return Colors.dawnPink;
};

const getTotalToDisplay = (
  rowType: TableRowType,
  { cryo, euploid, embryosThawed }: GameteThawReport
): number | null => {
  switch (rowType) {
    case TableRowType.EMBRYOS_THAWED:
    case TableRowType.GRADE_AT_ET:
      return embryosThawed;
    case TableRowType.PLOIDY:
      return euploid;
    case TableRowType.FATE:
      return cryo;
    default:
      return null;
  }
};

const getCellContent = (
  rowType: TableRowType,
  {
    fate,
    assistedHatching,
    biopsy,
    biopsyResults,
    gradeAtThaw,
    gradeAtTransfer,
    gradeAtFreeze,
    geneticStatus,
    eggAndEmbryoId,
    semenVialId,
    gameteType
  }: GameteThawed
): string | number => {
  switch (rowType) {
    case TableRowType.ID:
      return gameteType === Gamete.SEMEN ? semenVialId : eggAndEmbryoId;
    case TableRowType.EMBRYOS_THAWED:
      return '';
    case TableRowType.GRADE_AT_FREEZE:
      return gradeAtFreeze;
    case TableRowType.GRADE_AT_THAW:
      return gradeAtThaw;
    case TableRowType.GRADE_AT_ET:
      return gradeAtTransfer;
    case TableRowType.ASSISTED_HATCHING:
      return assistedHatching ? YesOrNo.YES : YesOrNo.NO;
    case TableRowType.BIOPSY:
      return biopsy ? YesOrNo.YES : YesOrNo.NO;
    case TableRowType.BIOPSY_RESULTS:
      return biopsyResults;
    case TableRowType.PLOIDY:
      return geneticStatus;
    case TableRowType.FATE:
      return fate;
  }
};

const CustomEditComponent: FC<{ params: GridRenderEditCellParams }> = ({
  params
}) => {
  const { t } = useTranslation();

  const {
    row: { type },
    api,
    id,
    field
  } = params;

  const handleSelectUpdateValue = async (newValue: string | boolean) => {
    await api.setEditCellValue({
      id,
      field,
      value: newValue
    });
    api.stopCellEditMode({
      field,
      id
    });
  };

  switch (type) {
    case TableRowType.PLOIDY:
      return (
        <StyledSelect
          options={Object.entries(GeneticStatus).map(([key, value]) => ({
            label: t(key),
            value
          }))}
          onChange={async (ev) => handleSelectUpdateValue(ev.target.value)}
        />
      );
    case TableRowType.FATE:
      return (
        <StyledSelect
          options={Object.entries(Fate).map(([key, value]) => ({
            label: t(key),
            value
          }))}
          onChange={async (ev) => handleSelectUpdateValue(ev.target.value)}
        />
      );
    case TableRowType.ASSISTED_HATCHING:
    case TableRowType.BIOPSY:
      return (
        <StyledSelect
          options={Object.entries(YesOrNo).map(([key, value]) => ({
            label: t(key),
            value
          }))}
          onChange={async (ev) =>
            handleSelectUpdateValue(ev.target.value === YesOrNo.YES)
          }
        />
      );
    default:
      return (
        <StyledDefaultInputWrapper>
          <GridEditInputCell {...params} />
        </StyledDefaultInputWrapper>
      );
  }
};

export const StyledTypesTable = styled(Table)`
  .MuiDataGrid-cell {
    border: 0;
  }
`;

export const StyledTable = styled(Table)`
  .MuiDataGrid-cell:first-of-type {
    padding-inline-start: 0;
  }
`;

export const GameteThawTable: FC<GameteThawTableProps> = ({
  variant = GameteThawTableVariant.DEFAULT,
  cycleId,
  patientId
}) => {
  const { t } = useTranslation();
  const { getGameteThawReport, updateGameteThawed } = useGameteThaw();
  const [editingCell, setEditingCell] = useState<string>(null);

  const { data: thawReport, isLoading } = getGameteThawReport(
    patientId,
    cycleId,
    {
      enabled: !!cycleId && !!patientId
    }
  );

  const { mutate: handleUpdateGamete } = updateGameteThawed();

  const { gametes } = thawReport || {};

  const rows: GridRowDef[] = useMemo(() => {
    if (!gametes?.length) return [];

    const rowTypesToShow: TableRowType[] = [];

    Object.values(TableRowType).forEach((rowType: TableRowType) => {
      const rowsToShowInShortVariant = [
        TableRowType.GRADE_AT_FREEZE,
        TableRowType.ASSISTED_HATCHING,
        TableRowType.BIOPSY_RESULTS,
        TableRowType.GRADE_AT_ET,
        TableRowType.FATE
      ];

      if (
        (variant === GameteThawTableVariant.SHORT &&
          rowsToShowInShortVariant.includes(rowType)) ||
        variant !== GameteThawTableVariant.SHORT
      ) {
        rowTypesToShow.push(rowType);
      }
    });

    return rowTypesToShow.map((rowType: TableRowType, index) => {
      return {
        id: `${index}`,
        type: rowType,
        total: getTotalToDisplay(rowType, thawReport),
        ...gametes.reduce((acc, egg) => {
          acc[egg.id] = getCellContent(rowType, egg) || '';
          return acc;
        }, {})
      };
    });
  }, [gametes]);

  const rowTypesColumns: GridColDef[] = useMemo(
    () => [
      {
        field: 'type',
        headerName: '',
        width: 170,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <Box paddingLeft={spacings.medium}>
              <Typography
                fontWeight={weights.extraBold}
                textTransform="uppercase"
              >
                {t(row.type)}
              </Typography>
            </Box>
          );
        }
      }
    ],
    [gametes]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'total',
        headerName: '#',
        renderHeader: ({ colDef }) => (
          <Typography>{colDef.headerName}</Typography>
        ),
        align: 'center',
        headerAlign: 'center',
        width: 30,
        minWidth: 30,
        sortable: false
      },
      {
        field: 'percentage',
        headerName: '%',
        renderHeader: ({ colDef }) => (
          <Typography>{colDef.headerName}</Typography>
        ),
        align: 'center',
        headerAlign: 'center',
        width: 45,
        sortable: false,
        renderCell: () => <></>
      },
      ...(gametes?.map(({ id }, index) => {
        return {
          field: `${id}`,
          sortable: false,
          headerName: `${index + 1}`.padStart(2, '0'),
          renderHeader: ({ colDef }) => (
            <Typography>{colDef.headerName}</Typography>
          ),
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          width: 45,
          editable: true,
          renderEditCell: (params) => <CustomEditComponent params={params} />,
          renderCell: ({ colDef, value, row }) => {
            const cellBgColor =
              variant === 'short'
                ? Colors.white
                : getCellBackgroundColor(row.type);

            return (
              <Box bgcolor={cellBgColor} width="100%" height="100%">
                <Box title={value}>
                  <Typography
                    noWrap
                    color={
                      cellBgColor === Colors.mineShaft
                        ? Colors.white
                        : undefined
                    }
                    fontWeight={weights.extraBold}
                    align={colDef.align}
                  >
                    {value}
                  </Typography>
                </Box>
              </Box>
            );
          }
        } as GridColDef;
      }) || [])
    ],
    [thawReport]
  );

  const handleEditCell = async (
    rowType: TableRowType,
    newValue: string | number
  ) => {
    let fieldToUpdate: string;

    switch (rowType) {
      case TableRowType.GRADE_AT_THAW:
        fieldToUpdate = 'gradeAtThaw';
        break;
      case TableRowType.GRADE_AT_ET:
        fieldToUpdate = 'gradeAtTransfer';
        break;
      case TableRowType.ASSISTED_HATCHING:
        fieldToUpdate = 'assistedHatching';
        break;
      case TableRowType.BIOPSY:
        fieldToUpdate = 'biopsy';
        break;
      case TableRowType.BIOPSY_RESULTS:
        fieldToUpdate = 'biopsyResults';
        break;
      case TableRowType.PLOIDY:
        fieldToUpdate = 'geneticStatus';
        break;
      case TableRowType.FATE:
        fieldToUpdate = 'fate';
        break;
      default:
        return;
    }

    await handleUpdateGamete({
      patientId,
      gameteId: editingCell,
      cycleId,
      gamete: { [fieldToUpdate]: newValue }
    });
  };

  if (isLoading)
    return (
      <Center height="100%">
        <Loader size={iconSizes.xlarge} />
      </Center>
    );

  return !gametes?.length ? (
    <Center height="100%">
      <Typography fontWeight={weights.extraBold}>{t('NO_DATA')}</Typography>
    </Center>
  ) : (
    <Flex width={'100%'}>
      <StyledTypesTable
        columns={rowTypesColumns}
        rows={rows}
        variant="grid"
        autoHeight
        hideFooter
        paginationModel={{ pageSize: rows.length, page: 0 }}
        columnHeaderHeight={25}
        disableColumnMenu
      />
      <Box overflow={'auto'} flex={1}>
        <StyledTable
          columns={columns}
          rows={rows}
          initialState={{
            columns: {
              columnVisibilityModel: {
                type: false,
                total: variant !== 'short',
                percentage: variant !== 'short'
              }
            }
          }}
          hasBorder
          variant="grid"
          autoHeight
          hideFooter
          paginationModel={{ pageSize: rows.length, page: 0 }}
          columnHeaderHeight={25}
          disableColumnMenu
          processRowUpdate={async (newRow, oldRow) => {
            if (newRow[editingCell] === oldRow[editingCell]) return newRow;

            await handleEditCell(newRow.type, newRow[editingCell]);
            return newRow;
          }}
          onCellEditStart={(props) => {
            setEditingCell(props.field);
          }}
          editMode="cell"
          isCellEditable={({ row }: { row: GridRowDef }) =>
            variant !== 'short' &&
            row.type !== TableRowType.ID &&
            row.type !== TableRowType.EMBRYOS_THAWED &&
            row.type !== TableRowType.GRADE_AT_FREEZE
          }
        />
      </Box>
    </Flex>
  );
};
