import { PatientPersonalInfo } from './patient';

export enum FeedActivityEventType {
  APPOINTMENT = 'APPOINTMENT',
  CYCLE = 'CYCLE',
  DOCUMENTS = 'DOCUMENTS',
  BILLING = 'BILLING',
  MEDICAL_CHART = 'MEDICAL_CHART',
  PATIENT_INFORMATION = 'PATIENT_INFORMATION',
  PROGRESS_NOTE = 'PROGRESS_NOTE',
  ENCOUNTER = 'ENCOUNTER'
}

export interface FeedActivityServer
  extends Omit<FeedActivity, 'patientName' | 'patient'> {
  patient?: Pick<
    PatientPersonalInfo,
    'firstName' | 'lastName' | 'middleInitial'
  >;
}

export interface FeedActivity {
  id: string;
  patientId: string;
  summary?: string;
  name?: string;
  eventType: FeedActivityEventType;
  isFlagged?: boolean;
  appointmentId?: number;
  cycleId?: string;
  invoiceId?: string;
  documentId?: string;
  createdAt: Date;
  updatedAt: Date;
  createdByStaff: string;
  updatedByStaff?: string;
  patientName?: string;
}

export interface AddProgressNoteForm {
  patientId: string;
  name: string;
  summary: string;
}

export interface FlagActivityRequest {
  activityId: string;
  isFlagged: boolean;
}
