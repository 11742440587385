const enSessionTranslations = {
  LOGOUT_TITLE: 'Are you still there?',
  LOGOUT_CONTENT:
    'You’ve been inactive for 10 minutes and your session will soon expire. Do you want to stay logged in?',
  LOGOUT_ACTION_TITLE: "Yes, I'm still here",
  BACK_TO_DASHBOARD: 'Back to Dashboard',
  COMING_SOON_PAGE_TITLE: 'Status - Coming Soon',
  COMING_SOON: 'Coming soon',
  TIMES_UP: "Time's up!",
  COMING_SOON_MSG:
    "We're working on implementing the last features before our launch!",
  COMING_SOON_TITLE: '',
  FACEBOOK_TITLE: 'Facebook',
  TWITTER_TITLE: 'Twitter',
  INSTAGRAM_TITLE: 'Instagram',
  NOTIFY_ME: 'Notify Me',
  WELL_EMAIL_YOU_MSG: "We'll email you once our website is launched!",
  ENTER_EMAIL_ADDRESS: 'Enter your email address here...',
  MAINTENANCE_PAGE_TITLE: 'Status - Maintenance',
  MAINTENANCE_MSG: 'The site is currently down for maintenance',
  MAINTENANCE_SUB_MSG: 'We apologize for any inconveniences caused',
  PHONE: 'Phone',
  FOUR_O_FOUR_PAGE_TITLE: 'Status - 404',
  FOUR_O_FOUR_MSG: "The page you were looking for doesn't exist.",
  FOUR_O_FOUR_SUB_MSG:
    "It's on us, we moved the content to a different page. The search below should help!",
  SERACH_TERMS_PLACEHOLDER: 'Search terms here...',
  SERACH_TERMS_HELPER: 'Start typing to see the search results...',
  SEARCH: 'Search',
  GO_TO_HOME_PAGE: 'Go to homepage',
  ERROR_IMG_ALT: '500',
  ERROR_PAGE_TITLE: 'Status - 500',
  ERROR_MSG: 'There was an error, please try again later',
  ERROR_SUB_MSG:
    'The server encountered an internal error and was not able to complete your request',
  REFRESH_VIEW: 'Refresh view',
  GO_BACK: 'Go back',
  OVERVIEW: 'Overview',
  RECENT_SEARCHES: 'Recent searches',
  SAVED_SEARCHES: 'Saved searches',
  SAVE_THIS_SEARCH: 'Save this search',
  REMOVE_SEARCH_FROM_HISTORY: 'Remove this search from history',
  RECENT_ORDERS: 'Recent orders',
  PRODUCTS_LIST: 'Products list',
  FILE_MANAGER: 'File manager',
  CALENDAR: 'Calendar',
  MANAGEMENT: 'Management',
  BANKING_INTERFACE: 'Banking interface',
  MONITORING_ADMIN: 'Monitoring admin',
  STATISTICS_DASHBOARD: 'Statistics dashboard',
  TASKS_FOR_TODAY: 'Tasks for today',
  DASHBOARD: 'Dashboard',
  REQUIRING_ATTENTION: 'Requiring attention',
  POPULAR_SEARCHES: 'Popular searches',
  REMOVE_SEARCH_FROM_FAVORITES: 'Remove this search from favourites',
  SIGN_OUT: 'Sign out',
  CLINIC_SETTINGS: 'Clinic settings',
  ADD_NEW_PATIENT: 'Add new patient',
  EDIT_PATIENT: 'Edit patient',
  BLOODS: 'Bloods',
  ADD_FOLLICULAR_SCAN: 'Add follicular scan',
  ULTRASOUND: 'Ultrasound',
  EGG_RETRIEVAL: 'Egg retrieval',
  SPERM: 'Sperm',
  EMBRYO: 'Embryo',
  PATIENT_NAME: 'PATIENT NAME',
  ADD_LABS: 'Add Labs',
  SELECT_STAFF: 'Select Staff',
  PHISICIAN: 'Phisician',
  STOP_MEDICATIONS_LABEL:
    'Would you like to end any ongoing medications associated with this cycle?',
  YES: 'Yes',
  NO: 'No',
  PLEASE_CHOOSE_CATEGORY: 'Please choose category',
  PLEASE_CHOOSE_PRIORITY: 'Please choose priority',

  OOPS_SOMETHING_WENT_WRONG: 'Oops! Something went wrong.',
  THIS_PAGE_DOESNT_EXIST: "This page doesn't exist",
  PLEASE_CONTACT_SUPPORT: 'Please contact support at',
  CANNOT_BE_PERFORMED:
    'This action cannot be performed, please contact support if you believe it is an error',
  SLOW_DOWN: 'Please slow down a bit',
  TRY_AGAIN_IF_PERSISTS_CONTACT_SUPPORT:
    'Please try again, if this issue persists, please contact support',
  ONE_ALEADY_EXISTS: 'One already exists',
  TRY_AGAIN: 'Please try again',
  TRY_AGAIN_LATER: 'Please wait a bit before retrying',
  REPORT_TO_EMBIE_MAIL: 'Please report to {{email}}',
  PLEASE_LOG_IN: 'Please log in or provide valid credentials',
  CONTACT_CLINIC_ADMIN: 'Contact your clinic administrator',
  REQUEST_400_TOAST_TEMPLATE: 'This request for {{action}} is not valid',
  REQUEST_401_TOAST_TEMPLATE:
    'You do not have proper permissions for {{action}}',
  REQUEST_403_TOAST_TEMPLATE: 'This request for {{action}} is forbidden',
  REQUEST_404_TOAST_TEMPLATE: 'The resources for {{action}} could not be found',
  REQUEST_409_TOAST_TEMPLATE: 'Failed the request of {{action}}',
  REQUEST_422_TOAST_TEMPLATE: 'This request for {{action}} is unprocessable',
  REQUEST_429_TOAST_TEMPLATE:
    'Too many request for {{action}} are being sent in a certain amount of time',
  REQUEST_500_TOAST_TEMPLATE: 'Something went wrong while {{action}}',

  SUBTITLE_ONGOING_CYCLE_ALREADY_EXISTS:
    'An ongoing cycle already exists, please make sure to complete it before starting a new one',
  SUBTITLE_CANNOT_HAVE_TWO_FOLLICULAR_EXAMS_IN_ONE_DAY:
    'Cannot have two follicular exams in one day',
  SUBTITLE_PATIENT_ALREADY_EXISTS: 'A patient with this email already exists',
  SUBTITLE_STAFF_MEMBER_ALREADY_EXISTS:
    'A staff with this email already exists',
  SUBTITLE_CANNOT_CREATE_PRESCRIPTION_GAP:
    'This creates a gap in the current prescription, please create a new one',
  SUBTITLE_CANNOT_DELETE_APPOINTMENT_IN_THE_PAST:
    'Cannot delete past appointments',

  ACTION_TITLE_GET_APPOINTMENTS: 'getting appointments',
  ACTION_TITLE_GET_APPOINTMENT: 'getting an appointment',
  ACTION_TITLE_CREATE_APPOINTMENTS: 'creating an appointment',
  ACTION_TITLE_GET_PATIENT_APPOINTMENTS: 'getting patient appointments',
  ACTION_TITLE_UPDATE_APPOINTMENT: 'updating an appointment',
  ACTION_TITLE_UPDATE_APPOINTMENT_STATUS: 'updating appointment status',
  ACTION_TITLE_DELETE_APPOINTMENT: 'deleting an appointment',
  ACTION_TITLE_GET_CLINIC_ROOMS: 'getting clinic rooms',
  ACTION_TITLE_GET_CLINIC_ROOMS_BUSY_TIMES: 'getting clinic rooms busy times',
  ACTION_TITLE_GET_STAFF_BUSY_TIMES: 'getting staff busy times',
  ACTION_TITLE_GET_BILLING_STATS: 'getting billing stats',
  ACTION_TITLE_GET_INVOICES: 'getting invoices',
  ACTION_TITLE_GET_INVOICE: 'getting an invoice',
  ACTION_TITLE_GET_BILLING_PROFILE: 'getting billing profile',
  ACTION_TITLE_CREATE_INVOICE: 'creating an invoice',
  ACTION_TITLE_UPDATE_INVOICE: 'updating an invoice',
  ACTION_TITLE_UPDATE_INVOICE_STATUS: 'updating the status of an invoice',
  ACTION_TITLE_UPDATE_INVOICE_METHOD: 'updating an invoice method',
  ACTION_TITLE_ADD_CARE_TEAM_MEMBER: 'adding care team member',
  ACTION_TITLE_GET_PRIMARY_CARE_TEAM_MEMBER: 'getting primary care team member',
  ACTION_TITLE_GET_CARE_TEAM_MEMBERS: 'getting care team members',
  ACTION_TITLE_REMOVE_CARE_TEAM_MEMBER: 'removing care team member',
  ACTION_TITLE_GET_CLINIC: 'getting clinic',
  ACTION_TITLE_GET_CLINIC_SETTINGS: 'getting clinic settings',
  ACTION_TITLE_REMOVE_CYCLE_PRESCRIPTOIN: 'removing a prescription from cycle',
  ACTION_TITLE_UPDATE_CYCLE: 'updating a cycle',
  ACTION_TITLE_CREATE_CYCLE: 'creating a cycle',
  ACTION_TITLE_UPSERT_CYCLE_NOTES: 'updating or inserting cycle notes',
  ACTION_TITLE_GET_PATIENT_CYCLES: 'getting patient cycles',
  ACTION_TITLE_GET_CYCLE: 'getting a cycle',
  ACTION_TITLE_CHECK_FOR_EMBRYOLOGY_REPORT:
    'verifying if the cycle has an embryology report',
  ACTION_TITLE_GET_CURRENT_CYCLE: 'getting the current cycle',
  ACTION_TITLE_UPDATE_CYCLE_GAMETE_COMMENTS: 'updating cycle gamete comments',
  ACTION_TITLE_GET_DOCUMENT_TEMPLATES: 'getting document templates',
  ACTION_TITLE_CREATE_DOCUMENT_TEMPLATE: 'creating a document template',
  ACTION_TITLE_CREATE_PATIENT_DOCUMENT: 'creating a patient document',
  ACTION_TITLE_GET_PATIENT_DOCUMENTS: 'getting patient documents',
  ACTION_TITLE_GET_DOCUMENT: 'getting a document',
  ACTION_TITLE_GET_DOCUMENT_TEMPLATE: 'getting a document template',
  ACTION_TITLE_UPDATE_PATIENT_DOCUMENT: 'updating a patient document',
  ACTION_TITLE_UPDATE_DOCUMENT_TEMPLATE: 'updating a document template',
  ACTION_TITLE_GET_S3_PRESIGNED_URL: 'getting the S3 presigned document URL',
  ACTION_TITLE_GET_ALIVE_EGGS: 'getting the list of alive eggs',
  ACTION_TITLE_CREATE_EMBRYOLOGY_REPORT: 'creating an embryology report',
  ACTION_TITLE_GET_EGGS_AND_EMBRYOS_REPORT:
    'getting the eggs and embryos report',
  ACTION_TITLE_UPDATE_EGG: 'updating the egg',
  ACTION_TITLE_GET_EVENTS: 'getting events',
  ACTION_TITLE_CREATE_EXAM_REPORT: 'creating an exam report',
  ACTION_TITLE_UPDATE_EXAM_REPORT: 'updating an exam report',
  ACTION_TITLE_GET_EXAMINATIONS: 'getting examinations',
  ACTION_TITLE_GET_EXAMINATION: 'getting an examination',
  ACTION_TITLE_GET_GAMETE_THAW_REPORT: 'getting a gemete thaw report',
  ACTION_TITLE_CREATE_THAW_REPORT: 'creating a thaw report',
  ACTION_TITLE_UPDATE_GAMETE_THAWED: 'updating gamete thawed',
  ACTION_TITLE_GET_CONST_VALUES: 'getting const values',
  ACTION_TITLE_ADD_CHECKLIST_TEMPLATE: 'adding a checklist template',
  ACTION_TITLE_GET_HL7_VENDORS: 'getting HL7 vendors',
  ACTION_TITLE_CREATE_LAB_REPORTS: 'creating lab reports',
  ACTION_TITLE_GET_PATIENT_LAB_RESULTS: 'getting patient lab results',
  ACTION_TITLE_LAB_RESULT: 'getting a lab result',
  ACTION_TITLE_CREATE_LAB_RESULT: 'creating a lab result',
  ACTION_TITLE_UPDATE_LAB_RESULT: 'updating a lab result',
  ACTION_TITLE_DELETE_LAB_RESULT: 'deleting a lab result',
  ACTION_TITLE_CHECK_PENDING_OTP: 'checking the pending one time password',
  ACTION_TITLE_SEND_OTP_CODE: 'sending one time password code',
  ACTION_TITLE_VERIFYING_OTP_CODE: 'verifying one time password code',
  ACTION_TITLE_GET_PATIENT_CHANNEL: 'getting the patient channel',
  ACTION_TITLE_GET_MESSAGES: 'getting messages',
  ACTION_TITLE_SEND_MESSAGE: 'sending a message',
  ACTION_TITLE_MARK_AS_READ: 'marking as read',
  ACTION_TITLE_GET_RECENTS: 'getting inbox recents',
  ACTION_TITLE_GET_PATIENTS: 'getting patients',
  ACTION_TITLE_GET_PATIENT: 'getting a patient',
  ACTION_TITLE_GET_PATIENT_OVERVIEW: 'getting patient overview',
  ACTION_TITLE_CREATE_PATIENT: 'creating a patient',
  ACTION_TITLE_GET_PATIENT_TASKS: 'getting patient tasks',
  ACTION_TITLE_UPDATE_PATIENT: 'updating a patient',
  ACTION_TITLE_UPDATE_PATIENT_PROPERTIES: 'updating patient properties',
  ACTION_TITLE_UPDATE_PATIENT_ID_INFO: 'updating patient identification',
  ACTION_TITLE_UPDATE_PATIENT_KPI_STATUS: 'updating patient kpi status',
  ACTION_TITLE_GET_PATIENT_GAMETE_REPORT: 'getting the patient gamete report',
  ACTION_TITLE_GET_PATIENT_HISTORY: 'getting patient history',
  ACTION_TITLE_GET_PATIENT_TREATMENT_DATA: 'getting patient treatment data',
  ACTION_TITLE_GET_PATIENT_ESTRODIAL_HISTORY:
    'getting patient estrodial history',
  ACTION_TITLE_UPDATE_PATIENT_FLAGS: 'updating patient flags',
  ACTION_TITLE_GET_STAFF_MEMBER: 'getting a staff member',
  ACTION_TITLE_ASSIGN_DOCTOR_TO_TASK: 'assigning a doctor to a task',
  ACTION_TITLE_UPDATE_PATIENT_MEDICATION_TREATMENT:
    'updating patient medication',
  ACTION_TITLE_UPDATE_PATIENT_WITNESSED_TASK: 'updating patient witnessed task',
  ACTION_TITLE_GET_DASHBOARD_SUMMARY: 'getting the dashboard summary',
  ACTION_TITLE_GET_MEDICATIONS: 'getting medications',
  ACTION_TITLE_GET_MEDICATIONS_RX: 'getting medications rx',
  ACTION_TITLE_GET_PATIENT_DIAGNOSIS: 'getting patient diagnoses',
  ACTION_TITLE_GET_TELEHEALTH_LINK: 'getting the telehealth link',
  ACTION_TITLE_UPDATE_PATIENTS_ALLERGIES: 'updating patient allergies',
  ACTION_TITLE_GET_LAB_TESTS: 'getting the lab tests',
  ACTION_TITLE_RESEND_PATIENT_CONFIRMATION_EMAIL:
    'resending the patient confirmation email',
  ACTION_TITLE_CREATE_DIAGNOSIS: 'creating a diagnosis',
  ACTION_TITLE_UPDATE_PATIENT_NEXT_OF_KIN: 'updating patient emergency contact',
  ACTION_TITLE_UPDATE_GENERAL_PHYSICIAN: 'updating general provider',
  ACTION_TITLE_CREATE_ORDER: 'creating a lab order',
  ACTION_TITLE_GET_LAB_ORDER: 'getting a lab order',
  ACTION_TITLE_GET_CYCLE_AVAILABLE_GAMETE: 'getting cycle available gamete',
  ACTION_TITLE_GET_PANELS: 'getting lab panels',
  ACTION_TITLE_RESENT_WELCOME_EMAIL: 'resending welcome email',
  ACTION_TITLE_CREATE_PRESCRIPTION: 'creating a prescription',
  ACTION_TITLE_UPDATE_PRESCRIPTION: 'updating a prescription',
  ACTION_TITLE_GET_PRESCRIPTION: 'getting a prescription',
  ACTION_TITLE_UPDATING_PRESCTIPTION_DOSAGE: 'updating prescription dosage',
  ACTION_TITLE_GET_PRESCRIPTIONS: 'getting prescriptions',
  ACTION_TITLE_GET_PATIENT_QUESTIONS_BY_CATEGORY_ID:
    'getting patient questions by category id',
  ACTION_TITLE_POST_ANSWER_FOR_QUESTION: 'post answer for question',
  ACTION_TITLE_GET_MEDICAL_QUESTIONS: 'getting medical questions',
  ACTION_TITLE_DELETE_ANSWER_FOR_QUESTION: 'deleting answer for question',
  ACTION_TITLE_UPDATE_STAFF_MEMBER: 'updating a staff member',
  ACTION_TITLE_CREATE_STAFF_MEMBER: 'creating a staff member',
  ACTION_TITLE_GET_STAFF_NAMES: 'getting the staff member names',
  ACTION_TITLE_GET_STAFFS: 'getting staff members',
  ACTION_TITLE_GET_STAFF: 'getting a staff members',
  ACTION_TITLE_CREATE_TASK: 'creating a task',
  ACTION_TITLE_UPDATE_TASK: 'updating a task',
  ACTION_TITLE_GET_TASKS_ASSIGNED_TO_STAFF: 'getting tasks assigned to staff',
  ACTION_TITLE_GET_TASKS_BY_PATIENT: 'getting tasks of a patient',
  ACTION_TITLE_CREATE_PROGRESS_NOTE: 'creating a progress note',
  ACTION_TITLE_UPDATE_ACTIVITY_FLAG: 'updating activity flag',
  ACTION_TITLE_GET_ENCOUNTER: 'getting an encounter',
  ACTION_TITLE_UPDATE_ENCOUNTER: 'updating an encounter',
  ACTION_TITLE_UPDATE_ENCOUNTER_CODE_ROWS: 'updating encounter code rows',
  ACTION_TITLE_GET_CODE_ROWS: 'getting code rows',
  ACTION_TITLE_GET_MODIFIERS: 'getting modifiers',
  ACTION_TITLE_GET_ENCOUNTER_SERVICE_CODES: 'getting encounter service codes',
  ACTION_TITLE_GET_NDC_CODES: 'getting NDC codes',
  ACTION_TITLE_GET_ICD10_CODES: 'getting ICD10 codes',
  ACTION_TITLE_GET_ENCOUNTER_INSURANCE: 'getting encounter insurance',
  ACTION_TITLE_GENERATE_ENCOUNTER_ACTIONS_TAKEN:
    'generating encounter actions taken',
  ACTION_TITLE_TOGGLE_ENCOUNTER_LOCK_STATUS: 'toggling encounter lock status',
  ACTION_TITLE_GET_INSURANCE_TYPES: 'getting insurance types',
  ACTION_TITLE_GET_PATIENT_INSURANCES: 'getting patient insurances',
  ACTION_TITLE_CREATE_PATIENT_INSURANCE: 'creating patient insurance',
  ACTION_TITLE_UPDATE_PATIENT_INSURANCE: 'updating patient insurance',
  ACTION_TITLE_GET_FEED_ACTIVITIES: 'getting feed activities',
  EQUALS: 'equals'
};

export default enSessionTranslations;
