import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, css, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { patientRoutes } from 'src/router/routes';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import Button from 'src/components/display/Button/Button';
import HeaderUserbox from './Userbox';
import { PatientsSearchBar } from './PatientsSearchBar';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useContext } from 'react';
import { SidebarContext } from 'src/contexts/SidebarContext';
import IconButton from 'src/components/display/IconButton';
import { SearchFieldVariants } from 'src/components/data-entry/SearchField/SearchField';

const HeaderWrapper = styled(Box)`
  ${({ theme }) => css`
    height: ${theme.header.height};
    color: ${theme.header.textColor};
    right: 0;
    top: 0;
    justify-content: space-between;
    margin-bottom: ${spacings.large};
    width: 100%;
    @media (min-width: ${theme.breakpoints.values.md}px) {
      left: ${theme.spacing(12)};
      width: auto;
    }
  `}
`;

function Header() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isDrawerOpen, setIsDrawerOpen } = useContext(SidebarContext);

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Flex alignItems="center" width="100%">
        <Flex alignItems="center" justifyContent={'space-between'} width="100%">
          <Flex gap={spacings.medium} width="50%">
            <PatientsSearchBar
              onSelectPatient={(patientId) =>
                navigate(patientRoutes.getByIdLink(patientId))
              }
              variant={SearchFieldVariants.STYLED}
            />
            <Button
              startIcon={<AddIcon />}
              onClick={() => navigate(patientRoutes.getAddLink())}
              id="add-new-patient-button"
            >
              {t('ADD_NEW_PATIENT')}
            </Button>
          </Flex>
          <Flex alignItems="center" gap={spacings.medium}>
            <HeaderUserbox />
            <Box
              sx={{
                display: { lg: 'none', xs: 'inline-block' }
              }}
            >
              <IconButton
                iconColor="emperor"
                bgColor="transparent"
                size="large"
                onClick={() => setIsDrawerOpen((prevState) => !prevState)}
              >
                {!isDrawerOpen ? (
                  <MenuTwoToneIcon fontSize="large" />
                ) : (
                  <CloseTwoToneIcon fontSize="large" />
                )}
              </IconButton>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </HeaderWrapper>
  );
}

export default Header;
