import { FC, useEffect } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
  useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { CopyAllOutlined } from '@mui/icons-material';
import { styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Flex from 'src/components/layout/Flex/Flex';
import Box from 'src/components/layout/Box/Box';
import Button from 'src/components/display/Button/Button';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import { spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography/Typography';
import InputField from 'src/components/data-entry/InputField/InputField';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import Select from 'src/components/data-entry/Select/Select';
import Loader from 'src/components/display/Loader/Loader';
import { HowOftenOptions } from 'src/types/patient';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { getFullName } from 'src/utils/general';
import {
  AppointmentWithOrderDocumentPayload,
  CycleWizardVariant,
  NewCycleFormValues,
  ProtocolPhases
} from 'src/types/cycle';
import {
  MedicalProtocolForm,
  Prescription,
  Units
} from 'src/types/prescription';
import DateRangePicker from 'src/components/data-entry/DateRangePicker';
import { Range } from 'react-date-range';
import TimePicker from '../../../components/data-entry/TimePicker';
import { getPrescriptionTimes } from '../utils/prescription-times';
import useCycleAppointmentsOrders from 'src/hooks/useCycleAppointmentsOrders';
import Autocomplete from 'src/components/data-entry/Autocomplete';

enum columnWidths {
  tiny = '75px',
  xsmall = '115px',
  small = '150px',
  medium = '165px',
  large = '200px'
}

const getEmptyMedicationProtocol = (startDate?: Date): Prescription => ({
  medicationId: null,
  protocolPhase: '',
  startDate: dayjs(startDate).toDate(),
  endDate: dayjs(startDate).add(1, 'day').toDate(),
  dosage: null,
  unit: Units.IU,
  timesPerDay: null,
  howOften: '',
  timeRange: {
    startDate: dayjs(startDate).toDate(),
    endDate: dayjs(startDate).add(1, 'day').toDate(),
    key: 'selection'
  },
  time: []
});

export const StyledForm = styled('form')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

interface NestedTimeFieldsProps {
  index: number;
  control: Control<MedicalProtocolForm>;
  errors: FieldErrors<MedicalProtocolForm>;
}

const NestedTimeFields: FC<NestedTimeFieldsProps> = ({
  control,
  index,
  errors
}) => {
  const { t } = useTranslation();
  const { fields } = useFieldArray({
    control,
    name: `medicationProtocols.${index}.time` as 'medicationProtocols'
  });

  return (
    <>
      {fields.map((_, timeIndex) => (
        <Box
          width={columnWidths.medium}
          key={`medication-${index}-time-${timeIndex}`}
        >
          <Controller
            name={`medicationProtocols.${index}.time.${timeIndex}`}
            control={control}
            rules={{
              required: t('TIME_REQUIRED')
            }}
            render={({ field: { ref, ...field } }) => {
              return (
                <TimePicker
                  {...field}
                  label={t('TIME')}
                  inputRef={ref}
                  ampm={false}
                  error={
                    !!errors.medicationProtocols?.[index]?.time?.[timeIndex]
                  }
                  helperText={
                    errors.medicationProtocols?.[index]?.time?.[timeIndex]
                      .message
                  }
                  fullWidth
                />
              );
            }}
          />
        </Box>
      ))}
    </>
  );
};

export interface SubmitMedicationProtocolFormArgs {
  medicationsProtocolForm: MedicalProtocolForm;
  appointmentsWithOrderDocumentPayload: AppointmentWithOrderDocumentPayload[];
}

interface MedicationProtocolFormProps {
  patientId: string;
  hideCycleDayOne?: boolean;
  handleSubmitForm: ({
    medicationsProtocolForm,
    appointmentsWithOrderDocumentPayload
  }: SubmitMedicationProtocolFormArgs) => void;
  cycleData: NewCycleFormValues;
  variant?: CycleWizardVariant;
  handleClickBack?: () => void;
}

const MedicationProtocolForm: FC<MedicationProtocolFormProps> = ({
  patientId,
  cycleData,
  handleSubmitForm,
  hideCycleDayOne,
  variant,
  handleClickBack
}) => {
  const { t } = useTranslation();
  const { getPatientById, getMedications } = usePatientsApi();
  const isActivateCycle = variant === CycleWizardVariant.ACTIVATE_CYCLE;

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  const { data: medicationList, isLoading: isLoadingMedications } =
    getMedications();

  const getDefaultMedicationProtocols = (): Prescription[] => {
    if (!cycleData?.medicationProtocols?.length && !isActivateCycle)
      return [getEmptyMedicationProtocol(cycleData.startDate)];

    return (
      cycleData?.medicationProtocols?.map(
        ({ startDate, endDate, ...rest }) => ({
          ...rest,
          startDate,
          endDate,
          timeRange: {
            startDate: dayjs(startDate).toDate(),
            endDate: dayjs(endDate).toDate(),
            key: 'selection'
          }
        })
      ) || []
    );
  };

  const defaultValues: MedicalProtocolForm = {
    patientName: '',
    cycleDayOne: cycleData?.startDate || dayjs().toDate(),
    medicationProtocols: getDefaultMedicationProtocols(),
    medicationsToDelete: []
  };

  const { control, formState, handleSubmit, watch, setValue, getValues } =
    useForm<MedicalProtocolForm>({
      mode: 'onChange',
      defaultValues
    });
  const {
    fields: defaultMedicationProtocols,
    append,
    remove,
    insert
  } = useFieldArray({
    control,
    name: 'medicationProtocols'
  });

  useEffect(() => {
    if (!patient) return;
    setValue('patientName', getFullName(patient.personalInfo));
  }, [patient]);

  const hasOrders = cycleData?.appointments?.some(
    (appointment) => appointment?.panelIds?.length
  );

  const {
    appointmentsWithOrderDocumentPayload,
    isLoading: isLoadingAppointmentsWithOrderDocumentPayload
  } =
    useCycleAppointmentsOrders({
      appointments: cycleData?.appointments,
      patientId,
      isEnabled: hasOrders
    }) || {};

  const { medicationProtocols } = watch();
  const { errors } = formState;

  const onSubmit = async (details: MedicalProtocolForm) => {
    handleSubmitForm({
      medicationsProtocolForm: details,
      appointmentsWithOrderDocumentPayload
    });
  };

  const handleDateRangePicker = ({
    ranges,
    index,
    onChange
  }: {
    ranges: Range;
    index: number;
    onChange: (...event: any[]) => void;
  }) => {
    const { endDate, startDate } = ranges;

    const medication = getValues(`medicationProtocols.${index}`);

    setValue(`medicationProtocols.${index}`, {
      ...medication,
      startDate,
      endDate,
      timeRange: {
        ...medication.timeRange,
        startDate,
        endDate
      }
    });

    onChange(ranges);
  };

  if (isLoadingPatient || isLoadingMedications) return <Loader />;

  return (
    <Box marginTop={spacings.xlarge} height="100%">
      <StyledForm noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Flex
            alignItems="flex-start"
            gap={spacings.large}
            marginBottom={spacings.medium}
          >
            <Box flex={3}>
              <Controller
                name="patientName"
                control={control}
                rules={{
                  required: t('PATIENT_NAME_REQUIRED')
                }}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    label={t('PATIENT_NAME_LABEL')}
                    placeholder={t('PATIENT_NAME_PLACEHOLDER')}
                    error={!!errors.patientName}
                    helperText={errors?.patientName?.message}
                    required
                    disabled
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box width={150}>
              {!hideCycleDayOne && (
                <Controller
                  name="cycleDayOne"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <DatePicker
                      {...field}
                      disabled
                      inputRef={ref}
                      label={t('CYCLE_DAY_1_LABEL').toUpperCase()}
                      error={!!errors.cycleDayOne}
                      helperText={errors?.cycleDayOne?.message}
                      fullWidth
                    />
                  )}
                />
              )}
            </Box>
            <Box flex={4} />
          </Flex>
          {defaultMedicationProtocols.map((medication, index) => (
            <Flex key={medication.id} marginBottom={spacings.large}>
              <Flex flexDirection="column" alignSelf="flex-start">
                <Flex
                  gap={spacings.small}
                  marginRight={spacings.medium}
                  flex={1}
                  marginY={spacings.medium}
                  paddingTop={spacings.xxlarge}
                >
                  {!isActivateCycle && (
                    <MiniIconButton
                      icon={<CopyAllOutlined />}
                      onClick={() => {
                        insert(index + 1, medicationProtocols[index]);
                      }}
                    />
                  )}
                  <MiniIconButton
                    icon={<RemoveIcon />}
                    onClick={() => {
                      const medicationToRemove = medicationProtocols[index];
                      if (medicationToRemove.id) {
                        setValue('medicationsToDelete', [
                          ...getValues('medicationsToDelete'),
                          medicationToRemove.id
                        ]);
                      }
                      remove(index);
                    }}
                  />
                </Flex>
              </Flex>
              <Flex flexDirection="column">
                <Flex gap={spacings.large} width="100%">
                  <Box width={columnWidths.large}>
                    <Controller
                      name={`medicationProtocols.${index}.medicationId`}
                      control={control}
                      rules={{
                        required: t('MEDICATION_TYPE_REQUIRED')
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disabled={isActivateCycle}
                          options={medicationList?.map(({ id }) => `${id}`)}
                          shouldSortOptions
                          getOptionLabel={(option) => {
                            const medication = medicationList?.find(
                              ({ id }) => id === option
                            );
                            return medication?.name;
                          }}
                          onChange={(_, value: string) => {
                            setValue(
                              `medicationProtocols.${index}.medicationId`,
                              value
                            );
                            setValue(
                              `medicationProtocols.${index}.medicationName`,
                              medicationList?.find(({ id }) => id === value)
                                ?.name
                            );
                          }}
                          renderInput={(params) => (
                            <InputField
                              {...params}
                              label={t('MEDICATION')}
                              error={
                                !!errors.medicationProtocols?.[index]
                                  ?.medicationId
                              }
                              helperText={
                                errors.medicationProtocols?.[index]
                                  ?.medicationId?.message
                              }
                              fullWidth
                            />
                          )}
                        />
                      )}
                    />
                  </Box>
                  <Box width={columnWidths.medium}>
                    <Controller
                      name={`medicationProtocols.${index}.protocolPhase`}
                      control={control}
                      rules={{
                        required: t('PROTOCOL_PHASE_REQUIRED')
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <Select
                          {...field}
                          inputRef={ref}
                          label={t('PROTOCOL_PHASE')}
                          error={
                            !!errors.medicationProtocols?.[index]?.protocolPhase
                          }
                          helperText={
                            errors.medicationProtocols?.[index]?.protocolPhase
                              ?.message
                          }
                          initialDisabled
                          defaultOption={t('PROTOCOL_PHASE_DEFAULT')}
                          options={Object.entries(ProtocolPhases).map(
                            ([label, value]) => ({
                              label: t(label),
                              value
                            })
                          )}
                          fullWidth
                        />
                      )}
                    />
                  </Box>
                  <Box width={columnWidths.large}>
                    <Controller
                      name={`medicationProtocols.${index}.timeRange`}
                      control={control}
                      rules={{
                        required: t('DATES_REQUIRED')
                      }}
                      render={({
                        field: { ref: _ref, value, onChange, name }
                      }) => (
                        <DateRangePicker
                          label={t('DATES')}
                          key={name}
                          ranges={value}
                          onChange={(ranges) =>
                            handleDateRangePicker({ ranges, index, onChange })
                          }
                        />
                      )}
                    />
                  </Box>
                  <Box width={columnWidths.tiny}>
                    <Controller
                      name={`medicationProtocols.${index}.dosage`}
                      control={control}
                      rules={{
                        required: t('DOSAGE_REQUIRED'),
                        min: { value: 0, message: t('DOSAGE_VALIDATION_MSG') }
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <InputField
                          {...field}
                          inputRef={ref}
                          InputProps={{ inputProps: { min: 0 } }}
                          label={t('DOSAGE_LABEL')}
                          type="number"
                          placeholder="#"
                          error={!!errors.medicationProtocols?.[index]?.dosage}
                          helperText={
                            errors?.medicationProtocols?.[index]?.dosage
                              ?.message
                          }
                          onChange={(ev) => {
                            const { value } = ev.currentTarget;
                            if (parseFloat(value) < 0) return;
                            setValue(
                              `medicationProtocols.${index}.dosage`,
                              parseFloat(value)
                            );
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box width={columnWidths.xsmall}>
                    <Controller
                      name={`medicationProtocols.${index}.unit`}
                      control={control}
                      rules={{
                        required: 'Please choose a unit',
                        min: { value: 0, message: t('UNIT_VALIDATION_MSG') }
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <Select
                          {...field}
                          inputRef={ref}
                          label={t('UNIT_LABEL')}
                          error={!!errors.medicationProtocols?.[index]?.unit}
                          helperText={
                            errors.medicationProtocols?.[index]?.unit?.message
                          }
                          defaultOption={t('UNIT_DEFAULT_OPTION')}
                          options={Object.entries(Units).map(
                            ([label, value]) => ({
                              label: t(label),
                              value
                            })
                          )}
                        />
                      )}
                    />
                  </Box>
                  <Box width={columnWidths.xsmall}>
                    <Controller
                      name={`medicationProtocols.${index}.howOften`}
                      control={control}
                      rules={{
                        required: t('HOW_OFTEN_REQUIRED')
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <Select
                          {...field}
                          inputRef={ref}
                          label={t('HOW_OFTEN_LABEL')}
                          error={
                            !!errors.medicationProtocols?.[index]?.howOften
                          }
                          helperText={
                            errors.medicationProtocols?.[index]?.howOften
                              ?.message
                          }
                          defaultOption={t('HOW_OFTEN_PLACEHOLDER')}
                          initialDisabled
                          options={Object.entries(HowOftenOptions).map(
                            ([label, value]) => ({
                              label: t(label),
                              value
                            })
                          )}
                          fullWidth
                        />
                      )}
                    />
                  </Box>
                </Flex>
                {!cycleData.isHistorical && (
                  <Flex
                    alignItems="flex-start"
                    marginTop={spacings.medium}
                    gap={spacings.large}
                  >
                    <Box width={columnWidths.xsmall}>
                      <Controller
                        name={`medicationProtocols.${index}.timesPerDay`}
                        control={control}
                        rules={{
                          required: t('TIMES_PER_DAY_REQUIRED')
                        }}
                        render={({ field: { ref, ...field } }) => (
                          <InputField
                            {...field}
                            inputRef={ref}
                            InputProps={{ inputProps: { min: 0 } }}
                            type="number"
                            label={t('TIMES_PER_DAY_LABEL')}
                            placeholder={t('TIMES_PER_DAY_PLACEHOLDER')}
                            error={
                              !!errors.medicationProtocols?.[index]?.timesPerDay
                            }
                            helperText={
                              errors.medicationProtocols?.[index]?.timesPerDay
                                ?.message
                            }
                            onChange={async (ev) => {
                              const { value } = ev.currentTarget;
                              const timesPerDayValue = parseFloat(value);
                              setValue(
                                `medicationProtocols.${index}.timesPerDay`,
                                timesPerDayValue
                              );
                              await setValue(
                                `medicationProtocols.${index}.time`,
                                []
                              );
                              setValue(
                                `medicationProtocols.${index}.time`,
                                getPrescriptionTimes(timesPerDayValue)
                              );
                            }}
                            fullWidth
                          />
                        )}
                      />
                    </Box>
                    <NestedTimeFields
                      index={index}
                      control={control}
                      errors={errors}
                    />
                  </Flex>
                )}
              </Flex>
            </Flex>
          ))}
          {variant !== CycleWizardVariant.ACTIVATE_CYCLE && (
            <Flex
              alignItems="center"
              marginTop={spacings.xlarge}
              gap={spacings.xxlarge}
            >
              <MiniIconButton
                icon={<AddIcon />}
                onClick={() =>
                  append(getEmptyMedicationProtocol(cycleData.startDate))
                }
              />
              <Typography variant="body1">{t('ADD_MEDICATION')}</Typography>
            </Flex>
          )}
        </Box>
        <Flex mt={'20px'} justifyContent={'space-between'}>
          <Box width="20%">
            {handleClickBack && (
              <Button
                type="button"
                bgColor="alto"
                textColor="darkGray"
                fullWidth
                onClick={handleClickBack}
              >
                {t('BACK')}
              </Button>
            )}
          </Box>
          <Box width="20%">
            <Button
              fullWidth
              type="submit"
              disabled={isLoadingAppointmentsWithOrderDocumentPayload}
            >
              {t('CONFIRM_PROTOCOL')}
            </Button>
          </Box>
        </Flex>
      </StyledForm>
    </Box>
  );
};

export default MedicationProtocolForm;
