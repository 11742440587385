import { Range } from 'react-date-range';
import { Cycle } from './cycle';

export interface PrescriptionsRx {
  id?: string;
  medicationRxId: string;
  medicationRx?: MedicationRx;
  deliveryDate: Date;
  quantity: number;
  refill: number;
  createdByStaffId?: string;
  description?: string;
}
export interface MedicationRx {
  id: string;
  medicationId: string;
  description: string;
  medication?: Medication;
}
export interface Medication {
  id: string;
  internalId?: string;
  name: string;
  refrigerated?: boolean;
  about?: string;
  sideEffects?: string;
  instructions?: string;
  websiteUrl?: string;
  pdfUrl?: string;
  videoUrl?: string;
  videoLocation?: string;
  dragCategory?: string;
  medicationRx?: MedicationRx[];
}

export interface PrescriptionRxForm {
  patientName?: string;
  prescriptionsRx?: PrescriptionsRx[];
}

export interface MedicalProtocolForm {
  patientName?: string;
  patientId?: string;
  cycleDayOne?: Date;
  medicationProtocols?: Prescription[];
  medicationsToDelete?: string[];
}

export enum Units {
  IU = 'iu',
  ML = 'ml',
  MG = 'mg',
  MCG = 'mcg',
  GMS = 'gms',
  SERVING = 'Serving',
  CLICK = 'Click'
}

export interface Prescription {
  id?: string;
  medicationId: string;
  dosage: number;
  unit: Units;
  endDate?: Date;
  startDate?: Date;
  instructions?: string;
  medicationName?: string;
  patientId?: string;
  cycleId?: string;
  protocolPhase?: string;
  timesPerDay?: number;
  howOften?: string;
  createdByStaffId?: string;
  timeRange?: Range;
  time: Date[];
  cycle?: Cycle;
}

export interface UpdatePrescriptionDosage {
  dosage: number;
  prescriptionId: string;
  patientId: string;
  cycleId: string;
  startDate: Date;
  endDate?: Date;
}
