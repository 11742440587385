import { styled, Tab, Tabs as MuiTabs } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, PropsWithChildren, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'src/components/styles/colors';
import { spacings } from 'src/components/styles/constants';

const StyledTabs = styled(MuiTabs)`
  margin-bottom: ${spacings.large};
  border-bottom: 1px solid ${Colors.mercury};

  .MuiTabs-indicator {
    background-color: ${Colors.darkGray};
    border-width: 0;
    min-height: 2px;
    height: 2px;
    box-shadow: none;
  }
  .MuiTab-root {
    color: ${Colors.emperor};
    &.Mui-selected {
      color: ${Colors.darkGray};
      &:hover {
        color: ${Colors.darkGray};
      }
    }
  }
`;
export type TabContentsProps = PropsWithChildren<{
  value: string;
  label?: ReactNode;
}>;

interface TabsProps {
  defaultTab?: string;
  tabContents: TabContentsProps[];
  onTabChange?: (value: string) => void;
  selectedTab?: string;
}

export type TabPanelProps = PropsWithChildren<{
  index: string;
  value: string;
}>;

const CustomTabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

const Tabs: FC<TabsProps> = ({
  tabContents,
  defaultTab,
  onTabChange,
  selectedTab: selectedTabProp
}) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<string>(
    defaultTab || tabContents[0]?.value
  );

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: string
  ): void => {
    if (onTabChange) {
      onTabChange(newValue);
    } else {
      setSelectedTab(newValue);
    }
  };

  const tabToDisplay = selectedTabProp || selectedTab;

  return (
    <>
      <StyledTabs value={tabToDisplay} onChange={handleChange}>
        {tabContents.map(({ value, label }, index) => (
          <Tab key={index} label={label || t(value)} value={value} />
        ))}
      </StyledTabs>
      {tabContents.map((tabContent, index) => {
        if (tabContent.value === tabToDisplay) {
          return (
            <CustomTabPanel
              key={index}
              value={tabToDisplay}
              index={tabContent.value}
            >
              {tabContent.children}
            </CustomTabPanel>
          );
        }
      })}
    </>
  );
};

export default Tabs;
