import { GridColDef } from '@mui/x-data-grid-premium';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'src/components/display/Card';
import Table from 'src/components/display/Table';
import Typography from 'src/components/display/Typography';
import Box from 'src/components/layout/Box';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import { weights } from 'src/components/styles/fonts';
import useEggsEmbryos from 'src/hooks/useEggsEmbryos';
import { Cycle } from 'src/types/cycle';

import {
  AddOns,
  EggAndEmbryoNotes,
  Fate,
  InseminationMethods,
  Maturity,
  Day1
} from 'src/types/eggAndEmbryo';
import NotesWrapper from '../../NotesWrapper';
import dayjs from 'dayjs';
import { dashSeparatedDateFormat } from 'src/utils/dateAndTIme';
import { DoctorChips } from 'src/modules/patients/common/DoctorChips';
import { MultiSelectOption } from 'src/components/data-entry/MultiSelect/MultiSelect';
import TableMultiSelect from './TableMultiSelect';
import { GeneticStatus } from 'src/types/patient';
import { useToast } from 'src/components/components-api/ToastProvider';
import { ToastType } from 'src/components/display/Toast/Toast';

interface Props {
  cycleToDisplay: Cycle;
  patientId: string;
}

enum TableRowType {
  INSEMINATION_TIME = 'inseminationTime',
  NO = 'no',
  ID = 'id',
  TLID = 'tlid',
  MATURE = 'mature',
  INSEMINATION_METHOD = 'inseminationMethod',
  ADD_ONS = 'addOns',
  DAY1 = 'day1',
  DAY2 = 'day2',
  DAY3 = 'day3',
  DAY4 = 'day4',
  DAY5 = 'day5',
  DAY6 = 'day6',
  DAY7 = 'day7',
  AI_SCORE = 'aiScore',
  NOTES = 'notes',
  BIOPSY = 'biopsy',
  BIOPSY_WITNESS = 'biopsyWitness',
  BIOPSY_TIME = 'biopsyTime',
  TUBE = 'tube',
  TUBE_WITNESS = 'tubeWitness',
  TUBE_TIME = 'tubeTime',
  LOCATION = 'location'
}
interface TableRow {
  no: number;
  id: string;
  tlid?: string;
  mature?: Maturity;
  inseminationTime?: Date;
  fate?: Fate;
  inseminationMethod?: InseminationMethods;
  addOns?: AddOns[];
  day1?: string;
  day2?: string;
  day3?: string;
  day4?: string;
  day5?: string;
  day6?: string;
  day7?: string;
  aiScore?: string;
  notes?: EggAndEmbryoNotes[];
  biopsy?: string;
  biopsyWitness?: string;
  biopsyTime?: Date;
  tube?: string;
  tubeWitness?: string;
  tubeTime?: Date;
  location?: string;
}

const fateOptions = Object.values(Fate);
const addOnsOptions: MultiSelectOption[] = Object.values(AddOns).map(
  (option) => ({
    value: option,
    label: option
  })
);
const maturityOptions = Object.values(Maturity);
const geneticStatusOptions = Object.values(GeneticStatus);
const inseminationMethodOptions = Object.values(InseminationMethods);
const day1Options = Object.values(Day1);

const EmbryologyTable: FC<Props> = ({ cycleToDisplay, patientId }: Props) => {
  const { t } = useTranslation();
  const { getEggsAndEmbryosReport, updateEgg } = useEggsEmbryos();
  const { mutateAsync: handleUpdateEgg } = updateEgg();

  const [editingCell, setEditingCell] = useState<{ type: string; id: string }>(
    null
  );

  const { data: eggAndEmbryoReport } = getEggsAndEmbryosReport(
    patientId,
    cycleToDisplay.id,
    {
      enabled: !!cycleToDisplay.id && !!patientId
    }
  );
  const { upsertNotes } = useEggsEmbryos();

  const { mutateAsync: handleUpsertNotes } = upsertNotes();
  const { openToast } = useToast();

  const handleEditCell = async (
    rowType: TableRowType,
    newValue: string | number,
    eggId?: string
  ): Promise<void> => {
    if (
      [
        TableRowType.INSEMINATION_TIME,
        TableRowType.BIOPSY_TIME,
        TableRowType.TUBE_TIME
      ].includes(rowType)
    ) {
      // check if insemination time is in the future
      if (dayjs(newValue).isAfter(dayjs())) {
        openToast({
          title: t('TIME_CANNOT_BE_IN_THE_FUTURE'),
          type: ToastType.ERROR
        });
        return;
      }
    }
    const eggIdToUpdate = eggId || editingCell.id;
    await handleUpdateEgg({
      patientId,
      cycleId: cycleToDisplay.id,
      eggId: eggIdToUpdate,
      egg: { [rowType]: newValue }
    });
  };
  const [columns, rows] = useMemo(() => {
    const columns: GridColDef<TableRow>[] = [
      {
        field: 'no',
        headerName: t('NUMBER'),
        align: 'center',
        headerAlign: 'center',
        sortable: false
      },
      {
        field: 'displayId',
        headerName: t('ID'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        valueGetter: (value: string) => value.toUpperCase()
      },
      {
        field: 'tlid',
        headerName: t('TLID'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true
      },
      {
        field: 'mature',
        headerName: t('MATURITY'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'singleSelect',
        valueOptions: maturityOptions
      },
      {
        field: 'inseminationMethod',
        headerName: t('INSEMINATION_METHOD'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'singleSelect',
        valueOptions: inseminationMethodOptions
      },
      {
        field: 'inseminationTime',
        headerName: t('INSEMINATION_TIME'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'dateTime',
        valueGetter: (value) => (value ? new Date(value) : null)
      },
      {
        field: 'addOns',
        headerName: t('ADD_ONS'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'singleSelect',
        valueOptions: addOnsOptions,
        width: 200,
        renderEditCell: ({ value, field, id }) => (
          <TableMultiSelect
            gridId={id}
            value={value || []}
            field={field}
            options={addOnsOptions}
          />
        ),
        renderCell: ({ row }) => row.addOns.join(', ')
      },
      {
        field: 'day1',
        headerName: t('DAY1'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'singleSelect',
        valueOptions: day1Options
      },
      {
        field: 'day2',
        headerName: t('DAY2'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true
      },
      {
        field: 'day3',
        headerName: t('DAY3'),
        align: 'center',
        sortable: false,
        editable: true
      },
      { field: 'day4', headerName: t('DAY4'), align: 'center', editable: true },
      { field: 'day5', headerName: t('DAY5'), align: 'center', editable: true },
      { field: 'day6', headerName: t('DAY6'), align: 'center', editable: true },
      { field: 'day7', headerName: t('DAY7'), align: 'center', editable: true },
      { field: 'aiScore', headerName: t('AI_SCORE'), editable: true },
      {
        field: 'geneticStatus',
        headerName: t('GENETIC_STATUS'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'singleSelect',
        valueOptions: geneticStatusOptions
      },
      {
        field: 'fate',
        headerName: t('FATE'),
        editable: true,
        type: 'singleSelect',
        valueOptions: fateOptions
      },
      {
        field: 'notes',
        headerName: t('NOTES'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: false,
        renderCell: ({ row }) => {
          return (
            <Flex justifyContent={'center'} alignItems={'center'}>
              <NotesWrapper
                date={dayjs().format(dashSeparatedDateFormat)}
                initialNotes={row.notes}
                onUpsertNotes={({
                  date,
                  cycleId,
                  patientId,
                  notesToCreate,
                  notesToDelete
                }) =>
                  handleUpsertNotes({
                    date,
                    cycleId,
                    patientId,
                    notesToCreate,
                    notesToDelete,
                    embryoId: row.id
                  })
                }
              />
            </Flex>
          );
        }
      },
      {
        field: 'biopsy',
        headerName: t('BIOPSY'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        renderCell: ({ row }) => (
          <DoctorChips
            id="biopsy-done-by-doctor-chips"
            value={[row.biopsy]}
            showSelectedValue
            onAddChip={async (selectedDoctorId) => {
              await handleEditCell(
                TableRowType.BIOPSY,
                selectedDoctorId,
                row.id
              );
            }}
          />
        )
      },
      {
        field: 'biopsyWitness',
        headerName: t('BIOPSY_WITNESS'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        renderCell: ({ row }) => (
          <DoctorChips
            id="biopsy-witness-done-by-doctor-chips"
            value={[row.biopsyWitness]}
            showSelectedValue
            onAddChip={async (selectedDoctorId) => {
              await handleEditCell(
                TableRowType.BIOPSY_WITNESS,
                selectedDoctorId,
                row.id
              );
            }}
          />
        )
      },
      {
        field: 'biopsyTime',
        headerName: t('BIOPSY_TIME'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'dateTime',
        valueGetter: (value) => (value ? dayjs(value).toDate() : null)
      },
      {
        field: 'tube',
        headerName: t('TUBE'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        renderCell: ({ row }) => (
          <DoctorChips
            id="tube-done-by-doctor-chips"
            value={[row.tube]}
            showSelectedValue
            onAddChip={async (selectedDoctorId) => {
              await handleEditCell(TableRowType.TUBE, selectedDoctorId, row.id);
            }}
          />
        )
      },
      {
        field: 'tubeWitness',
        headerName: t('TUBE_WITNESS'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        renderCell: ({ row }) => (
          <DoctorChips
            id="tube-witness-done-by-doctor-chips"
            value={[row.tubeWitness]}
            showSelectedValue
            onAddChip={async (selectedDoctorId) => {
              await handleEditCell(
                TableRowType.TUBE_WITNESS,
                selectedDoctorId,
                row.id
              );
            }}
          />
        )
      },
      {
        field: 'tubeTime',
        headerName: t('TUBE_TIME'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'dateTime',
        valueGetter: (value) => (value ? dayjs(value).toDate() : null)
      },
      {
        field: 'location',
        headerName: t('LOCATION'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true
      },
      {
        field: 'straw',
        headerName: t('STRAW'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true
      },
      {
        field: 'instrument',
        headerName: t('INSTRUMENT'),
        align: 'center',
        headerAlign: 'center',
        type: 'number',
        sortable: false,
        editable: true
      },
      {
        field: 'dishNumber',
        headerName: t('DISH_NUMBER'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'number'
      },
      {
        field: 'wellNumber',
        headerName: t('WELL_NUMBER'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'number'
      }
    ];
    const rows =
      eggAndEmbryoReport?.eggs?.map((egg, index) => {
        return {
          ...egg,
          biopsy: egg.biopsy || '',
          biopsyWitness: egg.biopsyWitness || '',
          no: index + 1,
          addOns: egg.addOns || [],
          tube: egg.tube || '',
          tubeWitness: egg.tubeWitness || '',
          straw: egg.strawNum
        };
      }) || [];
    return [columns, rows];
  }, [eggAndEmbryoReport]);

  return (
    <Card sx={{ padding: spacings.xxlarge }}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        marginBottom={spacings.medium}
      >
        <Typography fontWeight={weights.black}>
          {`${t(
            'CURRENT_TREATMENT'
          )} #${cycleToDisplay?.displayId?.toUpperCase()} ${t('LAB CHART')}`}
        </Typography>
      </Flex>
      <Box>
        <Table
          processRowUpdate={async (newRow, oldRow) => {
            if (newRow[editingCell.type] === oldRow[editingCell.type])
              return newRow;

            await handleEditCell(
              editingCell.type as TableRowType,
              newRow[editingCell.type]
            );
            return newRow;
          }}
          onCellEditStart={(props) => {
            setEditingCell({ id: `${props.id}`, type: props.field });
          }}
          editMode="cell"
          columns={columns}
          rows={rows || []}
          hideFooter
          disableColumnMenu
          paginationModel={{ page: 0, pageSize: 50 }}
        />
      </Box>
    </Card>
  );
};

export default EmbryologyTable;
