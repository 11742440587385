import { FC, ReactNode, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  PopoverProps as MuiPopoverProps,
  Popover as MuiPopover,
  Typography
} from '@mui/material';
import Box from 'src/components/layout/Box/Box';
import CloseIcon from '@mui/icons-material/Close';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '../IconButton/IconButton';
import { TooltipWrapper } from '../LogoSign';
import MiniIconButton from '../MiniIconButton';

export interface PopoverProps extends MuiPopoverProps {
  header?: string;
  hideToolbar?: boolean;
  paperPadding?: string;
  onEditClick?: (ev?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  headerChildren?: ReactNode;
  closeOnEdit?: boolean;
  closeTooltipTitle?: string;
}

const Popover: FC<PopoverProps> = ({
  children,
  hideToolbar,
  header,
  onClose,
  paperPadding,
  onEditClick,
  headerChildren,
  closeOnEdit = false,
  closeTooltipTitle,
  id,
  ...otherProps
}) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const previousPath = useRef(currentPath);

  useEffect(() => {
    if (previousPath.current !== currentPath) {
      if (onClose) {
        onClose(null, 'escapeKeyDown');
      }
    }

    previousPath.current = currentPath;
  }, [currentPath, onClose]);

  return (
    <MuiPopover
      onClose={onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right'
      }}
      sx={{
        marginInlineStart: spacings.small
      }}
      PaperProps={{
        sx: {
          padding: paperPadding || spacings.none,
          overflow: 'visible'
        }
      }}
      {...otherProps}
    >
      {!hideToolbar && (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={headerChildren ? spacings.small : spacings.xxlarge}
          width="100%"
        >
          {header && <Typography variant="h4">{header}</Typography>}
          {headerChildren}
          <Flex gap={spacings.small} alignSelf="end">
            {onEditClick && (
              <IconButton
                icon={<EditIcon style={{ fill: 'black' }} />}
                onClick={(ev) => {
                  onEditClick(ev);
                  if (closeOnEdit) onClose(ev, 'escapeKeyDown');
                }}
                bgColor="alto"
                iconSize="xsmall"
                id={id ? `popover-edit-button-${id}` : undefined}
              />
            )}

            <TooltipWrapper title={closeTooltipTitle}>
              <MiniIconButton
                icon={<CloseIcon />}
                onClick={(ev) => onClose(ev, 'escapeKeyDown')}
              />
            </TooltipWrapper>
          </Flex>
        </Flex>
      )}
      <Box marginTop={hideToolbar ? spacings.none : spacings.xlarge}>
        {children}
      </Box>
    </MuiPopover>
  );
};

export default Popover;
