import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  Outlet,
  Route,
  Routes,
  useParams
} from 'react-router-dom';
import Tabs from '../../components/display/Tabs';
import Typography from '../../components/display/Typography';
import Box from '../../components/layout/Box';
import { radii, spacings } from '../../components/styles/constants';
import { TabContentsProps } from '../../components/display/Tabs/Tabs';
import Card from '../../components/display/Card';
import { getClinicFeedLink } from '../../router/routes';
import { ClinicFeedActivity } from './ClinicFeedActivity';
import { styled } from '@mui/material';

export enum ClinicFeedTabs {
  ACTIVITY = 'activity'
}

type ClinicActivityFeedParams = {
  tab?: ClinicFeedTabs;
};

const StyledCard = styled(Card)`
  border-radius: ${radii.large};
  padding: ${spacings.xlarge};
  padding-bottom: 0;
`;

export const ClinicFeed: React.FC = () => {
  const { tab } = useParams<ClinicActivityFeedParams>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tabs = useMemo(
    () => [{ value: ClinicFeedTabs.ACTIVITY, label: t('ACTIVITY') }],
    [t]
  );

  const currentTab = useMemo((): ClinicFeedTabs => {
    return (
      (tabs.find((t) => t.value === tab)?.value as ClinicFeedTabs) ||
      ClinicFeedTabs.ACTIVITY
    );
  }, [tab]);

  const handleTabChange = (value: string) => {
    const baseUrl = getClinicFeedLink();
    navigate(`${baseUrl}/${value}`);
  };

  const tabContents: TabContentsProps[] = [
    {
      value: ClinicFeedTabs.ACTIVITY,
      label: t('ACTIVITY'),
      children: (
        <Routes>
          <Route index element={<ClinicFeedActivity />} />
        </Routes>
      )
    }
  ];

  return (
    <StyledCard>
      <Box marginBottom={spacings.xlarge}>
        <Typography variant="h1">
          {t('CLINIC_FEED_TABS_TITLE', {
            currentTab: t(currentTab.toUpperCase())
          })}
        </Typography>
      </Box>
      <Tabs
        tabContents={tabContents}
        onTabChange={handleTabChange}
        selectedTab={currentTab}
      />
      <Outlet />
    </StyledCard>
  );
};
