import { GridColDef } from '@mui/x-data-grid-premium';
import { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material';
import Table from 'src/components/display/Table/Table';
import Card from 'src/components/display/Card/Card';
import { spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography/Typography';
import { useTranslation } from 'react-i18next';
import Flex from 'src/components/layout/Flex/Flex';
import { TreatmentHistory } from 'src/types/patient';
import { useNavigate, useParams } from 'react-router-dom';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { weights } from 'src/components/styles/fonts';
import { Colors } from 'src/components/styles/colors';
import dayjs from 'dayjs';
import { getDateFormat } from 'src/utils/dateAndTIme';
import { patientRoutes } from 'src/router/routes';
import { CycleWizardVariant, CycleStatus } from 'src/types/cycle';
import MiniIconButton from 'src/components/display/MiniIconButton';
import { useCycleWizard } from '../../../components/components-api/CycleWizardProvider';

const StyledTable = styled(Table)`
  .MuiDataGrid-columnHeaders,
  .MuiDataGrid-row {
    padding-inline-start: ${spacings.large};
    color: ${Colors.emperor};
    cursor: pointer;
  }

  .MuiDataGrid-columnHeaders {
    background-color: ${Colors.whiteSand};
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: ${weights.extraBold};
  }
  .MuiDataGrid-cell:not(:first-of-type) {
    font-weight: ${weights.extraBold};
  }
`;

export const PatientTreatmentHistoryTable: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientId } = useParams();
  const { getPatientHistory } = usePatientsApi();
  const { openWizard } = useCycleWizard();

  const { data: history, isLoading } = getPatientHistory(patientId);

  const { previousCycles } = history || {};

  const columns: GridColDef<TreatmentHistory>[] = [
    {
      field: 'date',
      align: 'left',
      headerName: t('DATE').toUpperCase(),
      type: 'date' as const,
      valueGetter: (value) => new Date(value),
      renderCell: ({ row: { date } }) => dayjs(date).format(getDateFormat())
    },
    {
      field: 'treatment',
      align: 'left',
      headerName: t('TREATMENT').toUpperCase(),
      valueGetter: (value) => t(value),
      renderCell: ({ value }) => value,
      width: 150
    },
    {
      field: 'protocol',
      align: 'left',
      headerName: t('PROTOCOL').toUpperCase(),
      valueGetter: (value) => t(value),
      renderCell: ({ value }) => value,
      width: 150
    },
    {
      field: 'beta',
      align: 'left',
      headerName: t('BETA').toUpperCase(),
      filterable: false
    },
    {
      field: 'outcome',
      align: 'left',
      valueGetter: (_, row) => row.outcome || row.status || null,
      renderCell: ({ value }) => value || '--',
      headerName: t('OUTCOME').toUpperCase()
    },
    {
      field: 'displayId',
      align: 'left',
      headerName: t('CYCLE_ID').toUpperCase(),
      filterable: false
    }
  ];
  return (
    <Card
      maxWidth={'100%'}
      marginBottom={spacings.large}
      paddingBottom={spacings.medium}
    >
      <StyledTable
        variant="striped"
        onRowClick={({ row }) =>
          navigate(
            patientRoutes.getCurrentTreatmentByCycleLink(patientId, row.id)
          )
        }
        toolbar={
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
          >
            <Typography
              paddingY={spacings.large}
              fontWeight={weights.extraBold}
            >
              {t('TREATMENT_HISTORY')}
            </Typography>
            <MiniIconButton
              icon={<AddIcon />}
              onClick={() =>
                openWizard({
                  variant: CycleWizardVariant.PREVIOUS_CYCLE,
                  patientId
                })
              }
            />
          </Flex>
        }
        columns={columns}
        rows={
          previousCycles?.filter(
            ({ status }) =>
              status !== CycleStatus.ONGOING && status !== CycleStatus.PENDING
          ) || []
        }
        loading={isLoading}
        autoHeight
      />
    </Card>
  );
};
