import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import InputField from 'src/components/data-entry/InputField/InputField';
import Button from 'src/components/display/Button/Button';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import { Examination } from 'src/types/exam';
import Loader from 'src/components/display/Loader/Loader';
import Select from 'src/components/data-entry/Select/Select';
import Chips from 'src/components/data-entry/Chips/Chips';
import { getFullName } from 'src/utils/general';
import usePatientsApi from '../../../hooks/usePatientsApi';
import dayjs from 'dayjs';
import useGameteThaw from 'src/hooks/useGameteThaw';
import { Gamete, SemenVial, ThawReportForm } from 'src/types/cycle';
import { EggAndEmbryo } from 'src/types/eggAndEmbryo';

const getGameteOptions = (
  gameteType: Gamete,
  eggs: EggAndEmbryo[],
  embryos: EggAndEmbryo[],
  semenVials: SemenVial[]
): EggAndEmbryo[] | SemenVial[] => {
  switch (gameteType) {
    case Gamete.EGG:
      return eggs;
    case Gamete.EMBRYO:
      return embryos;
    case Gamete.SEMEN:
      return semenVials;
    default:
      return [];
  }
};

export const AddEditThawReportForm: FC<{
  patientId?: string;
  cycleId?: string;
  examination?: Examination;
}> = ({ patientId, cycleId, examination }) => {
  const { getPatientById, getCycleAvailableGamete } = usePatientsApi();
  const { createThawReport } = useGameteThaw();
  const { t } = useTranslation();

  const { data: patient, isLoading: isLoadingPatient } = getPatientById(
    examination?.patientId || patientId
  );
  const { data: availableGamete, isLoading: isLoadingGamete } =
    getCycleAvailableGamete(patientId);

  const { mutate: handleCreateThawReport, isLoading: isCreatingReport } =
    createThawReport();

  const defaultValues: ThawReportForm = {
    patientId,
    cycleId,
    patientName: '',
    dateOfThaw: new Date(),
    gameteType: null,
    thawed: null,
    gameteIds: [],
    straw: null
  };

  const { control, formState, handleSubmit, setValue, watch, getValues } =
    useForm<ThawReportForm>({
      mode: 'onChange',
      defaultValues
    });

  useEffect(() => {
    if (!patient) return;
    setValue('patientName', getFullName(patient.personalInfo));
  }, [patient]);

  const { errors } = formState;
  const { gameteType } = watch();

  const onSubmit = async (report: ThawReportForm) => {
    await handleCreateThawReport(report);
  };

  if (isLoadingPatient || isLoadingGamete) return <Loader />;

  const { eggs, embryos, semenVials } = availableGamete || {};

  return (
    <Box marginTop={spacings.large}>
      <form noValidate>
        <Flex gap={spacings.large}>
          <Box flex={1}>
            <Box marginBottom={spacings.large}>
              <Controller
                name="patientName"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    disabled
                    label={t('PATIENT_NAME')}
                    placeholder={t('PATIENT_NAME')}
                    error={!!errors.patientName}
                    helperText={errors?.patientName?.message}
                    required
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box marginBottom={spacings.large}>
              <Controller
                name={'dateOfThaw'}
                control={control}
                rules={{
                  required: t('DATE_REQUIRED'),
                  validate: (value) => {
                    const date = dayjs(value);
                    if (date.isAfter(dayjs())) {
                      return t('DATE_CANT_BE_IN_THE_FUTURE');
                    }
                    return true;
                  }
                }}
                render={({ field: { ref, ...field } }) => (
                  <DatePicker
                    {...field}
                    label={t('DATE_OF_PROCEDURE')}
                    inputRef={ref}
                    error={!!errors?.dateOfThaw}
                    helperText={errors?.dateOfThaw?.message}
                    fullWidth
                  />
                )}
              />
            </Box>
            <Flex gap={spacings.large} marginBottom={spacings.large}>
              <Controller
                name="gameteType"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Select
                    {...field}
                    label={t('GAMETE_THAWED')}
                    inputRef={ref}
                    error={!!errors?.gameteType}
                    helperText={errors?.gameteType?.message}
                    defaultOption={t('PLEASE_CHOOSE_GAMETE')}
                    onChange={(ev) => {
                      onChange(ev.target.value);
                      setValue('gameteIds', []);
                    }}
                    options={Object.keys(Gamete).map((option) => ({
                      label: t(option),
                      value: option
                    }))}
                  />
                )}
              />
              <Controller
                name="thawed"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    label={t('THAWED')}
                    placeholder={t('#')}
                    error={!!errors?.thawed}
                    helperText={errors?.thawed?.message}
                    required
                    fullWidth
                  />
                )}
              />
            </Flex>
          </Box>
        </Flex>
        <Box marginBottom={spacings.large}>
          <Controller
            name={'gameteIds'}
            rules={{
              validate: (value) => {
                const maxAllowed = getValues('thawed');
                if (!maxAllowed) {
                  return t('PLEASE_CHOOSE_AN_AMOUNT_TO_THAW');
                }

                if (value.length > maxAllowed) {
                  return t('MAX_GAMETE_YOU_CAN_CHOOSE_IS') + `: ${maxAllowed}`;
                }
              }
            }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Chips
                helperText={errors?.gameteIds?.message}
                value={value}
                options={getGameteOptions(
                  gameteType,
                  eggs,
                  embryos,
                  semenVials
                ).map(({ id }) => ({
                  value: id
                }))}
                renderSelectedOptionsOutside
                title={t('CHOOSE_AVAILABLE_GAMETE')}
                onAddChip={(newChip) => onChange([...value, newChip])}
                onRemoveChip={(chipValueToDelete) => {
                  const updatedEmbryos = value.filter(
                    (embryo) => embryo !== chipValueToDelete
                  );
                  onChange(updatedEmbryos);
                }}
              />
            )}
          />
        </Box>
        <Controller
          name="straw"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <InputField
              {...field}
              inputRef={ref}
              label={t('STRAW')}
              placeholder={t('#')}
              error={!!errors?.straw}
              helperText={errors?.straw?.message}
              required
              fullWidth
            />
          )}
        />
        <Box marginBottom={spacings.medium}></Box>
        <Flex justifyContent="center" marginTop={spacings.large} width="20%">
          <Button
            fullWidth
            disabled={isCreatingReport}
            onClick={handleSubmit(onSubmit)}
            type="button"
          >
            {t('SUBMIT')}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};
