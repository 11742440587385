import {
  BusyTimeServer,
  EncounterServer
} from './../../../types/patient-server';
import {
  Patient,
  PatientFormValues,
  Basics,
  PatientChartStatus,
  LabResult
} from 'src/types/patient';
import {
  PatientServer,
  NewCycleServer,
  AppointmentServer,
  LabResultServer,
  TaskServer,
  BasicsPropertiesServer,
  CycleServer,
  ExaminationServer,
  CreateAppointmentDetails,
  UpdateEggServer,
  CareTeamMemberServer,
  CycleGraphDataServer,
  LabResultCreateServer,
  PrescriptionServer,
  SemenVialServer,
  ThawReportFormServer,
  AddPrescriptionsToPatientServer,
  UpdatePrescriptionDosageServer
} from 'src/types/patient-server';
import { YesOrNo } from 'src/types/global';
import dayjs from 'dayjs';
import {
  convertStringIdToNumber,
  convertNumberIdToString,
  validateIsNumberOrStringNumber,
  getFullName
} from 'src/utils/general';
import {
  convertDateToDayjs,
  dashSeparatedDateFormat
} from '../../../utils/dateAndTIme';
import { DbUser, DbUserServer } from 'src/types/user';
import { CareTeamMember } from 'src/types/staff';
import {
  Cycle,
  CycleGraphData,
  NewCycleFormValues,
  SemenVial,
  ThawReportForm
} from 'src/types/cycle';
import {
  Appointment,
  BusyTime,
  BusyTimeType,
  EggRetrievalAppointmentStatusType,
  LabOrder,
  LabReport,
  LabResultClient
} from 'src/types/appointment';
import { Examination, OneTwoThree } from 'src/types/exam';
import { Task } from 'src/types/task';
import {
  MedicalProtocolForm,
  Prescription,
  PrescriptionRxForm,
  UpdatePrescriptionDosage
} from 'src/types/prescription';
import { LabResultForm } from '../medicalHistory/EditPatientLabResult';
import { EmbryologyReport, UpdateEgg } from 'src/types/eggAndEmbryo';
import { Message, MessageServer } from 'src/types/inbox';
import { Encounter } from 'src/types/encounter';
import { BillingServiceCode, BillingServiceCodeServer } from 'src/types/codes';
import { FeedActivity, FeedActivityServer } from 'src/types/feed';

export const convertPatientClientToServer = ({
  firstName,
  middleInitial,
  lastName,
  phoneNumber,
  email,
  city,
  state,
  zip,
  country,
  ssn,
  dateOfBirth,
  maritalStatus,
  treatmentReason,
  address,
  primaryPhysician,
  ethnicity,
  language,
  lastNameAtBirth,
  nickname,
  hasDisability,
  hasPartner,
  partnerAddress,
  partnerCity,
  partnerCountry,
  partnerDateOfBirth,
  partnerEmail,
  partnerEthnicity,
  partnerFirstName,
  partnerMiddleInitial,
  partnerSexAtBirth,
  partnerSsn,
  partnerLastName,
  partnerPhoneNumber,
  partnerState,
  partnerZip,
  createdAt,
  clinicId,
  sexAtBirth
}: PatientFormValues): PatientServer => {
  return {
    personalInfo: {
      firstName,
      middleInitial,
      lastName,
      email,
      phoneNumber,
      address,
      city,
      state,
      postalCode: zip,
      country,
      dateOfBirth: formatDateToString(dateOfBirth),
      ssn,
      sexAtBirth,
      ethnicity,
      language,
      lastNameAtBirth,
      nickname,
      hasDisability
    },
    maritalStatus,
    createdAt,
    mainReasonForTreatment: treatmentReason,
    hasPartner,
    primaryPhysician,
    partnerInfo: {
      country: partnerCountry,
      dateOfBirth: formatDateToString(partnerDateOfBirth),
      email: partnerEmail,
      firstName: partnerFirstName,
      middleInitial: partnerMiddleInitial,
      lastName: partnerLastName,
      phoneNumber: partnerPhoneNumber,
      address: partnerAddress,
      city: partnerCity,
      ethnicity: partnerEthnicity,
      sexAtBirth: partnerSexAtBirth,
      ssn: partnerSsn,
      postalCode: partnerZip,
      state: partnerState
    },
    clinicId,
    chartStatus: PatientChartStatus.PATIENT
  };
};

export const convertPatientServerToClient = ({
  role,
  personalInfo,
  chartStatus,
  partnerInfo,
  hasPartner,
  ...restPatient
}: PatientServer): Patient => {
  return {
    personalInfo: {
      ...personalInfo,
      patientRole: role,
      id: convertNumberIdToString(personalInfo.id),
      dateOfBirth: dayjs(personalInfo.dateOfBirth).toDate()
    },
    hasPartner,
    partnerInfo: hasPartner
      ? {
          ...partnerInfo,
          countryOfBirth: partnerInfo?.countryOfBirth,
          id: convertNumberIdToString(partnerInfo.id),
          dateOfBirth: dayjs(partnerInfo?.dateOfBirth).toDate()
        }
      : null,
    patientStatus: chartStatus,
    ...restPatient
  };
};

export const convertCycleFormClientToServer = ({
  id: _id,
  surrogacy,
  prescriptionsRx,
  appointments,
  medicationProtocols,
  treatmentProtocolTemplate,
  startDate,
  cycleDayOne,
  dateOfBirth,
  dueDate,
  outcomeDate,
  ...rest
}: NewCycleFormValues): NewCycleServer => {
  const convertedCycle: NewCycleServer = {
    ...rest,
    treatmentProtocolTemplate: treatmentProtocolTemplate || null,
    surrogacy: surrogacy === YesOrNo.YES,
    startDate: formatDateToString(startDate),
    prescriptions:
      medicationProtocols?.map(
        ({ startDate, endDate, time, ...restMedication }) => ({
          startDate: formatDateToString(startDate),
          endDate: formatDateToString(endDate),
          time: time
            ?.map((time) => convertPrescriptionTimeClientToServer(time))
            .join(','),
          ...restMedication
        })
      ) || [],
    prescriptionsRx:
      prescriptionsRx?.map(({ deliveryDate, ...restPrescription }) => ({
        deliveryDate: formatDateToString(deliveryDate),
        ...restPrescription
      })) || [],
    appointments:
      appointments?.map(
        ({
          appointmentType,
          id: _id,
          endTime,
          panelIds,
          labOrders: _labOrders,
          staffIds,
          appointmentPurpose,
          ...restAppointment
        }) => ({
          type: appointmentType,
          endDate: endTime,
          purpose: appointmentPurpose,
          staffIds: staffIds?.map((id) => convertStringIdToNumber(id)),
          status: EggRetrievalAppointmentStatusType.PRE_ARRIVAL,
          panelIds: panelIds?.map((id) => convertStringIdToNumber(id)),
          ...restAppointment
        })
      ) || []
  };

  if (cycleDayOne) {
    convertedCycle.cycleDayOne = formatDateToString(cycleDayOne);
  }
  if (dateOfBirth) {
    convertedCycle.dateOfBirth = formatDateToString(dateOfBirth);
  }
  if (dueDate) {
    convertedCycle.dueDate = formatDateToString(dueDate);
  }
  if (outcomeDate) {
    convertedCycle.outcomeDate = formatDateToString(outcomeDate);
  }
  return convertedCycle;
};

export const convertAppointmentServerToClient = ({
  type,
  endDate,
  labOrders,
  purpose,
  staffMembers,
  cycleId: _cycleId,
  labOrderInfo: _labOrderInfo,
  ...rest
}: AppointmentServer): Appointment => {
  const labOrderInfoFromOrder = labOrders?.[0] || {};

  return {
    endTime: endDate,
    appointmentType: type,
    labOrderInfo: labOrderInfoFromOrder,
    labOrders: labOrders?.map(
      ({ appointmentId, id, panelId, panel, labTest }) => {
        return {
          id,
          appointmentId,
          panelId,
          panel,
          labTestId: labTest?.id,
          labTest
        };
      }
    ),
    appointmentPurpose: purpose,
    staffIds: (
      staffMembers as unknown as { AppointmentStaff: { staffId: number } }[]
    )?.map((staff) =>
      convertNumberIdToString(staff?.AppointmentStaff?.staffId)
    ),
    ...rest
  };
};

export const convertLabReportClientToServer = ({
  date,
  results,
  patientId,
  cycleId
}: LabReport): Omit<LabResultServer, 'id'>[] => {
  return results
    .map((result) => ({
      ...result,
      cycleId,
      patientId,
      date: formatDateToString(date)
    }))
    .filter((result) => !!result.value);
};

export const convertTaskClientToServer = ({
  id,
  assignedToStaffId,
  name,
  dueDate,
  priority,
  category,
  status,
  comment,
  doneDate,
  doneByStaffId,
  patientId
}: Partial<Task>): TaskServer => {
  return {
    id,
    assignedToStaffId,
    description: name.value,
    dueDate: formatDateToString(dueDate),
    doneByStaffId,
    doneDate: formatDateToString(doneDate),
    priority,
    category,
    translationKey: name.translationKey,
    status,
    comment,
    patientId
  };
};
export const convertTaskServerToClient = ({
  description,
  translationKey,
  patientId,
  dueDate,
  doneDate,
  ...restTask
}: TaskServer): Task => ({
  name: {
    translationKey,
    value: description
  },
  doneDate: doneDate ? dayjs(doneDate).toDate() : null,
  dueDate: dueDate ? dayjs(dueDate).toDate() : null,
  patientId,
  ...restTask
});

export const convertAppointmentClientToServer = ({
  appointmentPurpose,
  appointmentType,
  endTime,
  isVirtual,
  location,
  labTestIds,
  ...rest
}: Appointment): CreateAppointmentDetails => {
  return {
    purpose: appointmentPurpose,
    endDate: endTime,
    isVirtual,
    location: isVirtual ? null : location,
    type: appointmentType,
    labTestIds,
    ...rest
  };
};

export const convertAppointmentWithStatusPayloadToServer = ({
  appointmentPurpose,
  appointmentType,
  endTime,
  isVirtual,
  location,
  labTestIds,
  patient,
  ...rest
}: Appointment): CreateAppointmentDetails => {
  return {
    purpose: appointmentPurpose,
    endDate: endTime,
    location: isVirtual ? null : location,
    type: appointmentType,
    labTestIds,
    patientFullName: getFullName(patient),
    clinicName: patient?.clinic?.name,
    clinicEmail: patient?.clinic?.email,
    ...rest
  };
};

export const convertBasicsDetailsClientToServer = ({
  patientId,
  height,
  ttcMonths,
  ttcYears,
  weight,
  cycle
}: Basics): BasicsPropertiesServer => {
  const ttcSince = dayjs()
    .subtract(ttcYears, 'years')
    .subtract(ttcMonths, 'months')
    .toDate();
  return {
    patientId,
    weightKg: weight,
    heightCm: height,
    ttcSince,
    cycleLength: cycle
  };
};

export const convertCycleServerToClient = (
  cycle: CycleServer
): Cycle | null => {
  if (!cycle) {
    return null;
  }

  const {
    surrogacy,
    appointments,
    prescriptions,
    startDate,
    dueDate,
    dateOfBirth,
    cycleDayOne,
    outcomeDate,
    prescriptionsToDelete: _prescriptionsToDelete,
    ...rest
  } = cycle;

  return {
    surrogacy: surrogacy ? YesOrNo.YES : YesOrNo.NO,
    startDate: dayjs(startDate).toDate(),
    dueDate: dueDate ? dayjs(dueDate).toDate() : null,
    dateOfBirth: dateOfBirth ? dayjs(dateOfBirth).toDate() : null,
    cycleDayOne: cycleDayOne ? dayjs(cycleDayOne).toDate() : null,
    outcomeDate: outcomeDate ? dayjs(outcomeDate).toDate() : null,
    medicationProtocols:
      prescriptions?.map(({ startDate, endDate, time, ...restMedication }) => ({
        ...restMedication,
        startDate: dayjs(startDate).toDate(),
        endDate: dayjs(endDate).toDate(),
        time: convertPrescriptionTimeServerToClient(time)
      })) || [],
    appointments: appointments?.map(
      ({ id: _id, labOrders, purpose, ...restAppointment }) => ({
        status: EggRetrievalAppointmentStatusType.PRE_ARRIVAL,
        appointmentPurpose: purpose,
        labOrders:
          labOrders?.map((labOrder) => {
            const { appointmentId, id, panelId } = labOrder;

            return {
              appointmentId,
              id,
              labTest: labOrder?.labTest?.id ? labOrder?.labTest : null,
              labTestId: labOrder?.labTest?.id || null,
              panelId: panelId || null
            } as LabOrder;
          }) || [],
        ...restAppointment
      })
    ),
    ...rest
  };
};

export const convertCycleClientToServer = ({
  id,
  displayId,
  surrogacy,
  appointments,
  medicationProtocols,
  startDate,
  eggComments: _eggComments,
  spermComments: _spermComments,
  embryoComments: _embryoComments,
  dueDate,
  dateOfBirth,
  cycleDayOne,
  outcomeDate,
  medicationsToDelete,
  ...rest
}: Partial<Cycle>): CycleServer => {
  const convertedCycle: CycleServer = {
    id,
    displayId,
    surrogacy: surrogacy === YesOrNo.YES,
    prescriptionsToDelete:
      medicationsToDelete?.map((id) => convertStringIdToNumber(id)) || [],
    prescriptions:
      medicationProtocols?.map(
        ({
          createdByStaffId: _createdByStaffId,
          startDate,
          endDate,
          time,
          ...restMedication
        }) => ({
          ...restMedication,
          cycleId: id,
          startDate: formatDateToString(startDate),
          endDate: formatDateToString(endDate),
          time: time
            ?.map((time) => convertPrescriptionTimeClientToServer(time))
            .join(',')
        })
      ) || [],
    appointments:
      appointments?.map(
        ({
          appointmentType,
          id: _id,
          endTime,
          panelIds,
          labOrders: _labOrders,
          staffIds,
          appointmentPurpose,
          ...restAppointment
        }) => ({
          ...restAppointment,
          type: appointmentType,
          cycleId: id,
          endDate: endTime,
          purpose: appointmentPurpose,
          staffIds: staffIds?.map((id) => convertStringIdToNumber(id)),
          status: EggRetrievalAppointmentStatusType.PRE_ARRIVAL,
          panelIds: panelIds || []
        })
      ) || [],
    ...rest
  };

  if (startDate) {
    convertedCycle.startDate = formatDateToString(startDate);
  }
  if (dateOfBirth) {
    convertedCycle.dateOfBirth = formatDateToString(dateOfBirth);
  }
  if (dueDate) {
    convertedCycle.dueDate = formatDateToString(dueDate);
  }
  if (cycleDayOne) {
    convertedCycle.cycleDayOne = formatDateToString(cycleDayOne);
  }
  if (outcomeDate) {
    convertedCycle.outcomeDate = formatDateToString(outcomeDate);
  }

  return convertedCycle;
};

export const convertExaminationServerToClient = ({
  medicationsAdministered,
  noOfEmbryosTransferred,
  eggAndEmbryoTransferred,
  ...restExam
}: ExaminationServer): Examination => {
  const examToReturn: Examination = {
    ...restExam
  };

  if (medicationsAdministered?.length) {
    examToReturn.medicationsAdministered = medicationsAdministered.map(
      ({ id, ...rest }) => ({
        id: convertNumberIdToString(id),
        ...rest
      })
    );
  }
  if (validateIsNumberOrStringNumber(noOfEmbryosTransferred)) {
    switch (noOfEmbryosTransferred) {
      case 1:
        examToReturn.noOfEmbryosTransferred = OneTwoThree.ONE;
        break;
      case 2:
        examToReturn.noOfEmbryosTransferred = OneTwoThree.TWO;
        break;
      case 3:
        examToReturn.noOfEmbryosTransferred = OneTwoThree.THREE;
        break;
      default:
        examToReturn.noOfEmbryosTransferred = null;
    }
  }

  if (eggAndEmbryoTransferred?.length) {
    examToReturn.eggAndEmbryoTransferred = eggAndEmbryoTransferred.map((id) =>
      convertNumberIdToString(id)
    );
  }

  return examToReturn;
};

export const convertMedicalFormToPrescriptions = ({
  medicationProtocols,
  prescriptionsRx,
  cycleId,
  patientId
}: MedicalProtocolForm &
  PrescriptionRxForm & {
    cycleId?: string;
  }): AddPrescriptionsToPatientServer => ({
  cycleId,
  patientId,
  prescriptions: medicationProtocols.map(
    ({ cycleId: _cycleId, startDate, endDate, time, ...rest }) => ({
      ...rest,
      startDate: formatDateToString(startDate),
      endDate: formatDateToString(endDate),
      time: time
        ?.map((time) => convertPrescriptionTimeClientToServer(time))
        .join(',')
    })
  ),
  prescriptionsRx: prescriptionsRx.map(({ deliveryDate, ...rest }) => ({
    deliveryDate: formatDateToString(deliveryDate),
    ...rest
  }))
});

export const convertUpdateEggClientToServer = ({
  ploidy,
  straw,
  ...restUpdate
}: UpdateEgg): UpdateEggServer => ({
  geneticStatus: ploidy,
  strawNum: straw,
  ...restUpdate
});

export const formatDateToString = (date: string | Date): string | null => {
  if (!date) return null;
  const originalDate = dayjs(date);
  const formattedDate = originalDate.format(dashSeparatedDateFormat);
  return formattedDate;
};

export const convertCareTeamClientToServer = ({
  isPrimary,
  staffId
}: CareTeamMember): CareTeamMemberServer => ({
  isPrimary,
  staffId
});

export const convertCycleGraphDataServerToClient = ({
  data,
  cyclePhases,
  medicationsList,
  ...rest
}: CycleGraphDataServer): CycleGraphData => ({
  data: data.map(({ follicularExamId, notes, ...data }) => ({
    ...data,
    follicularExamId: convertNumberIdToString(follicularExamId),
    notes:
      notes?.map(({ date, ...note }) => ({
        ...note,
        date: dayjs(date).toDate()
      })) || []
  })),
  cyclePhases: cyclePhases?.map(
    ({
      transferExamId,
      semenAnalysisId,
      eggRetrievalId,
      iuiExamId,
      ...restPhase
    }) => ({
      transferExamId: convertNumberIdToString(transferExamId),
      semenAnalysisId: convertNumberIdToString(semenAnalysisId),
      eggRetrievalId: convertNumberIdToString(eggRetrievalId),
      iuiExamId: convertNumberIdToString(iuiExamId),
      ...restPhase
    })
  ),
  medicationsList: medicationsList?.map(({ id, ...restMedication }) => ({
    ...restMedication,
    id: convertNumberIdToString(id)
  })),
  ...rest
});

export const convertPrescriptionClientToServer = ({
  createdByStaffId: _createdByStaffId,
  startDate,
  endDate,
  time,
  ...prescription
}: Partial<Prescription>): Partial<PrescriptionServer> => {
  const updatedPrescription: Partial<PrescriptionServer> = {
    startDate: formatDateToString(startDate),
    endDate: formatDateToString(endDate),
    time: time
      ?.map((time) => convertPrescriptionTimeClientToServer(time))
      .join(','),
    ...prescription
  };

  return updatedPrescription;
};

export const convertPrescriptionServerToClient = ({
  startDate,
  endDate,
  time,
  ...prescription
}: PrescriptionServer): Prescription => ({
  ...prescription,
  startDate: dayjs(startDate).toDate(),
  endDate: dayjs(endDate).toDate(),
  time: convertPrescriptionTimeServerToClient(time)
});

const convertLabResultToServer = ({
  date,
  ...labResult
}: Partial<LabResultClient>): Partial<LabResultCreateServer> => {
  const updatedLabResult: Partial<LabResultCreateServer> = {
    ...labResult
  };
  if (date) {
    updatedLabResult.date = formatDateToString(date);
  }

  return updatedLabResult;
};

export const convertPatientAndPartnerInfoWithTzToTimezone = ({
  clinicId,
  primaryPhysician,
  ...restPatient
}: Partial<PatientServer>): Partial<PatientServer> => {
  const updatedPatient: Partial<PatientServer> = {
    ...restPatient,
    clinicId,
    primaryPhysician,
    personalInfo: {
      ...restPatient.personalInfo,
      dateOfBirth: formatDateToString(restPatient.personalInfo.dateOfBirth),
      timeZone: getIANATimeZone()
    },
    partnerInfo: {
      ...restPatient.partnerInfo,
      dateOfBirth: formatDateToString(restPatient.partnerInfo.dateOfBirth)
    }
  };

  if (updatedPatient.hasPartner && updatedPatient.partnerInfo) {
    updatedPatient.partnerInfo = {
      ...updatedPatient.partnerInfo,
      timeZone: getIANATimeZone()
    };
  }

  return updatedPatient;
};

const getIANATimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const convertSemenVialServerToClient = ({
  semenAnalysisId,
  id,
  ...rest
}: SemenVialServer): SemenVial => ({
  semenAnalysisId: convertNumberIdToString(semenAnalysisId),
  id: convertNumberIdToString(id),
  ...rest
});

export const convertThawReportFormClientToServer = ({
  dateOfThaw,
  ...rest
}: ThawReportForm): ThawReportFormServer => ({
  thawDate: formatDateToString(dateOfThaw),
  ...rest
});

export const convertDbUserServerToClient = ({
  user: { clinicId, id, ...rest },
  userType
}: DbUserServer): DbUser => ({
  userType,
  user: {
    ...rest,
    id: convertNumberIdToString(id),
    clinicId
  }
});

export const transformQuestionSerializedIdToLabel = (
  serializedId: string | undefined
): string =>
  serializedId
    ?.split('_')
    .map((s: string) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ') || '';

export const convertEmbryologyReportClientToServer = ({
  retrievalDate,
  ...rest
}: EmbryologyReport) => ({
  ...rest,
  retrievalDate: formatDateToString(retrievalDate)
});

export const convertDosageChangeClientToServer = ({
  startDate,
  endDate,
  ...rest
}: Omit<
  UpdatePrescriptionDosage,
  'cycleId'
>): UpdatePrescriptionDosageServer => ({
  ...rest,
  startDate: formatDateToString(startDate),
  endDate: formatDateToString(endDate)
});

export const getLabResultAsFormData = ({
  labResult
}: {
  labResult: Partial<LabResultForm>;
}): FormData => {
  const { comment, date, id, measurementType, patientId, testId, value } =
    convertLabResultToServer(labResult);

  const formData = new FormData();

  if (comment) {
    formData.append('comment', comment);
  }
  if (date) {
    formData.append('date', date);
  }

  if (id) {
    formData.append('id', id);
  }

  if (measurementType) {
    formData.append('measurementType', measurementType);
  }

  if (patientId) {
    formData.append('patientId', patientId);
  }

  if (testId) {
    formData.append('testId', testId);
  }

  if (value) {
    formData.append('value', value);
  }

  if (labResult?.externalLabImage?.[0]) {
    formData.append('images', labResult?.externalLabImage[0]);
  }

  if (labResult?.deleteImage) {
    formData.append('deleteImage', JSON.stringify(labResult?.deleteImage));
  }

  return formData;
};

const convertPrescriptionTimeClientToServer = (time: Date) => {
  return dayjs(time).format('hh:mma');
};

const convertPrescriptionTimeServerToClient = (time?: string) => {
  return time?.split(',').map((time) => dayjs(time, 'hh:mma').toDate()) || null;
};

export const convertBusyTimeServerToClient = (
  busyTime: BusyTimeServer
): BusyTime => {
  const startTime = dayjs(busyTime.startTime);
  const endTime = dayjs(busyTime.endTime);
  switch (busyTime.type) {
    case BusyTimeType.ROOM:
      return {
        ...busyTime,
        startTime,
        endTime,
        roomId: convertNumberIdToString(busyTime.roomId)
      };

    case BusyTimeType.STAFF:
      return {
        ...busyTime,
        startTime,
        endTime,
        staffId: busyTime.staffId
      };
  }
};

export const convertMessageServerToClient = ({
  seenAt,
  readAt,
  createdAt,
  updatedAt,
  ...message
}: MessageServer): Message => ({
  seenAt: convertDateToDayjs(seenAt),
  readAt: convertDateToDayjs(readAt),
  createdAt: convertDateToDayjs(createdAt),
  updatedAt: convertDateToDayjs(updatedAt),
  ...message
});

export const convertLabResultServerToClient = ({
  id,
  ...rest
}: LabResultServer): LabResult => ({
  id: convertNumberIdToString(id),
  ...rest
});

export const convertEncounterServerToClient = ({
  encounterDate,
  lockedAt,
  unlockedAt,
  ...rest
}: EncounterServer): Encounter => ({
  encounterDate: convertDateToDayjs(encounterDate),
  lockedAt: convertDateToDayjs(lockedAt),
  unlockedAt: convertDateToDayjs(unlockedAt),
  ...rest
});

export const convertBillingServiceCodeServerToClient = (
  billingServiceCode: BillingServiceCodeServer
): BillingServiceCode => {
  return {
    ...billingServiceCode,
    serviceDescription:
      billingServiceCode.frontendDescription ||
      billingServiceCode.serviceDescription
  };
};

export const convertFeedActivitiesServerToClient = (
  feedActivities: FeedActivityServer[],
  byClinic?: boolean
): FeedActivity[] => {
  return feedActivities.map((activity) => ({
    ...activity,
    patientName: byClinic ? getFullName(activity.patient) : null
  }));
};
