import Button from 'src/components/display/Button';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Flex from 'src/components/layout/Flex';
import Box from 'src/components/layout/Box';
import { spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography';
import useStaffMembers from 'src/hooks/useStaffMembers';
import Card from 'src/components/display/Card';
import Table from 'src/components/display/Table';
import { StaffMember } from 'src/types/staff';
import { GridColDef } from '@mui/x-data-grid-premium';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from 'src/components/display/IconButton';
import Avatar from 'src/components/display/Avatar';
import { UserTypes } from 'src/types/user';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { usePopover } from 'src/components/components-api/PopoverProvider';
import ActiveMenu from './ActiveMenu';
import useClinicsApi from 'src/hooks/useClinicsApi';
import useMeApi from 'src/hooks/useMeApi';
import { AvatarSizes } from '../../../components/display/Avatar/Avatar';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const StaffList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getStaffMembers } = useStaffMembers();
  const { getClinicInfo } = useClinicsApi();
  const { getMe } = useMeApi();
  const { resendWelcomeEmail } = useStaffMembers();

  const { data: me } = getMe();
  const { data: clinic } = getClinicInfo(me?.user?.clinicId);
  const { data: staff, isLoading } = getStaffMembers();
  const { getDefaultAvatar } = useGetDefaultAvatar();
  const { isLoading: isResending, mutate: resendWelcomeEmailMutate } =
    resendWelcomeEmail();
  const { openPopover, closePopover } = usePopover();

  const columns: GridColDef<StaffMember>[] = [
    {
      field: 'edit',
      headerName: '',
      renderCell: ({ row: staff }) => (
        <IconButton
          icon={<EditIcon sx={{ color: 'black' }} />}
          iconSize="small"
          bgColor="transparent"
          onClick={() => {
            navigate(`/app/clinic-settings/staff/${staff.id}/edit`);
          }}
        />
      )
    },
    {
      flex: 1,
      field: 'firstName',
      headerName: t('STAFF_NAME'),
      renderCell: ({ row: staff }: { row?: StaffMember }) => {
        const avatar = getDefaultAvatar({
          userId: staff?.id,
          userType: UserTypes.staff,
          pictureId: staff?.pictureId
        });

        return (
          <Flex gap={spacings.large}>
            <Avatar size={AvatarSizes.SMALL} image={avatar} />
            <Typography alignSelf={'center'}>
              {staff.firstName} {staff.lastName}
            </Typography>
          </Flex>
        );
      }
    },
    {
      flex: 1,
      field: 'jobTitle',
      headerName: t('TITLE'),
      renderCell: ({ row: staff }) => (
        <Flex gap={spacings.large}>
          <Typography alignSelf={'center'}>{staff.jobTitle}</Typography>
        </Flex>
      )
    },
    {
      flex: 1,
      field: t('ROLE_TITLE'),
      headerName: t('ROLE')
    },
    {
      flex: 1,
      field: t('EMAIL_TITLE'),
      headerName: t('EMAIL').toUpperCase()
    },
    {
      flex: 1,
      field: t('CLINIC').toUpperCase(),
      renderCell: () => (
        <Flex gap={spacings.large}>
          <Typography alignSelf={'center'}>{clinic?.name}</Typography>
        </Flex>
      )
    },
    {
      headerName: t('ACTIVE'),
      field: 'isActive',
      renderCell: ({ row: staff }) => (
        <CircleRoundedIcon
          sx={{
            fill: staff.isActive === false ? '#f28d92' : '#90e9ce',
            cursor: 'pointer'
          }}
          onClick={(ev) =>
            openPopover({
              hideToolbar: true,
              paperPadding: spacings.large,
              children: <ActiveMenu staff={staff} />,
              anchorEl: ev.currentTarget,
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom'
              },
              transformOrigin: {
                horizontal: 'right',
                vertical: 'top'
              }
            })
          }
        />
      )
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      renderCell: ({ row }) => (
        <IconButton
          bgColor="transparent"
          onClick={(ev) =>
            openPopover({
              hideToolbar: true,
              paperPadding: spacings.large,
              children: (
                <Menu anchorEl={ev.currentTarget} open onClose={closePopover}>
                  <MenuItem
                    disabled={isResending}
                    onClick={() => {
                      closePopover();
                      resendWelcomeEmailMutate(row.id);
                    }}
                  >
                    {t('RESEND_WELCOME_EMAIL')}
                  </MenuItem>
                </Menu>
              ),
              anchorEl: ev.currentTarget,
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom'
              },
              transformOrigin: {
                horizontal: 'right',
                vertical: 'top'
              }
            })
          }
        >
          <MoreVertIcon sx={{ color: 'grey' }} />
        </IconButton>
      )
    }
  ];
  return (
    <>
      <Flex justifyContent="space-between">
        <Box marginBottom={spacings.xlarge}>
          <Typography variant="h1" paddingBottom={spacings.xlarge}>
            {t('STAFF_LIST_HEADER')}
          </Typography>
        </Box>
        <Button
          startIcon={<AddIcon />}
          onClick={() => navigate('/app/clinic-settings/staff/add')}
        >
          {t('ADD_NEW_STAFF')}
        </Button>
      </Flex>
      <Card marginTop={spacings.large}>
        <Table
          getRowHeight={() => 40}
          loading={isLoading}
          columns={columns}
          rows={staff || []}
          paginationModel={{ page: 0, pageSize: 25 }}
          hideFilterToolbar
        />
      </Card>
    </>
  );
};

export default StaffList;
