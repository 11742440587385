import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Control, Controller, Path, FieldValues } from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';
import Chip from 'src/components/data-entry/Chips/Chip';
import InputLabel from 'src/components/data-entry/InputLabel';
import Box from 'src/components/layout/Box';
import Flex from 'src/components/layout/Flex';
import { Colors } from 'src/components/styles/colors';
import { radii, spacings } from 'src/components/styles/constants';
import Icd10Selector from 'src/modules/calendar/Icd10Selector';
import { Icd10Code } from 'src/types/codes';
import { ChipsProps } from 'src/components/data-entry/Chips/Chips';

const StyledCodesChipsContainer = styled(Box)`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: ${spacings.small};
  padding: ${spacings.small};
  flex: 1;
`;

const StyledCodesChipsLabel = styled(InputLabel)`
  display: flex;
  flex-direction: column;
  gap: ${spacings.large};
  margin-bottom: ${spacings.small};
`;

type Icd10ChipsBaseProps = Pick<
  ChipsProps,
  'error' | 'helperText' | 'disabled'
>;

interface Icd10ChipsProps<T extends FieldValues> extends Icd10ChipsBaseProps {
  control: Control<T>;
  name: Path<T>;
  label?: string;
  flattenValue?: boolean;
}

export const Icd10Chips = <T extends FieldValues>({
  error,
  helperText,
  control,
  name,
  label,
  disabled,
  flattenValue
}: Icd10ChipsProps<T>): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <StyledCodesChipsLabel label={label || t('ENTER_DIAGNOSTIC_CODE')} />
      <Flex
        flexDirection="column"
        border={`1px solid ${Colors.gray}`}
        borderRadius={`${radii.medium}`}
        height="100%"
      >
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => {
            const codes: Icd10Code[] | string[] = value || [];
            const codeLabelList = codes as string[];
            const codeList = codes as Icd10Code[];

            const handleAddChip = (newCode: Icd10Code) => {
              if (flattenValue) {
                if (
                  newCode &&
                  !codeLabelList.some((code) => code === newCode.label)
                ) {
                  onChange([...codeLabelList, newCode.label]);
                }
              } else {
                if (
                  newCode &&
                  !codeList.some((code) => code.code === newCode.code)
                ) {
                  onChange([...codeList, newCode]);
                }
              }
            };

            const handleRemoveChip = (codeToRemove: Icd10Code | string) => {
              if (flattenValue) {
                onChange(codeLabelList.filter((code) => code !== codeToRemove));
              } else {
                const chip = codeToRemove as Icd10Code;
                onChange(codeList.filter((code) => code.code !== chip.code));
              }
            };

            return (
              <>
                <Icd10Selector
                  error={error}
                  helperText={helperText}
                  onChange={handleAddChip}
                  value={null}
                  disabled={disabled}
                />
                <StyledCodesChipsContainer>
                  {codes.map((code) => {
                    return (
                      <Chip
                        key={!flattenValue ? code.code : code}
                        label={!flattenValue ? code.label : code}
                        deleteIcon={<ClearIcon />}
                        onDelete={() => handleRemoveChip(code)}
                        disabled={disabled}
                      />
                    );
                  })}
                </StyledCodesChipsContainer>
              </>
            );
          }}
        />
      </Flex>
    </>
  );
};
