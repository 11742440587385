import { convertTaskServerToClient } from 'src/modules/patients/utils/conversions';
import { CycleTreatmentWitness } from 'src/types/cycle';
import {
  AddChecklistTemplateType,
  ChecklistTemplate,
  Task
} from 'src/types/task';
import { TaskServer } from 'src/types/patient-server';
import axiosApiInstance from 'src/utils/axios';
import { dashSeparatedDateFormat } from 'src/utils/dateAndTIme';

export const updatePatientWitnessedTaskRequest = async ({
  patientId,
  witnessedTask
}: {
  patientId: string;
  witnessedTask: CycleTreatmentWitness;
}): Promise<{ witnessedTask: CycleTreatmentWitness; patientId: string }> => {
  return { patientId, witnessedTask };
};

export async function createTaskRequest(task: TaskServer): Promise<string> {
  const response = await axiosApiInstance.post<TaskServer>(
    '/tasks/staff',
    task
  );
  const { data } = response;
  return `${data.patientId}`;
}

export async function updateTaskRequest(task: TaskServer): Promise<string> {
  const response = await axiosApiInstance.patch<any>(
    `/tasks/${task.id}/staff`,
    task
  );
  const { data } = response;
  return data._id;
}

export async function getPatientTasks({
  patientId,
  showDone = false
}: {
  patientId: string;
  showDone?: boolean;
}): Promise<Task[]> {
  const queryParams = `?patientId=${patientId}${
    showDone ? '&showDone=true' : '&showDone=false'
  }`;
  const response = await axiosApiInstance.get<TaskServer[]>(
    `/tasks${queryParams}`
  );
  const { data } = response;

  return data.map((task) => convertTaskServerToClient(task));
}

export const getTasksByStaffId = async (staffId: string): Promise<Task[]> => {
  const response = await axiosApiInstance.get<TaskServer[]>(
    `/tasks/staff/${staffId}`
  );
  const { data } = response;
  return data.map((task) => convertTaskServerToClient(task));
};

export const getTasksByStaffPatientId = async (
  staffId: string,
  patientId: string
): Promise<Task[]> => {
  const response = await axiosApiInstance.get<TaskServer[]>(
    `/tasks/staff/${staffId}/patient/${patientId}`
  );
  const { data } = response;
  return data.map((task) => convertTaskServerToClient(task));
};

export const getChecklistTemplatesRequest = async (): Promise<
  ChecklistTemplate[]
> => {
  const response = await axiosApiInstance.get<ChecklistTemplate[]>(
    'tasks/templates'
  );
  return response.data;
};

export const addChecklistTemplateRequest = async ({
  dueDate,
  ...rest
}: AddChecklistTemplateType): Promise<Task[]> => {
  const response = await axiosApiInstance.post<TaskServer[]>(
    'tasks/staff/templates',
    {
      ...rest,
      dueDate: dueDate ? dueDate.format(dashSeparatedDateFormat) : null
    }
  );
  const { data } = response;
  return data.map((task) => convertTaskServerToClient(task));
};
