import { FunctionComponent, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Flex from 'src/components/layout/Flex';
import { Colors } from 'src/components/styles/colors';
import { radii, spacings } from 'src/components/styles/constants';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { ChannelTypes, InboxTabs, InboxTypes } from 'src/types/inbox';
import InboxSidebar from './InboxSidebar';
import MessageInboxOverview from './messages/MessageInboxOverview';
import TaskInboxOverview from './tasks/TaskInboxOverview';

export const Inbox: FunctionComponent<{}> = ({}) => {
  const { t } = useTranslation();
  const { selectedInboxItem, selectedInboxTab } = useContext(SidebarContext);

  return (
    <>
      <Helmet>
        <title>{t('INBOX_PAGE_TITLE')}</title>
      </Helmet>
      <Flex
        borderRadius={radii.xlarge}
        overflow="hidden"
        height="100%"
        boxSizing="border-box"
      >
        <InboxSidebar
          sx={{
            display: { xs: 'none', lg: 'flex' }
          }}
        />
        <Flex
          bgcolor={Colors.white}
          flex={5}
          padding={`${spacings.xxlarge} ${spacings.xlarge} ${spacings.medium} ${spacings.xlarge}`}
        >
          {selectedInboxItem?.type === InboxTypes.Message && (
            <MessageInboxOverview
              chatType={
                selectedInboxTab === InboxTabs.PatientInbox
                  ? ChannelTypes.Patient
                  : ChannelTypes.Internal
              }
              channelId={selectedInboxItem.channelId}
              patientId={selectedInboxItem.patientId}
            />
          )}
          {selectedInboxItem?.type === InboxTypes.Task && (
            <TaskInboxOverview patientId={`${selectedInboxItem.patientId}`} />
          )}
        </Flex>
      </Flex>
    </>
  );
};
