import dayjs from 'dayjs';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'src/components/display/Card/Card';
import Typography from 'src/components/display/Typography/Typography';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import { Colors } from 'src/components/styles/colors';
import { radii, spacings } from 'src/components/styles/constants';
import { DiagnosisCategory } from 'src/types/patient';
import {
  getCycleLengthText,
  getDisplayValue,
  getFullName,
  getUserAge
} from 'src/utils/general';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { useParams } from 'react-router-dom';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import EditIcon from '@mui/icons-material/Edit';
import { AddEditOutcomeReportForm } from '../reportForms/AddEditOutcomeReportForm';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import { weights } from 'src/components/styles/fonts';
import Loader from 'src/components/display/Loader/Loader';
import Center from 'src/components/layout/Center/Center';
import { getDateFormat } from 'src/utils/dateAndTIme';
import useCycle from 'src/hooks/useCycle';
import { CycleStatus, TreatmentTypes } from 'src/types/cycle';
import { CreateActivateCycleButton } from '../overview/CreateActivateCycleButton';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';
import { UserTypes } from 'src/types/user';
import PatientAvatar from 'src/components/display/Avatar/PatientAvatar';

interface TreatmentOverviewTopCardsProps {
  cycleToDisplayId: string;
}

const getBetaString = (betaValue?: number, betaUnit?: string) => {
  if (!betaValue) return '--';
  return `${betaValue} ${betaUnit || ''}`.trim();
};

export const TreatmentOverviewTopCards: FC<TreatmentOverviewTopCardsProps> = ({
  cycleToDisplayId
}) => {
  const { t } = useTranslation();
  const { patientId } = useParams();
  const { openDialog } = useDialog();
  const { getPatientOverview, getPatientDiagnosis } = usePatientsApi();
  const { getPatientCycles } = useCycle();
  const { getDefaultAvatar } = useGetDefaultAvatar();

  const { data: patientOverview, isLoading: isLoadingPatientOverview } =
    getPatientOverview(patientId);

  const { data: cycles, isLoading: isLoadingCycles } =
    getPatientCycles(patientId);

  const { data: patientDiagnosis } = getPatientDiagnosis(patientId);

  const primaryDiagnosis = patientDiagnosis?.find(
    (diagnosis) => diagnosis.category === DiagnosisCategory.PRIMARY_DIAGNOSIS
  );

  const { amhValue, amhUnit } = useMemo(() => {
    const amhData = patientOverview?.hormonalData?.['Anti-Mullerian Hormone'];
    return {
      amhValue: amhData?.value,
      amhUnit: amhData?.unit
    };
  }, [patientOverview]);

  const { years, months } = patientOverview?.patientBasicInfo?.age || {};
  const cyclesMapByType = useMemo(
    () =>
      cycles?.reduce<Partial<Record<TreatmentTypes, number>>>(
        (acc, { treatmentType }) => {
          acc[treatmentType] = (acc[treatmentType] || 0) + 1;
          return acc;
        },
        {}
      ) || {},
    [cycles]
  );

  const cycle = cycles?.find(({ id }) => id === cycleToDisplayId);

  const {
    startDate,
    treatmentType,
    treatmentProtocol,
    eggSource,
    spermSource,
    surrogacy,
    status,
    pregnancyStatus,
    eggOptions,
    spermOptions,
    uterusOptions,
    geneticOptions,
    outcome
  } = cycle || {};

  const addOns = [
    ...(eggOptions || []),
    ...(spermOptions || []),
    ...(uterusOptions || []),
    ...(geneticOptions || [])
  ]
    .map((addOn) => t(addOn))
    .join(', ');

  const showBetaAndPregnancyStatus =
    treatmentType === TreatmentTypes.IVF_ET ||
    treatmentType === TreatmentTypes.FET ||
    treatmentType === TreatmentTypes.IUI ||
    treatmentType === TreatmentTypes.OVULATION_INDUCTION;

  const cyclesByType = Object.entries(cyclesMapByType);
  const isCyclePending = cycle?.status === CycleStatus.PENDING;

  const patientAvatar = getDefaultAvatar({
    userId: patientId,
    userType: UserTypes.patients,
    pictureId: patientOverview?.patientBasicInfo?.pictureId
  });

  return (
    <Flex flexWrap={'wrap'} gap={spacings.large} flex={1}>
      <Card
        borderRadius={radii.medium}
        display="flex"
        flexDirection="column"
        flex={1}
        shadow
        padding={spacings.large}
        gap={spacings.large}
      >
        <Typography variant="h4" color={Colors.emperor}>
          {getFullName(patientOverview?.patientBasicInfo).toUpperCase()}
        </Typography>
        <Box border={`1px solid ${Colors.mercury}`} />

        <Flex gap={spacings.xxlarge}>
          {isLoadingPatientOverview ? (
            <Center width="100%">
              <Loader />
            </Center>
          ) : (
            <>
              <PatientAvatar image={patientAvatar} patientId={patientId} />
              <Flex gap={spacings.medium}>
                <Flex flexDirection="column" gap={spacings.small}>
                  <Flex gap={spacings.medium} justifyContent="space-between">
                    <Typography variant="h4" color={Colors.emperor}>
                      {t('AGE')}
                    </Typography>
                    <Typography variant="h4" color={Colors.emperor}>
                      {getUserAge({ months, years, isShort: true })}
                    </Typography>
                  </Flex>
                  <Flex gap={spacings.medium} justifyContent="space-between">
                    <Typography variant="h4" color={Colors.emperor}>
                      {t('SEX')}
                    </Typography>
                    <Typography variant="h4" color={Colors.emperor}>
                      {t(patientOverview.patientBasicInfo.sex) || '-'}
                    </Typography>
                  </Flex>
                  <Flex gap={spacings.medium} justifyContent="space-between">
                    <Typography variant="h4" color={Colors.emperor}>
                      {t('BMI')}
                    </Typography>
                    <Typography variant="h4" color={Colors.emperor}>
                      {getDisplayValue(
                        Math.floor(patientOverview?.patientProperties?.bmi)
                      )}
                    </Typography>
                  </Flex>
                  <Flex gap={spacings.medium} justifyContent="space-between">
                    <Typography variant="h4" color={Colors.emperor}>
                      {t('PATIENT_ID')}
                    </Typography>
                    <Typography variant="h4" color={Colors.emperor}>
                      {patientOverview?.patientBasicInfo?.displayId?.toUpperCase()}
                    </Typography>
                  </Flex>
                </Flex>
              </Flex>
              <Flex gap={spacings.medium}>
                <Flex flexDirection="column" gap={spacings.small}>
                  <Flex gap={spacings.medium} justifyContent="space-between">
                    <Typography variant="h4" color={Colors.emperor}>
                      {t('AMH')}
                    </Typography>
                    <Typography variant="h4" color={Colors.emperor}>
                      {getDisplayValue(amhValue) + amhUnit}
                    </Typography>
                  </Flex>
                  <Flex gap={spacings.medium} justifyContent="space-between">
                    <Typography variant="h4" color={Colors.emperor}>
                      {t('CYCLE')}
                    </Typography>
                    <Typography variant="h4" color={Colors.emperor}>
                      {getCycleLengthText(
                        patientOverview?.patientProperties?.cycle
                      )}
                    </Typography>
                  </Flex>
                </Flex>
              </Flex>
              <Flex gap={spacings.medium}>
                <Flex flexDirection="column" gap={spacings.small}>
                  <Typography variant="h4" color={Colors.emperor}>
                    {t('PRIMARY')}
                  </Typography>
                  {primaryDiagnosis ? (
                    <Typography variant="h4" color={Colors.emperor}>
                      {primaryDiagnosis.values.map(
                        (diagnosis, index) =>
                          (index !== 0 ? ', ' : '') + diagnosis.value
                      )}
                    </Typography>
                  ) : (
                    <Typography variant="h4" color={Colors.emperor}>
                      -
                    </Typography>
                  )}
                </Flex>
              </Flex>
            </>
          )}
        </Flex>
      </Card>
      <Flex flex={2} gap={spacings.large}>
        <Card
          borderRadius={radii.medium}
          display="flex"
          flexDirection="column"
          flex={1}
          shadow
          padding={spacings.large}
          gap={spacings.large}
        >
          <Typography variant="h4" color={Colors.emperor}>
            {t('HISTORY')}
          </Typography>
          <Box border={`1px solid ${Colors.mercury}`} />
          <Flex flexDirection="column" gap={spacings.small}>
            {cyclesByType.length === 0 ? (
              <Center>
                <Typography fontWeight={weights.extraBold}>
                  {t('NO_DATA')}
                </Typography>
              </Center>
            ) : (
              cyclesByType.map(([type, count]) => (
                <Flex
                  key={`type-${type}-count-${count}`}
                  justifyContent="space-between"
                >
                  <Typography variant="h4" color={Colors.emperor}>
                    {t(type)}
                  </Typography>
                  <Typography variant="h4" color={Colors.emperor}>
                    {count}
                  </Typography>
                </Flex>
              ))
            )}
          </Flex>
        </Card>
        {isCyclePending ? (
          <Card
            borderRadius={radii.medium}
            display="flex"
            alignItems={'center'}
            justifyContent={'center'}
            flexDirection={'column'}
            flex={1}
            shadow
            gap={spacings.medium}
          >
            <Typography variant="h2" fontWeight={weights.black}>
              {t('THIS_CYCLE_IS_PENDING')}
            </Typography>
            <Box width="50%">
              <CreateActivateCycleButton patientId={patientId} />
            </Box>
          </Card>
        ) : (
          <>
            <Card
              borderRadius={radii.medium}
              display="flex"
              flexDirection="column"
              flex={3}
              shadow
              padding={spacings.large}
              gap={spacings.large}
            >
              <Typography variant="h4" color={Colors.emperor}>
                {t('TREATMENT OVERVIEW')}
              </Typography>
              <Box border={`1px solid ${Colors.mercury}`} />
              {cycle ? (
                <>
                  <Flex gap={spacings.large} flexWrap={'wrap'}>
                    <Flex
                      minWidth={'50%'}
                      flex={1}
                      flexDirection="column"
                      gap={spacings.small}
                    >
                      <Flex>
                        <Box flex={1}>
                          <Typography variant="h4" color={Colors.emperor}>
                            {t('START_DATE')}
                          </Typography>
                        </Box>
                        <Box flex={1} textAlign="start">
                          <Typography variant="h4" color={Colors.emperor}>
                            {dayjs(startDate).format(getDateFormat())}
                          </Typography>
                        </Box>
                      </Flex>
                      <Flex>
                        <Box flex={1}>
                          <Typography variant="h4" color={Colors.emperor}>
                            {t('TREATMENT')}
                          </Typography>
                        </Box>
                        <Box flex={1}>
                          <Typography variant="h4" color={Colors.emperor}>
                            {treatmentType}
                          </Typography>
                        </Box>
                      </Flex>
                      <Flex>
                        <Box flex={1}>
                          <Typography variant="h4" color={Colors.emperor}>
                            {t('PROTOCOL')}
                          </Typography>
                        </Box>
                        <Box flex={1}>
                          <Typography variant="h4" color={Colors.emperor}>
                            {treatmentProtocol}
                          </Typography>
                        </Box>
                      </Flex>
                    </Flex>

                    <Flex
                      flex={1}
                      minWidth={'50%'}
                      flexDirection="column"
                      gap={spacings.small}
                    >
                      <Flex>
                        <Box flex={1}>
                          <Typography variant="h4" color={Colors.emperor}>
                            {t('EGG_SOURCE')}
                          </Typography>
                        </Box>
                        <Box flex={1}>
                          <Typography variant="h4" color={Colors.emperor}>
                            {eggSource}
                          </Typography>
                        </Box>
                      </Flex>
                      <Flex>
                        <Box flex={1}>
                          <Typography variant="h4" color={Colors.emperor}>
                            {t('SPERM_SOURCE')}
                          </Typography>
                        </Box>
                        <Box flex={1}>
                          <Typography variant="h4" color={Colors.emperor}>
                            {spermSource}
                          </Typography>
                        </Box>
                      </Flex>
                      <Flex>
                        <Box flex={1}>
                          <Typography variant="h4" color={Colors.emperor}>
                            {t('SURROGACY')}
                          </Typography>
                        </Box>
                        <Box flex={1}>
                          <Typography variant="h4" color={Colors.emperor}>
                            {t(surrogacy)}
                          </Typography>
                        </Box>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Box flex={1}>
                      <Typography variant="h4" color={Colors.emperor}>
                        {t('ADD_ONS')}
                      </Typography>
                    </Box>
                    <Box flex={3}>
                      <Typography variant="h4" color={Colors.emperor}>
                        {addOns ? addOns : t('NONE')}
                      </Typography>
                    </Box>
                  </Flex>
                </>
              ) : (
                <Center>
                  {isLoadingCycles ? (
                    <Loader />
                  ) : (
                    <Typography fontWeight={weights.extraBold}>
                      {t('NO_DATA')}
                    </Typography>
                  )}
                </Center>
              )}
            </Card>
            <Card
              borderRadius={radii.medium}
              display="flex"
              flexDirection="column"
              flex={1}
              shadow
              padding={spacings.large}
              gap={spacings.medium}
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                borderBottom={`1px solid ${Colors.mercury}`}
                paddingBottom={spacings.medium}
              >
                <Typography variant="h4" color={Colors.emperor}>
                  {t('STATUS').toUpperCase()}
                </Typography>
                {cycle && (
                  <MiniIconButton
                    onClick={() =>
                      openDialog({
                        header: t('OUTCOME_REPORT'),
                        children: (
                          <AddEditOutcomeReportForm
                            patientId={patientId}
                            cycleId={cycleToDisplayId}
                          />
                        ),
                        maxWidth: 'md',
                        fullWidth: true
                      })
                    }
                    icon={<EditIcon style={{ fill: 'black' }} />}
                  />
                )}
              </Flex>
              {cycle ? (
                <Flex flexDirection="column" gap={spacings.small}>
                  <Flex justifyContent="space-between" gap={spacings.medium}>
                    <Typography variant="h4" color={Colors.emperor}>
                      {t('CYCLE')}
                    </Typography>
                    <Typography variant="h4" color={Colors.emperor}>
                      {status || '--'}
                    </Typography>
                  </Flex>
                  {showBetaAndPregnancyStatus && (
                    <>
                      <Flex
                        justifyContent="space-between"
                        gap={spacings.medium}
                      >
                        <Typography variant="h4" color={Colors.emperor}>
                          {t('BETA_HCG')}
                        </Typography>
                        <Typography variant="h4" color={Colors.emperor}>
                          {getBetaString(
                            cycle.firstBetaValue,
                            cycle.firstBetaUnit
                          )}
                        </Typography>
                      </Flex>
                      <Flex
                        justifyContent="space-between"
                        gap={spacings.medium}
                      >
                        <Typography variant="h4" color={Colors.emperor}>
                          {t('SECOND_BETA')}
                        </Typography>
                        <Typography variant="h4" color={Colors.emperor}>
                          {getBetaString(
                            cycle.secondBetaValue,
                            cycle.secondBetaUnit
                          )}
                        </Typography>
                      </Flex>
                      <Flex
                        justifyContent="space-between"
                        gap={spacings.medium}
                      >
                        <Typography variant="h4" color={Colors.emperor}>
                          {t('PREGNANCY')}
                        </Typography>
                        <Typography variant="h4" color={Colors.emperor}>
                          {pregnancyStatus || outcome || '--'}
                        </Typography>
                      </Flex>
                    </>
                  )}
                </Flex>
              ) : (
                <Center>
                  {isLoadingCycles ? (
                    <Loader />
                  ) : (
                    <Typography fontWeight={weights.extraBold}>
                      {t('NO_DATA')}
                    </Typography>
                  )}
                </Center>
              )}
            </Card>
          </>
        )}
      </Flex>
    </Flex>
  );
};
