import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/display/Loader';
import MiniIconButton from 'src/components/display/MiniIconButton';
import Typography from 'src/components/display/Typography';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import { weights } from 'src/components/styles/fonts';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { getFullName } from 'src/utils/general';

import DiagnosticsSelector, {
  getNewLetterFromIndex
} from '../DiagnosticsSelector';
import EncounterDetailsSection from '../EncounterDetailsSection';
import AddIcon from '@mui/icons-material/Add';
import CodingTable from '../CodingTable';
import { DevTool as ReactHookFormDevTool } from '@hookform/devtools';
import { v4 as uuidv4 } from 'uuid';
import useEncounters from 'src/hooks/useEncounters';
import Button from 'src/components/display/Button';
import { EncounterCodeRow, EncounterDiagnosis } from 'src/types/encounter';

interface CodesTabProps {
  codeRows: EncounterCodeRow[];
  diagnoses: Array<EncounterDiagnosis | null>;
  createdByStaffId?: string;
}

export interface DxOptionMappedType {
  [key: string]: EncounterDiagnosis;
}

const CodingTab: FC<{
  appointmentId: string;
  patientId: string;
  onDirtyFormChange: (dirty: boolean) => void | Promise<void>;
}> = ({ appointmentId, patientId, onDirtyFormChange }) => {
  const { t } = useTranslation();
  const { getPatientById } = usePatientsApi();
  const {
    getEncounterByAppointmentId,
    getEncounterCodeRowsByAppointmentId,
    updateEncounterRows
  } = useEncounters();
  const { mutate: updateEncounterRowsMutation } = updateEncounterRows();
  const { data: encounter, isLoading: isLoadingEncounter } =
    getEncounterByAppointmentId(appointmentId);

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);
  const { data: serverCodeRows, isLoading: isLoadingServerCodeRows } =
    getEncounterCodeRowsByAppointmentId(appointmentId);
  const [dxPointerOptions, setDxPointerOptions] = useState<DxOptionMappedType>(
    {}
  );

  //const documents = [mockDocument, mockDocument]; // TODO - create a request that grabs related documents with the PatientDocument type
  const defaultValues: CodesTabProps = {
    diagnoses: encounter?.diagnoses || [null],
    codeRows: serverCodeRows || [],
    createdByStaffId: encounter?.createdByStaffId
  };

  const { control, formState, watch, setValue, setError, reset } =
    useForm<CodesTabProps>({
      mode: 'onChange',
      defaultValues
    });

  useEffect(() => {
    reset(defaultValues);
  }, [serverCodeRows, encounter]);

  const { isDirty, errors } = formState;

  const { createdByStaffId, diagnoses, codeRows } = watch();
  useEffect(() => {
    const newDxOptions: DxOptionMappedType = {};
    diagnoses.forEach((dx, index) => {
      if (dx) {
        newDxOptions[index] = {
          index,
          description: dx.description,
          label: getNewLetterFromIndex(index),
          icd10Code: dx.icd10Code
        };
      }
    });

    setDxPointerOptions(newDxOptions);
  }, [diagnoses]);

  const handleDiagnosisIndexChange = ({
    index,
    isDeleting
  }: {
    index: number;
    isDeleting?: boolean;
  }) => {
    // remove all the pointers to the edited index
    const newCodeRows = codeRows?.map((row) => {
      const newDxPointers = row.dxPointers
        ?.filter((pointer) => pointer.index !== index)
        .map((pointer) => {
          // if remove happened, shift all following indexes down by 1
          if (isDeleting) {
            if (+pointer.index > +index) {
              const newKey = +pointer.index - 1;
              const newPointer: EncounterDiagnosis = {
                ...pointer,
                index: newKey,
                label: getNewLetterFromIndex(newKey)
              };
              return newPointer;
            }
          }
          return pointer;
        });
      return {
        ...row,
        dxPointers: newDxPointers
      };
    });
    setValue('codeRows', newCodeRows);
  };

  const isDiagnosisArrayValid = !diagnoses.filter((dx) => dx === null).length;

  const handleSubmit = () => {
    if (!isDiagnosisArrayValid) {
      setError('diagnoses', {
        type: 'custom',
        message: t('NO_NULL_DIAGNOSES_ERROR')
      });
      return;
    }

    updateEncounterRowsMutation({
      appointmentId,
      codeRows: codeRows.map(
        ({ billingService, modifiers, dxPointers, ...restOfRow }) => ({
          ...restOfRow,
          billingServiceId: billingService.id,
          modifiers: modifiers?.map((modifier) => modifier.id) || [],
          dxPointers: dxPointers?.map((pointer) => pointer.index) || []
        })
      ),
      encounterId: encounter?.id,
      encounter: {
        diagnoses,
        createdByStaffId
      }
    });
  };

  useEffect(() => {
    if (isDirty) {
      onDirtyFormChange(true);
    }
  }, [isDirty]);

  const isLoading =
    isLoadingEncounter || isLoadingPatient || isLoadingServerCodeRows;
  if (isLoading) {
    return <Loader />;
  }
  return (
    <Flex
      flexDirection="column"
      gap={spacings.xxlarge}
      paddingBottom={spacings.large}
    >
      <Flex gap={spacings.xxxlarge}>
        <Flex flexDirection="column" gap={spacings.xlarge} flex={1}>
          <Flex gap={spacings.xlarge}>
            <Controller
              name="createdByStaffId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <EncounterDetailsSection
                  patientFullName={getFullName(patient?.personalInfo)}
                  encounterDate={encounter?.encounterDate}
                  encounterId={encounter?.displayId}
                  performedBy={value}
                  performedByHeader={t('RENDERING_PROVIDER')}
                  onPerformedByChange={onChange}
                />
              )}
            />
            {
              // TODO - Bring this back when ready
              /* <InsuranceDetailsSection
                primaryInsurance={insuranceInfo?.primaryInsurance}
                secondaryInsurance={insuranceInfo?.secondaryInsurance}
                eligibility={insuranceInfo?.eligibility}
              /> */
            }
          </Flex>
          <Flex flexDirection="column">
            <Controller
              name="diagnoses"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DiagnosticsSelector
                  error={!!errors?.diagnoses}
                  helperText={errors?.diagnoses?.message}
                  values={value}
                  onChange={({
                    indexChanged,
                    diagnoses,
                    isDeleting
                  }: {
                    diagnoses: Array<EncounterDiagnosis | null>;
                    indexChanged?: number;
                    isDeleting?: boolean;
                  }) => {
                    if (indexChanged)
                      handleDiagnosisIndexChange({
                        index: indexChanged,
                        isDeleting
                      });
                    return onChange(diagnoses);
                  }}
                />
              )}
            />
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap={spacings.xlarge} flex={1}>
          {/* <Typography variant="h2" fontWeight={weights.extraBold}>
            {t('DOCUMENTS')}
          </Typography>
          <Flex flexDirection="column" gap={spacings.small}>
            {documents.map((document, index) => (
              <DocumentSummaryDisplay
                key={index}
                status={document.status}
                title={document.title}
                statusColor={document.color}
                onClickEdit={() => {
                  // open the document editor for the appropriate document
                }}
              />
            ))}
          </Flex> */}
        </Flex>
      </Flex>
      <Controller
        name="codeRows"
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <Flex justifyContent="space-between" alignItems="center">
              <Typography variant="h2" fontWeight={weights.extraBold}>
                {t('SERVICE_CODING')}
              </Typography>
              <Flex gap={spacings.medium} alignItems="center">
                <Typography variant="caption">{t('SERVICE_LINE')}</Typography>
                <MiniIconButton
                  disabled={isLoadingServerCodeRows}
                  onClick={() => onChange([...value, { id: uuidv4() }])}
                  icon={<AddIcon />}
                  iconColor="emperor"
                  bgColor="alto"
                />
              </Flex>
            </Flex>
            <CodingTable
              rows={value}
              onChange={onChange}
              dxPointerOptions={dxPointerOptions}
            />
          </>
        )}
      />
      <Flex justifyContent="flex-end">
        <Button type="submit" onClick={handleSubmit} disabled={isLoading}>
          {t('SAVE')}
        </Button>
      </Flex>
      <ReactHookFormDevTool control={control} />
    </Flex>
  );
};

export default CodingTab;
