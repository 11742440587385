import { SemenVial } from './cycle';
import { GeneticStatus } from './patient';

export interface EmbryologyReport {
  id?: string;
  patientId: string;
  cycleId: string;
  patientName: string;
  retrievalDate: Date;
  eggsInseminated: number;
  eggsMature: number;
  eggsRetrieved: number;
}

export enum Fate {
  CRYO = 'Cryo',
  DISCARDED = 'Discarded',
  TRANSFERRED = 'Transferred'
}

export enum NotAliveSince {
  DAY_ONE = 'day1',
  DAY_TWO = 'day2',
  DAY_THREE = 'day3',
  DAY_FOUR = 'day4',
  DAY_FIVE = 'day5',
  DAY_SIX = 'day6',
  DAY_SEVEN = 'day7',
  INSEMINATED = 'inseminated'
}

export interface UpdateGameteThawed {
  gradeAtFreeze?: string;
  gradeAtThaw?: string;
  gradeAtTransfer?: string;
  assistedHatching?: boolean;
  biopsy?: boolean;
  biopsyResults?: string;
  geneticStatus?: GeneticStatus;
  fate?: Fate;
}

interface EggDayCount {
  total: number;
  percentage: number;
}

export interface AvailableGamete {
  eggs: EggAndEmbryo[];
  embryos: EggAndEmbryo[];
  semenVials: SemenVial[];
}

export interface EggsCounts {
  collected: EggDayCount;
  mature: EggDayCount;
  inseminated: EggDayCount;
  dayOne: EggDayCount;
  dayTwo: EggDayCount;
  dayThree: EggDayCount;
  dayFour: EggDayCount;
  dayFive: EggDayCount;
  daySix: EggDayCount;
  daySeven: EggDayCount;
  euploid: EggDayCount;
  cryo: EggDayCount;
}

export interface EggsAndEmbryosReport extends EggsCounts {
  totalNumberOfFollicles: number;
  eggs: EggAndEmbryo[];
}

export interface UpdateEgg {
  day1?: string;
  day2?: string;
  day3?: string;
  day4?: string;
  day5?: string;
  day6?: string;
  day7?: string;
  ploidy?: GeneticStatus;
  fate?: Fate;
  location?: string;
  straw?: string;
}

export enum Maturity {
  MII = 'MII',
  MI = 'MI',
  GV = 'GV'
}

export enum AddOns {
  EGG_ACTIVATION = 'Egg Activation',
  ASSISTED_HATCHING = 'Assisted Hatching',
  POLAR_BODY_BIOPSY = 'Polar body Biopsy',
  OTHER = 'Other'
}

export enum InseminationMethods {
  IVF = 'IVF',
  ICSI = 'ICSI',
  PICSI = 'PICSI',
  MICSI = 'MICSI',
  Other = 'Other'
}

export enum Day1 {
  ZERO_PN = '0PN',
  ONE_PN = '1PN',
  TWO_PN = '2PN'
}
export interface EggAndEmbryoNotes {
  id?: string;
  cycleId?: string;
  embryoId?: string;
  value: string;
  isDeleted?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  createdByStaffId?: string;
}

export interface EggAndEmbryoNotesToCreate {
  cycleId: string;
  embryoId: string;
  date: string;
  patientId: string;
  notesToCreate: string[];
  notesToDelete?: string[];
}

export interface EggAndEmbryo {
  id: string;
  patientId: string;
  cycleId: string;
  retrieved: boolean;
  mature?: Maturity;
  inseminated?: boolean;
  day1?: string;
  day2?: string;
  day3?: string;
  day4?: string;
  day5?: string;
  day6?: string;
  day7?: string;
  geneticStatus?: GeneticStatus;
  fate?: Fate;
  strawNum?: number;
  location?: string;
  isAlive: boolean;
  notLiveSince?: NotAliveSince;
  createdAt?: Date;
  updatedAt?: Date;
  tlid?: string;
  inseminationMethod?: InseminationMethods;
  inseminationTime?: Date;
  addOns?: AddOns[];
  aiScore?: string;
  notes?: EggAndEmbryoNotes[];
  biopsy?: string;
  biopsyWitness?: string;
  biopsyTime?: Date;
  tube?: string;
  tubeWitness?: string;
  tubeTime?: Date;
}
