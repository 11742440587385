import { useParams, useNavigate } from 'react-router-dom';
import { css, styled } from '@mui/material';
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
  useFieldArray,
  useForm
} from 'react-hook-form';
import { FC, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Box from '../../components/layout/Box';
import Card from '../../components/display/Card';
import { useTranslation } from 'react-i18next';
import Flex from '../../components/layout/Flex';
import Typography from '../../components/display/Typography';
import { iconSizes, spacings } from '../../components/styles/constants';
import { weights } from '../../components/styles/fonts';
import Chip from '../../components/data-entry/Chips/Chip';
import { getStatusColors } from './utils/invoiceTableColors';
import { Invoice, InvoiceItem, InvoiceStatus } from '../../types/billing';
import Avatar from '../../components/display/Avatar';
import useClinicsApi from '../../hooks/useClinicsApi';
import useMeApi from '../../hooks/useMeApi';
import Loader from '../../components/display/Loader';
import Center from '../../components/layout/Center';
import { AvatarSizes } from '../../components/display/Avatar/Avatar';
import { getFullAddress, getFullName } from '../../utils/general';
import useBilling from '../../hooks/useBilling';
import { Colors } from '../../components/styles/colors';
import { parseAmount } from './utils/parseAmount';
import DatePicker from '../../components/data-entry/DatePicker';
import MiniIconButton from '../../components/display/MiniIconButton';
import InputField, {
  InputFieldProps
} from '../../components/data-entry/InputField';
import Checkbox from '../../components/data-entry/Checkbox';
import { makeShouldForwardProps } from '../../components/utils';
import Button from '../../components/display/Button';
import TextArea from '../../components/data-entry/TextArea';

const StyledChip = styled(Chip)`
  width: 130px;
  height: 30px;
  font-size: 16px;
  font-weight: ${weights.bold};
`;

const shouldForwardProp = makeShouldForwardProps(['center']);
const StyledInputField = styled(InputField, { shouldForwardProp })<
  InputFieldProps & { center?: boolean }
>`
  input {
    ${({ center }) =>
      center &&
      css`
        text-align: center;
      `}
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  .MuiInputBase-root:hover {
    background-color: ${Colors.whiteSand};
  }
`;

const ItemsListHeader = styled(Flex)`
  background-color: ${Colors.whiteSand};
  padding-top: ${spacings.xxlarge};
  padding-bottom: ${spacings.large};
  border-bottom: 3px solid ${Colors.mineShaft};
`;

const emptyInvoiceItem: InvoiceItem = {
  price: null,
  description: '',
  quantity: 1,
  discount: 0,
  hasVat: false,
  currency: 'USD'
};

interface InvoiceForm extends Invoice {
  itemsToDelete?: string[];
}

interface InvoiceItemFieldRowProps {
  field: FieldArrayWithId<Invoice, 'invoiceItems', 'id'>;
  index: number;
  remove: (index: number) => void;
  item: InvoiceItem;
  control: Control<Invoice>;
  disabled?: boolean;
  errors?: FieldErrors<Invoice>;
}

const InvoiceItemFieldRow: FC<InvoiceItemFieldRowProps> = ({
  field,
  remove,
  index,
  item,
  control,
  disabled,
  errors
}) => {
  const { t } = useTranslation();

  const totalItemPrice = item.price * item.quantity;
  const discountedPrice =
    totalItemPrice - (totalItemPrice * item.discount) / 100;

  return (
    <Flex
      key={field.id}
      paddingX={spacings.large}
      alignItems={'center'}
      gap={spacings.large}
      marginBottom={spacings.medium}
    >
      <Box flex={1}>
        <MiniIconButton icon={<RemoveIcon />} onClick={() => remove(index)} />
      </Box>
      <Box flex={3}>
        <Typography fontWeight={weights.extraBold}>
          {item.code || '-'}
        </Typography>
      </Box>
      <Box flex={5}>
        <Controller
          name={`invoiceItems.${index}.description`}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <StyledInputField
              {...field}
              inputRef={ref}
              noBorder
              disabled={disabled}
              placeholder={t('ENTER_DESCRIPTION')}
              error={!!errors?.invoiceItems?.[index]?.description}
              helperText={errors?.invoiceItems?.[index]?.description?.message}
              fullWidth
            />
          )}
        />
      </Box>
      <Box flex={3} alignSelf={'center'}>
        <Controller
          name={`invoiceItems.${index}.price`}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <StyledInputField
              {...field}
              noBorder
              inputRef={ref}
              disabled={disabled}
              center
              type="number"
              placeholder={t('ENTER_PRICE')}
              error={!!errors?.invoiceItems?.[index]?.price}
              helperText={errors?.invoiceItems?.[index]?.price?.message}
              fullWidth
            />
          )}
        />
      </Box>
      <Box flex={3}>
        <Controller
          name={`invoiceItems.${index}.quantity`}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <StyledInputField
              {...field}
              noBorder
              center
              inputRef={ref}
              disabled={disabled}
              type="number"
              error={!!errors?.invoiceItems?.[index]?.quantity}
              helperText={errors?.invoiceItems?.[index]?.quantity?.message}
              fullWidth
            />
          )}
        />
      </Box>
      <Box flex={3}>
        <Controller
          name={`invoiceItems.${index}.discount`}
          rules={{
            max: {
              value: 100,
              message: t('VALUE_IN_PERCENTAGE')
            },
            min: {
              value: 0,
              message: t('VALUE_IN_PERCENTAGE')
            }
          }}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <StyledInputField
              {...field}
              noBorder
              center
              inputRef={ref}
              disabled={disabled}
              placeholder={t('ENTER_DISCOUNT')}
              type="number"
              error={!!errors?.invoiceItems?.[index]?.discount}
              helperText={errors?.invoiceItems?.[index]?.discount?.message}
              fullWidth
            />
          )}
        />
      </Box>
      <Box flex={3}>
        <Typography fontWeight={weights.extraBold}>
          {parseAmount(discountedPrice)}
        </Typography>
      </Box>
      <Center flex={1}>
        <Controller
          name={`invoiceItems.${index}.hasVat`}
          control={control}
          render={({ field: { ref: _ref, onChange, value, ...field } }) => (
            <Checkbox
              disabled={disabled}
              checked={value}
              {...field}
              onChange={(_, isChecked) => {
                onChange(isChecked);
              }}
            />
          )}
        />
      </Center>
    </Flex>
  );
};

export const AddEditInvoice: FC = () => {
  const { patientId, invoiceId } = useParams();
  const isEdit = !!invoiceId;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getMe } = useMeApi();
  const { getClinicInfo } = useClinicsApi();
  const { getBillingProfile, createInvoice, getInvoiceById, updateInvoice } =
    useBilling();

  const { data: me, isLoading: isLoadingMe } = getMe();
  const { data: clinicInfo, isLoading: isLoadingClinic } = getClinicInfo(
    me.user.clinicId,
    { enabled: !!me.user.clinicId }
  );
  const { data: billingProfile, isLoading: isLoadingBillingProfile } =
    getBillingProfile(patientId, { enabled: !!patientId });

  const { data: invoice, isLoading: isLoadingInvoice } = getInvoiceById(
    invoiceId,
    { enabled: isEdit }
  );

  const { mutate: handleCreateInvoice, isLoading: isCreatingInvoice } =
    createInvoice();
  const { mutate: handleUpdateInvoice, isLoading: isUpdatingInvoice } =
    updateInvoice();

  const {
    control,
    formState,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue
  } = useForm<InvoiceForm>({
    mode: 'onChange',
    defaultValues: {
      patientId,
      paidDate: null,
      dueDate: null,
      issueDate: new Date(),
      status: InvoiceStatus.QUOTE,
      method: null,
      notes: null,
      invoiceItems: [emptyInvoiceItem]
    }
  });

  const {
    fields: invoiceItemsFields,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'invoiceItems'
  });

  useEffect(() => {
    if (invoice) {
      reset({
        ...invoice,
        itemsToDelete: []
      });
    }
  }, [invoice?.id]);

  const { name, address, city, country, postalCode, state } = clinicInfo || {};
  const {
    address: billingAddress,
    city: billingCity,
    country: billingCountry
  } = billingProfile || {};
  const { errors } = formState;

  const onSubmit = async (data: InvoiceForm) => {
    if (data.id) {
      await handleUpdateInvoice(data);
    } else {
      await handleCreateInvoice(data, {
        onSuccess: () => {
          navigate(`/app/patients/${patientId}/billing`);
        }
      });
    }
  };

  const isLoading =
    isLoadingMe ||
    isLoadingClinic ||
    isLoadingBillingProfile ||
    isLoadingInvoice ||
    isCreatingInvoice ||
    isUpdatingInvoice;

  if (isLoading) {
    return (
      <Center minHeight={'300px'}>
        <Loader size={iconSizes.xlarge} />
      </Center>
    );
  }

  const { invoiceItems, status } = watch();

  const addItemToDelete = (index) => {
    const itemsToDelete = getValues('itemsToDelete');
    setValue('itemsToDelete', [...itemsToDelete, invoiceItems[index].id]);
    remove(index);
  };

  const {
    discount: invoiceDiscount,
    subtotal: invoiceSubtotal,
    vat: invoiceVat
  } = invoiceItems.reduce<{
    subtotal: number;
    vat: number;
    discount: number;
  }>(
    (acc, { price, quantity, discount }) => {
      const currItemPrice = price * quantity;
      const discountPrice = (currItemPrice * discount) / 100;
      const vatPrice = 0;
      acc.subtotal += currItemPrice;
      acc.discount += discountPrice;
      acc.vat += vatPrice;
      return acc;
    },
    {
      subtotal: 0,
      vat: 0,
      discount: 0
    }
  );
  const totalInvoicePrice = invoiceSubtotal - invoiceDiscount - invoiceVat;
  const statusChipSx = getStatusColors(status);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Flex flexDirection={'column'} gap={spacings.large} height={'100%'}>
        <Card
          shadow
          paddingX={spacings.xxlarge}
          paddingY={spacings.xlarge}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Flex gap={spacings.xlarge} alignItems={'center'}>
            <Flex gap={spacings.medium}>
              <Typography variant="h1" fontWeight={weights.extraBold}>
                {invoiceId ? t('INVOICE') : t('NEW_INVOICE')}
              </Typography>
              {invoiceId && (
                <Typography variant="h1" fontWeight={weights.extraBold}>
                  #{invoiceId}
                </Typography>
              )}
            </Flex>
            <Box>
              <StyledChip
                sx={{
                  ...statusChipSx
                }}
                label={t(status)}
              />
            </Box>
          </Flex>
          <Flex>
            <Box width={'200px'}>
              <Button type="submit" fullWidth disabled={isLoading}>
                {isLoading ? <Loader size={iconSizes.small} /> : t('SAVE')}
              </Button>
            </Box>
          </Flex>
        </Card>

        <Card shadow paddingX={spacings.xxlarge} paddingY={spacings.xlarge}>
          <Flex>
            <Box flex={1}>
              <Box marginBottom={spacings.medium}>
                <Typography fontWeight={weights.extraBold}>
                  {t('PAID_TO').toUpperCase()}
                </Typography>
              </Box>
              <Flex gap={spacings.medium}>
                <Avatar size={AvatarSizes.LARGE} />
                <Flex flexDirection={'column'}>
                  <Typography fontWeight={weights.extraBold}>{name}</Typography>
                  <Typography>{address}</Typography>
                  <Typography>
                    {getFullAddress({
                      country,
                      city,
                      state,
                      zip: postalCode
                    })}
                  </Typography>
                </Flex>
              </Flex>
            </Box>
            <Box flex={1}>
              <Box marginBottom={spacings.medium}>
                <Typography fontWeight={weights.extraBold}>
                  {t('BILL_TO').toUpperCase()}
                </Typography>
              </Box>
              <Flex flexDirection={'column'}>
                <Typography fontWeight={weights.extraBold}>
                  {getFullName(billingProfile)}
                </Typography>
                <Typography>{billingAddress}</Typography>
                <Typography>
                  {getFullAddress({
                    country: billingCountry,
                    city: billingCity
                  })}
                </Typography>
              </Flex>
            </Box>
            <Flex flexDirection={'column'} flex={1.5} gap={spacings.small}>
              <Flex gap={spacings.medium}>
                <Box>
                  <Controller
                    name="issueDate"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <StyledDatePicker
                        {...field}
                        noBorder
                        noShadow
                        labelSx={{
                          paddingInlineStart: spacings.large,
                          marginBottom: 0,
                          fontWeight: weights.black
                        }}
                        inputRef={ref}
                        disabled={isLoading}
                        label={t('ISSUED').toUpperCase()}
                        disableOpenPicker
                        error={!!errors.issueDate}
                        helperText={errors?.issueDate?.message}
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    name="dueDate"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <StyledDatePicker
                        {...field}
                        noBorder
                        noShadow
                        labelSx={{
                          paddingInlineStart: spacings.large,
                          marginBottom: 0,
                          fontWeight: weights.black
                        }}
                        disabled={isLoading}
                        inputRef={ref}
                        label={t('DUE').toUpperCase()}
                        disableOpenPicker
                        error={!!errors.dueDate}
                        helperText={errors?.dueDate?.message}
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    name="paidDate"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <StyledDatePicker
                        {...field}
                        noBorder
                        noShadow
                        labelSx={{
                          paddingInlineStart: spacings.large,
                          marginBottom: 0,
                          fontWeight: weights.black
                        }}
                        inputRef={ref}
                        disabled={isLoading}
                        label={t('PAID').toUpperCase()}
                        disableOpenPicker
                        error={!!errors.paidDate}
                        helperText={errors?.paidDate?.message}
                        fullWidth
                      />
                    )}
                  />
                </Box>
              </Flex>
              <Card
                sx={{ backgroundColor: Colors.whiteSand }}
                flex={1}
                width={'100%'}
              >
                <Flex
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  paddingX={spacings.xxlarge}
                  paddingY={spacings.large}
                >
                  <Typography variant="h2" fontWeight={weights.bold}>
                    {t('BALLANCE_DUE').toUpperCase()}
                  </Typography>
                  <Typography variant="h1" fontWeight={weights.bold}>
                    {parseAmount(totalInvoicePrice)}
                  </Typography>
                </Flex>
              </Card>
            </Flex>
          </Flex>
        </Card>

        <Card flex={1} overflow={'hidden'} paddingBottom={spacings.xxlarge}>
          <ItemsListHeader
            paddingX={spacings.large}
            marginBottom={spacings.large}
            gap={spacings.large}
          >
            <Box flex={1}></Box>
            <Box flex={3}>
              <Typography fontWeight={weights.extraBold}>
                {t('ITEM_CODE').toUpperCase()}
              </Typography>
            </Box>
            <Box flex={5}>
              <Box paddingLeft={spacings.medium}>
                <Typography fontWeight={weights.extraBold}>
                  {t('ITEM_DESCRIPTION').toUpperCase()}
                </Typography>
              </Box>
            </Box>
            <Center flex={3}>
              <Typography fontWeight={weights.extraBold}>
                {t('PRICE').toUpperCase()}
              </Typography>
            </Center>
            <Center flex={3}>
              <Typography fontWeight={weights.extraBold}>
                {t('QTY').toUpperCase()}
              </Typography>
            </Center>
            <Center flex={3}>
              <Typography fontWeight={weights.extraBold}>
                {t('DISCOUNT').toUpperCase()}
              </Typography>
            </Center>
            <Box flex={3}>
              <Typography fontWeight={weights.extraBold}>
                {t('TOTAL').toUpperCase()}
              </Typography>
            </Box>
            <Center flex={1}>
              <Typography fontWeight={weights.extraBold}>
                {t('VAT').toUpperCase()}
              </Typography>
            </Center>
          </ItemsListHeader>
          <Box
            marginBottom={spacings.large}
            borderBottom={`1px solid ${Colors.alto}`}
          >
            {invoiceItemsFields.map((field, index) => {
              const item = invoiceItems[index];

              return (
                <InvoiceItemFieldRow
                  key={field.id}
                  control={control}
                  index={index}
                  field={field}
                  item={item}
                  remove={addItemToDelete}
                  disabled={isLoading || isCreatingInvoice}
                />
              );
            })}
          </Box>
          <Flex
            paddingX={spacings.large}
            justifyContent={'space-between'}
            alignItems={'flex-start'}
          >
            <Flex flexDirection={'column'} gap={spacings.xxlarge}>
              <Flex alignItems="center" gap={spacings.xlarge}>
                <MiniIconButton
                  icon={<AddIcon />}
                  onClick={() => append(emptyInvoiceItem)}
                />
                <Typography variant="body1">{t('ADD_ITEM')}</Typography>
              </Flex>
              <Box>
                <Controller
                  name="notes"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <TextArea
                      {...field}
                      ref={ref}
                      label={t('NOTES')}
                      placeholder={t('NOTES_GOES_HERE')}
                      error={!!errors.notes}
                      helperText={errors?.notes?.message}
                      minRows={3}
                      maxRows={3}
                      fullWidth
                    />
                  )}
                />
              </Box>
            </Flex>
            <Flex
              flexDirection={'column'}
              gap={spacings.medium}
              paddingX={spacings.large}
            >
              <Flex justifyContent={'space-between'} gap={spacings.large}>
                <Typography variant="h2" fontWeight={weights.extraBold}>
                  {t('SUBTOTAL')}:
                </Typography>
                <Typography variant="h2" fontWeight={weights.extraBold}>
                  {parseAmount(invoiceSubtotal)}
                </Typography>
              </Flex>
              <Flex justifyContent={'space-between'} gap={spacings.large}>
                <Typography variant="h2" fontWeight={weights.extraBold}>
                  {t('VAT')}:
                </Typography>
                <Typography variant="h2" fontWeight={weights.extraBold}>
                  {parseAmount(invoiceVat)}
                </Typography>
              </Flex>
              <Flex justifyContent={'space-between'} gap={spacings.large}>
                <Typography variant="h2" fontWeight={weights.extraBold}>
                  {t('DISCOUNT')}:
                </Typography>
                <Typography variant="h2" fontWeight={weights.extraBold}>
                  {parseAmount(invoiceDiscount)}
                </Typography>
              </Flex>
              <Flex justifyContent={'space-between'} gap={spacings.large}>
                <Typography variant="h1" fontWeight={weights.extraBold}>
                  {t('TOTAL')}:
                </Typography>
                <Typography variant="h1" fontWeight={weights.extraBold}>
                  {parseAmount(totalInvoicePrice)}
                </Typography>
              </Flex>
            </Flex>
          </Flex>
        </Card>
      </Flex>
    </form>
  );
};
