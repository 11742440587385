import IconButton from 'src/components/display/IconButton/IconButton';
import { Colors } from 'src/components/styles/colors';
import { InboxTabs, MessageRecent } from 'src/types/inbox';
import Flex from 'src/components/layout/Flex';
import Typography from 'src/components/display/Typography/Typography';
import { longDateFormat, shortTimeFormat } from 'src/utils/dateAndTIme';
import dayjs from 'dayjs';
import { ReactComponent as PatientChatIcon } from '../../../../assets/icons/chat-message.svg';
import { ReactComponent as InteralChatIcon } from '../../../../assets/icons/InternalChat.svg';
import useMeApi from 'src/hooks/useMeApi';
import { useTranslation } from 'react-i18next';

interface Props {
  message: MessageRecent;
  selectedTab: InboxTabs;
}
const MessageInboxCard = ({ message, selectedTab }: Props) => {
  const { getMe } = useMeApi();
  const { t } = useTranslation();

  const { data: me } = getMe();

  return (
    <>
      <IconButton
        icon={
          selectedTab === InboxTabs.PatientInbox ? (
            <PatientChatIcon />
          ) : (
            <InteralChatIcon />
          )
        }
        iconSize="large"
        bgColor={
          !message.readAt && `${message.senderStaffId}` !== me?.user?.id
            ? 'riptide'
            : 'cupid'
        }
      />
      <Flex flex={0.5} flexDirection="column">
        <Typography variant="h4" color={Colors.silverChalice}>
          {dayjs(message.createdAt).format(longDateFormat)}
        </Typography>
        <Typography variant="h4" color={Colors.silverChalice}>
          {dayjs(message.createdAt).format(shortTimeFormat)}
        </Typography>
      </Flex>

      <Flex flexDirection="column" flex={1} position={'relative'}>
        <Typography variant="h4" color={Colors.emperor}>
          {message.patientName}
        </Typography>
        <Typography
          variant="h4"
          color={Colors.emperor}
          whiteSpace={'nowrap'}
          textOverflow={'ellipsis'}
          maxWidth={'170px'}
          overflow={'hidden'}
        >
          {selectedTab === InboxTabs.PatientInbox
            ? t('PATIENT_CHAT')
            : t('INTERNAL_CHAT')}
        </Typography>
      </Flex>
    </>
  );
};

export default MessageInboxCard;
