import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import InputField from 'src/components/data-entry/InputField/InputField';
import Button from 'src/components/display/Button/Button';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import { PatientTaskType } from 'src/types/task';
import TimePicker from 'src/components/data-entry/TimePicker/TimePicker';
import Select from 'src/components/data-entry/Select/Select';
import i18n from 'src/i18n/i18n';
import Chips, { ChipsVariants } from 'src/components/data-entry/Chips/Chips';
import { DoctorChips } from '../common/DoctorChips';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { useParams } from 'react-router-dom';
import { CycleTreatmentWitness } from 'src/types/cycle';
import { OptionExtended } from 'src/types/option';

interface EditWitnessedTaskForm extends CycleTreatmentWitness {
  patientName: string;
  time: Date;
}

enum ConsumableValues {
  ESTROGEN = 'ESTROGEN',
  FSH = 'FSH',
  LH = 'LH',
  PROGESTERONE = 'PROGESTERONE',
  FOLLICULAR_SCAN = 'FOLLICULAR_SCAN'
}

export const EditWitnessTaskForm: FC<{
  patientName: string;
  witnessedTask: CycleTreatmentWitness;
}> = ({ patientName, witnessedTask }) => {
  const { t } = useTranslation();
  const { patientId } = useParams();

  const { updatePatientWitnessedTask } = usePatientsApi();

  const { mutate: updatePatientWitnessedTaskMutate } =
    updatePatientWitnessedTask();

  const defaultValues: EditWitnessedTaskForm = {
    patientName,
    time: witnessedTask.task.taskDate,
    date: witnessedTask.task.taskDate,
    embryologist: witnessedTask.embryologist,
    task: { ...witnessedTask.task },
    witness: witnessedTask.witness,
    id: witnessedTask.id,
    consumables: []
  };

  const { control, formState, handleSubmit, setValue } =
    useForm<EditWitnessedTaskForm>({
      mode: 'onChange',
      defaultValues
    });

  const { errors } = formState;

  const consumableLabels: Record<ConsumableValues, string> = {
    [ConsumableValues.ESTROGEN]: t('ESTROGEN'),
    [ConsumableValues.FSH]: t('FSH'),
    [ConsumableValues.LH]: t('LH'),
    [ConsumableValues.PROGESTERONE]: t('PROGESTERONE'),
    [ConsumableValues.FOLLICULAR_SCAN]: t('FOLLICULAR_SCAN')
  };

  const consumablesOptions: OptionExtended[] = Object.entries(
    consumableLabels
  ).map(([key, value]) => ({
    value,
    label: key,
    labelText: key
  }));

  const onSubmit = async (editedWitnessedTask: EditWitnessedTaskForm) => {
    await updatePatientWitnessedTaskMutate({
      witnessedTask: editedWitnessedTask,
      patientId
    });
  };

  return (
    <Box marginTop={spacings.large} maxWidth={700}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Flex gap={spacings.xxlarge}>
          <Flex flexDirection="column" flex={1}>
            <Box marginBottom={spacings.medium}>
              <Controller
                name="patientName"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    disabled
                    label={t('PATIENT_NAME')}
                    placeholder={t('PATIENT_NAME')}
                    error={!!errors.patientName}
                    helperText={errors?.patientName?.message}
                    fullWidth
                  />
                )}
              />
            </Box>

            <Flex gap={spacings.xlarge}>
              <Box flex={1}>
                <Controller
                  name={'date'}
                  control={control}
                  rules={{
                    required: t('DATE_REQUIRED')
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <DatePicker
                      {...field}
                      label={t('DATE_LABEL')}
                      inputRef={ref}
                      error={!!errors?.date}
                      helperText={errors.date?.message}
                      fullWidth
                      disabled
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name={'time'}
                  control={control}
                  rules={{
                    required: t('TIME_REQUIRED')
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <TimePicker
                      {...field}
                      label={t('TIME_LABEL')}
                      inputRef={ref}
                      ampm={false}
                      error={!!errors.time}
                      helperText={errors.time?.message}
                      fullWidth
                      disabled
                    />
                  )}
                />
              </Box>
            </Flex>

            <Box flex={4}>
              <Controller
                name={'task'}
                control={control}
                rules={{
                  required: t('TASK_PERFORMED_REQUIRED')
                }}
                render={({ field: { ref, value, ...field } }) => (
                  <Select
                    {...field}
                    value={value.type}
                    inputRef={ref}
                    label={t('TASK_PERFORMED_LABEL')}
                    error={!!errors.task}
                    helperText={errors.task?.message}
                    options={Object.entries(PatientTaskType).map(
                      ([key, value]) => ({
                        label: i18n.t(key),
                        value: value
                      })
                    )}
                    onChange={(event) => {
                      const selectedTaskType = event.target.value;

                      setValue('task', {
                        ...value,
                        type: selectedTaskType as PatientTaskType
                      });
                    }}
                  />
                )}
              />
            </Box>
          </Flex>

          <Flex flexDirection="column" flex={1}>
            <Flex marginBottom={spacings.large}>
              <Box flex={1}>
                <Controller
                  name={'embryologist.id'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DoctorChips
                      id="edit-witnessed-task-embryologist"
                      showSelectedValue
                      label={t('PERFORMED_BY')}
                      value={[value]}
                      onAddChip={(selectedDoctorId) => {
                        onChange(selectedDoctorId);
                      }}
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name={'witness.id'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DoctorChips
                      id="edit-witnessed-task-witness"
                      showSelectedValue
                      label={t('WITNESSED_BY')}
                      value={[value]}
                      onAddChip={(selectedDoctorId) =>
                        onChange(selectedDoctorId)
                      }
                    />
                  )}
                />
              </Box>
            </Flex>

            <Box flex={1}>
              <Controller
                name={'consumables'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Chips
                    value={value}
                    variant={ChipsVariants.EXPANDED}
                    options={consumablesOptions}
                    title={t('CONSUMABLES')}
                    onAddChip={(chipValue) => onChange([...value, chipValue])}
                    onRemoveChip={(chipValue) => {
                      const filteredChips = value.filter(
                        (finding) => finding !== chipValue
                      );
                      onChange(filteredChips);
                    }}
                    shouldSortOptions
                  />
                )}
              />
            </Box>
          </Flex>
        </Flex>

        <Flex
          justifyContent="center"
          paddingX={spacings.xxlarge}
          marginTop={spacings.large}
          width="50%"
          marginX="auto"
        >
          <Button fullWidth type="submit">
            {t('SUBMIT')}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};
