import { Dayjs } from 'dayjs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'src/components/display/Typography';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import { weights } from 'src/components/styles/fonts';
import { longDateFormat } from 'src/utils/dateAndTIme';
import { DoctorChips } from '../patients/common/DoctorChips';

const EncounterDetailsSection: FC<{
  patientFullName: string;
  encounterDate: Dayjs;
  encounterId?: string;
  performedBy: string;
  performedByHeader?: string;
  onPerformedByChange?: (value: string) => void;
  disabled?: boolean;
}> = ({
  patientFullName,
  encounterDate,
  encounterId,
  performedBy,
  performedByHeader,
  onPerformedByChange,
  disabled
}) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" gap={spacings.xlarge}>
      <Flex flexDirection="column">
        <Typography fontWeight={weights.extraBold}>
          {t('PATIENT').toUpperCase()}
        </Typography>
        <Typography>{patientFullName}</Typography>
      </Flex>
      <Flex flexDirection="column">
        <Typography fontWeight={weights.extraBold}>
          {t('DATE_OF_SERVICE').toUpperCase()}
        </Typography>
        <Typography>{encounterDate?.format(longDateFormat)}</Typography>
      </Flex>
      <Flex flexDirection="column">
        <Typography fontWeight={weights.extraBold}>
          {t('ENCOUNTER_ID').toUpperCase()}
        </Typography>
        <Typography>{encounterId?.toUpperCase()}</Typography>
      </Flex>
      <Flex flexDirection="column">
        <Typography fontWeight={weights.extraBold}>
          {performedByHeader || t('PERFORMED_BY').toUpperCase()}
        </Typography>
        <DoctorChips
          id="encounter-details-created-by-staff"
          value={[performedBy]}
          disabled={disabled}
          onAddChip={(selectedStaffId: string) => {
            const idToSet =
              selectedStaffId === performedBy ? null : selectedStaffId;
            onPerformedByChange?.(idToSet);
          }}
          showSelectedValue
        />
      </Flex>
    </Flex>
  );
};

export default EncounterDetailsSection;
