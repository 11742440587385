export interface BillingServiceCode {
  id: string;
  billingCode: string;
  serviceDescription: string;
  orderCode?: string;
  orderCodeType?: string;
  billingCodeType?: BillingServiceCodeType;
}

export interface BillingServiceCodeServer extends BillingServiceCode {
  frontendDescription: string;
}

export enum ServiceType {
  APPOINTMENT = 'Appointment',
  LAB = 'Lab',
  MEDICATION = 'Medication',
  MODIFIER = 'Modifier',
  PROCEDURE = 'Procedure',
  SERVICE = 'Service'
}

export type Modifier = BillingServiceCode;

type BillingServiceCodeType =
  | EncounterServiceCodeType
  | ModifierServiceCodeType;

export enum ModifierServiceCodeType {
  MOD = 'MOD'
}

export enum EncounterServiceCodeType {
  CPT = 'CPT',
  HCPCS = 'HCPCS'
}

export interface EncounterServiceCode extends BillingServiceCode {
  billingCodeType: EncounterServiceCodeType;
}

export interface NDCCode {
  code: string;
  description: string;
  label: string;
}

export interface Icd10Code {
  code: string;
  description: string;
  label: string;
}

export interface PaginatedApiRequestStructure {
  searchTerm: string;
  page?: number;
  pageSize?: number;
}

// more info about this index mapping can be found in the official nih website regarding the icd10 api
// https://clinicaltables.nlm.nih.gov/apidoc/icd10cm/v3/doc.html
export enum NihIcd10ApiResultIndices {
  TOTAL_RESULTS = 0,
  RESULTS = 3,
  CODE = 0,
  DESCRIPTION = 1
}
