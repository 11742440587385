import { FC, useEffect, useMemo, useState } from 'react';
import {
  CycleWizardVariant,
  CycleStatus,
  NewCycleFormValues,
  CycleWizardStepTypes,
  CycleWizardStep,
  NewCycleForm,
  CycleScheduleForm as CycleScheduleFormType,
  CycleOutcomeDetails
} from '../../../types/cycle';
import AddEditNewCycleForm from '../actionMenu/AddEditCycleForm';
import useCycle from '../../../hooks/useCycle';
import CycleScheduleForm from '../actionMenu/CycleScheduleForm';
import { useNavigate } from 'react-router-dom';
import { patientRoutes } from '../../../router/routes';
import MedicationProtocolForm, {
  SubmitMedicationProtocolFormArgs
} from '../actionMenu/MedicationProtocolForm';
import ConfirmPrescriptionForm, {
  ConfirmPrescriptionFormSubmit
} from '../actionMenu/ConfirmPrescriptionForm';
import OpenNewOrdersPrescriptionsDocumentEditor from '../actionMenu/OpenNewOrdersPrescriptionsDocumentEditor';
import Dialog from '../../../components/display/Dialog';
import i18n from '../../../i18n/i18n';
import usePrescription from '../../../hooks/usePrescription';
import dayjs from 'dayjs';
import Flex from '../../../components/layout/Flex';
import Loader from '../../../components/display/Loader';
import { iconSizes } from '../../../components/styles/constants';
import { AddEditOutcomeReportForm } from '../reportForms/AddEditOutcomeReportForm';

const cycleWizardSteps: Record<CycleWizardStepTypes, CycleWizardStep> = {
  EDIT_CYCLE_INFO: {
    type: CycleWizardStepTypes.EDIT_CYCLE_INFO,
    header: i18n.t('START_NEW_DIALOG_HEADER'),
    fullWidth: true,
    maxWidth: 'lg'
  },
  MEDICATION_PROTOCOL: {
    type: CycleWizardStepTypes.MEDICATION_PROTOCOL,
    header: i18n.t('MEDICATION_PROTOCOL_HEADER'),
    fullWidth: true,
    maxWidth: 'lg'
  },
  CONFIRM_PRESCRIPTION: {
    type: CycleWizardStepTypes.CONFIRM_PRESCRIPTION,
    header: i18n.t('CONFIRM_PRESCRIPTION_DIALOG_HEADER'),
    fullWidth: true,
    maxWidth: 'lg'
  },
  CYCLE_SCHEDULE: {
    type: CycleWizardStepTypes.CYCLE_SCHEDULE,
    header: i18n.t('CYCLE_SCHEDULE_DIALOG_HEADER'),
    fullWidth: true,
    maxWidth: 'lg'
  },
  SIGN_DOCUMENTS: {
    type: CycleWizardStepTypes.SIGN_DOCUMENTS,
    header: i18n.t('OPEN_DOCUMENTS_EDITOR'),
    fullWidth: true,
    maxWidth: 'sm'
  },
  CYCLE_OUTCOME: {
    type: CycleWizardStepTypes.CYCLE_OUTCOME,
    header: i18n.t('CYCLE_OUTCOME_HEADER'),
    fullWidth: true,
    maxWidth: 'lg'
  }
};

const EditCycleInfoSteps: CycleWizardStepTypes[] = [
  CycleWizardStepTypes.EDIT_CYCLE_INFO
];

const AddIntendedCycleSteps: CycleWizardStepTypes[] = [
  CycleWizardStepTypes.EDIT_CYCLE_INFO,
  CycleWizardStepTypes.MEDICATION_PROTOCOL,
  CycleWizardStepTypes.CONFIRM_PRESCRIPTION,
  CycleWizardStepTypes.SIGN_DOCUMENTS
];

const ActivateCycleSteps: CycleWizardStepTypes[] = [
  CycleWizardStepTypes.EDIT_CYCLE_INFO,
  CycleWizardStepTypes.CYCLE_SCHEDULE,
  CycleWizardStepTypes.MEDICATION_PROTOCOL,
  CycleWizardStepTypes.SIGN_DOCUMENTS
];

const AddHistoricalCycleSteps: CycleWizardStepTypes[] = [
  CycleWizardStepTypes.EDIT_CYCLE_INFO,
  CycleWizardStepTypes.CYCLE_OUTCOME,
  CycleWizardStepTypes.MEDICATION_PROTOCOL
];

const AddPrescriptionSteps: CycleWizardStepTypes[] = [
  CycleWizardStepTypes.MEDICATION_PROTOCOL,
  CycleWizardStepTypes.CONFIRM_PRESCRIPTION,
  CycleWizardStepTypes.SIGN_DOCUMENTS
];

export interface CycleWizardProps {
  patientId: string;
  variant: CycleWizardVariant;
  onClose: () => void;
  cycleId?: string;
}

const getCycleStatusByVariant = (
  variant: CycleWizardVariant,
  currCycleStatus?: CycleStatus
) => {
  if (currCycleStatus && variant === CycleWizardVariant.EDIT_CYCLE_INFO) {
    return currCycleStatus;
  }
  switch (variant) {
    case CycleWizardVariant.INTENDED_CYCLE:
      return CycleStatus.PENDING;
    case CycleWizardVariant.ACTIVATE_CYCLE:
      return CycleStatus.ONGOING;
    case CycleWizardVariant.PREVIOUS_CYCLE:
      return CycleStatus.COMPLETE;
    default:
      return null;
  }
};

export const CycleWizard: FC<CycleWizardProps> = ({
  variant,
  patientId,
  cycleId,
  onClose
}) => {
  const navigate = useNavigate();
  const steps = useMemo(() => {
    switch (variant) {
      case CycleWizardVariant.EDIT_CYCLE_INFO:
        return EditCycleInfoSteps;
      case CycleWizardVariant.INTENDED_CYCLE:
        return AddIntendedCycleSteps;
      case CycleWizardVariant.ACTIVATE_CYCLE:
        return ActivateCycleSteps;
      case CycleWizardVariant.PREVIOUS_CYCLE:
        return AddHistoricalCycleSteps;
      case CycleWizardVariant.ADD_PRESCRIPTION:
        return AddPrescriptionSteps;
    }
  }, [variant]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [ordersDocumentIds, setOrderDocumentIds] = useState<string[]>([]);
  const [prescriptionDocumentId, setPrescriptionDocumentId] =
    useState<string>('');
  const [wizardData, setWizardData] = useState<NewCycleFormValues>({
    patientId,
    id: cycleId,
    status: getCycleStatusByVariant(variant),
    isHistorical: variant === CycleWizardVariant.PREVIOUS_CYCLE
  });

  const { createCycle, updateCycle, getCycleById } = useCycle();
  const { addPrescription } = usePrescription();

  const shouldFetchCycle = useMemo(
    () =>
      !!cycleId &&
      (variant === CycleWizardVariant.EDIT_CYCLE_INFO ||
        variant === CycleWizardVariant.ACTIVATE_CYCLE),
    [cycleId, variant]
  );

  const { data: cycle, isLoading: isLoadingCycle } = getCycleById(cycleId, {
    enabled: shouldFetchCycle
  });
  const { mutate: handleCreateCycle, isLoading: isCreatingCycle } =
    createCycle();
  const { mutate: handleUpdateCycle, isLoading: isUpdatingCycle } =
    updateCycle();
  const { mutate: handleAddPrescription, isLoading: isAddingPrescription } =
    addPrescription();

  useEffect(() => {
    if (!cycle || !shouldFetchCycle) return;
    setWizardData({
      ...cycle,
      status: getCycleStatusByVariant(variant, cycle.status)
    });
  }, [cycle]);

  const handleSubmitCycleDetailsForm = async (details: NewCycleForm) => {
    const updatedCycleData = { ...wizardData, ...details };

    if (variant === CycleWizardVariant.ACTIVATE_CYCLE) {
      const startDateDiff = dayjs(updatedCycleData.startDate).diff(
        wizardData?.startDate,
        'days'
      );

      if (startDateDiff) {
        const updatedProtocols = updatedCycleData.medicationProtocols.map(
          (protocol) => ({
            ...protocol,
            startDate: dayjs(protocol.startDate)
              .add(startDateDiff, 'days')
              .toDate(),
            endDate: dayjs(protocol.endDate).add(startDateDiff, 'days').toDate()
          })
        );

        updatedCycleData.medicationProtocols = updatedProtocols;
      }

      setWizardData(updatedCycleData);
      setCurrentStep(currentStep + 1);
    } else if (variant === CycleWizardVariant.EDIT_CYCLE_INFO) {
      await handleUpdateCycle({
        cycle: {
          ...details,
          patientId
        }
      });
      onClose();
    } else {
      setWizardData(updatedCycleData);
      setCurrentStep(currentStep + 1);
    }
  };

  const handleSubmitCycleScheduleForm = (details: CycleScheduleFormType) => {
    const updatedCycleData = { ...wizardData, ...details };
    setWizardData(updatedCycleData);
    setCurrentStep(currentStep + 1);
  };

  const handleSubmitMedicationProtocolForm = async ({
    medicationsProtocolForm,
    appointmentsWithOrderDocumentPayload
  }: SubmitMedicationProtocolFormArgs) => {
    const updatedCycleData = { ...wizardData, ...medicationsProtocolForm };

    if (variant === CycleWizardVariant.ACTIVATE_CYCLE) {
      if (appointmentsWithOrderDocumentPayload?.length) {
        delete updatedCycleData.appointments;
      }

      await handleUpdateCycle(
        {
          cycle: updatedCycleData,
          appointmentsWithOrderDocumentPayload
        },
        {
          onSuccess: ({ orderDocumentIds }) => {
            setOrderDocumentIds(orderDocumentIds);
            setWizardData({ ...updatedCycleData });
            navigate(
              patientRoutes.getCurrentTreatmentByCycleLink(patientId, cycleId)
            );
            setCurrentStep(currentStep + 1);
          }
        }
      );
    } else if (variant === CycleWizardVariant.PREVIOUS_CYCLE) {
      await handleCreateCycle(
        {
          cycle: updatedCycleData
        },
        {
          onSuccess: ({ id }) => {
            navigate(
              patientRoutes.getCurrentTreatmentByCycleLink(patientId, id)
            );
          }
        }
      );

      onClose();
    } else {
      setWizardData({ ...updatedCycleData });
      setCurrentStep(currentStep + 1);
    }
  };

  const handleSubmitConfirmPrescriptionForm = async ({
    prescriptionsRx,
    prescriptionsDocumentPayload
  }: ConfirmPrescriptionFormSubmit) => {
    const updatedCycleData = { ...wizardData, prescriptionsRx };
    if (variant === CycleWizardVariant.ADD_PRESCRIPTION) {
      await handleAddPrescription(
        {
          cycle: {
            ...updatedCycleData,
            cycleId
          },
          prescriptionsDocumentPayload
        },
        {
          onSuccess: ({ prescriptionsDocumentId }) => {
            setWizardData(updatedCycleData);
            setPrescriptionDocumentId(prescriptionsDocumentId);
            setCurrentStep(currentStep + 1);
          }
        }
      );
    } else if (variant === CycleWizardVariant.INTENDED_CYCLE) {
      await handleCreateCycle(
        {
          cycle: updatedCycleData,
          prescriptionsDocumentPayload
        },
        {
          onSuccess: ({ id, prescriptionsDocumentId }) => {
            setWizardData(updatedCycleData);
            setPrescriptionDocumentId(prescriptionsDocumentId);
            navigate(
              patientRoutes.getCurrentTreatmentByCycleLink(patientId, id)
            );
            setCurrentStep(currentStep + 1);
          }
        }
      );
    }
  };

  const handleSubmitOutcomeReportForm = (report: CycleOutcomeDetails) => {
    const updatedCycleData = { ...wizardData, ...report };
    setWizardData(updatedCycleData);
    setCurrentStep(currentStep + 1);
  };

  const handleCloseDocsStep = () => {
    if (
      variant === CycleWizardVariant.ADD_PRESCRIPTION ||
      variant === CycleWizardVariant.INTENDED_CYCLE
    ) {
      onClose();
      return;
    }
    navigate(patientRoutes.getCurrentTreatmentByCycleLink(patientId, cycleId));
    onClose();
  };

  const handlePreviousStep = () => {
    if (currentStep === 0) {
      return;
    }

    setCurrentStep(currentStep - 1);
  };

  const getCurrentStep = () => {
    switch (steps[currentStep]) {
      case CycleWizardStepTypes.EDIT_CYCLE_INFO:
        return (
          <AddEditNewCycleForm
            cycleData={wizardData}
            variant={variant}
            handleSubmitForm={handleSubmitCycleDetailsForm}
          />
        );
      case CycleWizardStepTypes.CYCLE_SCHEDULE:
        return (
          <CycleScheduleForm
            patientId={patientId}
            cycleData={wizardData}
            handleSubmitForm={handleSubmitCycleScheduleForm}
            handleClickBack={handlePreviousStep}
          />
        );
      case CycleWizardStepTypes.MEDICATION_PROTOCOL:
        return (
          <MedicationProtocolForm
            variant={variant}
            patientId={patientId}
            cycleData={wizardData}
            hideCycleDayOne={variant === CycleWizardVariant.ADD_PRESCRIPTION}
            handleSubmitForm={handleSubmitMedicationProtocolForm}
            handleClickBack={
              variant !== CycleWizardVariant.ADD_PRESCRIPTION
                ? handlePreviousStep
                : undefined
            }
          />
        );

      case CycleWizardStepTypes.CONFIRM_PRESCRIPTION:
        return (
          <ConfirmPrescriptionForm
            patientId={patientId}
            newCycle={wizardData}
            handleSubmitForm={handleSubmitConfirmPrescriptionForm}
            handleClickBack={handlePreviousStep}
          />
        );

      case CycleWizardStepTypes.SIGN_DOCUMENTS:
        return (
          <OpenNewOrdersPrescriptionsDocumentEditor
            patientId={patientId}
            prescriptionsDocumentId={prescriptionDocumentId}
            ordersDocumentIds={ordersDocumentIds}
            handleSubmit={handleCloseDocsStep}
          />
        );
      case CycleWizardStepTypes.CYCLE_OUTCOME:
        return (
          <AddEditOutcomeReportForm
            newCycleData={wizardData}
            handleSubmitForm={handleSubmitOutcomeReportForm}
            patientId={patientId}
            handleClickBack={handlePreviousStep}
          />
        );
    }
  };

  const isLoading =
    isLoadingCycle ||
    isCreatingCycle ||
    isUpdatingCycle ||
    isAddingPrescription;
  const wizardHeight =
    cycleWizardSteps[steps[currentStep]].type ===
    CycleWizardStepTypes.SIGN_DOCUMENTS
      ? 'auto'
      : '80vh';

  return (
    <Dialog
      open
      onClose={(reason) => {
        if (reason === 'backdropClick') return;
        onClose();
      }}
      PaperProps={{
        sx: {
          height: wizardHeight
        }
      }}
      {...cycleWizardSteps[steps[currentStep]]}
    >
      {isLoading ? (
        <Flex justifyContent={'center'} alignItems={'center'} height="100%">
          <Loader size={iconSizes.xlarge} />
        </Flex>
      ) : (
        getCurrentStep()
      )}
    </Dialog>
  );
};
