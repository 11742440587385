import { FC, useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import InputField from 'src/components/data-entry/InputField/InputField';
import Select from 'src/components/data-entry/Select/Select';
import Button from 'src/components/display/Button/Button';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import { PersonSex } from 'src/types/patient';
import Loader from 'src/components/display/Loader/Loader';
import dayjs from 'dayjs';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { getFullName } from 'src/utils/general';
import useCycle from 'src/hooks/useCycle';
import {
  BabiesInfo,
  CycleOutcome,
  CycleOutcomeDetails,
  CycleStatus,
  NewCycleFormValues,
  PregnancyStatus,
  TreatmentTypes,
  finishCycleStatus
} from 'src/types/cycle';
import Center from 'src/components/layout/Center';
import { StyledForm } from '../actionMenu/MedicationProtocolForm';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';

const babyInfo: BabiesInfo = {
  height: null,
  sex: null,
  weight: null
};

const cycleTypesToDisplayBetaFields = [
  TreatmentTypes.IVF_ET,
  TreatmentTypes.FET,
  TreatmentTypes.IUI,
  TreatmentTypes.OVULATION_INDUCTION
];

interface AddEditOutcomeReportFormProps {
  cycleId?: string;
  patientId: string;
  newCycleData?: NewCycleFormValues;
  handleSubmitForm?: (report: CycleOutcomeDetails) => void;
  handleClickBack?: () => void;
}

export const AddEditOutcomeReportForm: FC<AddEditOutcomeReportFormProps> = ({
  cycleId,
  patientId,
  handleSubmitForm,
  newCycleData,
  handleClickBack
}) => {
  const { getPatientById } = usePatientsApi();
  const { updateCycle, getCycleById, removePrescriptionsFromCycle } =
    useCycle();
  const { t } = useTranslation();
  const { closeDialog, openDialog, closeAllDialogs } = useDialog();

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  const { data: cycle, isLoading: isLoadingCycle } = getCycleById(cycleId, {
    enabled: !!cycleId
  });

  const { mutate: handleUpdateCycle } = updateCycle();
  const { mutate: handleRemovePrescriptionsFromCycle } =
    removePrescriptionsFromCycle();

  const currentCycle = cycleId ? cycle : newCycleData;

  const statusOnly =
    currentCycle?.treatmentType === TreatmentTypes.IVF ||
    currentCycle?.treatmentType === TreatmentTypes.EGG_FREEZING ||
    currentCycle?.treatmentType === TreatmentTypes.MOCK_CYCLE_ERA;

  const defaultValues: CycleOutcomeDetails = {
    patientId,
    patientName: '',
    outcome: null,
    status: null,
    dateOfBirth: dayjs().toDate(),
    dueDate: dayjs().toDate(),
    outcomeDate: dayjs().toDate(),
    numberOfBabies: null,
    pregnancyStatus: null,
    babiesInfo: [],
    firstBetaValue: null,
    firstBetaUnit: null,
    secondBetaValue: null,
    secondBetaUnit: null
  };

  const { control, formState, handleSubmit, watch, reset } =
    useForm<CycleOutcomeDetails>({
      mode: 'onChange',
      defaultValues
    });
  const {
    append,
    remove,
    fields: babiesInfo
  } = useFieldArray({
    control,
    name: 'babiesInfo'
  });

  useEffect(() => {
    if (!cycle && !patient) return;

    reset({
      patientId,
      patientName: getFullName(patient?.personalInfo),
      outcome: cycle?.outcome || null,
      status: currentCycle?.status || null,
      dateOfBirth: cycle?.dateOfBirth || dayjs().toDate(),
      dueDate: cycle?.dueDate || dayjs().toDate(),
      numberOfBabies: cycle?.numberOfBabies || null,
      pregnancyStatus: cycle?.pregnancyStatus || null,
      outcomeDate: cycle?.outcomeDate || dayjs().toDate(),
      babiesInfo: cycle?.babiesInfo || [],
      firstBetaValue: cycle?.firstBetaValue || null,
      firstBetaUnit: cycle?.firstBetaUnit || null,
      secondBetaValue: cycle?.secondBetaValue || null,
      secondBetaUnit: cycle?.secondBetaUnit || null
    });
  }, [cycle, patient]);

  const { errors } = formState;
  const { outcome, pregnancyStatus } = watch();

  const onSubmit = async (report: CycleOutcomeDetails) => {
    if (handleSubmitForm) {
      handleSubmitForm(report);
      return;
    }

    const ongoingMedications = cycle.medicationProtocols.some((medication) =>
      dayjs(medication.endDate).isAfter(dayjs(), 'day')
    );
    if (
      finishCycleStatus.includes(report.status) &&
      ongoingMedications &&
      !finishCycleStatus.includes(cycle.status)
    ) {
      openDialog({
        header: t('STOP_MEDICATIONS_LABEL'),
        fullWidth: true,
        maxWidth: 'md',
        children: (
          <Center gap={spacings.xlarge} marginTop={spacings.large}>
            <Button
              onClick={async () => {
                await handleRemovePrescriptionsFromCycle({ cycleId: cycle.id });
                await handleUpdateCycle({ cycle: { id: cycleId, ...report } });
                closeDialog();
              }}
            >
              {t('YES')}
            </Button>
            <Button
              onClick={async () => {
                await handleUpdateCycle({ cycle: { id: cycleId, ...report } });
                closeAllDialogs();
              }}
            >
              {t('NO')}
            </Button>
          </Center>
        )
      });
    } else {
      await handleUpdateCycle({ cycle: { id: cycleId, ...report } });
      closeDialog();
    }
  };

  const cycleStatusOptions = useMemo(
    () =>
      Object.entries(CycleStatus)
        .filter(([_, value]) => {
          const statusesToExclude = [CycleStatus.PENDING];
          if (currentCycle?.isHistorical) {
            statusesToExclude.push(CycleStatus.ONGOING);
          }
          return !statusesToExclude.includes(value);
        })
        .map(([key, value]) => ({
          label: t(key),
          value
        })),
    [cycle, newCycleData]
  );

  if (isLoadingPatient || isLoadingCycle) return <Loader />;

  const shouldShowBetaFields =
    outcome &&
    outcome !== CycleOutcome.TBD &&
    cycleTypesToDisplayBetaFields.includes(currentCycle.treatmentType);

  return (
    <Box marginTop={spacings.large} height={'100%'}>
      <StyledForm noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Flex marginBottom={spacings.medium} gap={spacings.large}>
            <Box flex={1}>
              <Controller
                name="patientName"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    disabled
                    label={t('PATIENT_NAME')}
                    placeholder={t('PATIENT_NAME')}
                    error={!!errors.patientName}
                    helperText={errors?.patientName?.message}
                    required
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box flex={1}>
              <Controller
                name={'outcomeDate'}
                control={control}
                rules={{
                  required: t('DATE_REQUIRED'),
                  validate: (value) =>
                    dayjs(value).isBefore(dayjs()) ||
                    t('OUTCOME_DATE_MUST_BE_BEFORE_TODAY')
                }}
                render={({ field: { ref, ...field } }) => (
                  <DatePicker
                    {...field}
                    label={
                      currentCycle?.isHistorical
                        ? t('CYCLE_CLOSE_DATE')
                        : t('DATE_OF_OUTCOME')
                    }
                    inputRef={ref}
                    error={!!errors?.outcomeDate}
                    helperText={errors.outcomeDate?.message}
                    fullWidth
                  />
                )}
              />
            </Box>
          </Flex>
          <Flex gap={spacings.large} marginBottom={spacings.large}>
            <Box flex={1}>
              <Controller
                name="status"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    label={t('CYCLE_STATUS')}
                    inputRef={ref}
                    error={!!errors?.status}
                    helperText={errors?.status?.message}
                    defaultOption={t('CYCLE_STATUS')}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                    }}
                    options={cycleStatusOptions}
                  />
                )}
              />
            </Box>
            {statusOnly ? (
              <Box flex={1}></Box>
            ) : (
              <>
                <Box flex={1}>
                  <Controller
                    name="outcome"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...field}
                        label={t('CYCLE_OUTCOME')}
                        inputRef={ref}
                        error={!!errors?.outcome}
                        helperText={errors?.outcome?.message}
                        defaultOption={t('CYCLE_OUTCOME')}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                        }}
                        options={Object.entries(CycleOutcome).map(
                          ([key, value]) => ({
                            label: t(key),
                            value
                          })
                        )}
                      />
                    )}
                  />
                </Box>
                <Box flex={1}>
                  {outcome === CycleOutcome.PREGNANT && (
                    <Controller
                      name="pregnancyStatus"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <Select
                          {...field}
                          label={t('PREGNANCY_STATUS')}
                          inputRef={ref}
                          error={!!errors.pregnancyStatus}
                          helperText={errors?.pregnancyStatus?.message}
                          defaultOption={t('PREGNANCY_STATUS')}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          options={Object.entries(PregnancyStatus).map(
                            ([key, value]) => ({
                              label: t(key),
                              value
                            })
                          )}
                        />
                      )}
                    />
                  )}
                </Box>
                <Box flex={1}>
                  {outcome === CycleOutcome.PREGNANT && (
                    <Controller
                      name={'dueDate'}
                      control={control}
                      rules={{
                        required: t('DATE_REQUIRED')
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <DatePicker
                          {...field}
                          label={t('DUE_DATE')}
                          inputRef={ref}
                          error={!!errors?.dueDate}
                          helperText={errors.dueDate?.message}
                          fullWidth
                        />
                      )}
                    />
                  )}
                </Box>
              </>
            )}
          </Flex>
          {shouldShowBetaFields && (
            <Flex gap={spacings.large} marginBottom={spacings.large}>
              <Box flex={1}>
                <Controller
                  name={'firstBetaValue'}
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={t('FIRST_BETA_VALUE')}
                      placeholder={t('#')}
                      error={!!errors.firstBetaValue}
                      helperText={errors?.firstBetaValue?.message}
                      fullWidth
                      type="number"
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name={'firstBetaUnit'}
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={t('UNIT')}
                      error={!!errors.firstBetaUnit}
                      helperText={errors?.firstBetaUnit?.message}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name={'secondBetaValue'}
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={t('SECOND_BETA_VALUE')}
                      placeholder={t('#')}
                      error={!!errors.secondBetaValue}
                      helperText={errors?.secondBetaValue?.message}
                      fullWidth
                      type="number"
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name={'secondBetaUnit'}
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={t('UNIT')}
                      error={!!errors.secondBetaUnit}
                      helperText={errors?.secondBetaUnit?.message}
                      fullWidth
                    />
                  )}
                />
              </Box>
            </Flex>
          )}
          {!statusOnly && pregnancyStatus === PregnancyStatus.LIVE_BIRTH && (
            <Flex gap={spacings.large} marginBottom={spacings.large}>
              <Box flex={1}>
                <Controller
                  name={'dateOfBirth'}
                  control={control}
                  rules={{
                    required: t('DATE_REQUIRED')
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <DatePicker
                      {...field}
                      label={t('DATE_OF_BIRTH')}
                      inputRef={ref}
                      error={!!errors?.dateOfBirth}
                      helperText={errors.dateOfBirth?.message}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="numberOfBabies"
                  control={control}
                  render={({ field: { ref, onChange, value, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={t('NUMBER_OF_BABIES')}
                      placeholder={t('NUMBER_OF_BABIES')}
                      error={!!errors.numberOfBabies}
                      helperText={errors?.numberOfBabies?.message}
                      value={value}
                      fullWidth
                      onChange={(ev) => {
                        const diff: number = +ev.target.value - (value || 0);
                        if (diff > 0) {
                          Array.from(Array(diff)).forEach(() => {
                            append(babyInfo);
                          });
                        } else {
                          Array.from(Array(-diff)).forEach((_, index) => {
                            remove(babiesInfo.length - 1 - index);
                          });
                        }
                        onChange(ev);
                      }}
                      type="number"
                    />
                  )}
                />
              </Box>
              <Box flex={1}></Box>
              <Box flex={1}></Box>
            </Flex>
          )}
          {!statusOnly &&
            babiesInfo?.map((info, index) => (
              <Flex
                gap={spacings.large}
                key={info.id}
                marginBottom={spacings.medium}
              >
                <Box flex={1}>
                  <Controller
                    name={`babiesInfo.${index}.sex`}
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...field}
                        label={t('BABY_SEX')}
                        inputRef={ref}
                        error={!!errors.babiesInfo?.[index].sex}
                        helperText={errors?.babiesInfo?.[index].sex?.message}
                        defaultOption={t('BABY_SEX')}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                        }}
                        options={Object.keys(PersonSex).map((sex) => ({
                          label: t(sex),
                          value: sex
                        }))}
                      />
                    )}
                  />
                </Box>
                <Box flex={1}>
                  <Controller
                    name={`babiesInfo.${index}.weight`}
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <InputField
                        {...field}
                        inputRef={ref}
                        label={t('BABY_WEIGHT')}
                        placeholder={t('BABY_WEIGHT')}
                        error={!!errors.babiesInfo?.[index]?.weight}
                        helperText={
                          errors?.babiesInfo?.[index]?.weight?.message
                        }
                        fullWidth
                        type="number"
                      />
                    )}
                  />
                </Box>
                <Box flex={1}>
                  <Controller
                    name={`babiesInfo.${index}.height`}
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <InputField
                        {...field}
                        inputRef={ref}
                        label={t('BABY_HEIGHT')}
                        placeholder={t('BABY_HEIGHT')}
                        error={!!errors.babiesInfo?.[index]?.height}
                        helperText={
                          errors?.babiesInfo?.[index]?.height?.message
                        }
                        fullWidth
                        type="number"
                      />
                    )}
                  />
                </Box>
                <Box flex={1}></Box>
                <Box flex={1}></Box>
              </Flex>
            ))}
        </Box>

        <Flex mt={'20px'} justifyContent={'space-between'}>
          <Box width="20%">
            {handleClickBack && (
              <Button
                type="button"
                bgColor="alto"
                textColor="darkGray"
                fullWidth
                onClick={handleClickBack}
              >
                {t('BACK')}
              </Button>
            )}
          </Box>
          <Box width="20%">
            <Button fullWidth type="submit">
              {t('SUBMIT')}
            </Button>
          </Box>
        </Flex>
      </StyledForm>
    </Box>
  );
};
