import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputField from 'src/components/data-entry/InputField/InputField';
import { NextOfKin, NextOfKinRelationship } from 'src/types/patient';
import Select from 'src/components/data-entry/Select/Select';
import Box from 'src/components/layout/Box/Box';
import Button from 'src/components/display/Button/Button';
import { spacings } from 'src/components/styles/constants';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { usePopover } from 'src/components/components-api/PopoverProvider';
import Flex from 'src/components/layout/Flex/Flex';
import { emailRegex, phoneRegex } from '../utils/validations-regex';

interface NextOfKinFormFields extends NextOfKin {
  nextOfKinName?: string;
}
export const EditNextOfKinInfo: FC<{
  patientId: string;
  nextOfKinInfo: NextOfKin;
}> = ({ patientId, nextOfKinInfo }) => {
  const { t } = useTranslation();
  const { updatePatientNextOfKinInfo } = usePatientsApi();
  const { closePopover } = usePopover();

  const { mutate: updatePatient, isSuccess: isUpdatedSuccessfuly } =
    updatePatientNextOfKinInfo();

  const {
    nextOfKinAddress,
    nextOfKinEmail,
    nextOfKinName,
    nextOfKinPhoneNumber,
    nextOfKinRelationship
  } = nextOfKinInfo || {};

  const defaultValues: NextOfKinFormFields = {
    nextOfKinAddress: nextOfKinAddress || '',
    nextOfKinEmail: nextOfKinEmail || '',
    nextOfKinName: nextOfKinName || '',
    nextOfKinPhoneNumber: nextOfKinPhoneNumber || '',
    nextOfKinRelationship: nextOfKinRelationship || null
  };

  const { control, formState, handleSubmit } = useForm<NextOfKinFormFields>({
    mode: 'onChange',
    defaultValues
  });

  const { errors } = formState;

  useEffect(() => {
    if (isUpdatedSuccessfuly) closePopover();
  }, [isUpdatedSuccessfuly]);

  const onSubmit = async (details: NextOfKinFormFields) => {
    await updatePatient({
      patientId: `${patientId}`,
      nextOfKinInfo: {
        ...details
      }
    });
  };
  return (
    <Box width={'300px'}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Flex
          flexDirection="column"
          sx={{ gap: spacings.xlarge, marginBottom: spacings.medium }}
        >
          <Controller
            name="nextOfKinName"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('NAME_LABEL')}
                placeholder={t('NAME_PLACEHOLDER')}
                error={!!errors.nextOfKinName}
                helperText={errors?.nextOfKinName?.message}
                fullWidth
              />
            )}
          />

          <Controller
            name="nextOfKinRelationship"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                inputRef={ref}
                label={t('RELATION_LABEL').toUpperCase()}
                error={!!errors?.nextOfKinRelationship}
                helperText={errors?.nextOfKinRelationship?.message}
                defaultOption={t('RELATION_DEFAULT_OPTION')}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
                options={Object.entries(NextOfKinRelationship).map(
                  ([value, label]) => ({ value: label, label: t(value) })
                )}
              />
            )}
          />

          <Controller
            name="nextOfKinPhoneNumber"
            control={control}
            rules={{
              pattern: {
                value: phoneRegex,
                message: t('PHONE_NUMBER_INVALID')
              }
            }}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('PHONE_NUMBER_LABEL')}
                placeholder={t('PHONE_NUMBER_PLACEHOLDER')}
                error={!!errors.nextOfKinPhoneNumber}
                helperText={errors?.nextOfKinPhoneNumber?.message}
                fullWidth
              />
            )}
          />

          <Controller
            name="nextOfKinEmail"
            control={control}
            rules={{
              pattern: {
                value: emailRegex,
                message: t('EMAIL_INVALID')
              }
            }}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('EMAIL')}
                placeholder={t('EMAIL_PLACEHOLDER')}
                type="email"
                error={!!errors.nextOfKinEmail}
                helperText={errors?.nextOfKinEmail?.message}
                fullWidth
              />
            )}
          />

          <Controller
            name="nextOfKinAddress"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('ADDRESS').toUpperCase()}
                sx={{ flex: 1 }}
                placeholder={t('ADDRESS_PLACEHOLDER')}
                error={!!errors.nextOfKinAddress}
                helperText={errors?.nextOfKinAddress?.message}
                fullWidth
              />
            )}
          />
        </Flex>
        <Button fullWidth type="submit">
          {t('SUBMIT')}
        </Button>
      </form>
    </Box>
  );
};
