import React from 'react';
import { Link } from 'react-router-dom';
import {
  styled,
  tooltipClasses,
  Tooltip,
  TooltipProps,
  css
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getDashboardLink } from 'src/router/routes';
import useClinicSettingsApi from 'src/hooks/useClinicSettingsApi';
import { radii } from 'src/components/styles/constants';
import Loader from '../Loader';
import { DefaultIcon } from 'src/constants';

const LogoWrapper = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.palette.text.primary};
    display: flex;
    text-decoration: none;
    width: 53px;
    margin: 0 auto;
    font-weight: ${theme.typography.fontWeightBold};
  `}
`;

const LogoImg = styled('img')`
  width: 52px;
  max-height: 52px;
  border-radius: ${radii.full};
`;

export const TooltipWrapper = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));

function Logo() {
  const { t } = useTranslation();
  const { getClinicSettings } = useClinicSettingsApi();
  const { data: clinicSettings, isLoading } = getClinicSettings();

  return (
    <TooltipWrapper title={t('LOGO_SIGN_TITLE')} arrow>
      <LogoWrapper to={getDashboardLink()}>
        {isLoading ? (
          <Loader />
        ) : (
          <LogoImg
            sx={{ borderRadius: '50%' }}
            alt={t('LOGO_SIGN_TITLE')}
            src={clinicSettings?.clinicIcon || DefaultIcon}
          />
        )}
      </LogoWrapper>
    </TooltipWrapper>
  );
}

export default Logo;
