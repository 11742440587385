import { FC } from 'react';
import Box from '../../components/layout/Box';
import useBilling from '../../hooks/useBilling';
import { Colors } from '../../components/styles/colors';
import Card from '../../components/display/Card';
import Flex from '../../components/layout/Flex';
import Typography from '../../components/display/Typography';
import { useTranslation } from 'react-i18next';
import { spacings } from '../../components/styles/constants';
import { weights } from '../../components/styles/fonts';
import { Trend } from '../../types/global';
import { parseAmount } from './utils/parseAmount';
import Center from '../../components/layout/Center';
import Loader from '../../components/display/Loader';

import { ReactComponent as UpArrowIcon } from '../../assets/icons/up-arrow.svg';

enum BillingStatCardTitles {
  PAID = 'PAID',
  PENDING = 'PENDING',
  QUOTES = 'QUOTES'
}

interface BillingStatCardProps {
  title: BillingStatCardTitles;
  color: keyof typeof Colors;
  amount: number;
  trend?: Trend;
  percentage?: number;
  isLoading?: boolean;
}

const getArrowColor = (trend: Trend) => {
  switch (trend) {
    case Trend.ABOVE:
      return Colors.riptide;
    case Trend.BELOW:
      return Colors.mauvelous;
    default:
      return Colors.silverChalice;
  }
};

const BillingStatCard: FC<BillingStatCardProps> = ({
  amount,
  color,
  title,
  trend,
  percentage,
  isLoading
}) => {
  const { t } = useTranslation();

  return (
    <Card shadow flex={1} padding={spacings.xlarge}>
      <Flex
        alignItems="center"
        gap={spacings.medium}
        marginBottom={spacings.large}
      >
        <Box
          sx={{
            backgroundColor: Colors[color],
            borderRadius: '50%',
            width: 20,
            height: 20
          }}
        />
        <Typography variant="h3" fontWeight={weights.black}>
          {t(title).toUpperCase()}
        </Typography>
      </Flex>
      {isLoading ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        <Flex alignItems="flex-end" justifyContent="space-between">
          <Typography variant="h1" fontWeight={weights.extraBold}>
            {amount ? parseAmount(amount) : '-'}
          </Typography>
          {trend && percentage && (
            <Flex alignItems="center">
              <Box
                sx={{
                  scale: trend === Trend.ABOVE ? '1' : '-1'
                }}
              >
                <UpArrowIcon color={getArrowColor(trend)} />
              </Box>
              <Box>
                <Typography variant="h3" color={Colors.silverChalice}>
                  {percentage}%
                </Typography>
              </Box>
            </Flex>
          )}
        </Flex>
      )}
    </Card>
  );
};

export const BillingStatCards: FC = () => {
  const { getBillingStats } = useBilling();
  const { data: billingStats, isLoading: isLoadingStats } = getBillingStats();

  return (
    <Box>
      <Flex width="70%" gap={spacings.large}>
        <BillingStatCard
          title={BillingStatCardTitles.PAID}
          color="riptide"
          amount={billingStats?.paid.amount}
          percentage={billingStats?.paid.trendPercentage}
          trend={billingStats?.paid.trend}
          isLoading={isLoadingStats}
        />
        <BillingStatCard
          title={BillingStatCardTitles.QUOTES}
          color="cupid"
          amount={billingStats?.quotes.amount}
          percentage={billingStats?.quotes.trendPercentage}
          trend={billingStats?.quotes.trend}
          isLoading={isLoadingStats}
        />
        <BillingStatCard
          title={BillingStatCardTitles.PENDING}
          color="mauvelous"
          amount={billingStats?.pending.amount}
          percentage={billingStats?.pending.trendPercentage}
          trend={billingStats?.pending.trend}
          isLoading={isLoadingStats}
        />
      </Flex>
    </Box>
  );
};
