import { SvgIcon } from '@mui/material';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputField from 'src/components/data-entry/InputField';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import { Task, TaskPriority, TaskCategory, TaskStatus } from 'src/types/task';
import { DoctorChips } from '../common/DoctorChips';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import Box from 'src/components/layout/Box/Box';
import Select from 'src/components/data-entry/Select/Select';
import Button from 'src/components/display/Button/Button';
import { usePopover } from 'src/components/components-api/PopoverProvider';
import { taskNamesSuggestions } from 'src/utils/general';
import { convertTaskClientToServer } from '../utils/conversions';
import useTasks from 'src/hooks/useTasks';
import Checkbox from '../../../components/data-entry/Checkbox';
import { Colors } from '../../../components/styles/colors';
import { ReactComponent as FlagIcon } from '../../../assets/icons/flag.svg';
import Typography from '../../../components/display/Typography';
import TextArea from '../../../components/data-entry/TextArea';
import Autocomplete from 'src/components/data-entry/Autocomplete';

export const AddOneTodoItem: FC<{ patientId: string }> = ({ patientId }) => {
  const { t } = useTranslation();
  const { createTask } = useTasks();
  const { closeAllPopovers } = usePopover();

  const { mutate, isLoading } = createTask();

  const { control, formState, handleSubmit, watch } = useForm<Task>({
    mode: 'onChange',
    defaultValues: {
      assignedToStaffId: null,
      doneByStaffId: null,
      doneDate: null,
      dueDate: dayjs().toDate(),
      priority: TaskPriority.NORMAL,
      category: TaskCategory.EGG_RETRIEVAL,
      status: TaskStatus.NOT_DONE,
      comment: '',
      name: {
        translationKey: '',
        value: ''
      }
    }
  });

  const onSubmit = async (task: Task) => {
    const convertedTask = convertTaskClientToServer({ patientId, ...task });
    await mutate(convertedTask);
    closeAllPopovers();
  };

  const { errors } = formState;
  const { status } = watch();
  const isDone = status === TaskStatus.DONE;

  return (
    <Box width={'400px'}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Flex
          marginBottom={spacings.large}
          justifyContent="space-between"
          gap={spacings.small}
        >
          <Box flex={1}>
            <Controller
              name={'status'}
              control={control}
              render={({ field: { ref: _ref, onChange, value, ...field } }) => (
                <Checkbox
                  id="status-checkbox"
                  labelPlacement="top"
                  label={t('STATUS').toUpperCase()}
                  checked={value === TaskStatus.DONE}
                  {...field}
                  onChange={(_, isChecked) => {
                    onChange(isChecked ? TaskStatus.DONE : TaskStatus.NOT_DONE);
                  }}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            {isDone ? (
              <Controller
                name={'doneByStaffId'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DoctorChips
                    id="add-one-todo-item-done-by-staff"
                    showSelectedValue
                    label={t('DONE_BY').toUpperCase()}
                    value={[value]}
                    onAddChip={(newSelectedDoctorId) =>
                      onChange(newSelectedDoctorId)
                    }
                  />
                )}
              />
            ) : (
              <Controller
                name={'assignedToStaffId'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DoctorChips
                    id="add-one-todo-item-assigned-to-staff"
                    showSelectedValue
                    label={t('ASSIGN_TO').toUpperCase()}
                    value={[value]}
                    onAddChip={(newSelectedDoctorId) =>
                      onChange(newSelectedDoctorId)
                    }
                  />
                )}
              />
            )}
          </Box>
          <Box flex={1}>
            <Controller
              name={'priority'}
              control={control}
              rules={{
                required: t('PRIORITY')
              }}
              render={({ field: { ref, value, onChange } }) => (
                <Flex
                  id="one-off-priority"
                  flexDirection={'column'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  gap={spacings.large}
                  ref={ref}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    const newValue =
                      value === TaskPriority.HIGH
                        ? TaskPriority.NORMAL
                        : TaskPriority.HIGH;
                    onChange(newValue);
                  }}
                >
                  <Typography variant="label" lineHeight={1}>
                    {t('PRIORITY').toUpperCase()}
                  </Typography>
                  <SvgIcon>
                    <FlagIcon
                      color={
                        value === TaskPriority.HIGH
                          ? Colors.red
                          : Colors.emperor
                      }
                    />
                  </SvgIcon>
                </Flex>
              )}
            />
          </Box>
          <Box flex={2} id="one-off-date-box">
            {isDone ? (
              <Controller
                name="doneDate"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <DatePicker
                    {...field}
                    inputRef={ref}
                    label={t('DONE_DATE').toUpperCase()}
                    error={!!errors.dueDate}
                    helperText={errors?.dueDate?.message}
                    fullWidth
                  />
                )}
              />
            ) : (
              <Controller
                name="dueDate"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <DatePicker
                    {...field}
                    inputRef={ref}
                    label={t('DUE_DATE').toUpperCase()}
                    error={!!errors.dueDate}
                    helperText={errors?.dueDate?.message}
                    fullWidth
                  />
                )}
              />
            )}
          </Box>
        </Flex>
        <Box flex={1} marginBottom={spacings.large} id="one-off-task-name">
          <Controller
            name="name"
            control={control}
            rules={{
              required: t('NAME_REQUIRED')
            }}
            render={({ field: { onChange, value, ...field } }) => (
              <Autocomplete
                {...field}
                disableClearable
                freeSolo
                options={taskNamesSuggestions.map((item) => t(item))}
                getOptionLabel={(option: string) => option}
                shouldSortOptions
                onChange={(_, value: string) =>
                  onChange({
                    translationKey: value,
                    value: t(value)
                  })
                }
                renderInput={(params) => (
                  <InputField
                    inputProps={{ ...params.inputProps }}
                    InputProps={{ ...params.InputProps }}
                    placeholder={t('TASK_NAME')}
                    value={t(value.translationKey)}
                    label={t('TASK_NAME').toUpperCase()}
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    onChange={(ev) =>
                      onChange({
                        translationKey: ev.target.value,
                        value: ev.target.value
                      })
                    }
                    required
                    fullWidth
                  />
                )}
              />
            )}
          />
        </Box>

        <Box marginBottom={spacings.large} id="one-off-category">
          <Controller
            name={'category'}
            control={control}
            rules={{
              required: t('CATEGORY')
            }}
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                inputRef={ref}
                label={t('CATEGORY')}
                error={!!errors?.category}
                helperText={errors?.category?.message}
                defaultOption={t('PLEASE_CHOOSE_CATEGORY')}
                options={Object.entries(TaskCategory).map(([label, value]) => ({
                  label: t(label) === label ? value : t(label),
                  value
                }))}
              />
            )}
          />
        </Box>

        <Box marginBottom={spacings.large} id="one-off-task-comment">
          <Controller
            name="comment"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <TextArea
                {...field}
                ref={ref}
                label={t('COMMENT').toUpperCase()}
                placeholder={t('COMMENT')}
                error={!!errors.comment}
                helperText={errors?.comment?.message}
                minRows={3}
                maxRows={3}
                fullWidth
              />
            )}
          />
        </Box>

        <Flex justifyContent={'end'}>
          <Box mt={'20px'}>
            <Button disabled={isLoading} type="submit">
              {t('SUBMIT')}
            </Button>
          </Box>
        </Flex>
      </form>
    </Box>
  );
};
