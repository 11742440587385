import { FC, useEffect, useRef, useState } from 'react';
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import { orderTemplateId } from 'src/types/documents';
import {
  useAuth,
  useDialog,
  useDocumentEditor
} from 'src/components/components-api/GlobalProvider/GlobalProvider';
import useMeApi from 'src/hooks/useMeApi';
import useDocumentsApi from '../../hooks/useDocumentsApi';
import useGetPatientDocumentData from '../../hooks/useGetPatientDocumentData';
import { VerifyDocumentSignee } from './VerifyDocumentSignee';
import { YourDocumentIsSavingLoader } from './YourDocumentIsSavingLoader';
import { initNewDocumentInEditor } from './utils/editorUtils/webViewerFunctions';
import { zIndices } from 'src/components/styles/constants';

export const DocumentEditor: FC = () => {
  const { isAuthenticated } = useAuth();

  const viewer = useRef(null);
  const [instance, setInstance] = useState<WebViewerInstance>(null);

  const { openDialog, closeDialog } = useDialog();
  const { documentEditorParams, isDocumentsModalOpen } = useDocumentEditor();
  const { createPatientDocument, updatePatientDocument } = useDocumentsApi();

  const { getMe } = useMeApi();

  const {
    cycleId,
    documentId,
    labOrdersId,
    orderId: orderIdProp,
    patientId,
    templateId
  } = documentEditorParams;

  const orderId = orderIdProp?.split(',')?.[0] || null;

  const { data: loggedStaffMember, isLoading: isLoadingStaffMember } = getMe({
    enabled: isAuthenticated
  });

  const { mutate: createPatientDocumentMutate } = createPatientDocument();

  const { mutate: updatePatientDocumentMutate } = updatePatientDocument();

  const {
    templateData,
    documentToEdit,
    templates,
    isLoading: isLoadingPatientDocumentData
  } = useGetPatientDocumentData({
    patientId,
    cycleId,
    templateId,
    documentId,
    labOrdersId,
    orderId,
    enabled: isAuthenticated
  });

  const isLoading = isLoadingPatientDocumentData || isLoadingStaffMember;
  const chosenTemplate = templates?.find(({ id }) => id === templateId);
  const isClinicDocument = !Object.keys(documentToEdit || {})?.length;

  const stringifiedTemplateData = JSON.stringify(templateData);

  const documentMetadata = !documentId
    ? stringifiedTemplateData
    : documentToEdit?.metadata || '';

  const documentIsBeingSavedLoader = () =>
    openDialog({
      children: <YourDocumentIsSavingLoader />,
      closable: false,
      maxWidth: 'xl',
      sx: { zIndex: zIndices.highest }
    });

  useEffect(() => {
    const initializeViewer = async () => {
      if (!viewer.current) return;
      const instanceProp = await WebViewer(
        {
          path: '/apryse',
          licenseKey: process.env.REACT_APP_APRYSE_KEY
        },
        viewer.current
      );

      if (!instance) {
        setInstance(instanceProp);
      }
    };

    initializeViewer();
  }, [viewer]);

  // Open edited documents
  useEffect(() => {
    if (
      !instance ||
      isLoading ||
      !documentEditorParams ||
      !isDocumentsModalOpen
    ) {
      return;
    }

    const { ToolbarGroup, closeElements, setToolbarGroup, openElements } =
      instance.UI;

    const init = async () => {
      await initNewDocumentInEditor({
        openDialog,
        handleToolbarGroupChangeAuthChildren: (
          <VerifyDocumentSignee
            openElements={openElements}
            closeElements={closeElements}
            setToolbarGroup={setToolbarGroup}
            ToolbarGroup={ToolbarGroup}
          />
        ),
        isClinicDocument,
        instance,
        chosenTemplate,
        documentToEdit,
        orderTemplateId,
        patientId,
        documentId,
        closeDialog,
        createDocument: createPatientDocumentMutate,
        documentIsBeingSavedLoader,
        documentMetadata,
        loggedStaffMember,
        templateId,
        updateDocument: updatePatientDocumentMutate
      });
    };

    init();

    if (documentToEdit?.annotations) {
      instance.Core.documentViewer.setDocumentXFDFRetriever(() => {
        return new Promise((resolve) => {
          resolve(documentToEdit.annotations);
        });
      });
    }
  }, [documentToEdit, isLoading, isDocumentsModalOpen]);

  // Open templates
  useEffect(() => {
    if (
      !instance ||
      isLoading ||
      documentId ||
      !isDocumentsModalOpen ||
      !chosenTemplate
    ) {
      return;
    }

    const { ToolbarGroup, closeElements, setToolbarGroup, openElements } =
      instance.UI;

    const init = async () => {
      await initNewDocumentInEditor({
        openDialog,
        handleToolbarGroupChangeAuthChildren: (
          <VerifyDocumentSignee
            openElements={openElements}
            closeElements={closeElements}
            setToolbarGroup={setToolbarGroup}
            ToolbarGroup={ToolbarGroup}
          />
        ),
        isClinicDocument: true,
        instance,
        chosenTemplate,
        documentToEdit: {},
        orderTemplateId: null,
        patientId,
        documentId: null,
        closeDialog,
        createDocument: createPatientDocumentMutate,
        documentIsBeingSavedLoader,
        documentMetadata,
        loggedStaffMember,
        templateId,
        updateDocument: updatePatientDocumentMutate
      });
    };

    init();
  }, [documentEditorParams, isLoading, isDocumentsModalOpen]);

  return (
    <div className="MyComponent">
      <div className="webviewer" ref={viewer} style={{ height: '85vh' }}></div>
    </div>
  );
};
