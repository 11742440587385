import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flex from 'src/components/layout/Flex/Flex';
import { iconSizes, spacings } from 'src/components/styles/constants';
import Select from 'src/components/data-entry/Select/Select';
import Button from 'src/components/display/Button/Button';
import DatePicker from '../../../components/data-entry/DatePicker';
import Typography from '../../../components/display/Typography';
import { weights } from '../../../components/styles/fonts';
import Loader from '../../../components/display/Loader';
import useTasks from 'src/hooks/useTasks';
import dayjs from 'dayjs';

export const AddChecklistFromTemplateForm: FC<{ patientId: string }> = ({
  patientId
}) => {
  const { t } = useTranslation();
  const { getChecklistTemplates, addChecklistTemplateToPatient } = useTasks();

  const { isLoading: isLoadingTemplates, data: checklistTemplates } =
    getChecklistTemplates();
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [dueDate, setDueDate] = useState(new Date());

  const { mutate: addChecklist, isLoading: isAddingChecklist } =
    addChecklistTemplateToPatient();

  const onAddChecklist = () => {
    addChecklist({
      patientId,
      category: selectedCategory,
      dueDate: dueDate ? dayjs(dueDate) : null
    });
  };

  return (
    <Flex flexDirection="column" gap={spacings.medium} width={'250px'}>
      <Typography variant="h2" fontWeight={weights.extraBold}>
        {t('ADD_TASK_LIST')}
      </Typography>
      <Select
        label={t('CHOOSE_LIST_TEMPLATE')}
        defaultOption={t('TEMPLATE_LIST')}
        options={
          checklistTemplates?.map(({ category }) => ({
            label: category, // TODO - verify if we need to add translations or are hardcoded translations enough
            value: category
          })) || []
        }
        isLoading={isLoadingTemplates}
        value={selectedCategory}
        onChange={(ev) => setSelectedCategory(ev.target.value)}
        disabled={isAddingChecklist}
      />
      <DatePicker value={dueDate} onChange={setDueDate} label={t('DUE_DATE')} />
      <Flex alignItems={'center'} justifyContent={'center'}>
        <Button
          disabled={isAddingChecklist || !selectedCategory}
          onClick={onAddChecklist}
        >
          {isAddingChecklist ? <Loader size={iconSizes.small} /> : t('SUBMIT')}
        </Button>
      </Flex>
    </Flex>
  );
};
