import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { PatientOverviewCard } from './PatientOverviewCard';
import { PatientTreatmentHistoryTable } from './PatientTreatmentHistoryTable';
import { ScheduleCard } from './ScheduleCard';
import Card from 'src/components/display/Card/Card';
import { PatientDiagnosisDetails } from './PatientDiagnosis';
import { PatientFlags } from '../medicalHistory/PatientFlags';
import { Typography } from '@mui/material';
import { CheckListTable } from 'src/modules/ChecklistTable';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { AddChecklistMenu } from './AddChecklistMenu';
import AddIcon from '@mui/icons-material/Add';
import { usePopover } from 'src/components/components-api/PopoverProvider';
import MiniIconButton from 'src/components/display/MiniIconButton';
import { PatientCareTeam } from '../details/PatientCareTeam';

export const PatientOverview: FC<{}> = () => {
  const { t } = useTranslation();
  const { patientId } = useParams();

  const { openPopover } = usePopover();
  const { getPatientTasks } = usePatientsApi();

  const { data: tasks } = getPatientTasks({ patientId });
  return (
    <Box>
      <Helmet>
        <title>{t('PATIENT_OVERVIEW_PAGE_TITLE')}</title>
      </Helmet>
      <Flex flexDirection="column" maxWidth="100%" gap={spacings.xxlarge}>
        <Flex
          flexDirection={{
            xs: 'column',
            md: 'row'
          }}
          alignItems="stretch"
          gap={spacings.xxlarge}
          minWidth="100%"
        >
          <Box flex={1.5}>
            <Box marginBottom={spacings.large} width="100%">
              <PatientFlags patientId={patientId} />
            </Box>
            <PatientOverviewCard />
          </Box>

          <Flex
            flexDirection="column"
            flex="1"
            minWidth="450px"
            gap={spacings.xlarge}
          >
            <PatientCareTeam patientId={patientId} />
            <ScheduleCard />
          </Flex>
        </Flex>
        <Flex
          flexDirection={{
            xs: 'column',
            md: 'row'
          }}
          alignItems="stretch"
          gap={spacings.xxlarge}
          minWidth="100%"
        >
          <Box flex={1.5} paddingBottom={spacings.xxlarge}>
            <Card padding={spacings.large} height="100%" shadow>
              <PatientDiagnosisDetails />
            </Card>
          </Box>
          <Box flex={1} paddingBottom={spacings.xxlarge} minWidth="450px">
            <Card padding={spacings.large} height="100%" shadow>
              <PatientTreatmentHistoryTable />
            </Card>
          </Box>
        </Flex>

        <Box width="100%">
          <CheckListTable
            pageSize={10}
            tasks={tasks || []}
            toolbar={
              <Flex gap={spacings.large} alignItems="center">
                <Typography variant="h2">{t('TASKS')}</Typography>
                <MiniIconButton
                  id="add-tasks-button"
                  icon={<AddIcon />}
                  onClick={(ev) =>
                    openPopover({
                      children: (
                        <AddChecklistMenu
                          patientId={patientId}
                          anchorEl={ev.currentTarget}
                        />
                      ),
                      anchorEl: ev.currentTarget,
                      hideToolbar: true,
                      anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'bottom'
                      },
                      transformOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                      }
                    })
                  }
                />
              </Flex>
            }
          />
        </Box>
      </Flex>
    </Box>
  );
};
