import { FC } from 'react';
import dayjs from 'dayjs';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps
} from '@mui/x-date-pickers';
import Box from 'src/components/layout/Box';
import { BoxProps, SxProps, css, styled } from '@mui/material';
import { shadows, spacings } from 'src/components/styles/constants';
import { fonts } from 'src/components/styles/fonts';
import { Colors } from 'src/components/styles/colors';
import InputLabel from 'src/components/data-entry/InputLabel';
import { InputFieldProps } from '../InputField';

export enum DatePickerVariant {
  DEFAULT = 'default',
  TABLE_FILTER = 'tableFilter'
}
interface DatePickerProps
  extends Omit<MuiDatePickerProps<Date>, 'onChange'>,
    Pick<InputFieldProps, 'helperText' | 'error' | 'fullWidth'> {
  required?: boolean;
  onChange: (date: Date) => void;
  label?: string;
  labelSx?: SxProps;
  noBorder?: boolean;
  noShadow?: boolean;
  variant?: DatePickerVariant;
}

const StyledDatePicker = styled(MuiDatePicker)<DatePickerProps>`
  .MuiOutlinedInput-root {
    padding-right: ${spacings.small};
  }

  ${({ noBorder }) =>
    noBorder &&
    css`
      fieldset {
        border: none;
      }
    `}
  ${({ noShadow }) =>
    noShadow &&
    css`
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        box-shadow: unset;
      }
    `}
`;

type StyledDatePickerContainerProps = BoxProps & {
  variant?: DatePickerVariant;
};
const StyledDatePickerContainer = styled(
  ({
    variant: _variant = DatePickerVariant.DEFAULT,
    ...rest
  }: StyledDatePickerContainerProps) => <Box {...rest} />
)`
  ${({ variant }) => {
    switch (variant) {
      case DatePickerVariant.TABLE_FILTER:
        return css`
          .MuiInputBase-input {
            padding: 4px 0 ${spacings.small};
          }
          .MuiInputBase-root {
            margin-top: ${spacings.large};
            border-bottom: 1px solid rgba(0, 0, 0, 0.42);
            border-radius: 0;
          }
          .MuiInputLabel-root {
            transform: translate(0, -1.5px) scale(0.75);
          }
        `;
      case DatePickerVariant.DEFAULT:
      default:
        return css`
          .MuiInputBase-root {
            color: ${Colors.emperor};
            background-color: ${Colors.white};
            height: 40px;
          }

          .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: ${Colors.gray};
            border-width: 1pt;
            box-shadow: ${shadows.input};
          }

          .MuiSvgIcon-root {
            color: ${Colors.emperor};
          }

          .MuiFormControl-root.MuiTextField-root {
            width: 100%;
            border-color: black;

            &.Mui-error fieldset {
              border-color: ${Colors.redRibbon};
            }
          }
          .MuiOutlinedInput-notchedOutline {
            border-color: ${Colors.gray};
          }

          .MuiFormHelperText-root {
            margin: 3px ${spacings.none} ${spacings.none} ${spacings.none};
            color: ${Colors.gray};
            font: ${fonts.caption};
          }
        `;
    }
  }}
`;

const popperSx: SxProps = {
  '.MuiPickersDay-today': {
    backgroundColor: Colors.cupidAlpha25,
    color: Colors.black
  },
  '.MuiPickersDay-today:focus, .MuiPickersDay-today:focus-within': {
    backgroundColor: Colors.cupidAlpha25
  },
  '.MuiPickersDay-root.Mui-active': {
    backgroundColor: Colors.cupidAlpha50,
    color: Colors.black
  },
  '.MuiPickersDay-root:focus.Mui-active': {
    backgroundColor: Colors.cupidAlpha50,
    color: Colors.black
  },
  '.MuiPickersDay-root:hover': {
    backgroundColor: Colors.cupidAlpha25,
    color: Colors.black
  },
  '.MuiPickersDay-root.Mui-selected': {
    backgroundColor: Colors.cupidAlpha50,
    color: Colors.black
  },
  '.MuiPickersDay-root:focus.Mui-selected': {
    backgroundColor: Colors.cupidAlpha50,
    color: Colors.black
  }
};

const DatePicker: FC<DatePickerProps> = ({
  helperText,
  error,
  fullWidth,
  required = false,
  value: valueProp,
  variant = DatePickerVariant.DEFAULT,
  label,
  labelSx,
  ...otherProps
}) => {
  const valueDayJs = dayjs(valueProp);
  const value = valueProp && valueDayJs.isValid() ? valueDayJs.toDate() : null;

  return (
    <StyledDatePickerContainer
      width={fullWidth ? '100%' : 'auto'}
      variant={variant}
    >
      {label && variant !== DatePickerVariant.TABLE_FILTER && (
        <InputLabel
          sx={labelSx}
          label={label}
          error={error}
          required={required}
        />
      )}
      <Box width={'100%'}>
        <StyledDatePicker
          // this is MUI's format that is different from dayjs's format
          format={'MM/dd/yyyy'}
          value={value}
          label={variant === DatePickerVariant.TABLE_FILTER ? label : null}
          {...otherProps}
          slotProps={{
            textField: {
              helperText,
              // this differs from what we use in getDateFormat()
              placeholder: 'MM / DD / YYYY',
              error
            },
            popper: {
              sx: popperSx
            }
          }}
        />
      </Box>
    </StyledDatePickerContainer>
  );
};

export default DatePicker;
