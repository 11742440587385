import { styled } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import { Colors } from 'src/components/styles/colors';
import { spacings } from 'src/components/styles/constants';
import { weights } from 'src/components/styles/fonts';
import Typography from 'src/components/display/Typography/Typography';
import { usePopover } from 'src/components/components-api/PopoverProvider';
import { useParams } from 'react-router-dom';
import { DiagnosisCategory, SingleDiagnosis } from 'src/types/patient';
import usePatientsApi from '../../../hooks/usePatientsApi';
import dayjs from 'dayjs';
import { getDateFormat } from 'src/utils/dateAndTIme';
import { AddPatientDiagnosis } from './AddPatientDiagnosis/AddPatientDiagnosis';
import Loader from 'src/components/display/Loader/Loader';

const BoxWithBorder = styled(Box)`
  border-bottom: 1px solid ${Colors.mercury};
  padding-bottom: ${spacings.medium};
  margin-bottom: ${spacings.medium};
`;

const StyledMiniIconButton = styled(MiniIconButton)`
  padding: calc(${spacings.small} - ${spacings.xsmall}) !important;
`;

const DiagnosisRow: FC<{
  id: string;
  title: string;
  diagnoses: SingleDiagnosis[];
}> = ({ id, title, diagnoses }) => {
  return (
    <Flex width="100%" alignItems="flex-start">
      <Typography flex={1}>{title}</Typography>
      <Flex
        flex={3}
        flexDirection="column"
        id={id ? `${id}-rows-container` : undefined}
      >
        {diagnoses?.length ? (
          diagnoses.map((diagnosis, index) => (
            <Flex
              key={index}
              gap={spacings.small}
              id={id ? `${id}-row-container-${index}` : undefined}
            >
              <Typography
                id={id ? `${id}-row-value-${index}` : undefined}
                flex={3}
                fontWeight={weights.extraBold}
              >
                {diagnosis.value}
              </Typography>
              <Typography
                id={id ? `${id}-row-time-${index}` : undefined}
                flex={2}
              >
                {dayjs(diagnosis.createdAt).format(getDateFormat())}
              </Typography>
            </Flex>
          ))
        ) : (
          <Flex justifyContent="space-between">
            <Typography flex={3}>-</Typography>
            <Typography flex={2}>-</Typography>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export const PatientDiagnosisDetails: FC = () => {
  const { t } = useTranslation();
  const { openPopover } = usePopover();
  const { patientId } = useParams();
  const { getPatientDiagnosis } = usePatientsApi();

  const { data: patientDiagnosis, isLoading } = getPatientDiagnosis(patientId);

  const primaryDiagnosis = patientDiagnosis?.find(
    (diagnosis) => diagnosis.category === DiagnosisCategory.PRIMARY_DIAGNOSIS
  );
  const previousCycleObservations = patientDiagnosis?.find(
    (diagnosis) =>
      diagnosis.category === DiagnosisCategory.PREVIOUS_CYCLE_OBSERVATIONS
  );
  const generalHistory = patientDiagnosis?.find(
    (diagnosis) => diagnosis.category === DiagnosisCategory.GENERAL_HISTORY
  );

  const previousPregnancies = patientDiagnosis?.find(
    (diagnosis) => diagnosis.category === DiagnosisCategory.PREVIOUS_PREGNANCIES
  );

  return (
    <Box id="patient-diagnosis-card">
      <Flex flex={1}>
        <Box flex={10}>
          <BoxWithBorder>
            <Typography fontWeight={weights.extraBold}>
              {t('PRIMARY_DIAGNOSIS_AND_OBSERVATIONS').toUpperCase()}
            </Typography>
          </BoxWithBorder>
        </Box>
        <BoxWithBorder flex={4}>
          <Typography fontWeight={weights.extraBold}>
            {t('DATE_UPDATED')}
          </Typography>
        </BoxWithBorder>
        <BoxWithBorder flex={0.5}>
          <StyledMiniIconButton
            id="add-diagnosis-button"
            icon={<AddIcon />}
            onClick={(ev) =>
              openPopover({
                anchorEl: ev.currentTarget,
                header: t('DIAGNOSIS'),
                children: <AddPatientDiagnosis patientId={patientId} />,
                paperPadding: spacings.large,
                transformOrigin: {
                  horizontal: 'center',
                  vertical: 'top'
                },
                anchorOrigin: {
                  horizontal: 'center',
                  vertical: 'bottom'
                }
              })
            }
          />
        </BoxWithBorder>
      </Flex>
      {isLoading ? (
        <Flex justifyContent="center">
          <Loader />
        </Flex>
      ) : (
        <>
          <Flex marginBottom={spacings.medium}>
            <DiagnosisRow
              id="general-history-notes"
              title={t('GENERAL_HISTORY')}
              diagnoses={generalHistory?.values}
            />
          </Flex>
          <Flex marginBottom={spacings.medium}>
            <DiagnosisRow
              id="previous-cycle-notes"
              title={t('PREVIOUS_CYCLE_NOTES')}
              diagnoses={previousCycleObservations?.values}
            />
          </Flex>
          <Flex marginBottom={spacings.medium}>
            <DiagnosisRow
              id="primary-diagnosis-notes"
              title={t('PRIMARY_DIAGNOSIS')}
              diagnoses={primaryDiagnosis?.values}
            />
          </Flex>
          <Flex marginBottom={spacings.medium}>
            <DiagnosisRow
              id="previous-pregnancies-notes"
              title={t('PREVIOUS_PREGNANCIES')}
              diagnoses={previousPregnancies?.values}
            />
          </Flex>
        </>
      )}
    </Box>
  );
};
