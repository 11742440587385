import { useTranslation } from 'react-i18next';
import Avatar from 'src/components/display/Avatar';
import Typography from 'src/components/display/Typography';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import { Clinic } from 'src/types/clinic';
import { PatientPersonalInfo } from 'src/types/patient';
import { StaffMember } from 'src/types/staff';
import { UserTypes } from 'src/types/user';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';
import { getFullName } from 'src/utils/general';

export const getPrimaryPhysicianColumnRenderCell = ({
  row
}: {
  row: Partial<PatientPersonalInfo> & { clinic?: Clinic };
}) => {
  const staff: StaffMember | undefined = row.staff;

  const staffName = getFullName(staff);

  const { t } = useTranslation();
  const { getDefaultAvatar } = useGetDefaultAvatar();

  const avatar = getDefaultAvatar({
    userId: staff?.id,
    userType: UserTypes.staff,
    pictureId: staff?.pictureId
  });

  return (
    <Flex alignItems="center" gap={spacings.large} padding={spacings.large}>
      {staffName && <Avatar image={avatar} />}
      <Typography>{staffName || t('NO_PRIMARY_PHYSICIAN')}</Typography>
    </Flex>
  );
};
