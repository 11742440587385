import { FC, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePatientsApi from '../../../../hooks/usePatientsApi';
import { DiagnosisCategory, PatientDiagnosis } from 'src/types/patient';
import { Controller, useForm } from 'react-hook-form';
import Box from 'src/components/layout/Box/Box';
import { spacings } from 'src/components/styles/constants';
import Select from 'src/components/data-entry/Select/Select';
import Button from 'src/components/display/Button/Button';
import { usePopover } from 'src/components/components-api/PopoverProvider';
import Loader from 'src/components/display/Loader';
import ObservationView from './ObservationsView';
import Typography from 'src/components/display/Typography';
import { getDiagnosisCategory } from '../utils';
import { Icd10Chips } from '../../common/Icd10Chips';

export const AddPatientDiagnosis: FC<{ patientId: string }> = ({
  patientId
}) => {
  const { t } = useTranslation();
  const { createDiagnosis } = usePatientsApi();
  const { closePopover } = usePopover();

  const [currentCategory, setCurrentCategory] = useState<DiagnosisCategory>(
    DiagnosisCategory.GENERAL_HISTORY
  );
  const { mutate: handleCreateDiagnosis, isLoading: isCreatingDiagnosis } =
    createDiagnosis();

  const defaultValues: PatientDiagnosis = {
    category: DiagnosisCategory.GENERAL_HISTORY,
    values: [],
    patientId
  };

  const { control, formState, handleSubmit, getValues, setValue, watch } =
    useForm<PatientDiagnosis, unknown>({
      mode: 'onChange',
      defaultValues
    });

  const { errors } = formState;
  const category = watch('category');

  const sectionComponents: Record<DiagnosisCategory, ReactNode> =
    useMemo(() => {
      return {
        [DiagnosisCategory.PREVIOUS_CYCLE_OBSERVATIONS]: (
          <ObservationView control={control} getValues={getValues} />
        ),
        [DiagnosisCategory.PRIMARY_DIAGNOSIS]: (
          <ObservationView control={control} getValues={getValues} />
        ),
        [DiagnosisCategory.GENERAL_HISTORY]: (
          <Icd10Chips
            name="values"
            control={control}
            error={!!errors.values}
            helperText={errors?.values?.message}
            flattenValue
          />
        ),
        [DiagnosisCategory.PREVIOUS_PREGNANCIES]: (
          <ObservationView control={control} getValues={getValues} />
        )
      };
    }, [category, control]);

  const onSubmit = async (diagnosis: PatientDiagnosis) => {
    await handleCreateDiagnosis(diagnosis);
    closePopover();
  };

  return (
    <Box width={300}>
      <Typography variant="h6">{t('DIAGNOSIS_CODES_EXPLANATION')}</Typography>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box marginBottom={spacings.large} marginTop={spacings.medium}>
          <Controller
            name={'category'}
            control={control}
            rules={{
              required: t('PLEASE_CHOOSE_CATEGORY')
            }}
            render={({ field: { ref, onChange, ...field } }) => (
              <Select
                {...field}
                id="patient-diagnosis-category-select"
                inputRef={ref}
                label={t('PICK_SECTION')}
                error={!!errors?.category}
                helperText={errors?.category?.message}
                onChange={(ev) => {
                  onChange(ev);
                  setValue('values', []);
                  setCurrentCategory(getDiagnosisCategory(ev.target.value));
                }}
                defaultOption={t('PLEASE_CHOOSE_CATEGORY')}
                options={Object.entries(DiagnosisCategory).map(
                  ([label, value]) => ({
                    label: t(label),
                    value
                  })
                )}
              />
            )}
          />
        </Box>
        <Box height="300px" marginBottom={spacings.xxlarge}>
          {sectionComponents[currentCategory]}
        </Box>
        <Box margin="auto" width="50%" mt={'20px'}>
          <Button
            id="patient-diagnosis-submit-button"
            fullWidth
            type="submit"
            disabled={isCreatingDiagnosis}
          >
            {isCreatingDiagnosis ? <Loader /> : t('SUBMIT')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
