import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
  useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import usePatientsInsurancesApi from '../../../hooks/usePatientsInsurancesApi';
import {
  PatientInsurance,
  RelationshipToEnsured
} from '../../../types/patientInsurance';
import { YesOrNo } from '../../../types/global';
import Typography from '../../../components/display/Typography/Typography';
import InputField from '../../../components/data-entry/InputField/InputField';
import Select from '../../../components/data-entry/Select/Select';
import ImageUpload from '../../../components/data-entry/ImageUpload/ImageUpload';
import Box from '../../../components/layout/Box/Box';
import Button from '../../../components/display/Button/Button';
import { spacings } from '../../../components/styles/constants';
import Loader from '../../../components/display/Loader';
import Center from '../../../components/layout/Center';
import Flex from '../../../components/layout/Flex';
import { weights } from '../../../components/styles/fonts';
import DatePicker from '../../../components/data-entry/DatePicker/DatePicker';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import { PersonSex } from 'src/types/patient';
import { DataIndex, PatientInsurancesForm } from './types';
import styled from '@emotion/styled';

const StyledBox = styled(Box)`
  margin-bottom: ${spacings.xlarge};
  margin-top: ${spacings.xlarge};

  // reduce the width of the gap between two columns to keep StyledBox width equal to the columns width
  width: calc(50% - ${spacings.medium});
`;

const StyledFlexColumn = styled(Flex)`
  flex-direction: column;
  gap: ${spacings.xlarge};
`;

const StyledFlexRow = styled(Flex)<{
  gap?: string;
}>`
  gap: ${({ gap }) => gap ?? spacings.xlarge};
`;

const InsuredsDetails: FC<{
  index: number;
  control: Control<PatientInsurancesForm, any, PatientInsurancesForm>;
  errors: FieldErrors<PatientInsurancesForm>;
}> = ({ index, control, errors }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography fontWeight={weights.extraBold} variant="h2">
        {t('IF_INSURED_IS_NOT_SELF_OR_PARTNER')}
      </Typography>
      <StyledFlexRow marginTop={spacings.xlarge} marginBottom={spacings.xlarge}>
        <StyledFlexColumn flex={1}>
          <Controller
            name={`insurances.${index}.insuredName`}
            control={control}
            rules={{
              required: t('INSURED_NAME_REQUIRED')
            }}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('INSURED_NAME').toUpperCase()}
                placeholder={t('NAME_OF_INSURANCE_POLICY_HOLDER')}
                error={!!errors.insurances?.[index].insuredName}
                helperText={errors.insurances?.[index].insuredName?.message}
                fullWidth
                required
              />
            )}
          />
          <Controller
            name={`insurances.${index}.insuredDob`}
            control={control}
            rules={{
              required: t('INSURED_DOB_REQUIRED')
            }}
            render={({ field: { ref, ...field } }) => {
              const value = field.value as Date;
              return (
                <DatePicker
                  {...field}
                  value={value}
                  label={t('INSURED_DBO').toUpperCase()}
                  inputRef={ref}
                  error={!!errors.insurances?.[index].insuredDob}
                  helperText={errors.insurances?.[index].insuredDob?.message}
                  fullWidth
                  required
                />
              );
            }}
          />
        </StyledFlexColumn>
        <StyledFlexColumn flex={1}>
          <Controller
            name={`insurances.${index}.insuredGender`}
            control={control}
            rules={{
              required: t('INSURED_GENDER_REQUIRED')
            }}
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                defaultOption={t('INSURED_GENDER_PLACEHOLDER')}
                inputRef={ref}
                label={t('INSURED_GENDER').toUpperCase()}
                error={!!errors.insurances?.[index].insuredGender}
                helperText={errors.insurances?.[index].insuredGender?.message}
                onChange={(event) => field.onChange(event.target.value)}
                options={Object.entries(PersonSex).map(([_, value]) => ({
                  value,
                  label: value
                }))}
              />
            )}
          />
        </StyledFlexColumn>
      </StyledFlexRow>
      <StyledFlexRow flex={1} marginBottom={spacings.xxlarge}>
        <Controller
          name={`insurances.${index}.address`}
          control={control}
          rules={{
            required: t('INSURED_ADDRESS_REQUIRED')
          }}
          render={({ field: { ref, ...field } }) => (
            <InputField
              {...field}
              inputRef={ref}
              label={t('ADDRESS').toUpperCase()}
              placeholder={t('ADDRESS_PLACEHOLDER')}
              error={!!errors.insurances?.[index].address}
              helperText={errors.insurances?.[index].address?.message}
              fullWidth
              required
            />
          )}
        />
        <StyledFlexRow gap={spacings.small}>
          <StyledFlexColumn flex={2}>
            <Controller
              name={`insurances.${index}.city`}
              control={control}
              rules={{
                required: t('INSURED_CITY_REQUIRED')
              }}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('CITY_LABEL')}
                  placeholder={t('CITY_PLACEHOLDER')}
                  error={!!errors.insurances?.[index].city}
                  helperText={errors.insurances?.[index].city?.message}
                  fullWidth
                  required
                />
              )}
            />
          </StyledFlexColumn>
          <StyledFlexColumn flex={1}>
            <Controller
              name={`insurances.${index}.state`}
              control={control}
              rules={{
                required: t('INSURED_STATE_REQUIRED')
              }}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('STATE_LABEL')}
                  placeholder={t('STATE_PLACEHOLDER')}
                  error={!!errors.insurances?.[index].state}
                  helperText={errors.insurances?.[index].state?.message}
                  fullWidth
                  required
                />
              )}
            />
          </StyledFlexColumn>
          <StyledFlexColumn flex={1}>
            <Controller
              name={`insurances.${index}.zip`}
              control={control}
              rules={{
                required: t('INSURED_ZIP_REQUIRED')
              }}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('ZIP_LABEL')}
                  placeholder={t('ZIP_PLACEHOLDER')}
                  error={!!errors.insurances?.[index].zip}
                  helperText={errors.insurances?.[index].zip?.message}
                  fullWidth
                  required
                />
              )}
            />
          </StyledFlexColumn>
          <StyledFlexColumn flex={1}>
            <Controller
              name={`insurances.${index}.country`}
              control={control}
              rules={{
                required: t('INSURED_COUNTRY_REQUIRED')
              }}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('COUNTRY_LABEL')}
                  placeholder={t('COUNTRY_PLACEHOLDER')}
                  error={!!errors.insurances?.[index].country}
                  helperText={errors.insurances?.[index].country?.message}
                  fullWidth
                  required
                />
              )}
            />
          </StyledFlexColumn>
        </StyledFlexRow>
      </StyledFlexRow>
    </>
  );
};

const SelectNumberOfInsurances: FC<{
  patientInsuranceCount: number;
  setPageCount: (count: number) => void;
}> = ({ patientInsuranceCount, setPageCount }) => {
  const [numberOfInsurances, setNumberOfInsurances] = useState<number>(
    patientInsuranceCount || null
  );

  const { t } = useTranslation();

  const handlePageCountChange = (event) => {
    const selectedCount = parseInt(event.target.value, 10);

    setPageCount(selectedCount);
    setNumberOfInsurances(selectedCount);
  };

  const options = useMemo(
    () =>
      Object.entries({
        [DataIndex.PRIMARY]: '1',
        [DataIndex.SECONDARY]: '2',
        [DataIndex.TERTIARY]: '3'
      }).map(([key, value]) => {
        return {
          value: key,
          label: value,
          disabled:
            patientInsuranceCount && parseInt(key) < patientInsuranceCount
        };
      }),
    []
  );

  return (
    <StyledBox>
      <Select
        value={numberOfInsurances}
        label={t('HOW_MANY_INSURANCES_PATIENT_HAVE').toUpperCase()}
        onChange={(event) => {
          handlePageCountChange(event);
        }}
        defaultOption={t('DOES_NOT_HAVE_INSURANCE_COVERAGE')}
        options={options}
      />
    </StyledBox>
  );
};

const patientInsuranceFormData = ({
  patientInsurances,
  patientId
}: {
  patientInsurances: PatientInsurance[];
  patientId: string;
}) => {
  return patientInsurances.map((insurance) => ({
    id: insurance.id ?? '',
    patientId: insurance.patientId ?? patientId,
    relationshipToInsured: insurance.relationshipToInsured ?? null,
    registeredPartnerForTreatment:
      insurance.registeredPartnerForTreatment ?? null,
    insuranceType: insurance.insuranceType ?? null,
    insuranceId: insurance.insuranceId ?? '',
    insurancePlanName: insurance.insurancePlanName ?? '',
    insurancePolicyGroupOrFecaNumber:
      insurance.insurancePolicyGroupOrFecaNumber ?? '',
    employerName: insurance.employerName ?? '',
    insuredName: insurance.insuredName ?? '',
    insuredGender: insurance.insuredGender ?? null,
    insuredDob: insurance.insuredDob ?? null,
    address: insurance.address ?? '',
    city: insurance.city ?? '',
    zip: insurance.zip ?? '',
    country: insurance.country ?? '',
    state: insurance.state ?? '',
    imageIdCardFrontFile:
      insurance.imageIdCardFrontFile ?? insurance.imageIdCardFront
        ? [{ url: insurance.imageIdCardFront }]
        : null,
    imageIdCardBackFile:
      insurance.imageIdCardBackFile ?? insurance.imageIdCardBack
        ? [{ url: insurance.imageIdCardBack }]
        : null,
    imageIdCardFront: insurance.imageIdCardFront ?? '',
    imageIdCardBack: insurance.imageIdCardBack ?? ''
  }));
};

export const EditPatientInsurancesInfo: FC<{ patientId: string }> = ({
  patientId
}) => {
  const { t } = useTranslation();
  const { closeDialog } = useDialog();
  const {
    getInsuranceTypes,
    getPatientInsurances,
    createPatientInsurance,
    updatePatientInsurance
  } = usePatientsInsurancesApi();

  const { data: insuranceTypes, isLoading: isLoadingInsuranceTypes } =
    getInsuranceTypes();
  const {
    data: patientInsurances,
    isLoading,
    refetch
  } = getPatientInsurances(patientId);
  const {
    mutate,
    isLoading: isCreatingPatientInsurance,
    isSuccess: isCreatedSuccessfully
  } = createPatientInsurance();
  const {
    mutate: updateInsurance,
    isSuccess: isUpdatedSuccessfully,
    isLoading: isUpdatingPatientInsurance
  } = updatePatientInsurance();

  const [pageCount, setPageCount] = useState<number>(
    patientInsurances?.length || 1 // at least one page
  );

  const [currentStep, setCurrentStep] = useState<number>(1);

  const insuranceSkeletonObj = {
    patientId: patientId,
    relationshipToInsured: null,
    registeredPartnerForTreatment: null,
    insuranceType: null,
    insuranceId: '',
    insurancePlanName: '',
    insurancePolicyGroupOrFecaNumber: '',
    employerName: '',
    insuredName: '',
    insuredGender: null,
    insuredDob: null,
    address: '',
    city: '',
    zip: '',
    country: '',
    state: '',
    imageIdCardFrontFile: null,
    imageIdCardBackFile: null,
    imageIdCardFront: '',
    imageIdCardBack: ''
  };

  const defaultValues: PatientInsurancesForm = useMemo(
    () => ({
      insurances: patientInsurances?.length
        ? patientInsuranceFormData({ patientInsurances, patientId })
        : Array.from({ length: pageCount }, () => insuranceSkeletonObj)
    }),
    [pageCount, patientInsurances]
  );

  const { control, handleSubmit, watch, formState, reset } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'insurances'
  });

  const { errors } = formState;
  const updatedProps = watch();

  const onSubmit = async (details: PatientInsurancesForm) => {
    const currentInsurance = details.insurances[currentStep - 1];

    if (currentStep < details.insurances.length) {
      setCurrentStep(currentStep + 1);
    }

    if (!currentInsurance.id) {
      await mutate(currentInsurance);
    } else {
      if (
        !isEqual(defaultValues.insurances[currentStep - 1], currentInsurance) &&
        !isEqual(insuranceSkeletonObj, currentInsurance)
      ) {
        await updateInsurance(currentInsurance);
      }
    }
  };

  useEffect(() => {
    const isLastStep = currentStep === pageCount;
    const isSuccess = isCreatedSuccessfully || isUpdatedSuccessfully;
    const currentInsuranceObj = updatedProps.insurances[currentStep - 1];
    const isCurrentInsuranceFieldsEmpty = isEqual(
      insuranceSkeletonObj,
      currentInsuranceObj
    );

    if (isSuccess && isLastStep && !isCurrentInsuranceFieldsEmpty) {
      closeDialog();
      refetch();
    }
  }, [isCreatedSuccessfully, isUpdatedSuccessfully, currentStep, pageCount]);

  useEffect(() => {
    const currentInsuranceCount = fields.length;
    const hasMorePagesThanCurrentInsurances = pageCount > currentInsuranceCount;
    const hasFewerPagesThanCurrentInsurances =
      pageCount < currentInsuranceCount;

    if (hasMorePagesThanCurrentInsurances) {
      const fieldsToAddLength = pageCount - currentInsuranceCount;

      // Add insurance object if pageCount is greater than currentInsuranceCount
      const newFields = Array.from(
        { length: fieldsToAddLength },
        () => insuranceSkeletonObj
      );

      newFields.forEach((field) => append(field));
    } else if (hasFewerPagesThanCurrentInsurances) {
      // Remove insurance object if pageCount is less than currentInsuranceCount
      for (let i = currentInsuranceCount - 1; i >= pageCount; i--) {
        remove(i);
      }
    }
  }, [pageCount, fields, append, remove, defaultValues]);

  useEffect(() => {
    reset(defaultValues);
  }, []);

  if (isLoading || isLoadingInsuranceTypes) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Box>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, index) => {
          const currentInsurance = updatedProps.insurances[index];
          const isNotSelfInsured =
            currentInsurance.relationshipToInsured &&
            currentInsurance.relationshipToInsured !==
              RelationshipToEnsured.SELF;
          const isRegisteredPartner =
            currentInsurance.registeredPartnerForTreatment;
          const isLastStep = currentStep === pageCount;

          if (index + 1 === currentStep) {
            return (
              <Fragment key={`${field.id}-${index}`}>
                {currentStep === 1 && (
                  <SelectNumberOfInsurances
                    patientInsuranceCount={patientInsurances?.length}
                    setPageCount={setPageCount}
                  />
                )}
                <StyledFlexRow key={field.id}>
                  <StyledFlexColumn
                    flex={1}
                    marginBottom={spacings.xxlarge}
                    marginTop={currentStep > 1 ? spacings.xlarge : 'none'}
                  >
                    <Controller
                      name={`insurances.${index}.relationshipToInsured`}
                      control={control}
                      rules={{
                        required: t('RELATIONSHIP_TO_INSURED_PLACEHOLDER')
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <Select
                          {...field}
                          defaultOption={t(
                            'RELATIONSHIP_TO_INSURED_PLACEHOLDER'
                          )}
                          inputRef={ref}
                          label={t('RELATION_TO_INSURED').toUpperCase()}
                          error={
                            !!errors.insurances?.[index].relationshipToInsured
                          }
                          helperText={
                            errors.insurances?.[index].relationshipToInsured
                              ?.message
                          }
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          options={Object.entries(RelationshipToEnsured).map(
                            ([_, value]) => ({
                              value,
                              label: t(value)
                            })
                          )}
                        />
                      )}
                    />
                    <Controller
                      name={`insurances.${index}.insuranceType`}
                      control={control}
                      rules={{
                        required: t('INSURANCE_TYPE_PLACEHOLDER')
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <Select
                          {...field}
                          defaultOption={t('INSURANCE_TYPE_PLACEHOLDER')}
                          inputRef={ref}
                          label={t('INSURANCE_TYPE').toUpperCase()}
                          error={!!errors.insurances?.[index].insuranceType}
                          helperText={
                            errors.insurances?.[index].insuranceType?.message
                          }
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          options={Object.entries(insuranceTypes).map(
                            ([_, value]) => ({ value, label: value })
                          )}
                        />
                      )}
                    />
                    <Controller
                      name={`insurances.${index}.insurancePolicyGroupOrFecaNumber`}
                      control={control}
                      rules={{
                        required: t(
                          'INSURANCE_POLICY_OR_FECA_NUMBER_PLACEHOLDER'
                        )
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <InputField
                          {...field}
                          inputRef={ref}
                          label={t(
                            'INSURANCE_POLICY_OR_FECA_NUMBER'
                          ).toUpperCase()}
                          placeholder={t(
                            'INSURANCE_POLICY_OR_FECA_NUMBER_PLACEHOLDER'
                          )}
                          error={
                            !!errors.insurances?.[index]
                              .insurancePolicyGroupOrFecaNumber
                          }
                          helperText={
                            errors.insurances?.[index]
                              .insurancePolicyGroupOrFecaNumber?.message
                          }
                          fullWidth
                          required
                        />
                      )}
                    />
                  </StyledFlexColumn>
                  <StyledFlexColumn
                    flex={1}
                    marginBottom={spacings.xxlarge}
                    marginTop={currentStep > 1 ? spacings.xlarge : 'none'}
                  >
                    {isNotSelfInsured && (
                      <Controller
                        name={`insurances.${index}.registeredPartnerForTreatment`}
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <Select
                            {...field}
                            defaultOption={t('IS_THIS_YOUR_REGISTER_PARTNER')}
                            inputRef={ref}
                            label={t(
                              'IS_THIS_YOUR_REGISTER_PARTNER'
                            ).toUpperCase()}
                            error={
                              !!errors.insurances?.[index]
                                .registeredPartnerForTreatment
                            }
                            helperText={
                              errors.insurances?.[index]
                                .registeredPartnerForTreatment?.message
                            }
                            value={field.value ? YesOrNo.YES : YesOrNo.NO}
                            onChange={(event) => {
                              const eventValue: string = event.target.value;
                              field.onChange(eventValue === YesOrNo.YES);
                            }}
                            options={Object.entries(YesOrNo).map(
                              ([label, value]) => ({
                                label: t(label),
                                value
                              })
                            )}
                          />
                        )}
                      />
                    )}
                    <Controller
                      name={`insurances.${index}.insuranceId`}
                      control={control}
                      rules={{
                        required: t('INSURANCE_ID_REQUIRED')
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <InputField
                          {...field}
                          inputRef={ref}
                          label={t('INSURANCE_ID').toUpperCase()}
                          placeholder={t('INSURANCE_ID_PLACEHOLDER')}
                          error={!!errors.insurances?.[index].insuranceId}
                          helperText={
                            errors.insurances?.[index].insuranceId?.message
                          }
                          fullWidth
                          required
                        />
                      )}
                    />
                    <Controller
                      name={`insurances.${index}.insurancePlanName`}
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <InputField
                          {...field}
                          inputRef={ref}
                          label={t('PLAN_NAME').toUpperCase()}
                          placeholder={t('INSURANCE_PLAN_NAME_PLACEHOLDER')}
                          error={!!errors.insurances?.[index].insurancePlanName}
                          helperText={
                            errors.insurances?.[index].insurancePlanName
                              ?.message
                          }
                          fullWidth
                        />
                      )}
                    />
                    <Controller
                      name={`insurances.${index}.employerName`}
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <InputField
                          {...field}
                          inputRef={ref}
                          label={t('EMPLOYER_NAME').toUpperCase()}
                          placeholder={t('EMPLOYERS_NAME_PLACEHOLDER')}
                          error={!!errors.insurances?.[index].employerName}
                          helperText={
                            errors.insurances?.[index].employerName?.message
                          }
                          fullWidth
                        />
                      )}
                    />
                  </StyledFlexColumn>
                </StyledFlexRow>
                {isNotSelfInsured && !isRegisteredPartner && (
                  <InsuredsDetails
                    index={index}
                    control={control}
                    errors={errors}
                  />
                )}
                <Typography fontWeight={weights.extraBold} variant="h2">
                  {t('UPLOAD_INSURANCE_CARD')}
                </Typography>
                <StyledFlexRow
                  marginBottom={spacings.xlarge}
                  marginTop={spacings.xxlarge}
                >
                  <StyledFlexColumn flex={1}>
                    <Typography fontWeight={weights.extraBold}>
                      {t('FRONT_OF_CARD').toUpperCase()}
                    </Typography>
                    <Controller
                      name={`insurances.${index}.imageIdCardFrontFile`}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <ImageUpload
                          isDirectSelectMode
                          limit={1}
                          value={value}
                          accept={{
                            'image/png': ['.png'],
                            'image/jpeg': ['.jpeg', '.jpg'],
                            pdf: ['.pdf']
                          }}
                          onAddNewFiles={(newFiles) => {
                            onChange([...newFiles]);
                          }}
                          onRemoveFile={(imageIndexToRemove) => {
                            onChange(
                              value.filter(
                                (_, index) => index !== imageIndexToRemove
                              )
                            );
                          }}
                        />
                      )}
                    />
                  </StyledFlexColumn>
                  <StyledFlexColumn flex={1}>
                    <Typography fontWeight={weights.extraBold}>
                      {t('BACK_OF_CARD').toUpperCase()}
                    </Typography>
                    <Controller
                      name={`insurances.${index}.imageIdCardBackFile`}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <ImageUpload
                          isDirectSelectMode
                          limit={1}
                          value={value}
                          accept={{
                            'image/png': ['.png'],
                            'image/jpeg': ['.jpeg', '.jpg'],
                            pdf: ['.pdf']
                          }}
                          onAddNewFiles={(newFiles) => onChange([...newFiles])}
                          onRemoveFile={(imageIndexToRemove) => {
                            onChange(
                              value.filter(
                                (_, index) => index !== imageIndexToRemove
                              )
                            );
                          }}
                        />
                      )}
                    />
                  </StyledFlexColumn>
                </StyledFlexRow>
                <Button
                  fullWidth
                  disabled={
                    isCreatingPatientInsurance || isUpdatingPatientInsurance
                  }
                  type="submit"
                >
                  {!isLastStep ? t('NEXT') : t('SUBMIT')}
                </Button>
              </Fragment>
            );
          } else {
            return null;
          }
        })}
      </form>
    </Box>
  );
};
