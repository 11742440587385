import { BusyTime } from './../../../types/appointment';
export const getUnifiedBusyTimes = (
  busyTimes: BusyTime[]
): Partial<BusyTime>[] => {
  const sortedBusyTimes = busyTimes.sort(
    ({ startTime: a }: BusyTime, { startTime: b }: BusyTime) =>
      a.isBefore(b) ? -1 : 1
  );

  const unifiedBusyTimes: Partial<BusyTime>[] = [];

  for (let i = 0; i < sortedBusyTimes.length; i++) {
    const { startTime, endTime } = sortedBusyTimes[i];

    let currentEndTime = endTime;

    for (let j = i + 1; j < sortedBusyTimes.length; j++) {
      const { startTime, endTime } = sortedBusyTimes[j];
      if (
        startTime.isSameOrBefore(currentEndTime, 'minute') &&
        endTime.isAfter(currentEndTime)
      ) {
        currentEndTime = endTime;
      }

      if (startTime.isAfter(currentEndTime)) {
        i = j - 1;
        break;
      }

      i = j;
    }

    unifiedBusyTimes.push({
      startTime,
      endTime: currentEndTime,
      status: 'busy'
    });
  }

  return unifiedBusyTimes;
};
