import { Chip as MuiChip } from '@mui/material';
import { styled } from '@mui/material';
import { iconSizes, spacings } from 'src/components/styles/constants';
import { Colors } from 'src/components/styles/colors';
import { fonts } from 'src/components/styles/fonts';

const Chip = styled(MuiChip)`
  padding-inline: ${spacings.xlarge};
  background-color: ${Colors.alto};
  color: ${Colors.emperor};
  height: auto;
  font: ${fonts.badge};

  .MuiChip-label {
    padding: 0;
    padding-block: ${spacings.small};
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .MuiSvgIcon-root {
    color: ${Colors.emperor};
    font-size: ${iconSizes.small};
    padding-inline-start: ${spacings.xlarge};
    margin: 0;

    &:hover {
      color: ${Colors.emperor};
    }
  }
`;

export default Chip;
