import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loader from 'src/components/display/Loader/Loader';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import { iconSizes, spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography/Typography';
import { useTranslation } from 'react-i18next';
import { weights } from 'src/components/styles/fonts';
import {
  GridColDef,
  GridEditInputCell,
  GridRenderEditCellParams
} from '@mui/x-data-grid-premium';
import { GeneticStatus } from 'src/types/patient';
import { validateIsNumberOrStringNumber } from 'src/utils/general';
import Select from 'src/components/data-entry/Select/Select';
import { styled } from '@mui/material';
import { Colors } from 'src/components/styles/colors';
import Center from 'src/components/layout/Center/Center';
import {
  StyledTable,
  StyledTypesTable
} from '../gameteThawTable/GameteThawTable';
import useEggsEmbryos from 'src/hooks/useEggsEmbryos';
import {
  EggAndEmbryo,
  EggsAndEmbryosReport,
  Fate,
  NotAliveSince
} from 'src/types/eggAndEmbryo';

interface GridRowDef {
  id: string;
  type: TableRowType;
  percentage?: number;
  [key: number]: string | number;
}

enum TableRowType {
  ID = 'ID',
  FOLLICLES = 'follicles',
  EGGS_COLLECTED = 'eggs collected',
  EGGS_MATURE = 'eggs mature',
  EGGS_INSEMINATED = 'eggs inseminated',
  DAY_ONE = 'day1',
  DAY_TWO = 'day2',
  DAY_THREE = 'day3',
  DAY_FOUR = 'day4',
  DAY_FIVE = 'day5',
  DAY_SIX = 'day6',
  DAY_SEVEN = 'day7',
  PLOIDY = 'ploidy',
  FATE = 'fate',
  STRAW = 'straw',
  LOCATION = 'location'
}

const getCellBackgroundColor = (
  notLiveSince: NotAliveSince,
  rowType: TableRowType
): string => {
  const grayCellsRowType = [
    TableRowType.FOLLICLES,
    TableRowType.EGGS_COLLECTED,
    TableRowType.EGGS_INSEMINATED,
    TableRowType.EGGS_MATURE,
    TableRowType.ID
  ];

  const bottomRowsTypes = [
    TableRowType.FATE,
    TableRowType.PLOIDY,
    TableRowType.STRAW,
    TableRowType.LOCATION
  ];

  if (grayCellsRowType.includes(rowType)) {
    return Colors.mercury;
  }

  if (bottomRowsTypes.includes(rowType)) {
    return notLiveSince ? Colors.mineShaft : Colors.dawnPink;
  }

  if (!notLiveSince) {
    return Colors.dawnPink;
  }

  let isDead = false;

  switch (notLiveSince) {
    case NotAliveSince.DAY_ONE:
      isDead = true;
      break;
    case NotAliveSince.DAY_TWO:
      isDead = rowType !== TableRowType.DAY_ONE;
      break;
    case NotAliveSince.DAY_THREE:
      isDead =
        rowType !== TableRowType.DAY_ONE && rowType !== TableRowType.DAY_TWO;
      break;
    case NotAliveSince.DAY_FOUR:
      isDead =
        rowType !== TableRowType.DAY_ONE &&
        rowType !== TableRowType.DAY_TWO &&
        rowType !== TableRowType.DAY_THREE;
      break;
    case NotAliveSince.DAY_FIVE:
      isDead =
        rowType !== TableRowType.DAY_ONE &&
        rowType !== TableRowType.DAY_TWO &&
        rowType !== TableRowType.DAY_THREE &&
        rowType !== TableRowType.DAY_FOUR;
      break;
    case NotAliveSince.DAY_SIX:
      isDead =
        rowType !== TableRowType.DAY_ONE &&
        rowType !== TableRowType.DAY_TWO &&
        rowType !== TableRowType.DAY_THREE &&
        rowType !== TableRowType.DAY_FOUR &&
        rowType !== TableRowType.DAY_FIVE;
      break;
    default:
      isDead =
        rowType !== TableRowType.DAY_ONE &&
        rowType !== TableRowType.DAY_TWO &&
        rowType !== TableRowType.DAY_THREE &&
        rowType !== TableRowType.DAY_FOUR &&
        rowType !== TableRowType.DAY_FIVE &&
        rowType !== TableRowType.DAY_SIX;
  }

  return isDead ? Colors.mineShaft : Colors.dawnPink;
};

const getTotalAndPercentageToDisplay = (
  rowType: TableRowType,
  {
    cryo,
    euploid,
    inseminated,
    mature,
    dayFive,
    dayFour,
    dayOne,
    daySeven,
    daySix,
    dayThree,
    dayTwo,
    totalNumberOfFollicles,
    collected
  }: EggsAndEmbryosReport
): { total?: number; percentage?: number } => {
  switch (rowType) {
    case TableRowType.ID:
      return {};
    case TableRowType.FOLLICLES:
      return { total: totalNumberOfFollicles };
    case TableRowType.EGGS_COLLECTED:
      return collected;
    case TableRowType.EGGS_MATURE:
      return mature;
    case TableRowType.EGGS_INSEMINATED:
      return inseminated;
    case TableRowType.DAY_ONE:
      return dayOne;
    case TableRowType.DAY_TWO:
      return dayTwo;
    case TableRowType.DAY_THREE:
      return dayThree;
    case TableRowType.DAY_FOUR:
      return dayFour;
    case TableRowType.DAY_FIVE:
      return dayFive;
    case TableRowType.DAY_SIX:
      return daySix;
    case TableRowType.DAY_SEVEN:
      return daySeven;
    case TableRowType.PLOIDY:
      return euploid;
    case TableRowType.FATE:
      return {
        total: cryo.total
      };
  }
};

const getCellContent = (
  rowType: TableRowType,
  {
    day1,
    day2,
    day3,
    day4,
    day5,
    day6,
    day7,
    geneticStatus,
    fate,
    id,
    strawNum,
    location
  }: EggAndEmbryo
): string | number => {
  switch (rowType) {
    case TableRowType.ID:
      return id;
    case TableRowType.FOLLICLES:
    case TableRowType.EGGS_COLLECTED:
    case TableRowType.EGGS_MATURE:
    case TableRowType.EGGS_INSEMINATED:
      return '';
    case TableRowType.DAY_ONE:
      return day1 || '';
    case TableRowType.DAY_TWO:
      return day2;
    case TableRowType.DAY_THREE:
      return day3;
    case TableRowType.DAY_FOUR:
      return day4;
    case TableRowType.DAY_FIVE:
      return day5;
    case TableRowType.DAY_SIX:
      return day6;
    case TableRowType.DAY_SEVEN:
      return day7;
    case TableRowType.PLOIDY:
      return geneticStatus;
    case TableRowType.FATE:
      return fate;
    case TableRowType.STRAW:
      return strawNum;
    case TableRowType.LOCATION:
      return location;
  }
};

export const StyledDefaultInputWrapper = styled(Box)`
  .MuiInputBase-root input {
    padding: 0;
  }
`;

export const StyledSelect = styled(Select)`
  svg {
    right: 2px;
  }
`;

const CustomEditComponent: FC<{ params: GridRenderEditCellParams }> = ({
  params
}) => {
  const { t } = useTranslation();

  const {
    row: { type },
    api,
    id,
    field
  } = params;

  const handleSelectUpdateValue = async (newValue: string) => {
    await api.setEditCellValue({
      id,
      field,
      value: newValue
    });
    api.stopCellEditMode({
      field,
      id
    });
  };

  switch (type) {
    case TableRowType.PLOIDY:
      return (
        <StyledSelect
          options={Object.entries(GeneticStatus).map(([key, value]) => ({
            label: t(key),
            value
          }))}
          onChange={async (ev) => handleSelectUpdateValue(ev.target.value)}
        />
      );
    case TableRowType.FATE:
      return (
        <StyledSelect
          options={Object.entries(Fate).map(([key, value]) => ({
            label: t(key),
            value
          }))}
          onChange={async (ev) => handleSelectUpdateValue(ev.target.value)}
        />
      );
    default:
      return (
        <StyledDefaultInputWrapper>
          <GridEditInputCell {...params} />
        </StyledDefaultInputWrapper>
      );
  }
};

export const EggAndEmbryoTable: FC = () => {
  const { patientId, cycleId } = useParams();
  const [editingCell, setEditingCell] = useState<string>(null);

  const { updateEgg } = useEggsEmbryos();
  const { getEggsAndEmbryosReport } = useEggsEmbryos();
  const { t } = useTranslation();

  const {
    data: eggAndEmbryoReport,
    isLoading,
    isPreviousData
  } = getEggsAndEmbryosReport(patientId, cycleId, {
    enabled: !!cycleId && !!patientId
  });
  const { mutate: handleUpdateEgg } = updateEgg();

  const { eggs } = eggAndEmbryoReport || {};

  const rows: GridRowDef[] = useMemo(() => {
    if (!eggs) return [];

    return Object.values(TableRowType).map((rowType: TableRowType, index) => ({
      id: `${index}`,
      type: rowType,
      ...getTotalAndPercentageToDisplay(rowType, eggAndEmbryoReport),
      ...eggs.reduce((acc, egg) => {
        acc[egg.id] = getCellContent(rowType, egg) || '';
        return acc;
      }, {})
    }));
  }, [eggAndEmbryoReport]);

  const rowTypesColumns: GridColDef<GridRowDef>[] = useMemo(
    () => [
      {
        field: 'type',
        headerName: '',
        width: 170,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <Box paddingLeft={spacings.medium}>
              <Typography
                fontWeight={weights.extraBold}
                textTransform="uppercase"
              >
                {t(row.type)}
              </Typography>
            </Box>
          );
        }
      }
    ],
    [eggs]
  );
  const columns: GridColDef<GridRowDef>[] = useMemo(
    () => [
      {
        field: 'total',
        headerName: '#',
        renderHeader: ({ colDef }) => (
          <Typography>{colDef.headerName}</Typography>
        ),
        align: 'center',
        headerAlign: 'center',
        width: 30,
        minWidth: 30,
        sortable: false
      },
      {
        field: 'percentage',
        headerName: '%',
        renderHeader: ({ colDef }) => (
          <Typography>{colDef.headerName}</Typography>
        ),
        align: 'center',
        headerAlign: 'center',
        width: 45,
        sortable: false,
        renderCell: ({ row }) => {
          return validateIsNumberOrStringNumber(row.percentage)
            ? row.percentage?.toFixed() + '%'
            : '';
        }
      },
      ...(eggs?.map(({ id }, index) => {
        return {
          field: `${id}`,
          sortable: false,
          headerName: `${index + 1}`.padStart(2, '0'),
          renderHeader: ({ colDef }) => (
            <Typography>{colDef.headerName}</Typography>
          ),
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          width: 45,
          editable: true,
          renderEditCell: (params) => <CustomEditComponent params={params} />,
          renderCell: ({ colDef, value, field, row }) => {
            const egg = eggs.find(({ id }) => id === field);
            const cellBgColor = getCellBackgroundColor(
              egg.notLiveSince,
              row.type
            );

            return (
              <Box bgcolor={cellBgColor} width="100%" height="100%">
                <Box title={value}>
                  <Typography
                    noWrap
                    color={
                      cellBgColor === Colors.mineShaft
                        ? Colors.white
                        : undefined
                    }
                    fontWeight={weights.extraBold}
                    align={colDef.align}
                  >
                    {value}
                  </Typography>
                </Box>
              </Box>
            );
          }
        } as GridColDef;
      }) || [])
    ],
    [eggs]
  );

  if (isLoading || isPreviousData)
    return (
      <Flex height="100%" alignItems="center" justifyContent="center">
        <Loader size={iconSizes.xlarge} />
      </Flex>
    );

  const handleEditCell = async (
    rowType: TableRowType,
    newValue: string | number
  ) => {
    await handleUpdateEgg({
      patientId,
      cycleId,
      eggId: editingCell,
      egg: { [rowType]: newValue }
    });
  };

  return !eggs?.length ? (
    <Center height="100%">
      <Typography fontWeight={weights.extraBold}>{t('NO_DATA')}</Typography>
    </Center>
  ) : (
    <Flex width={'100%'}>
      <StyledTypesTable
        columns={rowTypesColumns}
        rows={rows}
        variant="grid"
        autoHeight
        hideFooter
        paginationModel={{ pageSize: rows.length, page: 0 }}
        columnHeaderHeight={25}
        disableColumnMenu
      />
      <Box overflow={'auto'} flex={1}>
        <StyledTable
          columns={columns}
          rows={rows}
          variant="grid"
          autoHeight
          hideFooter
          paginationModel={{ pageSize: rows.length, page: 0 }}
          columnHeaderHeight={25}
          disableColumnMenu
          processRowUpdate={async (newRow, oldRow) => {
            if (newRow[editingCell] === oldRow[editingCell]) return newRow;

            await handleEditCell(newRow.type, newRow[editingCell]);
            return newRow;
          }}
          onCellEditStart={(props) => {
            setEditingCell(props.field);
          }}
          editMode="cell"
          isCellEditable={({ row }: { row: GridRowDef }) =>
            row.type !== TableRowType.ID &&
            row.type !== TableRowType.FOLLICLES &&
            row.type !== TableRowType.EGGS_COLLECTED &&
            row.type !== TableRowType.EGGS_INSEMINATED &&
            row.type !== TableRowType.EGGS_MATURE
          }
        />
      </Box>
    </Flex>
  );
};
