import { FC } from 'react';
import Box from '../../components/layout/Box';
import { PatientBillingInfoCard } from './PatientBillingInfoCard';
import { useParams } from 'react-router-dom';
import Card from '../../components/display/Card';
import Typography from '../../components/display/Typography';
import { weights } from '../../components/styles/fonts';
import { useTranslation } from 'react-i18next';
import { InvoicesTable } from './InvoicesTable';
import useBilling from '../../hooks/useBilling';
import { spacings } from '../../components/styles/constants';

export const PatientBilling: FC = () => {
  const { patientId } = useParams();
  const { t } = useTranslation();

  const { getInvoices } = useBilling();

  const {
    data: invoices,
    isLoading: isLoadingInvoices,
    isFetching: isFetchingInvoices
  } = getInvoices(undefined, patientId);

  const isLoading = isLoadingInvoices || isFetchingInvoices;
  return (
    <Box>
      <PatientBillingInfoCard patientId={patientId} />
      <Card marginTop={spacings.large}>
        <Box padding={spacings.xlarge}>
          <Typography variant="h1" fontWeight={weights.extraBold}>
            {t('RECENT_INVOICES')}
          </Typography>
        </Box>
        <InvoicesTable invoices={invoices} isLoading={isLoading} />
      </Card>
    </Box>
  );
};
