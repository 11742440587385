import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'src/components/display/Card';
import Button from 'src/components/display/Button';
import {
  containerSizes,
  iconSizes,
  spacings
} from 'src/components/styles/constants';
import Flex from 'src/components/layout/Flex';
import { useParams, useNavigate } from 'react-router-dom';
import Typography from 'src/components/display/Typography';
import { Helmet } from 'react-helmet-async';
import usePatientsApi from '../../../hooks/usePatientsApi';
import Loader from 'src/components/display/Loader/Loader';
import { getFullName } from 'src/utils/general';
import { patientRoutes } from 'src/router/routes';

export const ConfirmEmailReceived: FunctionComponent<{}> = ({}) => {
  const { t } = useTranslation();
  const { patientId } = useParams();
  const navigate = useNavigate();

  const { getPatientById, resendPatientConfirmationEmail } = usePatientsApi();
  const { data: patient, isLoading } = getPatientById(patientId);
  const { mutate: resendConfirmation } = resendPatientConfirmationEmail();

  const { personalInfo } = patient || {};
  return (
    <>
      <Helmet>
        <title>{t('CONFIRM_EMAIL_PAGE_TITLE')}</title>
      </Helmet>
      <Flex marginLeft={spacings.xxlarge}>
        <Card
          shadow
          width={containerSizes.boxed}
          height={678}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {isLoading ? (
            <Loader size={iconSizes.large} />
          ) : (
            <Flex
              flexDirection="column"
              gap={spacings.xlarge}
              alignItems="center"
              width={576}
              height={178}
            >
              <Typography variant="h2" color="black">
                {`${getFullName(personalInfo)} ${t('CONFIRM_EMAIL_TITLE')}`}
              </Typography>
              <Typography variant="body1" textAlign="center">
                {t('CONFIRM_EMAIL_MSG')}
              </Typography>
              <Flex justifyContent="center" gap={spacings.large}>
                <Button
                  fullWidth
                  onClick={() => navigate(patientRoutes.getByIdLink(patientId))}
                >
                  {' '}
                  {t('CONFIRM_EMAIL_CONFIRM_BTN')}
                </Button>
                <Button
                  bgColor="cupid"
                  fullWidth
                  onClick={async () => {
                    await resendConfirmation(patientId);
                  }}
                >
                  {t('CONFIRM_EMAIL_RESEND_BTN')}
                </Button>
              </Flex>
            </Flex>
          )}
        </Card>
      </Flex>
    </>
  );
};
