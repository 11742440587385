import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputField from 'src/components/data-entry/InputField/InputField';
import { IdDocumentType, PatientIdentificationInfo } from 'src/types/patient';
import { SearchOutlined } from '@mui/icons-material';
import Select from 'src/components/data-entry/Select/Select';
import Checkbox from 'src/components/data-entry/Checkbox/Checkbox';
import ImageUpload from 'src/components/data-entry/ImageUpload/ImageUpload';
import Box from 'src/components/layout/Box/Box';
import Button from 'src/components/display/Button/Button';
import { spacings } from 'src/components/styles/constants';
import usePatientsApi from '../../../hooks/usePatientsApi';
import countries, { getName } from 'i18n-iso-countries';
import Autocomplete from 'src/components/data-entry/Autocomplete';
import Loader from 'src/components/display/Loader';
import Center from 'src/components/layout/Center';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';

export const EditIdentificationInfo: FC<{ patientId: string }> = ({
  patientId
}) => {
  const { t } = useTranslation();
  const { getPatientById, updatePatientIdentificationInfo } = usePatientsApi();
  const { closeDialog } = useDialog();

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);
  const {
    mutate: updatePatient,
    isSuccess: isUpdatedSuccessfully,
    isLoading: isUpdatingPatient
  } = updatePatientIdentificationInfo();
  const countriesName = countries.getNames('en', { select: 'official' });

  const countriesCode = countries.getAlpha2Codes();

  const defaultValues: PatientIdentificationInfo = {
    idNumber: '',
    countryOfBirth: countriesCode.US,
    idDocumentType: IdDocumentType.DRIVERS_LICENSE,
    idImage: null,
    isConfirmed: false,
    deleteImage: false
  };
  const { control, formState, handleSubmit, reset, setValue } =
    useForm<PatientIdentificationInfo>({
      mode: 'onChange',
      defaultValues
    });

  const { errors } = formState;

  useEffect(() => {
    if (isUpdatedSuccessfully) {
      closeDialog();
    }
  }, [isUpdatedSuccessfully]);

  useEffect(() => {
    if (patient) {
      const { personalInfo } = patient;
      reset({
        idNumber: personalInfo.idNumber || '',
        countryOfBirth: personalInfo.countryOfBirth || countriesCode.US,
        idDocumentType: personalInfo.idType || null,
        isConfirmed: personalInfo.isIdConfirmed,
        idImage: personalInfo.idImage ? [personalInfo.idImage] : null
      });
    }
  }, [patient]);

  const onSubmit = async (details: PatientIdentificationInfo) => {
    await updatePatient({
      patientId: `${patientId}`,
      identificationInfo: { ...details }
    });
  };

  if (isLoadingPatient) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Box width={'300px'}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box marginBottom={spacings.large}>
          <Controller
            name="idNumber"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('ID_NUMBER')}
                placeholder={t('ID_NUMBER')}
                error={!!errors.idNumber}
                helperText={errors?.idNumber?.message}
                fullWidth
              />
            )}
          />
        </Box>
        <Box marginBottom={spacings.large}>
          <Controller
            control={control}
            name="countryOfBirth"
            rules={{ required: t('COUNTRY_REQUIRED') }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                options={Object.entries(countriesName).map(
                  ([countryCode]) => countryCode
                )}
                shouldSortOptions
                getOptionLabel={(option: string) => getName(option, 'en')}
                renderInput={(params) => (
                  <InputField
                    {...params}
                    label={t('COUNTRY_OF_BIRTH')}
                    endIcon={<SearchOutlined />}
                  />
                )}
              />
            )}
          />
        </Box>
        <Box marginBottom={spacings.large}>
          <Controller
            name="idDocumentType"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                label={t('CHOOSE_DOCUMENT_TYPE')}
                inputRef={ref}
                error={!!errors?.idDocumentType}
                helperText={errors?.idDocumentType?.message}
                defaultOption={t('CHOOSE_DOCUMENT_TYPE')}
                options={Object.values(IdDocumentType).map((option) => ({
                  label: t(option),
                  value: option
                }))}
              />
            )}
          />
        </Box>
        <Box marginBottom={spacings.large}>
          <Controller
            name="idImage"
            control={control}
            render={({ field: { value, onChange } }) => (
              <ImageUpload
                label={t('ID_IMAGE')}
                limit={1}
                value={value}
                accept={{
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpeg', '.jpg'],
                  pdf: ['.pdf']
                }}
                onAddNewFiles={(newFiles) => {
                  onChange([...newFiles]);
                }}
                onRemoveFile={(imageIndexToRemove) => {
                  onChange(
                    value.filter((_, index) => index !== imageIndexToRemove)
                  );

                  setValue('deleteImage', true);
                }}
              />
            )}
          />
        </Box>
        <Box marginBottom={spacings.large}>
          <Controller
            name="isConfirmed"
            control={control}
            render={({ field: { ref, value, ...field } }) => (
              <Checkbox
                labelPlacement="start"
                inputRef={ref}
                {...field}
                checked={value}
                label={t('IDENTIFICATION_CONFIRMED_BY_STAFF_MEMBER')}
              />
            )}
          />
        </Box>
        <Button disabled={isUpdatingPatient} fullWidth type="submit">
          {t('SUBMIT')}
        </Button>
      </form>
    </Box>
  );
};
