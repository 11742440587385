import { useTranslation } from 'react-i18next';
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import { useToast } from 'src/components/components-api/ToastProvider';
import { ToastType } from 'src/components/display/Toast/Toast';
import { convertTaskClientToServer } from 'src/modules/patients/utils/conversions';
import { AppError } from 'src/types/global';
import {
  AddChecklistTemplateType,
  ChecklistTemplate,
  Task
} from 'src/types/task';
import { TaskServer } from 'src/types/patient-server';
import {
  addChecklistTemplateRequest,
  createTaskRequest,
  getChecklistTemplatesRequest,
  getTasksByStaffId,
  getTasksByStaffPatientId,
  updateTaskRequest
} from 'src/api/tasks.api';
import { queryKeys, querySubKeys } from './queryKeys';
import { usePopover } from 'src/components/components-api/PopoverProvider';

function useTasks() {
  const { openToast, handleQueryResultToast } = useToast();
  const { closeAllPopovers } = usePopover();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return {
    createTask: () =>
      useMutation<string, AppError, TaskServer>(createTaskRequest, {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_CREATE_TASK')
          }),

        onSuccess: (patientId) => {
          queryClient.invalidateQueries([
            queryKeys.PATIENTS,
            patientId,
            querySubKeys[queryKeys.PATIENTS].TASKS
          ]);
          openToast({
            title: t('CREATE_TASK_SUCCESS_TOAST_TITLE'),
            type: ToastType.SUCCESS
          });
        }
      }),
    updateTask: () =>
      useMutation<string, AppError, Partial<Task>>(
        (task) => updateTaskRequest(convertTaskClientToServer(task)),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_TASK')
            }),

          onSuccess: (_, { patientId }) => {
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].TASKS
            ]);
            queryClient.invalidateQueries([queryKeys.TASKS]);
            openToast({
              title: t('UPDATE_TASK_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
          }
        }
      ),
    getTasksAssignedToStaff: (
      staffId: string,
      options?: UseQueryOptions<Task[], AppError>
    ) =>
      useQuery<Task[], AppError>(
        [queryKeys.TASKS, staffId],
        () => getTasksByStaffId(staffId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_TASKS_ASSIGNED_TO_STAFF')
            }),

          ...options
        }
      ),
    getTasksByStaffPatientId: (
      staffId: string,
      patientId: string,
      options?: UseQueryOptions<Task[], AppError>
    ) =>
      useQuery<Task[], AppError>(
        [queryKeys.TASKS, patientId, staffId],
        () => getTasksByStaffPatientId(staffId, patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_TASKS_BY_PATIENT')
            }),

          ...options
        }
      ),
    getChecklistTemplates: () =>
      useQuery<ChecklistTemplate[], AppError>(
        [queryKeys.CHECKLIST_TEMPLATES],
        () => getChecklistTemplatesRequest()
      ),
    addChecklistTemplateToPatient: () =>
      useMutation<Task[], AppError, AddChecklistTemplateType>(
        (taskTemplateToAdd) => addChecklistTemplateRequest(taskTemplateToAdd),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_ADD_CHECKLIST_TEMPLATE')
            }),
          onSuccess: (_, { patientId }) => {
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].TASKS
            ]);
            openToast({
              title: t('ADD_CHECKLIST_TEMPLATE_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            closeAllPopovers();
          }
        }
      )
  };
}

export default useTasks;
