import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import EditIcon from '@mui/icons-material/Edit';

import usePatientsInsurancesApi from '../../../hooks/usePatientsInsurancesApi';
import { getDateFormat } from '../../../utils/dateAndTIme';
import { getFullAddress } from '../../../utils/general';
import {
  PatientInsurance,
  RelationshipToEnsured
} from '../../../types/patientInsurance';
import Box from '../../../components/layout/Box/Box';
import Flex from '../../../components/layout/Flex/Flex';
import Typography from '../../../components/display/Typography/Typography';
import { spacings } from '../../../components/styles/constants';
import { weights } from '../../../components/styles/fonts';
import { Colors } from '../../../components/styles/colors';
import MiniIconButton from '../../../components/display/MiniIconButton/MiniIconButton';
import { useDialog } from '../../../components/components-api/GlobalProvider/GlobalProvider';
import { EditPatientInsurancesInfo } from './EditPatientInsurancesInfo';

enum DataIndex {
  PRIMARY = 0,
  SECONDARY = 1,
  TERTIARY = 2
}

const RowContainer: FC<{
  title: string;
  value?: string;
}> = ({ title, value }) => {
  return (
    <Flex justifyContent="space-between" alignItems="center" gap={3}>
      <Typography>{title}</Typography>
      {value && <Typography>{value}</Typography>}
    </Flex>
  );
};

export const PatientInsurancesInfoCard: FC<{
  patientId: string;
}> = ({ patientId }) => {
  const { t } = useTranslation();
  const { getPatientInsurances } = usePatientsInsurancesApi();
  const { openDialog } = useDialog();

  const { data: insurances, isLoading } = getPatientInsurances(patientId);

  const InsuranceInfoTitle: FC<{ index: number }> = ({ index }) => {
    const dataIndexKeys = Object.keys(DataIndex).filter((key) =>
      isNaN(Number(key))
    );

    switch (index) {
      case DataIndex.PRIMARY:
        return (
          <RowContainer
            title={t('HOW_MANY_INSURANCES_PATIENT_HAVE')}
            value={insurances.length?.toString()}
          />
        );
      case DataIndex.SECONDARY:
      case DataIndex.TERTIARY:
        return (
          <Typography fontWeight={weights.bold}>
            {t(`${dataIndexKeys[index]}_INSURANCE`)}
          </Typography>
        );

      default:
        return null;
    }
  };

  const InsuranceInfo: FC<{ insurance: PatientInsurance; index: number }> = ({
    insurance,
    index
  }) => {
    const {
      id,
      relationshipToInsured,
      registeredPartnerForTreatment,
      insuranceType,
      insuranceId,
      insurancePlanName,
      insurancePolicyGroupOrFecaNumber,
      employerName,
      insuredName,
      insuredGender,
      insuredDob,
      address,
      city,
      zip,
      state,
      country
    } = insurance;

    const isNotSelfInsured =
      relationshipToInsured &&
      relationshipToInsured !== RelationshipToEnsured.SELF;
    const isRegisteredPartner = registeredPartnerForTreatment;

    const fullAddress = useMemo(
      () =>
        getFullAddress({
          country,
          city,
          address,
          state,
          zip
        }),
      [country, city, address, state, zip]
    );
    return (
      <Flex
        flexDirection="column"
        gap={spacings.small}
        key={id}
        marginBottom={index !== insurances.length - 1 && spacings.large}
      >
        <InsuranceInfoTitle index={index} />
        <RowContainer
          title={t('RELATION_TO_INSURED')}
          value={relationshipToInsured}
        />
        <RowContainer
          title={t('IS_THIS_YOUR_REGISTER_PARTNER')}
          value={registeredPartnerForTreatment ? 'Yes' : 'No'}
        />
        <RowContainer title={t('INSURANCE_TYPE')} value={insuranceType} />
        <RowContainer title={t('INSURANCE_ID')} value={insuranceId} />
        <RowContainer title={t('PLAN_NAME')} value={insurancePlanName} />
        <RowContainer
          title={t('INSURANCE_POLICY_OR_FECA_NUMBER')}
          value={insurancePolicyGroupOrFecaNumber}
        />
        <RowContainer title={t('EMPLOYER_NAME')} value={employerName} />
        {isNotSelfInsured && !isRegisteredPartner && (
          <>
            <RowContainer title={t('IF_INSURED_IS_NOT_SELF_OR_PARTNER')} />
            <RowContainer title={t('INSURED_NAME')} value={insuredName} />
            <RowContainer title={t('INSURED_GENDER')} value={insuredGender} />
            <RowContainer
              title={t('INSURED_DBO')}
              value={dayjs(insuredDob).format(getDateFormat())}
            />
            <RowContainer title={t('ADDRESS')} value={fullAddress} />
          </>
        )}
      </Flex>
    );
  };

  if (isLoading) return null;

  return (
    <Flex flexDirection="column">
      <Flex flex={1} justifyContent="space-between" alignItems="center">
        <Typography fontWeight={weights.extraBold}>
          {t('INSURANCE_INFORMATION').toUpperCase()}
        </Typography>
        <MiniIconButton
          icon={
            <EditIcon
              fill={Colors.black}
              onClick={() =>
                openDialog({
                  header: t('INSURANCE_INFORMATION'),
                  children: <EditPatientInsurancesInfo patientId={patientId} />,
                  fullWidth: true,
                  maxWidth: 'lg'
                })
              }
            />
          }
        />
      </Flex>
      <Box
        borderBottom={`1px solid ${Colors.alto2}`}
        width="100%"
        marginY={spacings.medium}
      />
      {insurances?.map((insurance, index) => (
        <InsuranceInfo
          key={`${insurance.id}-${index}`}
          insurance={insurance}
          index={index}
        />
      ))}
    </Flex>
  );
};
