import { FC } from 'react';
import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
  css,
  styled
} from '@mui/material';
import Box, { BoxProps } from 'src/components/layout/Box';
import { spacings } from 'src/components/styles/constants';
import Typography from '../Typography';
import { fonts } from 'src/components/styles/fonts';
import { Colors } from 'src/components/styles/colors';
import { makeShouldForwardProps } from 'src/components/utils';

type AvatarAlignment = 'left' | 'right';
export enum AvatarSizes {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  XLARGE = 'XLARGE'
}

export interface AvatarProps extends MuiAvatarProps {
  image?: string;
  title?: string;
  subtitle?: string;
  defaultAvatar?: React.ReactElement;
  backgroundColor?: keyof typeof Colors;
  fillColor?: keyof typeof Colors;
  alignAvatar?: AvatarAlignment;
  size?: AvatarSizes;
}

interface AvatarContainerProps extends BoxProps {
  alignAvatar: AvatarAlignment;
}

const shouldForwardPropContainer = makeShouldForwardProps(['alignAvatar']);
const StyledAvatarContainer = styled(Box, {
  shouldForwardProp: shouldForwardPropContainer
})<AvatarContainerProps>`
  display: flex;
  justify-content: flex-end;
  flex-direction: ${({ alignAvatar }) =>
    alignAvatar === 'right' ? 'row' : 'row-reverse'};
  gap: ${spacings.medium};
  align-items: center;

  .title {
    font: ${fonts.label};
  }
`;

const StyledTitlesContainer = styled(Box, {
  shouldForwardProp: shouldForwardPropContainer
})<AvatarContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignAvatar }) =>
    alignAvatar === 'right' ? 'flex-end' : 'flex-start'};
`;

const shouldForwardPropAvatar = makeShouldForwardProps([
  'fillColor',
  'backgroundColor',
  'alignAvatar',
  'size'
]);
const StyledAvatar = styled(MuiAvatar, {
  shouldForwardProp: shouldForwardPropAvatar
})<AvatarProps>`
  background-color: ${({ backgroundColor }) => Colors[backgroundColor]};
  .MuiSvgIcon-root {
    color: ${({ fillColor }) => Colors[fillColor]};
  }

  ${({ size }) => {
    switch (size) {
      case AvatarSizes.SMALL:
        return css`
          width: 30px;
          height: 30px;
        `;
      case AvatarSizes.LARGE:
        return css`
          width: 80px;
          height: 80px;
        `;
      case AvatarSizes.XLARGE:
        return css`
          width: 100px;
          height: 100px;
        `;
      case AvatarSizes.MEDIUM:
      default:
        return css`
          width: 40px;
          height: 40px;
        `;
    }
  }}
`;

export const Avatar: FC<AvatarProps> = ({
  image,
  title,
  subtitle,
  defaultAvatar,
  alignAvatar = 'right',
  size = AvatarSizes.MEDIUM,
  ...otherProps
}) => {
  return (
    <StyledAvatarContainer alignAvatar={alignAvatar}>
      {(title || subtitle) && (
        <StyledTitlesContainer alignAvatar={alignAvatar}>
          {title && (
            <Typography id={`${title}-title`} className="title" variant="h4">
              {title}
            </Typography>
          )}
          {subtitle && <Typography variant="body1">{subtitle}</Typography>}
        </StyledTitlesContainer>
      )}
      <StyledAvatar src={image} size={size} {...otherProps}>
        {defaultAvatar}
      </StyledAvatar>
    </StyledAvatarContainer>
  );
};

export default Avatar;
