import { FC, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import VideocamIcon from '@mui/icons-material/Videocam';
import { styled } from '@mui/material';
import Card from 'src/components/display/Card/Card';
import Typography from 'src/components/display/Typography/Typography';
import Flex from 'src/components/layout/Flex/Flex';
import IconButton from 'src/components/display/IconButton/IconButton';
import { Colors } from 'src/components/styles/colors';
import { spacings } from 'src/components/styles/constants';
import Table from 'src/components/display/Table/Table';
import { GridColDef } from '@mui/x-data-grid-premium';
import Box from 'src/components/layout/Box/Box';
import { Appointment, AppointmentStatus } from 'src/types/appointment';
import Select from 'src/components/data-entry/Select/Select';
import { weights } from 'src/components/styles/fonts';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFullName } from 'src/utils/general';
import dayjs from 'dayjs';
import Loader from 'src/components/display/Loader/Loader';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import AddEditAppointment from '../../calendar/AddEditAppointment';
import { getDateFormat, getTimeFormat } from 'src/utils/dateAndTIme';
import { UserTypes } from 'src/types/user';
import { AppointmentDetails } from './AppointmentDetails';
import useAppointments from 'src/hooks/useAppointments';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';
import PatientAvatar from 'src/components/display/Avatar/PatientAvatar';

const StyledScheduleContainer = styled(Card)`
  background-color: ${Colors.dawnPink};
  padding: 0 ${spacings.large};
  height: 100%;
`;

const StyledTable = styled(Table)`
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell {
    white-space: unset;
  }
  .MuiDataGrid-row {
    padding: ${spacings.medium};
    cursor: pointer;
  }
  .MuiDataGrid-row,
  .MuiDataGrid-row:hover {
    background-color: ${Colors.white};
  }
  .MuiDataGrid-cell .MuiBox-root {
    display: flex;
  }

  .MuiDataGrid-cell:last-of-type {
    padding-inline-end: 0;
  }

  .MuiTypography-root {
    min-width: 50px;
  }
`;

const StyledSelect = styled(Select)`
  .MuiInputBase-root {
    background-color: ${Colors.transparent};
    width: min-content;
    color: ${Colors.emperor};
    font-weight: ${weights.extraBold};

    &:hover,
    &.Mui-focused {
      box-shadow: none;
    }
  }
`;

const StyledAppointmentTypeTypography = styled(Typography)`
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  width: 100%;
`;

export const ScheduleCard: FC = () => {
  const { patientId } = useParams();
  const { t } = useTranslation();
  const [isPrevious, setIsPrevious] = useState(false);
  const { openDialog } = useDialog();
  const { getPatientById } = usePatientsApi();
  const { getPatientAppointments } = useAppointments();
  const { getDefaultAvatar } = useGetDefaultAvatar();

  const { data: appointments, isLoading: isLoadingAppointments } =
    getPatientAppointments(patientId);

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  const isLoadingAppointmentsData = isLoadingAppointments || isLoadingPatient;

  const { previousMeetings, upcomingMeetings } = useMemo(
    () =>
      appointments?.reduce<{
        upcomingMeetings: Appointment[];
        previousMeetings: Appointment[];
      }>(
        (acc, meeting) => {
          if (dayjs(meeting.date).isBefore(dayjs().subtract(1, 'day'))) {
            acc.previousMeetings.push(meeting);
          } else {
            acc.upcomingMeetings.push(meeting);
          }

          return acc;
        },
        {
          upcomingMeetings: [],
          previousMeetings: []
        }
      ) || { upcomingMeetings: [], previousMeetings: [] },
    [appointments]
  );

  const columns: GridColDef<Appointment>[] = [
    {
      field: 'avatar',
      flex: 1,
      align: 'center',
      filterable: false,
      renderCell: () => {
        const avatar = getDefaultAvatar({
          userId: patientId,
          userType: UserTypes.patients,
          pictureId: patient?.personalInfo?.pictureId
        });

        return (
          <Box alignSelf={'center'}>
            <PatientAvatar image={avatar} patientId={patientId} />
          </Box>
        );
      }
    },
    {
      field: 'date',
      flex: 2,
      type: 'date' as const,
      valueGetter: (value) => (value ? new Date(value) : null),
      renderCell: ({ row: { date } }) => (
        <Box paddingLeft={spacings.large} alignSelf={'center'}>
          {date ? (
            <Flex>
              <Typography fontWeight={weights.extraBold}>
                {`${dayjs(date).format(getDateFormat())} ${dayjs(date).format(
                  getTimeFormat({ isShort: true })
                )}`}
              </Typography>
            </Flex>
          ) : (
            '--'
          )}
        </Box>
      )
    },
    {
      field: 'details',
      flex: 4,
      align: 'left',
      renderCell: ({ row: { appointmentType, location, patientId } }) => {
        const isPartnerAppointment = patient?.partnerInfo?.id === patientId;
        const fullName = getFullName(
          isPartnerAppointment ? patient?.partnerInfo : patient?.personalInfo
        );
        return (
          <Flex
            gap={spacings.medium}
            alignSelf={'center'}
            width={1}
            justifyContent={'space-between'}
          >
            <Typography fontWeight={weights.extraBold}>{fullName}</Typography>
            <StyledAppointmentTypeTypography>
              {appointmentType || '--'}
            </StyledAppointmentTypeTypography>
            <Typography>{location || '--'}</Typography>
          </Flex>
        );
      }
    },
    {
      field: 'launchMeeting',
      flex: 1,
      align: 'center',
      filterable: false,
      renderCell: ({ row: { isVirtual } }) =>
        isVirtual && (
          <Box id="launch-meeting-button-container">
            <IconButton icon={<VideocamIcon />} bgColor="poloBlue" />
          </Box>
        )
    }
  ];

  if (isLoadingAppointmentsData) {
    return <Loader />;
  }

  return (
    <StyledScheduleContainer>
      <Flex
        alignItems="center"
        marginBottom={spacings.medium}
        justifyContent="space-between"
        padding={spacings.xlarge}
      >
        <Typography variant="h1">{t('SCHEDULE')}</Typography>
        <IconButton
          id="add-new-appointment-button"
          icon={<AddIcon />}
          onClick={() =>
            openDialog({
              header: t('ADD_APPOINTMENT'),
              children: <AddEditAppointment patientId={patientId} />,
              fullWidth: true,
              maxWidth: 'lg',
              closeButtonId: 'close-add-appointment-dialog'
            })
          }
        />
      </Flex>
      <Flex
        flexDirection="column"
        gap={spacings.medium}
        marginBottom={spacings.xxlarge}
      >
        <Box>
          <StyledSelect
            variant="filter"
            value={
              isPrevious
                ? AppointmentStatus.PREVIOUS
                : AppointmentStatus.UPCOMING
            }
            options={[
              { value: AppointmentStatus.UPCOMING, label: t('UPCOMING') },
              { value: AppointmentStatus.PREVIOUS, label: t('PREVIOUS') }
            ]}
            onChange={(evt) => {
              setIsPrevious(evt.target.value === AppointmentStatus.PREVIOUS);
            }}
          />
        </Box>
        <Box height={360} id="patient-scheduled-appointments-container">
          <StyledTable
            hideHeaders
            variant="carded"
            getRowSpacing={() => ({ bottom: 10 })}
            columns={columns}
            rows={(isPrevious ? previousMeetings : upcomingMeetings) || []}
            paginationModel={{ page: 0, pageSize: 3 }}
            onRowClick={(row) =>
              openDialog({
                header: ' ',
                fullWidth: true,
                maxWidth: 'sm',
                children: <AppointmentDetails appointmentId={`${row.id}`} />
              })
            }
          />
        </Box>
      </Flex>
    </StyledScheduleContainer>
  );
};
