import { FC } from 'react';
import Box from '../../components/layout/Box';
import { Controller, useForm } from 'react-hook-form';
import { spacings } from '../../components/styles/constants';
import InputField from '../../components/data-entry/InputField';
import { useTranslation } from 'react-i18next';
import TextArea from '../../components/data-entry/TextArea';
import Button from '../../components/display/Button';
import Loader from '../../components/display/Loader';
import { AddProgressNoteForm } from '../../types/feed';
import useFeed from '../../hooks/useFeed';
import { usePopover } from '../../components/components-api/PopoverProvider';

interface AddProgressNoteProps {
  patientId: string;
}

export const AddProgressNote: FC<AddProgressNoteProps> = ({ patientId }) => {
  const { t } = useTranslation();
  const { createProgressNote } = useFeed();
  const { closePopover } = usePopover();
  const { control, formState, handleSubmit } = useForm<AddProgressNoteForm>({
    mode: 'onChange',
    defaultValues: {
      patientId,
      name: '',
      summary: ''
    }
  });

  const {
    mutateAsync: handleCreateProgressNote,
    isLoading: isCreatingProgressNote
  } = createProgressNote();

  const { errors } = formState;

  const onSubmit = async (data: AddProgressNoteForm) => {
    await handleCreateProgressNote(data, {
      onSuccess: () => closePopover()
    });
  };

  return (
    <Box>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box marginBottom={spacings.medium} width={300}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: t('NOTE_SUBJECT_REQUIRED')
            }}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('NOTE_SUBJECT')}
                placeholder={t('ADD_NOTE_SUBJECT')}
                error={!!errors.name}
                helperText={errors?.name?.message}
                required
                fullWidth
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            name="summary"
            control={control}
            rules={{
              required: t('NOTE_SUMMARY_REQUIRED')
            }}
            render={({ field: { ref, ...field } }) => (
              <TextArea
                {...field}
                ref={ref}
                placeholder={t('TYPE_NOTE')}
                error={!!errors.summary}
                helperText={errors?.summary?.message}
                minRows={15}
                maxRows={15}
                fullWidth
              />
            )}
          />
        </Box>
        <Box margin="auto" width="50%" mt={spacings.xlarge}>
          <Button
            id="patient-diagnosis-submit-button"
            fullWidth
            type="submit"
            disabled={isCreatingProgressNote}
          >
            {isCreatingProgressNote ? <Loader /> : t('SAVE')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
