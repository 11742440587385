import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Flex from 'src/components/layout/Flex/Flex';
import Box from 'src/components/layout/Box/Box';
import Button from 'src/components/display/Button/Button';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import { spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography/Typography';
import InputField from 'src/components/data-entry/InputField/InputField';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import Select from 'src/components/data-entry/Select/Select';
import Loader from 'src/components/display/Loader/Loader';
import dayjs from 'dayjs';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { getFullName, prescriptionTemplateId } from 'src/utils/general';
import useGetPatientDocumentData from '../../../hooks/useGetPatientDocumentData';
import { CreatePatientDocumentPayload } from 'src/types/documents';
import { Colors } from 'src/components/styles/colors';
import useDocumentsApi from '../../../hooks/useDocumentsApi';
import { getDocumentAsPdfFileFromURL } from '../../documents/utils/getDocumentAsPdfFileFromURL';
import { PrescriptionRxForm, PrescriptionsRx } from 'src/types/prescription';
import { NewCycleFormValues } from '../../../types/cycle';
import { StyledForm } from './MedicationProtocolForm';

const emptyPrescription: PrescriptionsRx = {
  medicationRxId: null,
  deliveryDate: dayjs().toDate(),
  quantity: null,
  refill: null
};

export interface ConfirmPrescriptionFormSubmit {
  prescriptionsRx: PrescriptionsRx[];
  ordersDocumentPayload?: CreatePatientDocumentPayload;
  prescriptionsDocumentPayload?: CreatePatientDocumentPayload;
}

interface ConfirmPrescriptionFormProps {
  patientId: string;
  newCycle: NewCycleFormValues;
  handleSubmitForm: (details: ConfirmPrescriptionFormSubmit) => void;
  handleClickBack?: () => void;
}

const ConfirmPrescriptionForm: FC<ConfirmPrescriptionFormProps> = ({
  patientId,
  newCycle,
  handleSubmitForm,
  handleClickBack
}) => {
  const { t } = useTranslation();
  const { getPatientById, getMedications, getMedicationsRx } = usePatientsApi();
  const { getTemplateById } = useDocumentsApi();

  const { data: prescriptionsTemplate } = getTemplateById(
    prescriptionTemplateId
  );

  const { data: medicationsRx, isLoading: isLoadingMedicationsRx } =
    getMedicationsRx();

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  const { data: medicationsList, isLoading: isLoadingMedications } =
    getMedications();

  const prescriptionsToConfirm: PrescriptionsRx[] =
    newCycle?.medicationProtocols
      ?.map(({ medicationId, unit }) => {
        const medication = medicationsList.find(
          ({ id }) => medicationId === id
        );

        if (!medication || !medication?.medicationRx?.length) {
          return;
        }

        return {
          medicationRxId: medication.medicationRx[0].id,
          deliveryDate: dayjs().toDate(),
          quantity: null,
          refill: null,
          description: medication.name,
          unit
        };
      })
      .filter((prescription) => !!prescription) || [];

  const containsPrescriptionsWithNoRxInSystem = useMemo(
    () =>
      newCycle?.medicationProtocols?.length > prescriptionsToConfirm?.length,
    [newCycle.medicationProtocols]
  );

  const defaultValues: PrescriptionRxForm = {
    patientName: '',
    prescriptionsRx: prescriptionsToConfirm
  };

  const { control, formState, handleSubmit, watch, setValue } =
    useForm<PrescriptionRxForm>({
      mode: 'onChange',
      defaultValues
    });
  const {
    fields: defaultPrescriptions,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'prescriptionsRx'
  });

  const { errors } = formState;
  const { prescriptionsRx } = watch();

  useEffect(() => {
    if (newCycle?.cycleDayOne) {
      prescriptionsRx?.forEach((_, index) => {
        setValue(
          `prescriptionsRx.${index}.deliveryDate`,
          dayjs(newCycle?.cycleDayOne).toDate()
        );
      });
    }
  }, [newCycle?.cycleDayOne]);

  const descriptedPrescriptionsRx = prescriptionsRx?.map(
    ({ medicationRxId, ...restPrescription }) => {
      const medicationRx = medicationsRx?.find(
        ({ id }) => id === medicationRxId
      );

      return {
        ...restPrescription,
        medicationRxId,
        description: medicationRx?.description || ''
      };
    }
  );

  const {
    templateData: prescriptionTemplateMetaData,
    isLoading: isLoadingPrescriptionTemplateMetaData
  } =
    useGetPatientDocumentData({
      patientId,
      newCycleValues: {
        ...newCycle,
        prescriptionsRx: descriptedPrescriptionsRx
      },
      templateId: prescriptionTemplateId,
      enabled: !!prescriptionsRx?.length
    }) || {};

  useEffect(() => {
    if (!patient) return;
    setValue('patientName', getFullName(patient.personalInfo));
  }, [patient]);

  const disableSubmitButton = isLoadingPrescriptionTemplateMetaData;

  if (isLoadingPatient || isLoadingMedications || isLoadingMedicationsRx)
    return <Loader />;

  const onSubmit = async (details: PrescriptionRxForm) => {
    let prescriptionsDocumentPayload: CreatePatientDocumentPayload;

    if (prescriptionsRx.length) {
      const file: File = await getDocumentAsPdfFileFromURL({
        template: prescriptionsTemplate
      });

      prescriptionsDocumentPayload = {
        file,
        patientId,
        templateId: prescriptionTemplateId,
        metadata: JSON.stringify(prescriptionTemplateMetaData),
        isCompleted: false
      };
    }

    handleSubmitForm({
      prescriptionsRx: details.prescriptionsRx,
      prescriptionsDocumentPayload
    });
  };

  return (
    <Box marginTop={spacings.xlarge} height={'100%'}>
      <StyledForm noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Flex alignItems="flex-start" gap={spacings.xxlarge}>
            <Box flex={0.5} />
            <Box flex={4}>
              <Controller
                name="patientName"
                control={control}
                rules={{
                  required: t('PATIENT_NAME_REQUIRED')
                }}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    label={t('PATIENT_NAME_LABEL')}
                    placeholder={t('PATIENT_NAME_PLACEHOLDER')}
                    error={!!errors.patientName}
                    helperText={errors?.patientName?.message}
                    required
                    disabled
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box flex={2} />
            <Box flex={1} />
            <Box flex={1} />
          </Flex>
          {defaultPrescriptions.map((prescription, index) => (
            <Flex
              alignItems="flex-start"
              key={prescription.id}
              marginTop={spacings.xlarge}
              gap={spacings.xxlarge}
            >
              <Flex
                alignSelf="end"
                paddingBottom={spacings.xlarge}
                gap={spacings.medium}
                flex={0.5}
              >
                <MiniIconButton
                  icon={<ContentCopyIcon />}
                  onClick={() => append(prescriptionsRx[index])}
                />
                <MiniIconButton
                  icon={<RemoveIcon />}
                  onClick={() => remove(index)}
                />
              </Flex>
              <Box flex={4}>
                <Controller
                  name={`prescriptionsRx.${index}.medicationRxId`}
                  control={control}
                  rules={{
                    required: t('MEDICATION_NAME_REQUIRED')
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <Select
                      {...field}
                      inputRef={ref}
                      label={index === 0 ? t('MEDICATION_NAME_LABEL') : ''}
                      error={!!errors.prescriptionsRx?.[index]?.medicationRxId}
                      helperText={
                        errors.prescriptionsRx?.[index]?.medicationRxId?.message
                      }
                      defaultOption={t('MEDICATION_NAME_DEFAULT_OPTION')}
                      options={
                        medicationsRx?.map(({ description, id }) => ({
                          label: description,
                          value: id
                        })) || []
                      }
                    />
                  )}
                />
              </Box>
              <Box flex={2}>
                <Controller
                  name={`prescriptionsRx.${index}.deliveryDate`}
                  control={control}
                  rules={{
                    required: t('DELIVERY_DATE_REQUIRED')
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <DatePicker
                      {...field}
                      inputRef={ref}
                      label={index === 0 ? t('DELIVERY_DATE_LABEL') : ''}
                      error={!!errors.prescriptionsRx?.[index]?.deliveryDate}
                      helperText={
                        errors.prescriptionsRx?.[index]?.deliveryDate?.message
                      }
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name={`prescriptionsRx.${index}.quantity`}
                  control={control}
                  rules={{
                    required: t('QUANTITY_REQUIRED'),
                    min: { value: 1, message: t('INPUT_VALIDATION_MSG') }
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      type="number"
                      placeholder="#"
                      label={index === 0 ? t('QUANTITY_LABEL') : ''}
                      error={!!errors.prescriptionsRx?.[index]?.quantity}
                      helperText={
                        errors.prescriptionsRx?.[index]?.quantity?.message
                      }
                      onChange={(ev) => {
                        const { value } = ev.currentTarget;
                        if (+value < 0) return;
                        setValue(
                          `prescriptionsRx.${index}.quantity`,
                          parseFloat(value)
                        );
                      }}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name={`prescriptionsRx.${index}.refill`}
                  control={control}
                  rules={{
                    required: t('REFILL_REQUIRED')
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      type="number"
                      placeholder="#"
                      label={index === 0 ? t('REFILL_LABEL') : ''}
                      error={!!errors.prescriptionsRx?.[index]?.refill}
                      helperText={
                        errors.prescriptionsRx?.[index]?.refill?.message
                      }
                      onChange={(ev) => {
                        const { value } = ev.currentTarget;
                        if (+value < 0) return;
                        setValue(
                          `prescriptionsRx.${index}.refill`,
                          parseFloat(value)
                        );
                      }}
                      fullWidth
                    />
                  )}
                />
              </Box>
            </Flex>
          ))}
          <Flex
            alignItems="center"
            marginTop={spacings.xlarge}
            gap={spacings.xxlarge}
            marginBottom={spacings.large}
          >
            <MiniIconButton
              icon={<AddIcon />}
              onClick={() => append(emptyPrescription)}
            />
            <Typography variant="body1">{t('ADD_MEDICATION')}</Typography>
          </Flex>
          {containsPrescriptionsWithNoRxInSystem && (
            <Typography color={Colors.alizarinCrimson}>
              {t(
                'SOME_MEDICATIONS_DONT_HAVE_CORRESPONDING_PRESCRIPTIONS_MESSAGE'
              )}
            </Typography>
          )}
        </Box>
        <Flex mt={'20px'} justifyContent={'space-between'}>
          <Box width="20%">
            {handleClickBack && (
              <Button
                type="button"
                bgColor="alto"
                textColor="darkGray"
                fullWidth
                onClick={handleClickBack}
              >
                {t('BACK')}
              </Button>
            )}
          </Box>
          <Box width="20%">
            <Button disabled={disableSubmitButton} fullWidth type="submit">
              {t('CONFIRM_PRESCRIPTION')}
            </Button>
          </Box>
        </Flex>
      </StyledForm>
    </Box>
  );
};

export default ConfirmPrescriptionForm;
