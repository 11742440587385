import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Button from 'src/components/display/Button';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography';
import Autocomplete from 'src/components/data-entry/Autocomplete';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { PatientPersonalInfo } from 'src/types/patient';
import { getFullName } from 'src/utils/general';
import InputField from 'src/components/data-entry/InputField';
import { SearchOutlined } from '@mui/icons-material';
import Box from 'src/components/layout/Box';
import useGeneralApi from 'src/hooks/useGeneralApi';
import { longDateTimeFormat } from 'src/utils/dateAndTIme';
import { downloadBlobData } from 'src/components/utils';

const DataExportTab: FC = () => {
  const { t } = useTranslation();
  const { getPatients } = usePatientsApi();
  const { getPatientData } = useGeneralApi();
  const { data: patients, isLoading: isLoadingPatients } = getPatients();
  const [patientSearchTerm, setPatientSearchTerm] = useState('');
  const [chosenPatient, setChosenPatient] =
    useState<PatientPersonalInfo | null>(null);

  const patientId = chosenPatient?.id;

  const {
    refetch: fetchPatientData,
    isLoading: isExporting,
    data: blobToDownload,
    remove: removeBlobToDownload
  } = getPatientData(patientId);

  useEffect(() => {
    if (blobToDownload) {
      downloadBlobData(
        blobToDownload,
        `${patientId}-${dayjs().format(longDateTimeFormat)}.zip`
      );
    }
  }, [blobToDownload, patientId]);

  const handlePatientSelectAutocompleteChange = (
    patientPersonalInfo: PatientPersonalInfo
  ) => {
    if (blobToDownload) {
      removeBlobToDownload();
    }

    setPatientSearchTerm(getFullName(patientPersonalInfo));
    setChosenPatient(patientPersonalInfo);
  };

  return (
    <Flex
      justifyContent="space-between"
      flexDirection="column"
      gap={spacings.large}
    >
      <Typography variant="h2">{t('DATA_EXPORT_HEADER')}</Typography>

      <Flex flexDirection="column" gap={spacings.xsmall}>
        <Typography variant="body1">
          {t('DATA_EXPORT_DESCRIPTION_1')}
        </Typography>

        <Typography variant="body1">
          {t('DATA_EXPORT_DESCRIPTION_2')}
        </Typography>
      </Flex>

      <Typography variant="body1">
        {t('DATA_EXPORT_SUB_DESCRIPTION')}
      </Typography>

      <Box maxWidth="50%">
        <Autocomplete
          fullWidth
          options={patients || []}
          shouldSortOptions
          role="list-box"
          freeSolo
          loading={isLoadingPatients}
          disableClearable
          value={chosenPatient}
          filterOptions={(options, state) => {
            return options.filter((item: PatientPersonalInfo) =>
              getFullName(item)
                .toLowerCase()
                .includes(state.inputValue.toLowerCase())
            );
          }}
          onChange={(_, patientPersonalInfo: PatientPersonalInfo) => {
            handlePatientSelectAutocompleteChange(patientPersonalInfo);
          }}
          getOptionLabel={(option: PatientPersonalInfo) => getFullName(option)}
          renderInput={(params) => (
            <InputField
              label={t('PATIENT_LABEL')}
              placeholder={t('SEARCH_PATIENT_PLACEHOLDER')}
              inputRef={params.InputProps.ref}
              inputProps={params.inputProps}
              onChange={(ev) => setPatientSearchTerm(ev.target.value)}
              value={patientSearchTerm}
              endIcon={<SearchOutlined />}
            />
          )}
        />
      </Box>
      <Button
        onClick={() => fetchPatientData()}
        disabled={!chosenPatient || isExporting}
      >
        {isExporting ? t('EXPORTING_DATA') : t('GENERATE_EXPORT_BUTTON')}
      </Button>
    </Flex>
  );
};

export default DataExportTab;
