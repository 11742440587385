import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'src/components/display/Typography/Typography';
import { spacings } from 'src/components/styles/constants';
import Table from 'src/components/display/Table/Table';
import { GridColDef, GridRowSpacingParams } from '@mui/x-data-grid-premium';
import {
  AppointmentStatusType,
  EggRetrievalAppointmentStatusType,
  CycleMonitoringAppointmentStatusType,
  DiagnosticAppointmentAppointmentStatusType,
  FollowupAppointmentStatusType,
  InitialConsultationAppointmentStatusType,
  AnyUltrasoundAppointmentStatusType,
  AllCounselingEducationAppointmentStatusType,
  UrgentConsultAppointmentStatusType,
  AppointmentTypeCategory,
  Appointment,
  AppointmentTypes,
  InCycleAppointmentTypes,
  DiagnosticAppointmentTypes,
  ConsultationAppointmentTypes,
  ThirdPartyAppointmentStatusType,
  IUIAppointmentStatusType,
  ConsultationStatusType
} from 'src/types/appointment';
import { getFullName } from 'src/utils/general';
import Flex, { FlexProps } from 'src/components/layout/Flex/Flex';
import Avatar from 'src/components/display/Avatar/Avatar';
import IconButton from 'src/components/display/IconButton/IconButton';
import { SvgIcon, styled, css } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { ReactComponent as CycleIcon } from '../../../assets/icons/cycle.svg';
import { ReactComponent as ChatIcon } from '../../../assets/icons/chat-message.svg';
import { Colors } from 'src/components/styles/colors';
import { weights } from 'src/components/styles/fonts';
import { usePopover } from 'src/components/components-api/PopoverProvider';
import Box from 'src/components/layout/Box/Box';
import i18n from 'src/i18n/i18n';
import isToday from 'dayjs/plugin/isToday';
import { PatientActionsMenu } from '../actionMenu/PatientActionsMenu';
import dayjs, { Dayjs } from 'dayjs';
import useAppointments from 'src/hooks/useAppointments';
import { patientRoutes } from 'src/router/routes';
import Select from 'src/components/data-entry/Select';
import { SummaryCategory } from './DashboardSummary';
import Menu, {
  MenuItemProps,
  MenuTheme
} from '../../../components/display/Menu/Menu';
import { getPrimaryPhysicianColumnRenderCell } from '../utils/getPrimaryPhysicianColumnRenderCell';
import { UserTypes } from 'src/types/user';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';

dayjs.extend(isToday);

interface StyledAppointmentStatusFlexProps extends FlexProps {
  status?: AppointmentStatusType;
  isLoading?: boolean;
}

const getAppointmentCategory = (
  type: AppointmentTypes
): AppointmentTypeCategory => {
  switch (type) {
    case InCycleAppointmentTypes.EGG_RETRIEVAL:
      return AppointmentTypeCategory.EGG_RETRIEVAL;
    case InCycleAppointmentTypes.TRANSFER:
      return AppointmentTypeCategory.TRANSFER;
    case InCycleAppointmentTypes.IUI:
      return AppointmentTypeCategory.IUI;
    case InCycleAppointmentTypes.MONITORING:
    case InCycleAppointmentTypes.BASELINE:
    case InCycleAppointmentTypes.MEDICATED_TIMED_INTERCOURSE:
      return AppointmentTypeCategory.MONITORING;
    case DiagnosticAppointmentTypes.BLOOD_DRAW_ONLY:
    case DiagnosticAppointmentTypes.HSG_HYCOSY_HYFOSY:
    case DiagnosticAppointmentTypes.SALINE_SONOHYSTEROGRAM:
    case DiagnosticAppointmentTypes.ENDOMETRIAL_BIOPSY:
    case DiagnosticAppointmentTypes.SEMEN_ANALYSIS:
    case DiagnosticAppointmentTypes.ANTRAL_FOLLICLE_COUNT:
    case DiagnosticAppointmentTypes.TRIAL_EMBRYO_TRANSFER:
      return AppointmentTypeCategory.DIAGNOSTIC_APPOINTMENT;
    case ConsultationAppointmentTypes.EGG_DONOR_SCREENING_PRE_TREATMENT_FIRST_CYCLE:
    case ConsultationAppointmentTypes.EGG_DONOR_SCREENING_PRE_TREATMENT_SUBSEQUENT_CYCLE:
    case ConsultationAppointmentTypes.DONOR_SCREENING:
    case ConsultationAppointmentTypes.GESTATIONAL_CARRIER_CONSULTATION:
      return AppointmentTypeCategory.THIRD_PARTY;
    case ConsultationAppointmentTypes.APP_PHONE_CONSULT:
    case ConsultationAppointmentTypes.FOLLOW_UP_APP_VISIT:
    case ConsultationAppointmentTypes.FOLLOW_UP_APP_VISIT_MALE_EVAL:
    case ConsultationAppointmentTypes.FOLLOW_UP_REI_VISIT:
    case ConsultationAppointmentTypes.NEW_PATIENT_REI_VISIT:
    case ConsultationAppointmentTypes.NEW_PATIENT_REI_VISIT_APP_REFERRAL:
    case ConsultationAppointmentTypes.THIRD_PARTY_NEW_PATIENT_REI_VISIT:
    case ConsultationAppointmentTypes.NEW_PATIENT_APP_VISIT:
    case ConsultationAppointmentTypes.NEW_PATIENT_APP_VISIT_MALE_EVAL:
    case ConsultationAppointmentTypes.WELCOME_CALL:
    case ConsultationAppointmentTypes.IVF_PREP:
    case ConsultationAppointmentTypes.POST_IVF:
    case ConsultationAppointmentTypes.RN_HEALTHCARE_PROFESSIONAL_CONSULT:
    case ConsultationAppointmentTypes.FINANCIAL_CONSULTATION:
      return AppointmentTypeCategory.CONSULTATION;
    case ConsultationAppointmentTypes.URGENT_CONSULTATION_WITH_HEALTHCARE_PROFESSIONAL:
      return AppointmentTypeCategory.URGENT_CONSULT;
    default:
      return AppointmentTypeCategory.URGENT_CONSULT;
  }
};

const getAppointmentTypeCellBgColor = (type: AppointmentTypes): string => {
  const appointmentTypeCategory = getAppointmentCategory(type);
  switch (appointmentTypeCategory) {
    case AppointmentTypeCategory.EGG_RETRIEVAL:
    case AppointmentTypeCategory.TRANSFER:
    case AppointmentTypeCategory.IUI:
      return Colors.viola;
    case AppointmentTypeCategory.MONITORING:
      return Colors.halfBaked;
    case AppointmentTypeCategory.DIAGNOSTIC_APPOINTMENT:
    case AppointmentTypeCategory.ULTRASOUND:
      return Colors.cupid;
    case AppointmentTypeCategory.FOLLOWUP:
    case AppointmentTypeCategory.INITIAL_CONSULTATION:
      return Colors.brandy;
    case AppointmentTypeCategory.ALL_COUNSELING_EDUCATION:
    case AppointmentTypeCategory.THIRD_PARTY:
      return Colors.mercury;
    case AppointmentTypeCategory.URGENT_CONSULT:
      return Colors.mauvelous;
    case AppointmentTypeCategory.CONSULTATION:
      return Colors.brandy;
  }
};

const getAppointmentTypeSummaryCategory = (
  type: AppointmentTypes
): SummaryCategory => {
  const appointmentTypeCategory = getAppointmentCategory(type);
  switch (appointmentTypeCategory) {
    case AppointmentTypeCategory.EGG_RETRIEVAL:
      return SummaryCategory.RETRIEVAL;
    case AppointmentTypeCategory.IUI:
      return SummaryCategory.IUI;
    case AppointmentTypeCategory.TRANSFER:
      return SummaryCategory.TRANSFER;
    case AppointmentTypeCategory.MONITORING:
      return SummaryCategory.MONITORING;
    case AppointmentTypeCategory.ULTRASOUND:
      return SummaryCategory.ULTRASOUND;
    case AppointmentTypeCategory.DIAGNOSTIC_APPOINTMENT:
      return SummaryCategory.DIAGNOSTIC;
    default:
      return SummaryCategory.CONSULTATION;
  }
};

const getStatusesOptions = (
  type: AppointmentTypes
): { label: string; value: AppointmentStatusType }[] => {
  const appointmentTypeCategory = getAppointmentCategory(type);

  switch (appointmentTypeCategory) {
    case AppointmentTypeCategory.EGG_RETRIEVAL:
      return Object.entries(EggRetrievalAppointmentStatusType).map(
        ([label, value]) => ({ label: i18n.t(label), value })
      );
    case AppointmentTypeCategory.MONITORING:
      return Object.entries(CycleMonitoringAppointmentStatusType).map(
        ([label, value]) => ({ label: i18n.t(label), value })
      );
    case AppointmentTypeCategory.DIAGNOSTIC_APPOINTMENT:
      return Object.entries(DiagnosticAppointmentAppointmentStatusType).map(
        ([label, value]) => ({ label: i18n.t(label), value })
      );
    case AppointmentTypeCategory.FOLLOWUP:
      return Object.entries(FollowupAppointmentStatusType).map(
        ([label, value]) => ({ label: i18n.t(label), value })
      );
    case AppointmentTypeCategory.INITIAL_CONSULTATION:
      return Object.entries(InitialConsultationAppointmentStatusType).map(
        ([label, value]) => ({ label: i18n.t(label), value })
      );
    case AppointmentTypeCategory.ULTRASOUND:
      return Object.entries(AnyUltrasoundAppointmentStatusType).map(
        ([label, value]) => ({ label: i18n.t(label), value })
      );
    case AppointmentTypeCategory.ALL_COUNSELING_EDUCATION:
      return Object.entries(AllCounselingEducationAppointmentStatusType).map(
        ([label, value]) => ({ label: i18n.t(label), value })
      );
    case AppointmentTypeCategory.THIRD_PARTY:
      return Object.entries(ThirdPartyAppointmentStatusType).map(
        ([label, value]) => ({ label: i18n.t(label), value })
      );
    case AppointmentTypeCategory.URGENT_CONSULT:
      return Object.entries(UrgentConsultAppointmentStatusType).map(
        ([label, value]) => ({ label: i18n.t(label), value })
      );
    case AppointmentTypeCategory.CONSULTATION:
      return Object.entries(ConsultationStatusType).map(([label, value]) => ({
        label: i18n.t(label),
        value
      }));
    case AppointmentTypeCategory.IUI:
      return Object.entries(IUIAppointmentStatusType).map(([label, value]) => ({
        label: i18n.t(label),
        value
      }));
    default:
      return [];
  }
};

const getCycleCurrentDay = (cycleStartDate: Date, appointmentDate: Date) => {
  if (!cycleStartDate || !appointmentDate) return 'N/A';
  const result = dayjs(appointmentDate).diff(cycleStartDate, 'day');
  return result >= 0 ? result + 1 : result;
};

const AppointmentStatusMenu: FC<{
  statuses: { label: string; value: AppointmentStatusType }[];
  onSelectStatus: (status: AppointmentStatusType) => void;
}> = ({ statuses, onSelectStatus }) => {
  const menuItems = useMemo<MenuItemProps[]>(
    () =>
      statuses.map(({ label, value }) => ({
        label,
        action: () => onSelectStatus(value),
        justifyContent: 'center'
      })),
    [statuses, onSelectStatus]
  );
  return <Menu items={menuItems} menuTheme={MenuTheme.Dark} />;
};

const filterAppointmentsByType = (
  appointments: Appointment[],
  filteringType: SummaryCategory | string
): Appointment[] => {
  // check if it's the default value
  if (!Object.values<string>(SummaryCategory).includes(filteringType)) {
    return appointments;
  }

  return appointments.filter(
    (appointment) =>
      filteringType ===
      getAppointmentTypeSummaryCategory(appointment.appointmentType)
  );
};

const StyledAppointmentStatusFlex: FC<StyledAppointmentStatusFlexProps> = styled(
  Flex
)<StyledAppointmentStatusFlexProps>`
  ${({ status }) => {
    if (
      status === ThirdPartyAppointmentStatusType.RESULTS_AVAILABLE ||
      status === CycleMonitoringAppointmentStatusType.RESULTS_AVAILABLE ||
      status === DiagnosticAppointmentAppointmentStatusType.RESULTS_AVAILABLE
    ) {
      return `
      background-color: ${Colors.mauvelous};
      color: ${Colors.emperor};`;
    }
  }}

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.5;
    `}
`;

export const DashboardPatientsList: FC<{ date: Dayjs }> = ({ date }) => {
  const { t } = useTranslation();
  const { openPopover, closePopover } = usePopover();
  const { getAggregatedAppointments } = useAppointments();
  const { getDefaultAvatar } = useGetDefaultAvatar();

  const navigate = useNavigate();

  const [startOfDay, endOfDay] = useMemo(
    () => [date.startOf('day'), date.add(1, 'day').startOf('day')],
    [date]
  );

  const { data: appointments, isLoading: isAggregatedAppointmentsLoading } =
    getAggregatedAppointments({
      minDate: startOfDay,
      maxDate: endOfDay
    });
  const [appointmentTypeFilter, setAppointmentTypeFilter] = useState<
    SummaryCategory | string
  >('SHOW_ALL_APPOINTMENTS');

  const appointmentTypeOptions = useMemo(() => {
    const defaultValue = 'SHOW_ALL_APPOINTMENTS';
    return [
      {
        value: defaultValue,
        label: t(defaultValue)
      },
      ...Object.entries(SummaryCategory).map(([_, label]) => ({
        value: label,
        label: t(label)
      }))
    ];
  }, []);

  const columns: GridColDef<Appointment>[] = [
    {
      field: 'patient',
      headerName: t('PATIENT').toUpperCase(),
      flex: 3,
      align: 'left',
      headerAlign: 'center',
      sortComparator: (v1, v2) => v1.localeCompare(v2),
      valueGetter: (value) => getFullName(value),
      renderCell: ({
        row: { patient: { pictureId, id: patientId, ...rest } = {} } = {}
      }) => {
        const avatar = getDefaultAvatar({
          userId: patientId,
          userType: UserTypes.patients,
          pictureId: pictureId
        });

        return (
          <Flex
            flexDirection="row"
            alignItems="center"
            gap={spacings.large}
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(patientRoutes.getByIdLink(patientId))}
          >
            <Avatar image={avatar} />
            <Typography>{getFullName(rest)}</Typography>
          </Flex>
        );
      }
    },
    {
      field: 'currentDay',
      headerName: t('CD').toUpperCase(),
      flex: 1,
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      valueGetter: (_, row) => {
        return getCycleCurrentDay(row.cycle?.startDate, row.date);
      },
      renderCell: ({ value }) => (
        <Flex
          height="100%"
          width="100%"
          paddingX={spacings.small}
          justifyContent="center"
          alignItems="center"
          borderLeft={`1px solid ${Colors.alto}`}
        >
          {value}
        </Flex>
      )
    },
    {
      field: 'appointmentType',
      headerName: t('TYPE').toUpperCase(),
      sortComparator: (v1: AppointmentStatusType, v2: AppointmentStatusType) =>
        v1.localeCompare(v2),
      flex: 3,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (value) => getAppointmentCategory(value),
      renderCell: ({ row: { appointmentType } }) => (
        <Flex
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
          bgcolor={getAppointmentTypeCellBgColor(appointmentType)}
        >
          <Typography fontWeight={weights.extraBold}>
            {getAppointmentCategory(appointmentType)}
          </Typography>
        </Flex>
      )
    },
    {
      field: 'status',
      headerName: t('STATUS').toUpperCase(),
      flex: 3,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (value) => value,
      renderCell: ({ row: appointment }) => {
        const [selectedStatus, setSelectedStatus] =
          useState<AppointmentStatusType | null>(appointment.status);
        const { updateAppointmentStatus } = useAppointments();
        const {
          mutate: updateStatus,
          isLoading: isLoadingUpdateStatus,
          isError
        } = updateAppointmentStatus();

        useEffect(() => {
          if (isError) {
            setSelectedStatus(appointment.status);
          }
        }, [isError]);

        return (
          <StyledAppointmentStatusFlex
            isLoading={isLoadingUpdateStatus}
            width="100%"
            height="100%"
            sx={{ cursor: 'pointer' }}
            justifyContent="center"
            alignItems="center"
            bgcolor={Colors.emperor}
            color={Colors.white}
            status={selectedStatus}
            onClick={(ev) => {
              openPopover({
                anchorOrigin: {
                  horizontal: 'center',
                  vertical: 'bottom'
                },
                transformOrigin: {
                  horizontal: 'left',
                  vertical: 'top'
                },
                children: (
                  <AppointmentStatusMenu
                    statuses={getStatusesOptions(appointment.appointmentType)}
                    onSelectStatus={async (statusToUpdate) => {
                      if (statusToUpdate !== selectedStatus) {
                        setSelectedStatus(statusToUpdate);
                        await updateStatus({
                          appointmentId: appointment.id,
                          updatedAppointment: {
                            ...appointment,
                            status: statusToUpdate,
                            patient: appointment?.patient
                          }
                        });
                      }
                      closePopover();
                    }}
                  />
                ),
                anchorEl: ev.currentTarget,
                hideToolbar: true
              });
            }}
          >
            <Typography color={Colors.white} fontWeight={weights.extraBold}>
              {selectedStatus}
            </Typography>
          </StyledAppointmentStatusFlex>
        );
      }
    },
    {
      field: 'actions',
      headerName: t('ACTIONS').toUpperCase(),
      flex: 3,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
      renderCell: (params) => {
        return (
          <Flex justifyContent="space-between" gap={spacings.medium}>
            <IconButton
              icon={
                <SvgIcon>
                  <CycleIcon />
                </SvgIcon>
              }
              disabled={!params?.row?.cycle?.id}
              bgColor="gray"
              onClick={() => {
                if (!params?.row?.patientId || !params.row.cycle.id) return;
                navigate(
                  patientRoutes.getCurrentTreatmentByCycleLink(
                    params.row.patientId,
                    params.row.cycle.id
                  )
                );
              }}
            />
            <IconButton
              icon={
                <SvgIcon>
                  <ChatIcon />
                </SvgIcon>
              }
              bgColor="cupid"
              disabled={!params?.row?.patientId}
              onClick={() =>
                navigate(patientRoutes.getMessagingLink(params.row.patientId))
              }
            />

            <IconButton
              icon={<AddRoundedIcon />}
              onClick={(ev) => {
                if (!params?.row?.patientId) return;
                openPopover({
                  children: (
                    <PatientActionsMenu patientId={params.row.patientId} />
                  ),
                  anchorEl: ev.currentTarget,
                  hideToolbar: true
                });
              }}
            />
          </Flex>
        );
      }
    },
    {
      field: 'primaryPhysician',
      headerName: t('PHYSICIAN').toUpperCase(),
      flex: 2,
      align: 'left',
      headerAlign: 'left',
      valueGetter: (_, row) => getFullName(row?.patient?.staff),
      sortComparator: (v1, v2) => v1.localeCompare(v2),
      renderCell: ({ row }) =>
        getPrimaryPhysicianColumnRenderCell({ row: row.patient })
    }
  ];

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 2 : 5
    };
  }, []);

  const appointmentsToDisplay =
    appointments?.filter(({ date: appointmentDate }) => {
      return dayjs(appointmentDate).isSame(date, 'day');
    }) || [];

  const filteredAppointments = useMemo(
    () =>
      filterAppointmentsByType(appointmentsToDisplay, appointmentTypeFilter),
    [appointmentsToDisplay]
  );

  return (
    <>
      <Box>
        <Flex justifyContent="space-between">
          <Typography
            fontWeight={weights.extraBold}
            variant="h2"
            marginBottom={spacings.large}
          >
            {t('APPOINTMENTS')}
          </Typography>
          <Select
            width="250px"
            value={appointmentTypeFilter}
            options={appointmentTypeOptions}
            onChange={(e) =>
              setAppointmentTypeFilter(e.target.value as SummaryCategory)
            }
          ></Select>
        </Flex>
        <Box id="dashboard-patients-appointments-container">
          <Table
            variant="carded"
            getRowSpacing={getRowSpacing}
            loading={isAggregatedAppointmentsLoading}
            columns={columns}
            rows={filteredAppointments || []}
            autoHeight={true}
            getRowId={(row: Appointment) => row.id}
            paginationModel={{ page: 0, pageSize: 25 }}
            hideFilterToolbar
          />
        </Box>
      </Box>
    </>
  );
};
