import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/display/Button/Button';
import { spacings } from 'src/components/styles/constants';
import { orderTemplateId } from 'src/types/documents';
import Center from 'src/components/layout/Center/Center';
import Typography from 'src/components/display/Typography';
import { useDocumentEditor } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import { prescriptionTemplateId } from 'src/utils/general';

interface OpenNewOrdersPrescriptionsDocumentEditorProps {
  patientId: string;
  prescriptionsDocumentId?: string;
  ordersDocumentIds?: string[];
  handleSubmit: () => void;
}

const OpenNewOrdersPrescriptionsDocumentEditor: FC<
  OpenNewOrdersPrescriptionsDocumentEditorProps
> = ({
  patientId,
  ordersDocumentIds,
  prescriptionsDocumentId,
  handleSubmit
}) => {
  const { t } = useTranslation();

  const { setDocumentEditorParams, setDocumentsModalOpen } =
    useDocumentEditor();

  return (
    <Center
      marginTop={spacings.xxlarge}
      sx={{ flexDirection: 'column' }}
      gap={spacings.xlarge}
      maxWidth={700}
    >
      <Center marginTop={spacings.xlarge} gap={spacings.xlarge} flexWrap="wrap">
        {ordersDocumentIds?.length ? (
          ordersDocumentIds?.map((documentId, index) => {
            return (
              <Button
                key={documentId}
                onClick={() => {
                  setDocumentEditorParams({
                    patientId,
                    templateId: orderTemplateId,
                    documentId: documentId
                  });
                  setDocumentsModalOpen(true);
                }}
                sx={{
                  marginX: spacings.xlarge
                }}
              >
                {`${t('SIGN_ORDERS_DOCUMENT')} #${index + 1}`}
              </Button>
            );
          })
        ) : (
          <Typography>{t('NO_ORDERS_DOCUMENTS_TO_DISPLAY')}</Typography>
        )}
      </Center>

      {prescriptionsDocumentId ? (
        <Center marginTop={spacings.xlarge} gap={spacings.xlarge}>
          <Button
            onClick={() => {
              setDocumentEditorParams({
                patientId,
                templateId: prescriptionTemplateId, // TODO: Add prescription template id
                documentId: prescriptionsDocumentId
              });
              setDocumentsModalOpen(true);
            }}
            sx={{
              marginX: spacings.xlarge
            }}
          >
            {t('SIGN_PRESCRIPTIONS_DOCUMENT')}
          </Button>
        </Center>
      ) : (
        <Typography>{t('NO_PRESCRIPTION_DOCUMENT_TO_DISPLAY')}</Typography>
      )}

      <Button bgColor="gray" onClick={handleSubmit}>
        {t('FINISH')}
      </Button>
    </Center>
  );
};

export default OpenNewOrdersPrescriptionsDocumentEditor;
