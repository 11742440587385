import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import enPatientsTranslations from 'src/modules/patients/i18n/en';
import enCalendarTranslations from 'src/modules/calendar/i18n/en';
import enNavbarTranslations from 'src/layouts/CollapsedSidebarLayout/i18n/en';
import enAuthTranslations from 'src/content/pages/Auth/i18n/en';
import enOverviewTranslations from 'src/content/overview/i18n/en';
import enMeTranslations from 'src/modules/me/i18n/en';
import enStaffTranslations from 'src/modules/staff/i18n/en';
import enQnATranslations from 'src/modules/qna/i18n/en';
import enBillingTranslations from 'src/modules/billing/i18n/en';
import enClinicSettingsTranslations from 'src/modules/clinic/i18n/en';

// import LanguageDetector from 'i18next-browser-languagedetector';
import enJSON from './translations/en';
import enSessionTranslations from 'src/contexts/i18n/en';
import enFeedTranslations from '../modules/feed/i18n/en';

const resources = {
  en: {
    translation: {
      ...enJSON,
      ...enPatientsTranslations,
      ...enCalendarTranslations,
      ...enNavbarTranslations,
      ...enAuthTranslations,
      ...enOverviewTranslations,
      ...enMeTranslations,
      ...enStaffTranslations,
      ...enSessionTranslations,
      ...enQnATranslations,
      ...enBillingTranslations,
      ...enFeedTranslations,
      ...enClinicSettingsTranslations
    }
  }
};

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    keySeparator: false,
    lng: 'en',
    fallbackLng: 'en',
    react: {
      useSuspense: true
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
