export const weights = {
  regular: 400,
  semiBold: 500,
  bold: 600,
  extraBold: 700,
  black: 900
} as const;

export const fontFamilies = {
  primary: 'Nunito',
  chat: 'Trebuchet MS'
};

export const fonts = {
  body: `${weights.regular} 14px/21px ${fontFamilies.primary}, serif`,
  button: `${weights.bold} 14px/14px ${fontFamilies.primary}, sans-serif`,
  header1: `${weights.bold} 34px/34px ${fontFamilies.primary}, serif`,
  header2: `${weights.bold} 21px/21px ${fontFamilies.primary}, serif`,
  header3: `${weights.bold} 16px/16px ${fontFamilies.primary}, serif`,
  header4: `${weights.bold} 14px/14px ${fontFamilies.primary}, serif`,
  input: `${weights.regular} 14px/18px ${fontFamilies.primary}, serif`,
  label: `${weights.bold} 14px/14px ${fontFamilies.primary}, sans-serif`,
  caption: `${weights.regular} 10px/12px ${fontFamilies.primary}, serif`,
  pill: `${weights.regular} 12px/14px ${fontFamilies.primary}, serif`,
  tooltip: `${weights.semiBold} 12px/12px ${fontFamilies.primary}, serif`,
  modalTitle: `${weights.bold} 24px/24px ${fontFamilies.primary}, sans-serif`,
  scheduleTitle: `${weights.extraBold} 21px/24px ${fontFamilies.primary}, sans-serif`,
  tableColumnHeader: `${weights.regular} 12px/18px ${fontFamilies.primary}, serif`,
  tableCellTitle: `${weights.regular} 12px/18px ${fontFamilies.primary}, serif`,
  calendarContent: `${weights.regular} 18px/22px ${fontFamilies.primary}, serif`,
  calendarHeader: `${weights.regular} 24px/16px ${fontFamilies.primary}, serif`,
  badge: `${weights.regular} 12px/15px ${fontFamilies.primary}, sans-serif`,
  calendarSidebarTitle: `${weights.extraBold} 16px/16px ${fontFamilies.primary}, sans-serif`,
  bigErrorNumber: `${weights.extraBold} 300px/400px ${fontFamilies.primary}, sans-serif`
} as const;
