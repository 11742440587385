import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { spacings } from 'src/components/styles/constants';
import { weights } from 'src/components/styles/fonts';
import Typography from 'src/components/display/Typography/Typography';
import Flex from 'src/components/layout/Flex/Flex';
import Center from 'src/components/layout/Center';
import Button from 'src/components/display/Button';
import { MedicalAnswer, MedicalQuestion } from 'src/types/qna';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import useQnaApi from 'src/hooks/useQnaApi';

export const DeleteMedicalAnswerDialog: FC<{
  patientId: string;
  question: MedicalQuestion;
  answer: MedicalAnswer;
  answerKey?: string;
}> = ({ patientId, question, answer, answerKey }) => {
  const { t } = useTranslation();
  const { closeDialog } = useDialog();
  const { deleteAnswerForQuestion } = useQnaApi();

  const { mutate: handleDeleteAnswerForQuestion, isLoading } =
    deleteAnswerForQuestion();

  const isAnswerObject =
    typeof answer === 'object' && !Array.isArray(answer) && answerKey;

  const handleDelete = async () => {
    const questionId = question.id;

    if (isAnswerObject) {
      await handleDeleteAnswerForQuestion({
        patientId,
        questionId,
        answerKey
      });
    } else {
      await handleDeleteAnswerForQuestion({ patientId, questionId });
    }

    closeDialog();
  };

  return (
    <Flex
      flexDirection="column"
      minWidth="350px"
      maxWidth="650px"
      gap={spacings.medium}
      marginTop={spacings.medium}
    >
      <Typography fontWeight={weights.bold}>
        {t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_ANSWER')}
      </Typography>
      <Center gap={spacings.xlarge}>
        <Button onClick={() => closeDialog()} disabled={isLoading}>
          {t('CANCEL')}
        </Button>
        <Button onClick={() => handleDelete()} disabled={isLoading}>
          {t('DELETE')}
        </Button>
      </Center>
    </Flex>
  );
};
