import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Avatar from 'src/components/display/Avatar';
import Card from 'src/components/display/Card';
import Loader from 'src/components/display/Loader';
import Typography from 'src/components/display/Typography';
import Box from 'src/components/layout/Box';
import Flex from 'src/components/layout/Flex';
import { Colors } from 'src/components/styles/colors';
import { iconSizes, radii, spacings } from 'src/components/styles/constants';
import { weights } from 'src/components/styles/fonts';
import useCycle from 'src/hooks/useCycle';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { patientRoutes } from 'src/router/routes';
import { UserTypes } from 'src/types/user';
import { getDateFormat } from 'src/utils/dateAndTIme';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';
import { getCycleLengthText, getFullName, getUserAge } from 'src/utils/general';

interface Props {
  patientId: string;
}

const PatientHeader = ({ patientId }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getPatientOverview } = usePatientsApi();
  const { getCurrentCycle } = useCycle();
  const { getDefaultAvatar } = useGetDefaultAvatar();

  const {
    data: patientOverview,
    isLoading: isLoadingPatientOverview,
    refetch: refetchPatientOverview
  } = getPatientOverview(patientId);

  const { data: currentCycle, refetch: refetchCurrentCycle } =
    getCurrentCycle(patientId);

  const patientAvatar = getDefaultAvatar({
    userId: patientId,
    userType: UserTypes.patients,
    pictureId: patientOverview?.patientBasicInfo?.pictureId
  });

  useEffect(() => {
    if (patientId) {
      refetchPatientOverview();
      refetchCurrentCycle();
    }
  }, [patientId]);

  if (isLoadingPatientOverview)
    return (
      <Flex
        justifyContent={'center'}
        alignItems="center"
        height="50%"
        width="100%"
      >
        <Loader size={iconSizes.xlarge} />
      </Flex>
    );
  return (
    <Flex gap={spacings.xlarge}>
      <Card
        borderRadius={radii.medium}
        display="flex"
        flexDirection="column"
        flex={1}
        shadow
        padding={spacings.large}
        gap={spacings.large}
      >
        <Typography
          variant="h4"
          fontWeight={weights.extraBold}
          color={Colors.emperor}
        >
          {getFullName(patientOverview.patientBasicInfo).toUpperCase()}
        </Typography>
        <Box border={`1px solid ${Colors.mercury}`} />

        <Flex gap={spacings.xxlarge}>
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              navigate(patientRoutes.getByIdLink(patientId.toString()))
            }
          >
            <Avatar image={patientAvatar} />
          </Box>
          <Flex gap={spacings.medium}>
            <Flex flexDirection="column" gap={spacings.small}>
              <Typography variant="h4" color={Colors.emperor}>
                {t('AGE')}
              </Typography>
              <Typography variant="h4" color={Colors.emperor}>
                {t('SEX')}
              </Typography>
              <Typography variant="h4" color={Colors.emperor}>
                {t('BMI')}
              </Typography>
              <Typography variant="h4" color={Colors.emperor}>
                {t('AMH')}
              </Typography>
              <Typography variant="h4" color={Colors.emperor}>
                {t('CYCLE')}
              </Typography>
              <Typography variant="h4" color={Colors.emperor}>
                {t('PATIENT_ID')}
              </Typography>
            </Flex>

            <Flex flexDirection="column" gap={spacings.small}>
              <Typography variant="h4" color={Colors.emperor}>
                {getUserAge({
                  years: patientOverview?.patientBasicInfo.age?.years,
                  months: patientOverview?.patientBasicInfo.age?.months,
                  isShort: true
                })}
              </Typography>
              <Typography variant="h4" color={Colors.emperor}>
                {patientOverview?.patientBasicInfo.sex || '--'}
              </Typography>
              <Typography variant="h4" color={Colors.emperor}>
                {patientOverview?.patientProperties?.bmi?.toFixed(2) || '--'}
              </Typography>
              <Typography variant="h4" color={Colors.emperor}>
                {patientOverview?.patientProperties?.amh || '--'}
              </Typography>
              <Typography variant="h4" color={Colors.emperor}>
                {getCycleLengthText(
                  patientOverview?.patientProperties?.cycle
                ) || '--'}
              </Typography>
              <Typography variant="h4" color={Colors.emperor}>
                {patientOverview?.patientBasicInfo?.displayId?.toUpperCase()}
              </Typography>
            </Flex>
          </Flex>
        </Flex>
      </Card>
      <Card
        borderRadius={radii.medium}
        display="flex"
        flexDirection="column"
        flex={3}
        shadow
        padding={spacings.large}
        gap={spacings.large}
      >
        <Flex justifyContent="space-between">
          <Typography
            variant="h4"
            fontWeight={weights.extraBold}
            color={Colors.emperor}
          >
            {t('TREATMENT OVERVIEW')}
          </Typography>
          <Flex>
            <Typography variant="h4" color={Colors.emperor}>
              {t('START_DATE')}:&nbsp;
            </Typography>
            <Typography
              variant="h4"
              fontWeight={weights.extraBold}
              color={Colors.emperor}
            >
              {currentCycle?.startDate
                ? dayjs(currentCycle.startDate).format(getDateFormat())
                : '--'}
            </Typography>
          </Flex>
        </Flex>
        <Box border={`1px solid ${Colors.mercury}`} />
        <Flex gap={spacings.xxlarge} justifyContent="space-between" flex={1}>
          <Flex flexDirection="column" gap={spacings.small}>
            <Typography variant="h4" color={Colors.emperor}>
              {t('TREATMENT')}
            </Typography>
            <Typography variant="h4" color={Colors.emperor}>
              {currentCycle?.treatmentType}
            </Typography>
          </Flex>

          <Flex flexDirection="column" gap={spacings.small}>
            <Typography variant="h4" color={Colors.emperor}>
              {t('PROTOCOL')}
            </Typography>
            <Typography variant="h4" color={Colors.emperor}>
              {currentCycle?.treatmentProtocol}
            </Typography>
          </Flex>

          <Flex flexDirection="column" gap={spacings.small}>
            <Typography variant="h4" color={Colors.emperor}>
              {t('EGG_SOURCE')}
            </Typography>
            <Typography
              variant="h4"
              fontWeight={weights.extraBold}
              color={Colors.emperor}
            >
              {currentCycle?.eggSource}
            </Typography>
          </Flex>

          <Flex flexDirection="column" gap={spacings.small}>
            <Typography variant="h4" color={Colors.emperor}>
              {t('SPERM_SOURCE')}
            </Typography>
            <Typography
              variant="h4"
              fontWeight={weights.extraBold}
              color={Colors.emperor}
            >
              {currentCycle?.spermSource}
            </Typography>
          </Flex>

          <Flex flexDirection="column" gap={spacings.small}>
            <Typography variant="h4" color={Colors.emperor}>
              {t('SURROGACY')}
            </Typography>
            <Typography
              variant="h4"
              fontWeight={weights.extraBold}
              color={Colors.emperor}
            >
              {t(currentCycle?.surrogacy)}
            </Typography>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
};

export default PatientHeader;
