import { styled } from '@mui/material';
import { FC, useState } from 'react';
import InfiniteAutocomplete from 'src/components/data-entry/InfiniteAutocomplete';
import { InputFieldVariant } from 'src/components/data-entry/InputField/InputField';
import { Colors } from 'src/components/styles/colors';
import { PageSizes, radii, spacings } from 'src/components/styles/constants';
import useCodes from 'src/hooks/useCodes';
import useDebounce from 'src/hooks/useDebounce';
import { Icd10Code } from 'src/types/codes';

interface Icd10SelectorProps {
  onChange: (diagnosis: Icd10Code) => void | Promise<void>;
  value?: Icd10Code;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
}

const StyledIcd10Autocomplete = styled(InfiniteAutocomplete)`
  .MuiOutlinedInput-root.MuiInputBase-root.MuiAutocomplete-inputRoot {
    padding: 0;
    border-radius: ${radii.small};
  }

  .MuiInputBase-input.MuiAutocomplete-input {
    padding: ${spacings.small} ${spacings.medium};
  }

  .MuiInputBase-root {
    border: none;
  }
`;

const StyledInputWrapper = styled('div') <{
  error?: boolean;
  disabled?: boolean;
}>`
  border-bottom: 1px solid ${Colors.gray};
  border-radius: ${radii.small};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  &:hover {
    border-bottom: 1px solid ${Colors.gray};
    box-shadow: none;
  }
`;

const Icd10Selector: FC<Icd10SelectorProps> = ({
  onChange,
  value,
  error,
  helperText,
  disabled = false
}) => {
  const { getICD10Codes } = useCodes();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm);

  const {
    data: icd10Codes,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = getICD10Codes({
    searchTerm: debouncedSearchTerm,
    pageSize: PageSizes.MEDIUM
  });

  const optionsToRender: Icd10Code[] =
    icd10Codes?.pages.flatMap((page) => page.results) || [];

  return (
    <StyledInputWrapper disabled={disabled}>
      <StyledIcd10Autocomplete
        options={optionsToRender}
        getOptionLabel={(option: Icd10Code) => option?.label}
        value={value}
        onChange={(_event, newValue: Icd10Code) => onChange(newValue)}
        onInputChange={(_event, newInputValue) => setSearchTerm(newInputValue)}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        isLoading={isLoading}
        freeListWidth
        disabled={disabled}
        InputProps={{
          disabled,
          error,
          helperText,
          variant: InputFieldVariant.COMPACT,
          noBorder: true
        }}
      />
    </StyledInputWrapper>
  );
};

export default Icd10Selector;
