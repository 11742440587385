import React, { useState } from 'react';
import { SvgIcon } from '@mui/material';
import Button from '../../components/display/Button';
import useFeed from '../../hooks/useFeed';
import { ReactComponent as FlagIcon } from '../../assets/icons/flag.svg';
import { Colors } from '../../components/styles/colors';
import Loader from '../../components/display/Loader';

interface FlagActivityButtonProps {
  activityId: string;
  isFlagged: boolean;
}

export const FlagActivityButton: React.FC<FlagActivityButtonProps> = ({
  activityId,
  isFlagged: isFlaggedProp
}) => {
  const { flagActivity } = useFeed();
  const [isFlagged, setIsFlagged] = useState(isFlaggedProp);
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: toggleFlagActivity } = flagActivity();

  const handleActivityFlagClick = async () => {
    setIsLoading(true);

    await toggleFlagActivity({
      activityId,
      isFlagged: !isFlagged
    });
    setIsFlagged(!isFlagged);
    setIsLoading(false);
  };

  return (
    <Button
      bgColor="transparent"
      onClick={handleActivityFlagClick}
      disabled={isLoading}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <SvgIcon>
          <FlagIcon color={isFlagged ? Colors.red : Colors.emperor} />
        </SvgIcon>
      )}
    </Button>
  );
};
