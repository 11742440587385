import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { GridColDef } from '@mui/x-data-grid-premium';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material';
import Table from 'src/components/display/Table/Table';
import Card from 'src/components/display/Card/Card';
import { spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography/Typography';
import Flex from 'src/components/layout/Flex/Flex';
import { weights } from 'src/components/styles/fonts';
import { Colors } from 'src/components/styles/colors';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import { DocumentEditorParams, PatientDocument } from 'src/types/documents';
import { AddDocumentForm } from '../actionMenu/AddDocumentForm';
import useDocumentsApi from '../../../hooks/useDocumentsApi';
import Chip from 'src/components/data-entry/Chips/Chip';
import dayjs from 'dayjs';
import { getDateFormat, longDateTimeFormat } from 'src/utils/dateAndTIme';
import { getDocumentSignatureStatus } from '../../documents/utils/editorUtils/getDocumentSignatureStatus';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';

const StyledTable = styled(Table)`
  .MuiDataGrid-columnHeaders,
  .MuiDataGrid-row {
    color: ${Colors.emperor};
    padding-inline: 0;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: ${weights.extraBold};
  }
  .MuiDataGrid-cell:not(:first-of-type) {
    font-weight: ${weights.extraBold};
  }
  .MuiPaginationItem-root {
    margin-right: 20px;
  }
`;

export const PatientGeneralDocumentsTable: FC<{
  patientId: string;
  isPartner?: boolean;
  setDocumentsModalOpen?: () => void;
  setDocumentEditorInfo?: (info: DocumentEditorParams) => void;
}> = ({
  patientId,
  isPartner,
  setDocumentsModalOpen,
  setDocumentEditorInfo
}) => {
  const { t } = useTranslation();
  const { getPatientGeneralDocuments } = useDocumentsApi();
  const { openDialog } = useDialog();

  const { data: patientDocuments } = getPatientGeneralDocuments(patientId);

  const columns: GridColDef<PatientDocument>[] = [
    {
      field: 'edit',
      headerName: '',
      flex: 0.5,
      sortable: false,
      align: 'center',
      filterable: false,
      valueGetter: (_, row) => row.id,
      renderCell: ({ row: { templateId, id } }) => {
        return (
          <MiniIconButton
            icon={<EditIcon />}
            onClick={() => {
              setDocumentsModalOpen();
              setDocumentEditorInfo({
                patientId,
                templateId: templateId || null,
                documentId: id
              });
            }}
          />
        );
      }
    },
    {
      field: 'createdAt',
      flex: 2,
      headerName: t('ADDED_DATE'),
      type: 'date' as const,
      valueFormatter: (value) => dayjs(value).format(getDateFormat()),
      sortComparator: (v1, v2) => dayjs(v1).unix() - dayjs(v2).unix()
    },
    {
      field: 'name',
      flex: 5,
      headerName: t('NAME').toUpperCase(),
      renderCell: ({ row: { name, createdAt } }) => {
        return (
          <Typography>{`${name} - ${dayjs(createdAt).format(
            longDateTimeFormat
          )}`}</Typography>
        );
      }
    },
    {
      field: 'isCompleted',
      flex: 5,
      headerName: t('STATUS').toUpperCase(),
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: ({ row: { isCompleted, isInitialVendorEmailSent } }) => {
        const { color, status } =
          getDocumentSignatureStatus({
            isDocumentCompleted: isCompleted,
            isInitialVendorEmailSent
          }) || {};

        return (
          <Chip
            sx={{ backgroundColor: color, fontWeight: weights.extraBold }}
            label={t(status).toUpperCase()}
          />
        );
      }
    }
  ];

  return (
    <Card shadow marginBottom={spacings.xxlarge}>
      <StyledTable
        columns={columns}
        columnHeaderHeight={50}
        rows={patientDocuments || []}
        toolbar={
          <Flex
            width="100%"
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography
              paddingY={spacings.large}
              fontWeight={weights.extraBold}
            >
              {t(
                isPartner ? 'PARTNER_GENERAL_DOCUMENTS' : 'GENERAL_DOCUMENTS'
              ).toUpperCase()}
            </Typography>
            <MiniIconButton
              icon={<AddIcon />}
              onClick={() => {
                openDialog({
                  header: t('CHOOSE_A_DOCUMENT'),
                  children: <AddDocumentForm patientId={patientId} />,
                  maxWidth: 'md'
                });
              }}
            />
          </Flex>
        }
        autoHeight
        freeHeight
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'createdAt',
                sort: 'desc'
              }
            ]
          }
        }}
      />
    </Card>
  );
};
