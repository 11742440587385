import { FC, ReactElement } from 'react';
import { styled } from '@mui/material';
import { spacings } from 'src/components/styles/constants';
import { Colors } from 'src/components/styles/colors';
import { weights } from 'src/components/styles/fonts';
import Flex from 'src/components/layout/Flex/Flex';
import Box, { BoxProps } from 'src/components/layout/Box/Box';
import { Trend } from 'src/types/global';
import Typography from '../Typography/Typography';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';
import { makeShouldForwardProps } from 'src/components/utils';
import { NumberPreviewSize } from './types';

interface NumberPreviewProps extends BoxProps {
  value: number | string;
  topText?: string;
  bottomText?: string;
  size?: NumberPreviewSize;
  trend?: Trend;
}

const getNumberSize = (size: NumberPreviewSize): string => {
  switch (size) {
    case 'small':
      return '20px';
    case 'medium':
      return '45px';
    case 'large':
      return '70px';
  }
};

const StyledNumberPreviewContainer = styled(Flex)`
  flex-direction: column;
  overflow: hidden;
  width: fit-content;
  padding: 0 ${spacings.medium};
  align-items: center;
  min-width: 68px;

  &:not(:last-of-type) {
    border-inline-end: 1px solid ${Colors.alto2};
  }
`;

const shouldForwardProp = makeShouldForwardProps(['size']);
const StyledNumberTypography = styled(Typography, {
  shouldForwardProp
})<{
  size: NumberPreviewSize;
}>`
  color: ${Colors.darkGray};
  text-align: center;
  font-size: ${({ size }) => getNumberSize(size)};
  line-height: 1;
  font-weight: ${weights.bold};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const StyledNumberTitleTypography = styled(Typography)`
  font-size: 12px;
`;

const getComparisonArrowIcon = (trend: Trend): ReactElement => {
  switch (trend) {
    case Trend.ABOVE:
      return <ArrowIcon color={Colors.alizarinCrimson} />;
    case Trend.BELOW:
      return (
        <ArrowIcon transform="rotate(180)" color={Colors.alizarinCrimson} />
      );
    default:
      return <ArrowIcon color={Colors.emperor} />;
  }
};

const NumberPreview: FC<NumberPreviewProps> = ({
  value,
  topText,
  bottomText,
  size = NumberPreviewSize.medium,
  trend,
  id,
  ...otherProps
}) => {
  return (
    <StyledNumberPreviewContainer {...otherProps} id={id}>
      <StyledNumberTitleTypography marginBottom={spacings.small} align="center">
        {topText || ' '}
      </StyledNumberTitleTypography>
      <Flex alignItems="center" gap={spacings.small}>
        <Box flex={2}>
          <StyledNumberTypography
            size={size}
            id={id ? `${id}-number-dashboard` : undefined}
          >
            {value || value === 0 ? value : '--'}
          </StyledNumberTypography>
        </Box>
        {trend && <Box>{getComparisonArrowIcon(trend)}</Box>}
      </Flex>
      <StyledNumberTitleTypography align="center" marginTop={spacings.small}>
        {bottomText || ' '}
      </StyledNumberTitleTypography>
    </StyledNumberPreviewContainer>
  );
};

export default NumberPreview;
