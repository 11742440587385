import { FC, useMemo } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  useParams,
  Navigate
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Tabs from 'src/components/display/Tabs';
import Typography from 'src/components/display/Typography';
import { spacings } from 'src/components/styles/constants';
import StaffList from '../staff/staffList/StaffList';
import AddEditStaffMemberForm from '../staff/AddEditStaffMemberForm';
import PermissionsPage from '../staff/staffList/PermissionsPage';
import DataExportTab from './DataExport/DataExportTab';
import Card from 'src/components/display/Card';
import { TabContentsProps } from 'src/components/display/Tabs/Tabs';
import { FeatureFlags } from 'src/types/featureFlags';
import { useFeatureFlag } from 'configcat-react';
import Loader from 'src/components/display/Loader';

export enum ClinicSettingsTabs {
  STAFF = 'staff',
  DATA_EXPORT = 'data-export'
}

const ClinicSettings: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tab } = useParams<{ tab?: ClinicSettingsTabs }>();
  const { value: enableExportView, loading: enableExportViewLoading } =
    useFeatureFlag(FeatureFlags.ENABLE_EXPORT_VIEW, false);

  const tabs: TabContentsProps[] = [
    { value: ClinicSettingsTabs.STAFF, label: t('STAFF') }
  ];

  if (enableExportView) {
    tabs.push({
      value: ClinicSettingsTabs.DATA_EXPORT,
      label: t('DATA_EXPORT')
    });
  }

  const currentTab = useMemo(
    () => tabs.find((t) => t.value === tab)?.value || ClinicSettingsTabs.STAFF,
    [tab]
  );

  const handleTabChange = (value: ClinicSettingsTabs) => {
    navigate(`/app/clinic-settings/${value}`);
  };

  if (enableExportViewLoading) {
    return <Loader />;
  }

  return (
    <Card padding={spacings.xlarge}>
      <Typography variant="h1" paddingBottom={spacings.xlarge}>
        {t('CLINIC_SETTINGS')}
      </Typography>
      <Tabs
        tabContents={tabs}
        onTabChange={handleTabChange}
        selectedTab={currentTab}
      />
      <Routes>
        <Route index element={<Navigate to="staff" replace />} />
        <Route path="staff">
          <Route index element={<StaffList />} />
          <Route path="add" element={<AddEditStaffMemberForm />} />
          <Route path="permissions" element={<PermissionsPage />} />
          <Route path=":staffId/edit" element={<AddEditStaffMemberForm />} />
        </Route>
        {enableExportView && (
          <Route path="data-export" element={<DataExportTab />} />
        )}
      </Routes>
    </Card>
  );
};

export default ClinicSettings;
