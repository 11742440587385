import IconButton from 'src/components/display/IconButton/IconButton';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { Colors } from 'src/components/styles/colors';
import { Task } from 'src/types/task';
import Flex from 'src/components/layout/Flex';
import Typography from 'src/components/display/Typography';
import { getDateFormat } from 'src/utils/dateAndTIme';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

interface Props {
  task: Task;
}
const TaskInboxCard = ({ task }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <IconButton
        icon={<PlaylistAddCheckIcon sx={{ color: Colors.emperor }} />}
        iconSize="large"
        bgColor={'cupid'}
      />
      <Flex flex={0.5} flexDirection="column">
        <Typography variant="h4" color={Colors.silverChalice}>
          {task.lastAssignedAt
            ? dayjs(task.lastAssignedAt).format(getDateFormat())
            : '--'}
        </Typography>
      </Flex>

      <Flex flexDirection="column" flex={1} position={'relative'}>
        <Typography variant="h4" color={Colors.emperor}>
          {task.patientName}
        </Typography>
        <Typography
          variant="h4"
          color={Colors.emperor}
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          maxWidth="170px"
          overflow="hidden"
        >
          {t('NEW_TASKS_TITLE')}
        </Typography>
      </Flex>
    </>
  );
};

export default TaskInboxCard;
