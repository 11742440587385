import { PatientInsurance } from '../../../types/patientInsurance';

export type PatientInsurancesForm = {
  insurances: PatientInsurance[];
};

export enum DataIndex {
  PRIMARY = 1,
  SECONDARY = 2,
  TERTIARY = 3
}
