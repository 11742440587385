import { useMemo, useRef, useState } from 'react';
import { Box, css, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { Calendar as BigCalendar, dayjsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import {
  AllPurposesOption,
  ALL_ROOMS_OPTION,
  Appointment,
  AppointmentPurposeFilterOptions,
  BusyTime,
  DefaultClinicRoomOptions
} from 'src/types/appointment';
import { getFullName } from 'src/utils/general';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { makeShouldForwardProps } from 'src/components/utils';
import useMeApi from 'src/hooks/useMeApi';
import { getTimeFormat } from 'src/utils/dateAndTIme';
import { AppointmentDetails } from '../../../modules/patients/overview/AppointmentDetails';
import Typography from '../Typography';
import {
  shadows,
  radii,
  spacings,
  containerSizes,
  zIndices
} from '../../styles/constants';
import { Colors } from '../../styles/colors';
import { fontFamilies, fonts, weights } from '../../styles/fonts';
import CustomToolbar from './CustomToolbar';
import useAppointments from 'src/hooks/useAppointments';
import { getUnifiedBusyTimes } from './utils';

const localizer = dayjsLocalizer(dayjs);

const shouldForwardPropBigCalendarWrapper = makeShouldForwardProps(['dayView']);
const BigCalendarWrapper = styled(Box, {
  shouldForwardProp: shouldForwardPropBigCalendarWrapper
})<{ dayView?: boolean }>`
  height: ${containerSizes.boxed};
  overflow: scroll;
  overflow-x: hidden;

  ${({ dayView }) =>
    dayView &&
    css`
      .rbc-time-header {
        display: none;
      }
    `}

  .rbc-allday-cell {
    display: none;
  }

  .rbc-time-header-content {
    border-color: transparent;
  }
  .rbc-time-view {
    border-color: transparent;
  }

  .rbc-event-label {
    display: none;
  }

  .rbc-today {
    background: transparent;
    .rbc-events-container {
      .rbc-event {
        background: #f9f0f1;
      }
    }
  }

  .rbc-header.rbc-today {
    .MuiTypography-secondary {
      color: black;
    }
  }

  .rbc-event {
    background-color: transparent;
    border: none;
    box-shadow: ${shadows.default};
    z-index: 90;
    border-radius: ${radii.large};
    padding: 0;
  }
  .rbc-day-slot .rbc-event,
  .rbc-day-slot .rbc-background-event {
    border-color: transparent;
  }

  .rbc-event-content {
    display: flex;
    flex-direction: column;
  }

  .rbc-header + .rbc-header {
    border-color: transparent;
  }

  .rbc-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: transparent;
    .rbc-button-link {
      height: 91px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      span {
        ${({ theme }) => ({
          [theme.breakpoints.down('lg')]: css`
            font: ${fonts.header3};
          `,
          [theme.breakpoints.up('lg')]: css`
            font: ${fonts.calendarHeader};
          `
        })}
        font-weight: ${weights.regular};
        color: ${Colors.emperor};
      }
    }
    &.rbc-today {
      span {
        font-weight: bold;
      }
    }
  }

  .rbc-time-content {
    background: ${({ dayView }) =>
      dayView ? Colors.transparent : Colors.white};
    padding-left: ${({ dayView }) =>
      dayView ? spacings.none : spacings.xxlarge};
    border-color: ${Colors.transparent};
    overflow: visible;
  }

  .rbc-time-header-gutter {
    width: 64px !important;
    position: static !important;
  }

  .rbc-time-content:first-of-type {
    background: #191515;
    border-color: transparent;
  }

  .rbc-time-slot {
    border-color: transparent;
    border-top: none;
    position: relative;
  }

  .rbc-time-gutter rbc-time-column {
    border-color: transparent;
  }

  .rbc-time-gutter {
    width: 74px;
    & > .rbc-timeslot-group {
      border-color: transparent;
      min-height: 120px;
    }
  }

  .rbc-label {
    font-family: ${fontFamilies.primary};
    font-size: 10px;
    color: ${Colors.silverChalice};
    position: absolute;
    top: -10px;
    left: 0;
  }

  .rbc-events-container {
    overflow: visible;
    z-index: ${zIndices.low};
  }

  .rbc-time-gutter {
    .rbc-timeslot-group:first-of-type {
      .rbc-time-slot {
        .rbc-label {
          display: none;
        }
      }
    }
  }

  .rbc-events-container {
    z-index: unset;
  }
  .rbc-time-header {
    padding-left: 35px;
  }

  .rbc-calendar {
    .rbc-time-view {
      overflow: visible;
    }
  }

  .rbc-time-header {
    ${({ theme }) => ({
      [theme.breakpoints.down('lg')]: {
        height: '60px'
      },
      [theme.breakpoints.up('lg')]: {
        height: '118px'
      }
    })}
  }

  .rbc-row {
    height: 100%;
  }

  .rbc-time-gutter + .rbc-day-slot {
    .rbc-timeslot-group {
      border: none;
      border-left: 1px solid transparent;
      border-bottom: 1px solid #ddd;
    }
    .rbc-events-container {
      border-left: 1px solid transparent;
    }
  }

  .rbc-background-event {
    background-color: ${Colors.whiteIceAlpha50};
    cursor: default;
    left: 20% !important;
    width: 50%;
    border-radius: ${radii.large};
    .rbc-event-content .MuiBox-root {
      display: flex;
      felx-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

const DayHeaderWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
`;

const shouldForwardPropEventWrapper = makeShouldForwardProps(['isShort']);
const EventWrapper = styled(Box, {
  shouldForwardProp: shouldForwardPropEventWrapper
})<{ isShort?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 3px;
  padding: 7px 7px;
  width: 100%;
`;

const shouldForwardPropAppointmentType = makeShouldForwardProps(['isShort']);
const StyledAppointmentTypeTypography = styled(Typography, {
  shouldForwardProp: shouldForwardPropAppointmentType
})<{ isShort?: boolean }>`
  ${({ isShort }) =>
    isShort &&
    css`
      font-weight: 700;
    `}
`;

const dayHeader = ({ date }: { date: Date }) => {
  return (
    <DayHeaderWrapper
      sx={{
        gap: { xs: 0, lg: spacings.medium }
      }}
    >
      <Typography>
        {date
          .toLocaleDateString('en', {
            weekday: 'short'
          })
          .toUpperCase()}
      </Typography>
      <Typography>{date.getDate()}</Typography>
    </DayHeaderWrapper>
  );
};

const isShort = (event: Event) =>
  dayjs(event.end).diff(dayjs(event.start), 'minutes') < 30;

const getFilteredAppointments = (
  appointments: Appointment[],
  filterByAppointment: AppointmentPurposeFilterOptions,
  filterByStaff: string[],
  filterByRoom: string
): Appointment[] => {
  if (!filterByAppointment && !filterByStaff && !filterByRoom) {
    return appointments;
  }

  let filteredAppointments = appointments;

  if (filterByAppointment != AllPurposesOption.ALL_TYPES) {
    filteredAppointments = filteredAppointments.filter(
      (appointment) => appointment.appointmentPurpose === filterByAppointment
    );
  }

  if (filterByStaff.length > 0) {
    filteredAppointments = filteredAppointments.filter(
      (appointment) =>
        filterByStaff.filter((staffToFilterBy) =>
          appointment.staffIds?.includes(staffToFilterBy)
        ).length > 0
    );
  }

  if (filterByRoom !== ALL_ROOMS_OPTION) {
    filteredAppointments = filteredAppointments.filter((appointment) => {
      return appointment?.room === filterByRoom;
    });
  }
  return filteredAppointments;
};

const defaultRoomOptions = Object.values(DefaultClinicRoomOptions).map(
  (value: string) => value
);

interface Event extends Appointment {
  allDay?: boolean;
  color?: string;
  start: Date;
  end: Date;
  isBusyTime?: boolean;
}
const Calendar = ({
  date,
  onNavigate,
  dayView: dayViewProp,
  smallHeader,
  filterByStaff,
  filterByAppointment,
  filterByRoom
}: {
  date?: Dayjs;
  onNavigate?: (newDate: Dayjs) => void;
  dayView?: boolean;
  smallHeader?: boolean;
  filterByStaff?: Array<string>;
  filterByAppointment?: AppointmentPurposeFilterOptions;
  filterByRoom?: string;
}) => {
  const calendarRef = useRef<any | null>(null);
  const { t } = useTranslation();
  const { openDialog } = useDialog();
  const [dayView, setDayView] = useState(dayViewProp ?? false);
  const { getMe } = useMeApi();
  const {
    getClinicRoomBusyTimes,
    getStaffBusyTimes,
    getAggregatedAppointments
  } = useAppointments();

  const { data: me } = getMe();
  const [startOfDay, endOfDay] = useMemo(
    () => [date.startOf('week'), date.endOf('week')],
    [date]
  );

  const { data: appointments } = getAggregatedAppointments({
    minDate: startOfDay,
    maxDate: endOfDay
  });

  const filteredAppointments = useMemo(() => {
    return getFilteredAppointments(
      appointments,
      filterByAppointment,
      filterByStaff,
      filterByRoom
    );
  }, [appointments, filterByAppointment, filterByStaff, filterByRoom]);

  const [startOfWeek, endOfWeek] = useMemo(
    () => [date.startOf('week'), date.endOf('week')],
    [date]
  );

  const enableGetRoomBusyTimes = useMemo(() => {
    if (!filterByRoom) return false;

    if (filterByRoom === ALL_ROOMS_OPTION) return false;

    if (defaultRoomOptions.includes(filterByRoom)) return false;

    return true;
  }, [filterByRoom]);

  const enableGetStaffBusyTimes = filterByStaff?.length > 0;

  const { data: roomBusyTimes } = getClinicRoomBusyTimes(
    { roomId: filterByRoom, minDate: startOfWeek, maxDate: endOfWeek },
    {
      enabled: enableGetRoomBusyTimes
    }
  );

  const { data: staffBusyTimes } = getStaffBusyTimes(
    { staffIds: filterByStaff, minDate: startOfWeek, maxDate: endOfWeek },
    { enabled: enableGetStaffBusyTimes }
  );

  const appointmentElements = useMemo(() => {
    return filteredAppointments?.map((appointment) => ({
      ...appointment,
      end: dayjs(appointment.endTime).toDate(),
      start: dayjs(appointment.date).toDate(),
      title: t(appointment.appointmentType),
      allDay: false,
      color: appointment.staffIds?.includes(me?.user.id)
        ? Colors.cupid
        : Colors.whiteIce
    }));
  }, [filteredAppointments]);

  const busyTimeElements = useMemo(() => {
    const unifiedBusyTimes = getUnifiedBusyTimes([
      ...((enableGetRoomBusyTimes && roomBusyTimes) || []),
      ...((enableGetStaffBusyTimes && staffBusyTimes) || [])
    ]);
    return unifiedBusyTimes?.map(
      ({ startTime, endTime }: Partial<BusyTime>) => ({
        isBusyTime: true,
        end: endTime.toDate(),
        start: startTime.toDate(),
        allDay: false,
        color: null,
        title: t('BUSY')
      })
    );
  }, [
    roomBusyTimes,
    staffBusyTimes,
    enableGetRoomBusyTimes,
    enableGetStaffBusyTimes
  ]);

  const eventRenderer = ({ event }: { event: Event }) => {
    const { getPatientById } = usePatientsApi();
    const { data: patient, isLoading: isLoadingPatient } = getPatientById(
      event.patientId
    );

    if (event.createdByPatient) return <></>;

    const shortEvent = isShort(event);

    return (
      <>
        {event.isBusyTime ? (
          <EventWrapper
            isShort={shortEvent}
            bgcolor={event.color}
            height="100%"
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography style={{ fontWeight: 700 }}>{t('BUSY')}</Typography>
          </EventWrapper>
        ) : (
          <EventWrapper
            isShort={shortEvent}
            bgcolor={event.color}
            height="100%"
            width="100%"
            id={`appointment-event-${event.id}`}
            onClick={() => {
              openDialog({
                header: ' ',
                fullWidth: true,
                maxWidth: 'sm',
                children: <AppointmentDetails appointmentId={event.id} />
              });
            }}
          >
            {!shortEvent && (
              <>
                <Typography style={{ fontWeight: 700 }}>
                  {dayjs(event.start).format(getTimeFormat({ isShort: true }))}
                </Typography>
                {!isLoadingPatient && (
                  <Typography style={{ fontWeight: 700 }}>
                    {getFullName(patient?.personalInfo)}
                  </Typography>
                )}
              </>
            )}
            <StyledAppointmentTypeTypography isShort={shortEvent}>
              {event.appointmentType}
            </StyledAppointmentTypeTypography>
          </EventWrapper>
        )}
      </>
    );
  };

  return (
    <BigCalendarWrapper dayView={dayView}>
      <BigCalendar
        components={{
          toolbar: (toolbar) =>
            CustomToolbar({
              toolbar,
              dayView,
              currDate: date,
              setDayView,
              smallHeader
            }),
          week: {
            header: dayHeader,
            event: eventRenderer
          },
          day: {
            event: eventRenderer
          }
        }}
        min={dayjs().startOf('day').hour(6).toDate()}
        max={dayjs().endOf('day').hour(21).toDate()}
        localizer={localizer}
        defaultView={dayView ? 'day' : 'week'}
        events={appointmentElements}
        onSelecting={() => false} // prevents dragging
        ref={calendarRef}
        date={date.toDate()}
        onNavigate={(date: Date) => onNavigate?.(dayjs(date))}
        selectable
        backgroundEvents={busyTimeElements}
      />
    </BigCalendarWrapper>
  );
};

export default Calendar;
