const enMeTranslations = {
  GET_ME_ERROR_TOAST_TITLE: 'Error fetching user details',
  TRY_AGAIN_LATER: 'Try again later',
  SEND_OTP_CODE_SUCCESS_TOAST_TITLE: 'OTP code was sent successfully',
  VERIFY_OTP_CODE_SUCCESS_TOAST_TITLE: 'OTP code was verified successfully',
  HAVE_A_PENDING_OTP_CODE_TOAST_TITLE:
    'Already have a pending OTP code in your email',
  REDIRECTING_TO_OTP_CODE_VERIFY: 'Redirecting to OTP Verify',
  YOU_ARE_NOT_AUTHORIZED_TO_SIGN_THIS_DOCUMENT:
    "Oops! Looks like you're not listed as licensed to prescribe. If this is in error, please reach out to your admin.",
  VERIFY_YOUR_ACCOUNT: 'Verify your account',
  VERIFY_YOUR_ACCOUNT_DESCRIPTION:
    'For compliance, you’ll need to verify your account with a one time password',
  SEND_CODE: 'Send code',
  ALREADY_HAVE_A_CODE: 'Already have a code? Click here',
  ENTER_TOP_CODE_DESCRIPTION:
    'Enter the code you received in your email. The code will expire after 10 minutes',
  DONT_HAVE_A_CODE: 'Need a new code? Click here'
};

export default enMeTranslations;
