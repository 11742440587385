import { FC, useEffect } from 'react';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  ExamReason,
  Observations,
  ExamReport,
  OtherExaminationTypes
} from 'src/types/exam';
import Loader from 'src/components/display/Loader/Loader';
import Box from 'src/components/layout/Box/Box';
import {
  iconSizes,
  spacings,
  spacingsPercentage
} from 'src/components/styles/constants';
import InputField from 'src/components/data-entry/InputField/InputField';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import Flex from 'src/components/layout/Flex/Flex';
import FormActionsContainer from 'src/components/display/FormActionsContainer/FormActionsContainer';
import Chips, { ChipsVariants } from 'src/components/data-entry/Chips/Chips';
import Button from 'src/components/display/Button/Button';
import ImageUpload, {
  ImageFile
} from 'src/components/data-entry/ImageUpload/ImageUpload';
import { getFullName } from 'src/utils/general';
import { DoctorChips } from '../common/DoctorChips';
import dayjs from 'dayjs';
import useGeneralApi from '../../../hooks/useGeneralApi';
import useExams from 'src/hooks/useExams';
import TextArea from 'src/components/data-entry/TextArea';
import { styled } from '@mui/material';

const StyledLoaderBox = styled(Box)`
  margin-top: ${spacings.xlarge};
`;

export const AddEditFollicularExamForm: FC<{
  patientId: string;
  cycleId?: string;
  examinationId?: string;
}> = ({ patientId, cycleId, examinationId }) => {
  const isEdit = !!examinationId;
  const { t } = useTranslation();
  const { getPatientById } = usePatientsApi();
  const { createExamReport, updateExamReport } = useExams();

  const { getExaminationById } = useGeneralApi();

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  const { data: examination, isLoading: isLoadingExam } = getExaminationById(
    examinationId,
    {
      enabled: isEdit
    }
  );

  const { mutate: createReport, isLoading: isCreatingReport } =
    createExamReport();
  const { mutate: updateReport, isLoading: isUpdatingReport } =
    updateExamReport();

  const defaultValues: ExamReport = {
    afcLeft: null,
    afcRight: null,
    dateOfExamination: dayjs().toDate(),
    endometrial: null,
    observations: [],
    images: [],
    leftFollicles: [],
    rightFollicles: [],
    notes: '',
    patientId,
    cycleId: cycleId,
    patientName: '',
    performedById: null,
    requestingPhysicianId: null,
    signedOffById: null,
    type: OtherExaminationTypes.FOLLICULAR_SCAN,
    reason: ExamReason.IN_CYCLE
  };

  const { control, formState, handleSubmit, setValue, reset, getValues } =
    useForm<ExamReport>({
      mode: 'onChange',
      defaultValues
    });

  useEffect(() => {
    if (examination) {
      reset({
        id: examination?.id,
        afcLeft: examination?.afcLeft || null,
        afcRight: examination?.afcRight || null,
        dateOfExamination: dayjs(examination?.examDate).toDate(),
        endometrial: examination?.endometrialThickness || null,
        observations: examination.findings?.map(({ name }) => name),
        images:
          examination?.images.map(
            ({ url, id }) => ({ url, id } as ImageFile)
          ) || [],
        leftFollicles: examination?.folliclesSizeLeft || [],
        rightFollicles: examination?.folliclesSizeRight || [],
        notes: examination?.notes || '',
        patientId,
        cycleId: examination?.cycleId || cycleId,
        patientName: '',
        performedById: examination?.performedBy,
        requestingPhysicianId: examination?.requestedPhysician,
        signedOffById: examination?.signedOffBy,
        type: OtherExaminationTypes.FOLLICULAR_SCAN,
        reason: examination?.reason || ExamReason.IN_CYCLE,
        imagesToDelete: []
      });
    }

    if (patient) {
      setValue('patientName', getFullName(patient?.personalInfo));
    }
  }, [patient, examination]);

  const { errors } = formState;

  const onSubmit = async (details: ExamReport) => {
    if (isEdit) {
      await updateReport(details);
    } else {
      await createReport(details);
    }
  };

  if (isLoadingPatient || isLoadingExam) {
    return (
      <StyledLoaderBox>
        <Loader size={iconSizes.large} />
      </StyledLoaderBox>
    );
  }

  const formActions = [
    <Button
      key="action-save"
      width={spacingsPercentage.large}
      disabled={isCreatingReport || isUpdatingReport}
      onClick={handleSubmit(onSubmit)}
    >
      {t('SAVE')}
    </Button>
  ];

  return (
    <Box marginTop={spacings.large}>
      <form noValidate>
        <Flex marginBottom={spacings.large} gap={spacings.large}>
          <Box flex={1}>
            <Box marginBottom={spacings.medium}>
              <Controller
                name="patientName"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    disabled
                    inputRef={ref}
                    label={t('PATIENT_NAME')}
                    placeholder={t('PATIENT_NAME')}
                    error={!!errors.patientName}
                    helperText={errors?.patientName?.message}
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box marginBottom={spacings.large}>
              <Controller
                name={'dateOfExamination'}
                control={control}
                rules={{
                  required: t('DATE_REQUIRED'),
                  validate: (value) => {
                    const date = dayjs(value);
                    if (date.isAfter(dayjs())) {
                      return t('DATE_CANT_BE_IN_THE_FUTURE');
                    }
                    return true;
                  }
                }}
                render={({ field: { ref, ...field } }) => (
                  <DatePicker
                    {...field}
                    label={t('DATE_OF_EXAMINATION')}
                    inputRef={ref}
                    error={!!errors?.dateOfExamination}
                    helperText={errors.dateOfExamination?.message}
                    fullWidth
                  />
                )}
              />
            </Box>
            <Flex gap={spacings.medium} marginBottom={spacings.medium}>
              <Controller
                name="afcLeft"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    label={t('AFC_LEFT')}
                    placeholder={t('AFC_LEFT')}
                    error={!!errors.afcLeft}
                    helperText={errors?.afcLeft?.message}
                    fullWidth
                  />
                )}
              />
              <Controller
                name="afcRight"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    label={t('AFC_RIGHT')}
                    placeholder={t('AFC_RIGHT')}
                    error={!!errors.afcRight}
                    helperText={errors?.afcRight?.message}
                    fullWidth
                  />
                )}
              />
              <Controller
                name="endometrial"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    label={t('ENDOMETRIUM')}
                    placeholder={t('ENDOMETRIUM')}
                    error={!!errors.endometrial}
                    helperText={errors?.endometrial?.message}
                    fullWidth
                  />
                )}
              />
            </Flex>
            <Flex gap={spacings.medium} marginBottom={spacings.large}>
              <Controller
                name="leftFollicles"
                rules={{
                  validate: (value) => {
                    if (value.some((number) => isNaN(number))) {
                      return t('MUST_MATCH_THIS_PATTERN');
                    }
                    return true;
                  }
                }}
                control={control}
                render={({ field: { ref, onChange, value, ...field } }) => (
                  <InputField
                    {...field}
                    value={value.join(',')}
                    onChange={(ev) => {
                      onChange(ev.target.value.split(','));
                    }}
                    inputRef={ref}
                    label={t('LEFT_FOLLICLES')}
                    placeholder={t('LEFT_FOLLICLES')}
                    error={!!errors?.leftFollicles}
                    helperText={errors?.leftFollicles?.message}
                    fullWidth
                  />
                )}
              />
              <Controller
                name="rightFollicles"
                control={control}
                rules={{
                  validate: (value) => {
                    if (value.some((number) => isNaN(number))) {
                      return t('MUST_MATCH_THIS_PATTERN');
                    }
                    return true;
                  }
                }}
                render={({ field: { ref, value, onChange, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    value={value.join(',')}
                    onChange={(ev) => {
                      onChange(ev.target.value.split(','));
                    }}
                    label={t('RIGHT_FOLLICLES')}
                    placeholder={t('RIGHT_FOLLICLES')}
                    error={!!errors.rightFollicles}
                    helperText={errors?.rightFollicles?.message}
                    fullWidth
                  />
                )}
              />
            </Flex>
            <Box flex={1}>
              <Controller
                name="notes"
                control={control}
                render={({ field: { ref: _ref, ...field } }) => (
                  <TextArea
                    {...field}
                    label={t('PROCEDURE_NOTES')}
                    placeholder={t('ENTER_COMMENT')}
                    error={!!errors?.notes}
                    helperText={errors?.notes?.message}
                    fullWidth
                    minRows={7}
                    resize
                  />
                )}
              />
            </Box>
          </Box>
          <Flex flexDirection="column" flex={1}>
            <Flex flex={1} gap={spacings.large} marginBottom={spacings.medium}>
              <Box flex={1}>
                <Controller
                  name={'requestingPhysicianId'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DoctorChips
                      id="add-edit-follicular-exam-form-requesting-physician"
                      showSelectedValue
                      label={t('REQUESTING_PROVIDER').toUpperCase()}
                      value={[value]}
                      onAddChip={(newSelectedDoctorId) =>
                        onChange(newSelectedDoctorId)
                      }
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name={'performedById'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DoctorChips
                      id="add-edit-follicular-exam-form-performed-by"
                      showSelectedValue
                      label={t('PERFORMED_BY').toUpperCase()}
                      value={[value]}
                      onAddChip={(newSelectedDoctorId) =>
                        onChange(newSelectedDoctorId)
                      }
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name={'signedOffById'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DoctorChips
                      id="add-edit-follicular-exam-form-signed-off-by"
                      showSelectedValue
                      label={t('SIGNED_OFF_BY').toUpperCase()}
                      value={[value]}
                      onAddChip={(newSelectedDoctorId) =>
                        onChange(newSelectedDoctorId)
                      }
                    />
                  )}
                />
              </Box>
            </Flex>
            <Box flex={2} marginBottom={spacings.xlarge}>
              <Controller
                name={'observations'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Chips
                    variant={ChipsVariants.EXPANDED}
                    value={value}
                    options={Object.values(Observations).map((value) => ({
                      label: t(value),
                      value,
                      labelText: t(value)
                    }))}
                    title={t('OBSERVATIONS')}
                    allowAddingNewOptions
                    onAddChip={(newChip) => onChange([...value, newChip])}
                    onRemoveChip={(chipValue) => {
                      const updatedFindings = value.filter(
                        (finding) => finding !== chipValue
                      );
                      onChange(updatedFindings);
                    }}
                    shouldSortOptions
                  />
                )}
              />
            </Box>
          </Flex>
        </Flex>
        <Controller
          name="images"
          control={control}
          render={({ field: { value, onChange } }) => (
            <ImageUpload
              value={value}
              onAddNewFiles={(newFiles) => {
                onChange([...value, ...newFiles]);
              }}
              onRemoveFile={(imageIndexToRemove) => {
                const existingImage = value[imageIndexToRemove];

                if (existingImage.id) {
                  const existingImagesToDelete = getValues('imagesToDelete');
                  setValue('imagesToDelete', [
                    ...existingImagesToDelete,
                    existingImage.id
                  ]);
                }
                onChange(
                  value.filter((_, index) => index !== imageIndexToRemove)
                );
              }}
            />
          )}
        />
        <FormActionsContainer actions={formActions} />
      </form>
    </Box>
  );
};
