import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from 'react-query';
import { useTranslation } from 'react-i18next';

import { useToast } from '../components/components-api/ToastProvider';
import { ToastType } from '../components/display/Toast/Toast';
import { AppError } from '../types/global';
import { InsuranceType, PatientInsurance } from '../types/patientInsurance';
import {
  createPatientInsuranceRequest,
  getInsurancesByPatientIdRequest,
  updatePatientInsuranceRequest,
  getInsuranceTypesRequest
} from '../api/patientsInsurances.api';
import { queryKeys, querySubKeys } from './queryKeys';

function usePatientsInsurancesApi() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { openToast, handleQueryResultToast } = useToast();

  return {
    getInsuranceTypes: (options?: UseQueryOptions<InsuranceType[], AppError>) =>
      useQuery<InsuranceType[], AppError>(
        [
          queryKeys.PATIENT_INSURANCES,
          querySubKeys[queryKeys.PATIENT_INSURANCES].INSURANCE_TYPES
        ],
        () => getInsuranceTypesRequest(),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_INSURANCE_TYPES')
            }),
          ...options
        }
      ),
    getPatientInsurances: (
      patientId: string,
      options?: UseQueryOptions<PatientInsurance[], AppError>
    ) =>
      useQuery<PatientInsurance[], AppError>(
        [queryKeys.PATIENT_INSURANCES, patientId],
        () => getInsurancesByPatientIdRequest(patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENT_INSURANCES')
            }),
          enabled: !!patientId,
          ...options
        }
      ),
    createPatientInsurance: () =>
      useMutation<string, AppError, PatientInsurance>(
        createPatientInsuranceRequest,
        {
          onSettled: (data, error) => {
            return handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_CREATE_PATIENT_INSURANCE')
            });
          },
          onSuccess: (patientId) => {
            queryClient.invalidateQueries([
              queryKeys.PATIENT_INSURANCES,
              patientId
            ]);
            openToast({
              title: t('CREATE_INSURANCE_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
          }
        }
      ),
    updatePatientInsurance: () =>
      useMutation<string, AppError, Partial<PatientInsurance>>(
        updatePatientInsuranceRequest,
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_PATIENT_INSURANCE')
            }),

          onSuccess: (_, { patientId }) => {
            queryClient.invalidateQueries([
              queryKeys.PATIENT_INSURANCES,
              patientId
            ]);
            queryClient.invalidateQueries([queryKeys.PATIENT_INSURANCES]);
            openToast({
              title: t('UPDATE_INSURANCE_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
          }
        }
      )
  };
}

export default usePatientsInsurancesApi;
