/* eslint-disable react/display-name */
import React from 'react';
import {
  Controller,
  FieldValues,
  Path,
  UseFormReturn,
  ControllerFieldState,
  ControllerRenderProps,
  UseFormStateReturn
} from 'react-hook-form';

import { SchemaFieldType, SchemaProps } from './types';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import InputField from '../InputField';
import Select from '../Select';
import DatePicker from '../DatePicker/DatePicker';
import Chips from '../Chips/Chips';

type ElementBuilderProps<TFormValues extends FieldValues> = ({
  field,
  fieldState,
  formState
}: {
  field: ControllerRenderProps<TFormValues>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<TFormValues>;
}) => React.ReactElement;

interface ElementParserProps<TFormValues extends FieldValues> {
  schema: SchemaProps<TFormValues>;
  elmRef: React.RefObject<HTMLDivElement>;
  methods: UseFormReturn<TFormValues>;
  shouldUnregister?: boolean;
  onSave?: () => void;
}

const ElementParser = <T extends FieldValues = Record<string, any>>({
  schema,
  methods,
  elmRef,
  shouldUnregister = false,
  onSave = () => {
    // default empty function
    // do nothing
  }
}: ElementParserProps<T>): React.ReactElement => {
  let elementBuilder: ElementBuilderProps<T>;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { containerSx, ...restProps } = schema.props || {};

  switch (schema.type) {
    case SchemaFieldType.input:
      elementBuilder = ({ field: { onChange, value }, fieldState }) => (
        <InputField
          onChange={onChange}
          value={value}
          onEnterPress={onSave}
          error={!!fieldState.error}
          helperText={
            fieldState.error &&
            schema.errorMessages &&
            schema.errorMessages[fieldState.error?.type]
          }
          {...restProps}
        />
      );

      break;
    case SchemaFieldType.number:
      elementBuilder = ({ field: { onChange, value }, fieldState }) => (
        <InputField
          onChange={onChange}
          type="number"
          value={value}
          onEnterPress={onSave}
          error={!!fieldState.error}
          helperText={
            fieldState.error &&
            schema.errorMessages &&
            schema.errorMessages[fieldState.error?.type]
          }
          {...restProps}
        />
      );

      break;
    case SchemaFieldType.select:
      elementBuilder = ({ field: { onChange, value }, fieldState }) => (
        <Select
          onChange={onChange}
          value={value}
          error={!!fieldState.error}
          helperText={
            fieldState.error &&
            schema.errorMessages &&
            schema.errorMessages[fieldState.error?.type]
          }
          {...restProps}
          options={schema.options}
        />
      );

      break;
    case SchemaFieldType.date:
      elementBuilder = ({ field: { onChange, value }, fieldState }) => (
        <DatePicker
          onChange={onChange}
          value={value}
          error={!!fieldState.error}
          helperText={
            fieldState.error &&
            schema.errorMessages &&
            schema.errorMessages[fieldState.error?.type]
          }
          {...restProps}
        />
      );
      break;
    case SchemaFieldType.chips:
      elementBuilder = ({ field: { onChange, value }, fieldState }) => (
        <Chips
          {...restProps}
          value={value}
          error={!!fieldState.error}
          onAddChip={(valueToAdd) => {
            onChange([...value, valueToAdd]);
          }}
          onRemoveChip={(valueToDelete: string) => {
            onChange(value.filter((chip: string) => valueToDelete !== chip));
          }}
        />
      );
      break;
  }

  return (
    <Controller<T>
      shouldUnregister={shouldUnregister}
      name={schema.key as Path<T>}
      rules={schema.rules}
      control={methods.control}
      defaultValue={schema.defaultValue ? schema.defaultValue : undefined}
      render={(props) => (
        <Box
          ref={elmRef}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            ...((schema?.props?.containerSx as SxProps<Theme>) || {})
          }}
        >
          {schema.label && (
            <Typography
              sx={{
                display: 'flex',
                gap: '6px',
                alignItems: 'center',
                width: 'fit-content'
              }}
            >
              {schema.label}
              {/* {schema.tooltip && <Tooltip arrow title={schema.tooltip}></Tooltip>} TODO: Add tooltip */}
            </Typography>
          )}
          {elementBuilder(props)}
        </Box>
      )}
    />
  );
};
export default ElementParser;
