import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridRowSpacingParams } from '@mui/x-data-grid-premium';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Grid from '@mui/material/Grid';
import Card from 'src/components/display/Card';
import Table from 'src/components/display/Table';
import Typography from 'src/components/display/Typography';
import IconButton from 'src/components/display/IconButton';
import { containerSizes, spacings } from 'src/components/styles/constants';
import Flex from 'src/components/layout/Flex';
import { useNavigate } from 'react-router-dom';
import { PatientPersonalInfo } from 'src/types/patient';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { Helmet } from 'react-helmet-async';
import { getFullName } from 'src/utils/general';
import { ReactComponent as CycleIcon } from 'src/assets/icons/cycle.svg';
import { ReactComponent as ChatIcon } from 'src/assets/icons/chat-message.svg';
import { UserTypes } from 'src/types/user';
import { PatientActionsMenu } from '../actionMenu/PatientActionsMenu';
import { usePopover } from 'src/components/components-api/PopoverProvider';
import ApplicationsMessenger from '../messenger';
import Button from 'src/components/display/Button';
import { ResendEmailForm } from './ResendEmailForm';
import { ChannelTypes } from 'src/types/inbox';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import { getPrimaryPhysicianColumnRenderCell } from '../utils/getPrimaryPhysicianColumnRenderCell';
import { StyledMessengerContainer } from '../patientRelatedInstances/PatientRelatedInstances';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';
import PatientAvatar from 'src/components/display/Avatar/PatientAvatar';

export const PatientsList: FunctionComponent = () => {
  const { t } = useTranslation();
  const { openPopover } = usePopover();
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const { getDefaultAvatar } = useGetDefaultAvatar();

  const [messengerPatientId, setMessengerPatientId] = useState<string>();

  const columns: GridColDef<PatientPersonalInfo>[] = [
    {
      field: 'name',
      headerName: t('PATIENT_LIST_NAME_COL'),
      flex: 2,
      align: 'left',
      valueGetter: (_, row?: PatientPersonalInfo) => ({
        name: getFullName(row),
        avatar: getDefaultAvatar({
          userId: row?.id,
          userType: UserTypes.patients,
          pictureId: row?.pictureId
        }),
        id: row?.id
      }),
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
      filterable: false,
      renderCell: ({ value, row }) => {
        return (
          <Flex
            flexDirection="row"
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            gap={spacings.large}
            onClick={() => navigate(`${value.id}`)}
          >
            <PatientAvatar image={value.avatar} patientId={row?.id} />
            <Typography>{`${value.name}`}</Typography>
          </Flex>
        );
      }
    },
    {
      field: 'actions',
      headerName: '',
      flex: 2,
      align: 'right',
      filterable: false,
      valueGetter: (_, row) => row.id,
      renderCell: (params) => (
        <Flex
          alignSelf="flex-center"
          justifyContent="flex-end"
          gap={spacings.large}
        >
          <IconButton
            icon={<CycleIcon />}
            disabled={!params.row.currentCycleId}
            bgColor="gray"
            onClick={() =>
              navigate(
                `${params.row.id}/current-treatment/${params.row.currentCycleId}`
              )
            }
          />
          <IconButton
            icon={<ChatIcon />}
            bgColor="cupid"
            onClick={() => setMessengerPatientId(params.row.id)}
          />

          <IconButton
            icon={<AddRoundedIcon />}
            onClick={(ev) => {
              openPopover({
                children: <PatientActionsMenu patientId={params.row.id} />,
                anchorEl: ev.currentTarget,
                hideToolbar: true
              });
            }}
          />
        </Flex>
      )
    },
    {
      field: 'primaryPhysician',
      headerName: t('PHYSICIAN').toUpperCase(),
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      valueGetter: (_, row) => getFullName(row?.staff),
      sortComparator: (v1, v2) => v1.localeCompare(v2),
      renderCell: ({ row }) => getPrimaryPhysicianColumnRenderCell({ row })
    }
  ];

  const { getPatients } = usePatientsApi();
  const { data: patients, isLoading } = getPatients();

  // getRowSpacing - The purpose of this function is to calculate the spacing for the top and bottom of each row in the table.
  // If the current row is the first visible row, the top spacing will be 0 ( The columns are empty and they will space the top),
  // otherwise it will be 5. If the current row is the last visible row, the bottom spacing will be 2, otherwise it will be 5.

  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 2 : 5
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('PATIENT_LIST_PAGE_TITLE')}</title>
      </Helmet>
      <Grid
        container
        maxWidth={containerSizes.boxed}
        direction="column"
        justifyContent="start"
        alignItems="stretch"
        spacing={spacings.xxlarge}
      >
        <Grid
          item
          display="flex"
          alignItems="center"
          marginLeft={spacings.xlarge}
          gap={spacings.xlarge}
        />

        <Grid item xs={10} width="100%" marginBottom={spacings.xxlarge}>
          <Flex
            marginBottom={spacings.xlarge}
            justifyContent="space-between"
            alignItems={'center'}
          >
            <Typography variant="h1">{t('PATIENT_LIST_HEADER')}</Typography>
            <Button
              onClick={() =>
                openDialog({
                  children: <ResendEmailForm />,
                  fullWidth: true,
                  maxWidth: 'xs'
                })
              }
            >
              {t('RESEND_WELCOME_EMAIL')}
            </Button>
          </Flex>
          <Card height="100%" maxWidth="1050px" padding={spacings.large}>
            <Table
              variant="carded"
              hideFilterToolbar
              getRowSpacing={getRowSpacing}
              paginationModel={{ pageSize: 25, page: 0 }}
              loading={isLoading}
              columns={columns}
              rows={patients || []}
              autoHeight={true}
            />
          </Card>
        </Grid>
      </Grid>
      {messengerPatientId && (
        <StyledMessengerContainer>
          <ApplicationsMessenger
            showExpandButton
            chatType={ChannelTypes.Patient}
            patientId={messengerPatientId}
            onClose={() => setMessengerPatientId('')}
          />
        </StyledMessengerContainer>
      )}
    </>
  );
};
