import Card from 'src/components/display/Card';
import { Cycle, TreatmentTypes } from 'src/types/cycle';
import { GameteThawTable } from '../../../gameteThawTable/GameteThawTable';
import { useParams } from 'react-router-dom';
import EmbryologyTable from './EmbryologyTable';
import { spacings } from 'src/components/styles/constants';
import { shouldShowDevelopmentTable } from './utils';

interface Props {
  cycle: Cycle;
}
const LabView = ({ cycle }: Props) => {
  const { patientId, cycleId } = useParams();

  const showDevelopmentTable = shouldShowDevelopmentTable(cycle.treatmentType);
  return (
    <>
      <EmbryologyTable cycleToDisplay={cycle} patientId={patientId} />
      {showDevelopmentTable && cycle.treatmentType === TreatmentTypes.FET && (
        <Card flex={5} overflow={'hidden'} marginTop={spacings.large}>
          <GameteThawTable patientId={patientId} cycleId={cycleId} />
        </Card>
      )}
    </>
  );
};

export default LabView;
