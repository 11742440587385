import { FC, useEffect, useMemo, useState } from 'react';
import Calendar from 'react-calendar';
import dayjs, { Dayjs } from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { CalendarProps as ReactCalendarProps } from 'react-calendar';
import { Box, styled } from '@mui/material';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import 'react-calendar/dist/Calendar.css';

import { Colors } from '../../styles/colors';
import { radii, spacings, shadows } from '../../styles/constants';
import { fonts } from '../../styles/fonts';
import { dashSeparatedDateFormat } from '../../../utils/dateAndTIme';

dayjs.extend(weekOfYear);

const MiniCalendarWrapper = styled(Box)`
  .react-calendar {
    border-radius: ${radii.large};
    width: 100%;
    background-color: ${Colors.white};
    box-shadow: ${shadows.default};
    padding-top: ${spacings.small};
    padding-bottom: ${spacings.large};
    border: none;
  }

  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    border-radius: ${radii.medium};
    font: ${fonts.header4};
    color: ${Colors.emperor};

    &:hover {
      background-color: ${Colors.cupidAlpha25};
    }
    &.react-calendar__tile--now {
      background-color: ${Colors.cupidAlpha50};
    }
    &.react-calendar__tile--hasActive {
      background-color: ${Colors.cupid};
    }
  }

  .react-calendar__navigation {
    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__next-button {
      max-width: 40px;
      min-width: 40px;
      max-height: 40px;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      display: flex;
    }
    margin: 13px ${spacings.xxlarge};
    .react-calendar__navigation__label {
      border-radius: ${radii.full};
      margin: 0 ${spacings.small};
      span {
        font: ${fonts.scheduleTitle};
        color: ${Colors.silverChalice};
        text-decoration: none solid rgb(170, 170, 170);
        text-transform: uppercase;
      }
    }
    .react-calendar__navigation__arrow {
      border-radius: ${radii.full};
      background-color: ${Colors.cupid};
      &:hover,
      &:focus {
        background-color: ${Colors.cupid};
      }
    }
  }

  .react-calendar__viewContainer {
    margin: 0 ${spacings.large};
    .react-calendar__month-view {
      button {
        max-width: 40px;
        max-height: 40px;
        margin: 0;
        border-radius: ${radii.full};
        align-items: center;
        box-sizing: border-box;
      }
      abbr {
        font: ${fonts.calendarContent};
        color: ${Colors.emperor};
        text-decoration: none solid rgb(84, 84, 84);
      }
      .react-calendar__month-view__weekdays,
      .react-calendar__month-view__days {
        justify-content: center;
        padding: 0 1px;
        box-sizing: border-box;
      }
      .react-calendar__month-view__weekdays__weekday {
        padding: 0;
        max-width: 40px;
        margin: 0;
        abbr {
          font-weight: bold;
          color: ${Colors.black};
        }
      }
      .react-calendar__tile {
        user-select: none;
        &:hover {
          color: ${Colors.silverChalice};
        }

        &.hidden {
          display: none;
        }
      }

      .react-calendar__tile--active {
        background-color: ${Colors.cupid};
      }
      .react-calendar__tile--now {
        background-color: ${Colors.cupid};
        opacity: 0.6;
      }

      .react-calendar__month-view__days__day--neighboringMonth {
        abbr {
          color: ${Colors.silverChalice};
          text-decoration: none solid rgb(170, 170, 170);
        }
      }
    }
  }
`;

interface MiniCalendarProps
  extends Partial<Omit<ReactCalendarProps, 'view' | 'onChange' | 'value'>> {
  onChange?: (date: Dayjs) => void;
  view?: 'month' | 'week';
  value?: Dayjs;
  fullWidth?: boolean;
}

const MiniCalendar: FC<MiniCalendarProps> = ({
  onChange,
  view = 'month',
  value,
  fullWidth = false,
  ...props
}) => {
  const [isWeekView, setIsWeekView] = useState(view === 'week');
  const [date, setDate] = useState<Dayjs>(value || dayjs());

  const activeStartDate = useMemo(() => {
    const startDate = dayjs(date).date(1);
    return startDate.toDate();
  }, [date]);

  useEffect(() => {
    setDate(value);
  }, [value]);

  const handleDateChange = (date: Date) => {
    setDate(dayjs(date));
    onChange?.(dayjs(date));
  };
  return (
    <Box>
      <MiniCalendarWrapper
        width={fullWidth ? '100%' : '350px'}
        maxHeight={isWeekView ? '150px' : '400px'}
      >
        <Calendar
          value={date?.toDate()}
          onChange={handleDateChange}
          nextLabel={<ArrowForwardTwoToneIcon />}
          prevLabel={<ArrowBackTwoToneIcon />}
          onActiveStartDateChange={({ action }) => {
            if (isWeekView) {
              if (action === 'next') {
                setDate(dayjs(date).add(1, 'week'));
              } else if (action === 'prev') {
                setDate(dayjs(date).subtract(1, 'week'));
              }
            }
          }}
          onDrillUp={() => {
            if (view === 'week') {
              setIsWeekView(false);
              return;
            }
          }}
          activeStartDate={isWeekView ? activeStartDate : null}
          onClickMonth={(date) => {
            handleDateChange(date);
            if (view === 'week') setIsWeekView(true);
          }}
          next2Label={null}
          prev2Label={null}
          tileClassName={({ date: currDate }) => {
            let className = `tile-date-${dayjs(currDate).format(
              dashSeparatedDateFormat
            )}`;
            if (!isWeekView) return className;

            const isChosenWeek = dayjs(currDate).week() === dayjs(date).week();
            if (!isChosenWeek) {
              className += ' hidden';
            }
            return className;
          }}
          formatShortWeekday={(locale, date) =>
            date.toLocaleString(locale, { weekday: 'narrow' })
          }
          calendarType="US"
          {...props}
        />
      </MiniCalendarWrapper>
    </Box>
  );
};

export default MiniCalendar;
