import { FC, useState, createContext, Dispatch, SetStateAction } from 'react';
import { InboxItem, InboxTabs } from 'src/types/inbox';

type SidebarContextValue = {
  isSidebarShown: boolean;
  setIsSidebarShown: Dispatch<SetStateAction<boolean>>;
  isDrawerOpen: boolean;
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  isMessengerOpen: boolean;
  setIsMessengerOpen: Dispatch<SetStateAction<boolean>>;
  isTelehealthOpen: boolean;
  setIsTelehealthOpen: Dispatch<SetStateAction<boolean>>;
  selectedInboxItem: InboxItem;
  setSelectedInboxItem: Dispatch<SetStateAction<InboxItem>>;
  selectedInboxTab: InboxTabs;
  setSelectedInboxTab: Dispatch<SetStateAction<InboxTabs>>;
};

export const SidebarContext = createContext<SidebarContextValue>(
  {} as SidebarContextValue
);

export const SidebarProvider: FC = ({ children }) => {
  const [isSidebarShown, setIsSidebarShown] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // messenger & telehealth
  const [isMessengerOpen, setIsMessengerOpen] = useState(false);
  const [isTelehealthOpen, setIsTelehealthOpen] = useState(false);

  // inbox state
  const [selectedInboxItem, setSelectedInboxItem] = useState<InboxItem>();
  const [selectedInboxTab, setSelectedInboxTab] = useState<InboxTabs>();

  return (
    <SidebarContext.Provider
      value={{
        isSidebarShown,
        setIsSidebarShown,
        isDrawerOpen,
        setIsDrawerOpen,
        isMessengerOpen,
        setIsMessengerOpen,
        isTelehealthOpen,
        setIsTelehealthOpen,
        selectedInboxItem,
        setSelectedInboxItem,
        selectedInboxTab,
        setSelectedInboxTab
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
