import { Dayjs } from 'dayjs';
import { Task } from './task';

enum MessageParticipant {
  Staff = 'staff',
  Patient = 'patient'
}

export enum InboxTabs {
  PatientInbox = 'patientInbox',
  InternalInbox = 'internalInbox',
  Tasks = 'tasks'
}

export interface MessageServer {
  id: string;
  text: string;
  patientId: string;
  staffId: string;
  seenAt?: Date;
  readAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  channelId?: string;
  senderPatientId?: string;
  sender?: MessageParticipant;
  senderStaffId?: string;
  receiverPatientId?: string;
  receiverStaffId?: string;
  receiverStaffName?: string;
  senderStaffName?: string;
}

export interface Message {
  id: string;
  text: string;
  patientId: string;
  staffId: string;
  seenAt?: Dayjs;
  readAt?: Dayjs;
  createdAt?: Dayjs;
  updatedAt?: Dayjs;
  channelId?: string;
  senderPatientId?: string;
  sender?: MessageParticipant;
  senderStaffId?: string;
  receiverPatientId?: string;
  receiverStaffId?: string;
  receiverStaffName?: string;
  senderStaffName?: string;
}

export interface MessageRecent extends Message {
  patientName: string;
}

export enum InboxTypes {
  Message = 'Message',
  Task = 'Task'
}

type InboxMessage = {
  type: InboxTypes.Message;
  channelId: string;
  patientId?: string;
};

type InboxTask = {
  type: InboxTypes.Task;
  patientId?: string;
};

export type InboxItem = InboxMessage | InboxTask;

export type InboxData = {
  [value: string]: {
    label: string;
    value: number;
    data: MessageRecent[] | Task[];
    handleClickItem: (item: MessageRecent | Task) => void;
    type: InboxTypes;
    shouldDisplayAlertIcon?: boolean;
  };
};

export enum ChannelTypes {
  Internal = 'Internal',
  Patient = 'Patient'
}

export interface ChannelServer {
  id: string;
  type: ChannelTypes;
  patientId: string;
}

export interface Channel {
  id: string;
  type: ChannelTypes;
  patientId: string;
}
