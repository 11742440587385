import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from 'react-error-boundary';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import {
  ConfigCatProvider,
  createConsoleLogger,
  LogLevel
} from 'configcat-react';

import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license';
import { SnackbarProvider } from 'notistack';

import 'nprogress/nprogress.css';
import 'src/assets/styles/index.css';

import App from './App';
import * as serviceWorker from 'src/serviceWorker';
import './utils/registerCountries';

import { SidebarProvider } from './contexts/SidebarContext';
import SessionTerminationProvider from './contexts/SessionTerminationProvider';
import ThemeProvider from './theme/ThemeProvider';
import { ErrorFallback } from './components/errorBoundary';
import GlobalProvider from './components/components-api/GlobalProvider/GlobalProvider';
import ToastProvider from './components/components-api/ToastProvider';
import PopoverProvider from './components/components-api/PopoverProvider';
import CycleWizardProvider from './components/components-api/CycleWizardProvider';
import { CriticalAppErrorContextProvider } from './contexts/CriticalAppErrorContext';
import { DEFAULT_CACHE_STALE_TIME } from './utils/general';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);

dayjs.extend(isSameOrBefore);
dayjs.extend(timezone);

const logger = createConsoleLogger(LogLevel.Info);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryOnMount: false,
      retry: false,
      staleTime: DEFAULT_CACHE_STALE_TIME,
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  }
});

ReactDOM.render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <ConfigCatProvider
          sdkKey={process.env.REACT_APP_CONFIGCAT_KEY}
          options={{ logger }}
        >
          <BrowserRouter>
            <ThemeProvider>
              <CriticalAppErrorContextProvider>
                <CssBaseline />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <ToastProvider>
                    <GlobalProvider>
                      <CycleWizardProvider>
                        <SidebarProvider>
                          <SnackbarProvider
                            maxSnack={6}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right'
                            }}
                          >
                            <PopoverProvider>
                              <SessionTerminationProvider>
                                <App />
                              </SessionTerminationProvider>
                            </PopoverProvider>
                          </SnackbarProvider>
                        </SidebarProvider>
                      </CycleWizardProvider>
                    </GlobalProvider>
                  </ToastProvider>
                </LocalizationProvider>
              </CriticalAppErrorContextProvider>
            </ThemeProvider>
          </BrowserRouter>
        </ConfigCatProvider>
      </HelmetProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);

serviceWorker.unregister();
