import { FC } from 'react';
import {
  styled,
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
  TypographyVariant,
  css
} from '@mui/material';
import { fonts } from 'src/components/styles/fonts';
import { Colors } from 'src/components/styles/colors';
import Linkify from 'linkify-react';

// eslint-disable-next-line
type FontType = (typeof fonts)[keyof typeof fonts] | '';
type TypographyVariantType = TypographyVariant | 'inherit' | 'label';

export interface TypographyProps
  extends MuiTypographyProps<
    React.ElementType,
    { component?: React.ElementType }
  > {
  muted?: boolean;
  displayLinks?: boolean;
}

const getFontStyle = (variant: TypographyVariantType): FontType => {
  switch (variant) {
    case 'h1':
      return fonts.header1;
    case 'h2':
      return fonts.header2;
    case 'h3':
      return fonts.header3;
    case 'h4':
      return fonts.header4;
    case 'caption':
      return fonts.caption;
    case 'label':
      return fonts.label;
    case 'body1':
    case 'body2':
    default:
      return fonts.body;
  }
};

const StyledTypography = styled(MuiTypography)<TypographyProps>`
  ${({ variant }) => css`
    font: ${getFontStyle(variant)};
  `}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
`;

const Typography: FC<TypographyProps> = ({
  variant = 'body1',
  component = 'span',
  children,
  color = Colors.emperor,
  muted: _muted,
  displayLinks,
  ...otherProps
}) => {
  return (
    <StyledTypography
      variant={variant}
      component={component}
      color={color}
      {...otherProps}
    >
      {displayLinks ? <Linkify>{children}</Linkify> : children}
    </StyledTypography>
  );
};

export default Typography;
