import { Outlet, RouteObject } from 'react-router-dom';
import Authenticated from 'src/components/layout/Authenticated';
import BaseLayout from 'src/layouts/BaseLayout';
import CollapsedSidebarLayout from 'src/layouts/CollapsedSidebarLayout';
import { ConfirmEmailReceived } from 'src/modules/patients/confirmationEmail/ConfirmEmailReceived';
import EditPatient from 'src/modules/patients/editPatient/EditPatient';
import { Empty } from 'src/content/pages/Empty/Empty';
import { KPIDashboard } from 'src/modules/patients/kpi/KPIDashboard';
import { Inbox } from 'src/modules/patients/inbox/Inbox';
import { PatientOverview } from 'src/modules/patients/overview/PatientOverview';
import { PatientsList } from '../modules/patients/patientsList/PatientsList';
import Calendar from 'src/modules/calendar/Calendar';
import { TreatmentPage } from 'src/modules/patients/treatment/TreatmentPage';
import { PatientChecklists } from 'src/modules/patients/checklists/PatientChecklists';
import { PatientMedicalHistory } from 'src/modules/patients/medicalHistory/PatientMedicalHistory';
import { PatientButtonsSideBar } from 'src/modules/patients/sideBar/PatientButtonsSideBar';
import { Dashboard } from 'src/modules/patients/dashboard/Dashboard';
import accountRoutes from './account';
import baseRoutes from './base';
import Messenger from 'src/modules/patients/messenger';
import { PatientDetails } from 'src/modules/patients/details/PatientDetails';
import { DocumentEditor } from 'src/modules/documents/DocumentEditor';
import {
  ACCOUNT,
  ADD,
  PERMISSIONS,
  APP,
  BILLING,
  CALENDAR,
  CHECK_LISTS,
  CONFIRM_EMAIL_RECEIVED,
  CURRENT_TREATMENT,
  CYCLE_ID,
  DASHBOARD,
  DETAILS,
  DOCUMENTS,
  EDIT,
  FEED,
  FORMS,
  INBOX,
  INVOICE_ID,
  KPIS,
  MEDICAL_HISTORY,
  MESSAGING,
  PATIENTS,
  PATIENT_ID,
  STATISTICS,
  TEMPLATES_EDITOR,
  CLINIC_FEED,
  CLINIC_SETTINGS,
  DATA_EXPORT,
  STAFF,
  TAB,
  STAFF_ID
} from './routes';
import AddEditStaffMemberForm from 'src/modules/staff/AddEditStaffMemberForm';
import StaffList from 'src/modules/staff/staffList/StaffList';
import { PatientForms } from 'src/modules/patients/forms/PatientForms';
import { TemplateEditor } from 'src/modules/documents/TemplateEditor';
import { BillingDashboard } from '../modules/billing/BillingDashboard';
import { PatientBilling } from '../modules/billing/PatientBilling';
import { AddEditInvoice } from '../modules/billing/AddEditInvoice';
import PatientRelatedInstances from 'src/modules/patients/patientRelatedInstances/PatientRelatedInstances';
import { PatientFeed } from '../modules/feed/PatientFeed';
import { ChannelTypes } from 'src/types/inbox';
import PermissionsPage from 'src/modules/staff/staffList/PermissionsPage';
import { ClinicFeed } from 'src/modules/feed/ClinicFeed';
import ClinicSettings from 'src/modules/clinic/ClinicSettings';
import DataExportTab from 'src/modules/clinic/DataExport/DataExportTab';

const router: RouteObject[] = [
  {
    path: ACCOUNT,
    children: accountRoutes
  },
  {
    path: '',
    element: <BaseLayout />,
    children: baseRoutes
  },
  {
    path: APP,
    element: (
      <Authenticated>
        <CollapsedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: DASHBOARD,
        element: <Dashboard />
      },
      {
        path: CALENDAR,
        element: <Calendar />
      },

      {
        path: INBOX,
        element: <Inbox />
      },
      {
        path: KPIS,
        element: <KPIDashboard />
      },
      {
        path: BILLING,
        element: <BillingDashboard />
      },
      {
        path: `${CLINIC_SETTINGS}/*`,
        element: <ClinicSettings />,
        children: [
          {
            path: TAB,
            element: <ClinicSettings />,
            children: [
              {
                path: STAFF,
                children: [
                  {
                    index: true,
                    element: <StaffList />
                  },
                  {
                    path: ADD,
                    element: <AddEditStaffMemberForm />
                  },
                  {
                    path: PERMISSIONS,
                    element: <PermissionsPage />
                  },
                  {
                    path: STAFF_ID,
                    children: [
                      {
                        path: EDIT,
                        element: <AddEditStaffMemberForm />
                      }
                    ]
                  }
                ]
              },
              {
                path: DATA_EXPORT,
                element: <DataExportTab />
              }
            ]
          }
        ]
      },
      {
        path: PATIENTS,
        children: [
          {
            index: true,
            element: <PatientsList />
          },
          {
            path: ADD,
            element: <EditPatient />
          },
          {
            path: PATIENT_ID,
            element: (
              <>
                <PatientButtonsSideBar
                  sx={{
                    display: { lg: 'inline-block', xs: 'none' },
                    position: { lg: 'fixed', xs: 'relative' }
                  }}
                />
                <PatientRelatedInstances />
                <Outlet />
              </>
            ),
            children: [
              {
                index: true,
                element: <PatientOverview />
              },
              {
                path: EDIT,
                element: <EditPatient />
              },
              {
                path: CONFIRM_EMAIL_RECEIVED,
                element: <ConfirmEmailReceived />
              },
              {
                path: CURRENT_TREATMENT,
                element: <TreatmentPage />
              },
              {
                path: `${CURRENT_TREATMENT}/${CYCLE_ID}`,
                element: <TreatmentPage />
              },
              {
                path: CHECK_LISTS,
                element: <PatientChecklists />
              },
              {
                path: MEDICAL_HISTORY,
                element: <PatientMedicalHistory />
              },
              {
                path: MESSAGING,
                element: <Messenger chatType={ChannelTypes.Patient} />
              },
              {
                path: DETAILS,
                element: <PatientDetails />
              },
              {
                path: DOCUMENTS,
                element: <DocumentEditor />
              },
              {
                path: FORMS,
                element: <PatientForms />
              },
              {
                path: FEED,
                element: <PatientFeed />
              },
              {
                path: BILLING,
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: <PatientBilling />
                  },
                  {
                    path: ADD,
                    element: <AddEditInvoice />
                  },
                  {
                    path: INVOICE_ID,
                    element: <AddEditInvoice />
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: STATISTICS,
        element: <Empty />
      },
      {
        path: TEMPLATES_EDITOR,
        element: <TemplateEditor />
      },
      {
        path: CLINIC_FEED,
        element: <ClinicFeed />
      }
    ]
  }
];

export default router;
